import style from "./QuoteCreate.module.css";
import {useCallback, useEffect, useState, useMemo} from "react";
import {connect, useSelector} from "react-redux";
import MPLSAggTailHelper from "../../services/MPLSAggTailService";
import PremAddress from "./PremAddress";
import Term from "./Term";
import VendorExclusion from "./VendorExclusion";
import {
	setQuoteAddBW1,
	setQuoteAddBW2,
	setQuoteBW,
	setQuoteCPEType,
	setQuoteInternetRouter,
	setQuotePopLocation,
	setQuoteQty,
} from '../../redux/actions/quoteActions';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const MPLSAggTail = (props) => {
	const logger = useMemo(() => new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,}),[]);
	const [hasError, setHasError] = useState(null);
	const [aggTailBWOptions, setAggTailBWOptions] = useState([])
	const [bandwidthAdded, setBandwidthAdded] = useState(false);
	const [bandwidthCount, setBandwidthCount] = useState(0);
	const selectedMPLSAggTailValues = useSelector(state => state.quote.quoteValues);
	const selectedLecType = useSelector(state => state.quoteLocA.locA.a_lec_type);

	useEffect(() => {
		if(props.stepCode === 3){
			if(selectedMPLSAggTailValues.bw1 !== ""){
				setBandwidthCount(1);
			}
			if(selectedMPLSAggTailValues.bw2 !== ""){
				setBandwidthCount(2);
			}
		}
	},[props.stepCode, selectedMPLSAggTailValues.bw1, selectedMPLSAggTailValues.bw2])

	const getMPLSAggTailBW = useCallback(() => {
		if(props.stepCode === 3 && props.section === "bandwidth"){
			MPLSAggTailHelper.getAggTailBW().then(resp => {
				logger.info(resp.data);
				setAggTailBWOptions(resp.data);
			}).catch(error => {
				logger.error("Getting MPLS AGG TAIL Bandwidths: " + error);
				setHasError(error);
			})
		}
	},[logger, props.section, props.stepCode])

	useEffect(() => {
		getMPLSAggTailBW();
	}, [getMPLSAggTailBW])

	const addBandwidth = () => {
		setBandwidthAdded(!bandwidthAdded);
		setBandwidthCount(bandwidthCount < 3 ? bandwidthCount + 1 : bandwidthCount)
	}

	const removeBandwidth = async (ev) => {
		let counter = bandwidthCount - 1;
		setBandwidthCount(bandwidthCount - 1);
		if (counter === 0) {
			props.updateMPLSAggTailAddBw1("");
		}
		if (counter === 1) {
			props.updateMPLSAggTailAddBw2("")
		}
	}

	const inputChange = (ev) => {
		switch (ev.target.id) {
			case "bw":
				props.updateMPLSAggTailBw(ev.target.value);
				break;
			case "bw1":
				props.updateMPLSAggTailAddBw1(ev.target.value);
				break;
			case "bw2":
				props.updateMPLSAggTailAddBw2(ev.target.value);
				break;
			case "qty":
				props.updateMPLSAggTailQty(ev.target.value);
				break;
			case "cpe_type":
				props.updateMPLSAggTailCPEType(ev.target.value);
				if (ev.target.value === "2") {
					props.updateMPLSAggTailInternetRouter("0");
					document.getElementById("router_service").disabled = true;
				} else {
					document.getElementById("router_service").disabled = false;
					props.updateMPLSAggTailInternetRouter("1");
				}
				break;
			case "router_service":
				props.updateMPLSAggTailInternetRouter(ev.target.value);
				break;
			default:
				break;
		}
	}

	return (
		<>
		{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{!hasError && (<>{props.stepCode === 2 ?
				<PremAddress></PremAddress> : null
			}
			{(props.stepCode === 3) && (props.section === "term") ?
				<Term />
				: null}

			{(props.stepCode === 3) && (props.section === "bandwidth") ?
				<>
					<div className={style.bandwidthWrap}>
						<div className={`row ${style.rowGrp} `}>
							<div className="col-6 col-sm-4 col-md-3">
								<label className={style.labelSelectRequiredFld}>Bandwidth <span
									className={style.mandatory}>*</span></label>
								<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw"
									onChange={inputChange} value={selectedMPLSAggTailValues.bw}>
									{aggTailBWOptions.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}
								</select>
							</div>


							<div className="col-2 col-sm-2 col-md-2 align-self-center clearfix">
								<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `} onClick={addBandwidth}>
									<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
								</div>
							</div>
						</div>
					</div>
					{bandwidthCount >= 1 ?
						<div className={style.bandwidthWrap}>
							<div className={`row ${style.rowGrp} `}>
								<div className="col-6 col-sm-4 col-md-3">
									<label className={style.labelSelectRequiredFld}>Additional Bandwidth</label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw1"
										onChange={inputChange} value={selectedMPLSAggTailValues.bw1}>
										{aggTailBWOptions.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>
								</div>
								<div className={`col-2 col-sm-4 col-md-4 align-self-center clearfix ${style.svgWarp} `}>
									<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>
										<svg className="ico-plus" onClick={addBandwidth}><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
										<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
											<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
      C8.27,265.1,8.27,243.76,8.27,222.43z"/>
										</svg>
									</div>
								</div> </div> </div> : null}
					{bandwidthCount >= 2 ?
						<div className={style.bandwidthWrap}>
							<div className={`row ${style.rowGrp} `}>
								<div className="col-6 col-sm-4 col-md-3">
									<label className={style.labelSelectRequiredFld}>Additional Bandwidth</label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw2"
										onChange={inputChange} value={selectedMPLSAggTailValues.bw2}>
										{aggTailBWOptions.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>
								</div>
								<div className={`col-2 col-sm-4 col-md-4 align-self-center clearfix ${style.svgWarp} `}>
									<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>

										<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
											<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
C8.27,265.1,8.27,243.76,8.27,222.43z"/>
										</svg>
									</div>
								</div> </div> </div> : null}
				</> : null}

			{(props.stepCode === 3) && (props.section === "productConfig") ?
				<>
					<div className="col-12 col-sm-4 col-md-3 mb-5">
						<label className={style.labelSelectRequiredFld}>Quantity <span className={style.mandatory}>*</span></label>
						<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='qty' onChange={inputChange}>
							<option disabled>-</option>
							<option value="1">1</option>
						</select>
					</div>
					{/*ADO-852308 ILEC doesn't allow for the selection of CPE Provider or route service
					{selectedLecType !== "ILEC" ?
						<>
							<div className="col-5 col-sm-4 col-md-3 rel mb-5">
								<label className={style.labelSelectRequiredFld}> CPE Provider <span className={style.mandatory}>*</span></label>
								<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='cpe_type' onChange={inputChange} value={selectedMPLSAggTailValues.cpe_type}>
									<option value="" disabled>-</option>
									<option value="1">Windstream Supplied CPE</option>
									<option value="2">Customer Supplied CPE</option>
								</select>
							</div>
							<div className="col-5 col-sm-4 col-md-3 rel mb-5">
								<label className={style.labelSelectRequiredFld}> Selected Internet Router Service <span className={style.mandatory}>*</span></label>
								<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='router_service' onChange={inputChange} value={selectedMPLSAggTailValues.router_service}>

									{selectedMPLSAggTailValues.cpe_type === "2" ?
										<option value="0">N/A</option> :
										<>
											<option value="">-</option>
											<option value="0">N/A</option>
											<option value="1">Maintained Router</option>
										</>
									}
								</select>
							</div>
						</> : null
					}*/}
				</> : null}
			{(props.stepCode === 3) && (props.section === "Options") ?
				<VendorExclusion />
				: null}</>)}
		</>
	)


}


const mapDispatchToProps = {
	updateMPLSAggTailLocation: setQuotePopLocation,
	updateMPLSAggTailBw: setQuoteBW,
	updateMPLSAggTailAddBw1: setQuoteAddBW1,
	updateMPLSAggTailAddBw2: setQuoteAddBW2,
	updateMPLSAggTailQty: setQuoteQty,
	updateMPLSAggTailCPEType: setQuoteCPEType,
	updateMPLSAggTailInternetRouter: setQuoteInternetRouter,
}

export default connect(null, mapDispatchToProps)(MPLSAggTail);
