import Select from "react-select";
import style from './RouteCreator.module.css';

const RouteCreatorModal = (props) => {

    return (
        <>
        <div className={`${style.overlay}`}></div>
        <div className="modal fade show d-block">
            <div className={` modal-dialog modal-dialog-centered ${props.popupAction === "ROUTE DIVERSITY" ? "modal-lg" : "modal-md"}`}>
                <div className="modal-content">
                    {props.popupAction === "ROUTE DIVERSITY" ?
                        <div class="modal-header">
                            <h5 className="modal-title">Route Diversity</h5>
                        </div> : null
                    }
                    <div className={`modal-body ${props.popupAction === "ROUTE DIVERSITY" || props.popupAction === "NO DATA" ? "" : "text-center"}`}>
                        {props.popupAction === "ROUTE DIVERSITY" ?
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <span>Please choose any route(s) you would prefer to {props.routeDiversitySelected === "exclusion" ? "exclude" : "include"} in your search for a wave route.</span>
                                    </div>
                                </div>
                                {props.routeDiversitySelected === "exclusion" ?
                                    <div className="row mt-1">
                                        <div className="col-12">
                                            <label>Route Avoidance</label>
                                            <Select
                                                value={props.avoidanceRoutes}
                                                options={props.popList?.map((item) => {
                                                    return { value: item.id, label: item.name }
                                                })}
                                                onChange={(event) => props.routeAvoidanceHander(event)}
                                                isSearchable
                                                isMulti
                                            />
                                        </div>
                                    </div> : null
                                }
                                {props.routeDiversitySelected === "inclusion" ?
                                    <div className="row mt-1">
                                        <div className="col-12">
                                            <label>Route Inclusion</label>
                                            <Select
                                                value={props.includedRoutes}
                                                options={props.popList?.map((item) => {
                                                    return { value: item.id, label: item.name }
                                                })}
                                                onChange={(event) => props.includedRoutesHander(event)}
                                                isSearchable
                                                isMulti
                                            />
                                        </div>
                                    </div> : null
                                }
                            </> : null
                        }
                        {props.popupAction === "NO DATA" ?
                            <span>
                                <span className="mb-0">KMZ is currently unavailable for the following locations:</span>
                                <ul>
                                    <li><span className="mb-0" style={{"font-weight": "bolder"}}>{`${props.popLocA?.label}`}</span></li>
                                    <li><span className="mb-0" style={{"font-weight": "bolder"}}>{`${props.popLocZ?.label}`}</span></li>
                                </ul>
                                <br></br>
                                {props.isEmployee ? "Please select another location(s) or launch an icb quote." : " Contact your Account Executive for additional route options or launch a new quote which will lead to pricing and route options for the locations selected."}
                            </span> : null
                        }
                        {props.popupAction === "TIMEOUT" ?
                            <span>
                                The route generation took longer than expected. Please click refresh or cancel to select two new locations.
                            </span> : null
                        }
                        {props.popupAction === "SEARCHING ROUTE" ?
                        <>
                            <span>
                                Available route details may take a minute.  Thank you for your patience and for choosing Windstream!
                            </span>
                            <div className="row mt-2">
                                <div className="col-12 text-center">
                                    <div className='spinner-border spinner-border-lg ml-2' role='status' style={{ borderColor: '#753bbd', borderRightColor: 'transparent' }}/>
                                </div>
                            </div>
                        </> : null
                        }
                        {props.popupAction === "AUTO PRICING" ?
                            <span>Pre-approved pricing may not represent lowest latency routes. </span> : null
                        }
                    </div>
                    <div className="modal-footer d-block text-center">
                        {props.popupAction === "ROUTE DIVERSITY" ?
                            <>
                                <button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => props.setPopupShow(false)}>
                                    Save
                                </button>
                                <button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => props.setPopupShow(false)}>
                                    Cancel
                                </button>
                            </> : null
                        }
                        {props.popupAction === "NO DATA" ?
                            <>
                                {props.isCustomer &&  props.selectedQuoteAccess < 2  ?
                                    null :
                                    <button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => props.updateQuoteValues("NOT ICON")}>
                                        Launch a Quote
                                    </button>
                                }
                                <button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => props.setPopupShow(false)}>
                                    Return to Route Creator
                                </button>
                            </> : null
                        }
                        {props.popupAction === "TIMEOUT" ?
                            <>
                                <button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => props.checkAvailability()}>
                                    Refresh
                                </button>
                                <button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={() => props.setPopupShow(false)}>
                                    Cancel
                                </button>
                            </> : null
                        }
                        {props.popupAction === "AUTO PRICING" ?
                            <button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => props.setPopupShow(false)}>
                                Ok
                            </button> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default RouteCreatorModal;
