import {useEffect, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import {clearReduxStore, setUser, setPageAccess} from '../../redux/actions/userActions';
import {connect} from "react-redux";
import * as PendoService from "../../services/PendoService";
import {PendoUser} from "../../models/PendoUser";
import UserManagementService from "../../services/UserManagementService";

const EmployeeLoginResult = (props) => {
    const {authId, employeeId, employeeName} = useParams();
    const [dynatraceUser, setDynatraceUser] = useState("");
    const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});
    const employeeLoginAuthenticationURL = `${process.env.REACT_APP_LOGIN_URL}/employee.php`;
    const today = new Date();
    const todayFormatted = `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}:${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
    const historyPath = localStorage.getItem('currentPath');
    logger.info(`History Path is set to: ${historyPath}`);

	function updatePendo(resp) {
		const pendoUser = new PendoUser(resp.data);
		PendoService.initializePendo(pendoUser, "Employee");
	}

    function getTokenExpiration(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));   

        logger.info(`JWT Decoded: ${jsonPayload}`); 

        let tokenJSON = JSON.parse(jsonPayload);

        logger.info(`Token Expiration Unix Timestamp: ${tokenJSON.exp}`); 
        logger.info(`Token Expiration Date Format: ${new Date(tokenJSON.exp *1000)}`);

        return tokenJSON.exp;
    }

    useEffect(() => {
        logger.info("useEffect--EmployeeLoginResult");
        if (authId === "SUCCESS") {

            // User Management for Pendo
            UserManagementService.getUserAccessInfo().then(resp => { 
                //adding jwt token expiration time to redux store
                resp.data.expTime = getTokenExpiration(resp?.data?.jwt);
				props.updateUser(resp.data);
				updatePendo(resp);
                setDynatraceUser(resp.data.user);
            }).catch(error => {
                logger.error("Error Adding user to Pendo: " + error);
            });

            UserManagementService.getPageAccessMap().then(response => {
                logger.info(JSON.stringify(response?.data));
                props.updatePathAccess(response?.data);
            }).catch(error => {
                logger.error(`Error getting page access map ${error}`);
            })

            // Task #736801 -- Remove Customer Data
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("loggedInTime");
            localStorage.removeItem("user");
            // Add Employee Data
            localStorage.setItem("isEmployeeLoggedIn", "true");
            logger.info("Today's date: " + todayFormatted)
            localStorage.setItem("employeeLoggedInTime", todayFormatted);
            localStorage.setItem("eid", employeeId);
            localStorage.setItem("employeeName", employeeName);
            // TASK #743150 -- Remove Retry Variable
            localStorage.removeItem("retry");
            localStorage.removeItem("employeeLoginFailed");
            //Removing any local storage variable related to customer login
            localStorage.removeItem("loginFailed");
            localStorage.removeItem("loginAttempt");
            localStorage.removeItem("employeeLoginAttempt");
            //Clearing Redux Store
            props.clearStore();
        } else if (authId === "FAIL" && employeeId.toString().includes("NO TOKEN FOUND")) {
            logger.info("No Token Found!");
            // Remove Employee Data
            localStorage.removeItem("isEmployeeLoggedIn");
            localStorage.removeItem("employeeLoggedInTime");
            localStorage.removeItem("eid");
            localStorage.removeItem("employeeName");
            localStorage.removeItem("sessionReset");
            localStorage.setItem("employeeLoginFailed", 1);
            //Removing any local storage variable related to customer login
            localStorage.removeItem("loginFailed");
            localStorage.removeItem("loginAttempt");
            localStorage.removeItem("employeeLoginAttempt");
            //Clearing Redux Store
            props.clearStore();
            //OAuth
            window.location.href = employeeLoginAuthenticationURL;
        } else if (authId === "FAIL") {
            logger.info("AUTH FAIL!");
            logger.error(`Error: ${employeeId}`);
            // Remove Employee Data
            localStorage.removeItem("isEmployeeLoggedIn");
            localStorage.removeItem("employeeLoggedInTime");
            localStorage.removeItem("eid");
            localStorage.removeItem("employeeName");
            localStorage.removeItem("sessionReset");
            localStorage.setItem("employeeLoginFailed", 1);
            //Removing any local storage variable related to customer login
            localStorage.removeItem("loginFailed");
            localStorage.removeItem("loginAttempt");
            localStorage.removeItem("employeeLoginAttempt");
            //Clearing Redux Store
            props.clearStore();
        } else {
            logger.error("AUTH FAILED!");
            logger.error(`Error code: ${authId}`);
            // Remove Employee Data
            localStorage.removeItem("isEmployeeLoggedIn");
            localStorage.removeItem("employeeLoggedInTime");
            localStorage.removeItem("eid");
            localStorage.removeItem("employeeName");
            localStorage.removeItem("sessionReset");
            localStorage.setItem("employeeLoginFailed", 1);
            //Removing any local storage variable related to customer login
            localStorage.removeItem("loginFailed");
            localStorage.removeItem("loginAttempt");
            localStorage.removeItem("employeeLoginAttempt");
            localStorage.removeItem("historyPath");
            //Clear Redux Store
            props.clearStore();
        }
    });

    return (
        <>
            {historyPath ? <Redirect to={{pathname: historyPath}}/> : <Redirect to={{pathname: "/Employee/Home"}}/>}
        </>
    )


}

const mapDispatchToProps = {
    updateUser: setUser,
    updatePathAccess: setPageAccess,
    clearStore: clearReduxStore
}

export default connect(null, mapDispatchToProps)(EmployeeLoginResult);
