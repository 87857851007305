// CSS Imports
import style from "./UserInfoSection.module.css";

// React Core Libraries
import { useEffect, useState } from "react";
import { Link, Redirect, useLocation, useParams } from "react-router-dom";
import Loader from "../shared/Loader";
import { useSelector } from 'react-redux';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import UserManagementService from "../../services/UserManagementService";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

// 3rd party npm Libraries
import { DebounceInput } from 'react-debounce-input';

const UserInfoSection = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const profileFromStore = useSelector(state => state.employeeProfile.profile);
	const location = useLocation();
	let selectedEmployeeProfile = useSelector(state => state.employeeProfile.selectedEmployeeProfile);
	const [userInfoObj, setUserInfoObj] = useState({
		userId: '',
		firstName: '',
		lastName: '',
		email: '',
		newProfile: '',
		cam: '',
		company: '',
		webAccess: {
			transportServiceAvailability: '',
			webUserAdmin: '',
			quote: '',
			troubleTickets: '',
			order: '',
			invoiceSystem: ''
		}
	});
	const [errMsg, setErrMsg] = useState('');
	logger.info('<<< INSIDE USER INFO SELECTION PAGE >>>')
	logger.info(encodeURIComponent(userInfoObj).replace(/%20/g, " "))
	const [loader, setLoader] = useState(false);
	const [action, setAction] = useState(null);
	const [updateFlag, setUpdateFlag] = useState(false);
	const [updateFailedFlag, setUpdateFailedFlag] = useState(false);
	const [updateLoadingFlag, setUpdateLoadingFlag] = useState(false);
	const { userId } = useParams();
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const webAccessOptions = [{
		name: 'No Access',
		value: 0,
	}, {
		name: 'Read Only',
		value: 1,
	}, {
		name: 'Write Access',
		value: 2,
	}, {
		name: 'Full Access',
		value: 3,
	}];

	const getJSON = {
		"action": "get",
		//If this page is accessed by Customer it requires to pass the parameter in the URL and if used by employees the userId passed from the profile page
		"uid": isEmployee ? props.userIdFromProfile : userId
	};

	useEffect(() => {
		if ((selectedEmployeeProfile !== undefined &&
			Object.keys(selectedEmployeeProfile).length === 0)) {
			selectedEmployeeProfile = undefined;
		}
		if (userId || props.userIdFromProfile) {
			setAction('update');
			setLoader(true);
			UserManagementService.getUserData(getJSON).then(resp => {
				setUserInfoObj(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error("UserManagementService ERROR: " + error);
				setLoader(false);
				setHasError(error);
			});
		} else {
			setAction('add');
			if (profileFromStore.newProfile) {
				setUserInfoObj({
					...userInfoObj,
					userId: profileFromStore.email,
					firstName: profileFromStore.fName,
					lastName: profileFromStore.lName,
					email: profileFromStore.email,
					phoneNumber: profileFromStore.phone,
					newProfile: profileFromStore.newProfile,
					cam: profileFromStore.cam,
					company: profileFromStore.company,
					webAccess: {
						transportServiceAvailability: 3,
						webUserAdmin: 3,
						quote: 3,
						troubleTickets: 3,
						order: 3,
						invoiceSystem: 3
					}
				});
			}
		}
	}, [])

	const inputChangeHandler = e => {
		setErrMsg(null);
		setUpdateFailedFlag(false);
		const type = e.target.type;
		const name = e.target.name;
		const value = e.target.value;
		if (type === 'text') {
			setUserInfoObj(prevValues => {
				return { ...prevValues, [name]: value }
			})
		} else if (type === 'select-one') {
			if (value !== 'Select') {
				setUserInfoObj(prevValues => {
					prevValues = { ...prevValues, webAccess: { ...prevValues.webAccess, [name]: value } }
					return prevValues;
				})
			} else if (value === 'Select') {
				setUserInfoObj(prevValues => {
					prevValues = { ...prevValues, webAccess: { ...prevValues.webAccess, [name]: '' } }
					return prevValues;
				})
			}
		}
	}

	const validateInput = () => {
		const errText = 'Please verify all required fields are completed.';
		const emailFormat = /\S+@\S+\.\S+/;
		const spaceCheck = /^\S*$/;
		setErrMsg(null);

		if (!spaceCheck.test(userInfoObj.userId)) {
			setErrMsg('User Name can not contain spaces');
			return;
		}
		if (!userInfoObj.userId.trim()
			|| !userInfoObj.firstName.trim()
			|| !userInfoObj.lastName.trim()) {
			setErrMsg(errText);
			return;
		}
		// Email format validation
		if (!userInfoObj.email.trim()) {
			setErrMsg(errText);
			//return;
		} else if (!emailFormat.test(userInfoObj.email)) {
			setErrMsg('Please enter a valid email address');
			return;
		}

		return 1;
	}

	const saveOrUpdateUser = (ev) => {
		ev.preventDefault();
		if (validateInput()) {
			handleSubmit(ev);
		}
	}

	const submitJSON = {
		"action": action,
		"uid": userInfoObj.userId,
		"fname": userInfoObj.firstName,
		"lname": userInfoObj.lastName,
		"email": userInfoObj.email,
		"newProfile": userInfoObj.newProfile,
		"webaccess": {
			"UserAdmin": userInfoObj?.webAccess?.webUserAdmin ? userInfoObj.webAccess.webUserAdmin : 0,
			"TRANS_QUOTE": userInfoObj?.webAccess?.quote ? userInfoObj.webAccess.quote : 0,
			"RemedyTicket": userInfoObj?.webAccess?.troubleTickets ? userInfoObj.webAccess.troubleTickets : 0,
			"TRANS_ORDER": userInfoObj?.webAccess?.order ? userInfoObj.webAccess.order : 0,
			"TRANS_INVOICE": userInfoObj?.webAccess?.invoiceSystem ? userInfoObj.webAccess.invoiceSystem : 0
		}

	};

	const handleSubmit = (event) => {
		// Prevent the default form submission behavior
		event.preventDefault();

		if (!errMsg) {
			setUpdateLoadingFlag(true);
			setLoader(true);

			UserManagementService.getUserData(submitJSON)
				.then(response => {
					logger.info("User Add / Update Response: " + JSON.stringify(response));
					setUpdateLoadingFlag(false);

					// Check if response and its properties exist
					if (response?.data?.result === 'FAIL') {
						setErrMsg(response.data.error || 'An unknown error occurred.');
						setUpdateFailedFlag(true);
					} else if (response?.data?.result) {
						setUpdateFlag(true);

						// Check if userInfoObj and newProfile exist
						if (userInfoObj?.newProfile) {
							returnToProfile();
						}
					} else {
						// Handle case when there is no result
						setErrMsg('An unknown error occurred.');
						setUpdateFailedFlag(true);
					}
				})
				.catch(error => {
					setErrMsg(error.message || 'An error occurred during the service call.');
					setHasError(true);
					logger.error("User Add / Edit ERROR trying to do service call: " + error);
				})
				.finally(() => {
					setLoader(false);
				});
		}
	};

	const returnToProfile = () => {
		//calls function in Employee.js which makes the profile tab active
		props.handleNav("Profile");
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{!hasError &&
				(updateLoadingFlag ?
					<>
						<div className="my-4 text-center">
							<h1 className="text-success">{action === "update" ? "Updating User...." : "Adding User...."}</h1>
						</div>
					</>
					: null)}
			{!hasError &&
				(updateFlag ?
					<>
						<div className="my-4 text-center">
							<h1 className="text-success">{action === "update" ? "Successfully Updated User...." : "Successfully Added User...."}</h1>
						</div>
						{!isEmployee ?
							<>
								setTimeout(function() {
									<Redirect to={`/Manage/UserManage`} />
								}, 5000);
							</> : null
						}
					</>
					: null)}
			{!hasError &&
				(updateFailedFlag ?
					<>
						<div className="my-4 text-center">
							<h1 className="text-danger">{action === "update" ? "Updating User Failed...." : "Adding User Failed...."}</h1>
						</div>
					</>
					: null)}
			{loader ? <Loader /> : null}
			{!hasError &&
				(<div className="section">
					{/* <TabsQuote /> */}
					<div className="content">
						<div className="editContacts">
							<form onSubmit={saveOrUpdateUser}>
								{profileFromStore.newProfile ?
									<div>
										<p className={`text-center my-4 text-danger`}>
											Company Profile is not complete until one user is created.  Please fill-in the details below and click submit to send welcome and activation email
										</p>
									</div>
									: null}
								<div className={` row ${style.rowGrp}`}>
									<div className="col-5">
										<div className={` row ${style.rowGrp} contactTypeSubHeadingRow`}>
											<div className="col-6">
												<label className={style.lableTextS}></label>
											</div>
										</div>
										<div className={` row ${style.rowGrp}`}>
											<div className="col-12">
												<label className={` ${style.lableTextS} ${style.labelSelectRequiredFld} `}>User Name <span className={style.mandatory}>*</span></label>
												<DebounceInput type="text" id='userId' className={style.inputFld}
													name="userId" value={userInfoObj.userId} onChange={inputChangeHandler} debounceTimeout={500} maxLength="60" disabled={action === 'update' ? true : false} />
											</div>
										</div>
										<div className={` row ${style.rowGrp}`}>
											<div className="col-6">
												<label className={` ${style.lableTextS} ${style.labelSelectRequiredFld} `}>First Name<span className={style.mandatory}>*</span></label>
												<DebounceInput type="text" id='firstName' className={style.inputFld}
													name="firstName" value={userInfoObj.firstName} onChange={inputChangeHandler} debounceTimeout={500} maxLength="20" />
											</div>
											<div className="col-6">
												<label className={` ${style.lableTextS} ${style.labelSelectRequiredFld} `}>Last Name<span className={style.mandatory}>*</span></label>
												<DebounceInput type="text" id='lastName' className={style.inputFld}
													name="lastName" value={userInfoObj.lastName} onChange={inputChangeHandler} debounceTimeout={500} maxLength="20" />
											</div>
										</div>
										<div className={` row ${style.rowGrp}`}>
											<div className="col-12">
												<label className={` ${style.lableTextS} ${style.labelSelectRequiredFld} `}>Email<span className={style.mandatory}>*</span></label>
												<DebounceInput type="text" id='email' className={style.inputFld}
													name="email" value={userInfoObj.email} onChange={inputChangeHandler} debounceTimeout={500} maxLength="60" />
											</div>
										</div>
									</div>
									<div className="col-7">
										<div className="text-right">
											<button className={`ico-button light-button`}>
												{isEmployee ?
													<a href="../../assets/files/users/iconnect_user_onboarding_internal.xlsx">
														Download Bulk User Load Template
													</a>
													:
													<a href="../../assets/files/users/iconnect_user_onboarding_customer.xlsx">
														Download Bulk User Load Template
													</a>
												}
											</button>
										</div>
										<div className={` row ${style.rowGrp} contactTypeSubHeadingRow`}>
											<div className="col-6">
												<label className={style.lableTextS}>Web Access</label>
											</div>
										</div>
										<div className={` row ${style.rowGrp}`}>
											<div className="col-5 col-sm-4 col-md-6 rel mb-5">
												<label className={style.labelSelectRequiredFld}> Web User Administration <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='webUserAdmin' onChange={inputChangeHandler}
													name="webUserAdmin" value={userInfoObj?.webAccess?.webUserAdmin ? userInfoObj.webAccess.webUserAdmin : 0}>
													<option>Select</option>
													{webAccessOptions.map((obj) => {
														return <option key={obj.name} value={obj.value}>{obj.name}</option>
													})}
												</select>
											</div>
											<div className="col-5 col-sm-4 col-md-6 rel mb-5">
												<label className={style.labelSelectRequiredFld}> Trouble Tickets <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='troubleTickets' onChange={inputChangeHandler}
													name="troubleTickets" value={userInfoObj?.webAccess?.troubleTickets ? userInfoObj.webAccess.troubleTickets : 0}>
													<option>Select</option>
													{webAccessOptions.map((obj) => {
														return <option key={obj.name} value={obj.value}>{obj.name}</option>
													})}
												</select>
											</div>
										</div>
										<div className={` row ${style.rowGrp}`}>
											<div className="col-5 col-sm-4 col-md-6 rel mb-5">
												<label className={style.labelSelectRequiredFld}> Transport Quote <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='quote' onChange={inputChangeHandler}
													name="quote" value={userInfoObj?.webAccess?.quote ? userInfoObj.webAccess.quote : 0}>
													<option>Select</option>
													{webAccessOptions.map((obj) => {
														return <option key={obj.name} value={obj.value}>{obj.name}</option>
													})}
												</select>
											</div>
											<div className="col-5 col-sm-4 col-md-6 rel mb-5">
												<label className={style.labelSelectRequiredFld}> Transport Order <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='order' onChange={inputChangeHandler}
													name="order" value={userInfoObj?.webAccess?.order ? userInfoObj.webAccess.order : 0}>
													<option>Select</option>
													{webAccessOptions.map((obj) => {
														return <option key={obj.name} value={obj.value}>{obj.name}</option>
													})}
												</select>
											</div>
										</div>
										<div className={` row ${style.rowGrp}`}>
											<div className="col-5 col-sm-4 col-md-6 rel mb-5">
												<label className={style.labelSelectRequiredFld}> Invoice System <span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='invoiceSystem' onChange={inputChangeHandler}
													name="invoiceSystem" value={userInfoObj?.webAccess?.invoiceSystem ? userInfoObj.webAccess.invoiceSystem : 0}>
													<option>Select</option>
													{webAccessOptions.map((obj) => {
														return <option key={obj.name} value={obj.value}>{obj.name}</option>
													})}
												</select>
											</div>
										</div>
									</div>
								</div>
								{errMsg && <div className="mt-5">
									<p className="text-danger text-center">{errMsg}</p>
								</div>}

								<div className="text-center">

									<div className={`row ${style.rowGrp} updateContactBtnRow `}>
										{location.pathname === "/Employee/Profile" ?
											<button className={` ico-button lhs light-button ${style.nextBtn} ${style.cancelBtn} mr-4 `} onClick={returnToProfile}>
												Cancel
												<svg className="ico light-ico ico-x">
													<path
														d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
												</svg>
											</button> :
											<Link to="/Manage/UserManage" className={` ico-button lhs light-button ${style.nextBtn} ${style.cancelBtn} mr-4 `} type="button">
												Cancel
												<svg className="ico light-ico ico-x">
													<path
														d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
												</svg>
											</Link>
										}

										<button className={` ico-button lhs light-button ${style.nextBtn} `} type="button" onClick={saveOrUpdateUser}>
											Submit
											<svg className="ico light-ico ico-arrow">
												<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
											</svg>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>)}
		</>
	);
}

export default UserInfoSection;
