import { useState } from 'react';
import { useSelector } from "react-redux";

const TabsStaff = ({ handleNav, navLink }) => {
    const staffPermissions = useSelector(state => Number(state.userReducer.user.webaccess?.EmployeeAdmin) || 0);

    console.log("TabsStaff.js");

    return (
        <div className="tab-nav cf">
            {(staffPermissions >= 1) &&
                <a href className={`tab-opt ${navLink === 'Account' ? 'active' : ''}`} onClick={() => { handleNav('Account') }}>
                    <span>Account</span>
                </a>
            }
            {(staffPermissions >= 2) &&
                <a href className={`tab-opt ${navLink === 'EditUser' ? 'active' : ''}`} onClick={() => { handleNav('EditUser') }}>
                    <span>Edit User</span>
                </a>
            }
            {(staffPermissions === 3) &&
                <a href className={`tab-opt ${navLink === 'AddUser' ? 'active' : ''}`} onClick={() => { handleNav('AddUser') }}>
                    <span>Add User</span>
                </a>
            }
        </div>
    );
}

export default TabsStaff;