import { useEffect } from "react";

const TokenTest = () => {
    useEffect(() => {
        const environment = process.env.REACT_APP_NODE_ENV;
        switch(environment) {
            case "local":
                window.location.href = "https://iconnect-local.windstreamwholesale.com:8000/api/testCombined.php";
                break;
            case "development":
                window.location.href = "https://iconnect-dev.windstreamwholesale.com/api/testCombined.php";
                break;
            case "test":
                window.location.href = "https://iconnect-uat.windstreamwholesale.com/api/testCombined.php";
                break;
            default:
                window.location.href = "https://iconnect.windstreamwholesale.com/api/testCombined.php";
        }
    }, []); 

    return null;
}

export default TokenTest;