import {LOG_OUT, PAGE_ACCESS, SET_USER, USER_MANAGE} from "../actions/types/actionTypes"

const initialState = {
    user: {
        user: "",
        emulator: "",
        webaccess:{}
    },
    userManage: {
        userName: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        webAccess: {
        }
    },
    pageAccess: {}
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload
            }
        case LOG_OUT:
            return {
                ...state,
                user: {}
            }
        case USER_MANAGE:
            return {
                ...state,
                userManage: action.payload
            }
        case PAGE_ACCESS: 
            return {
                ...state,
                pageAccess: action.payload
            }
        default:
            return state
    }
}

export default userReducer;
