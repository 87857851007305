import { useState, useEffect, useMemo, useCallback } from 'react';
import { ConsoleLogger } from '../../../logger/ConsoleLogger';
import style from "./BillingDispute.module.css";
import Loader from "../../shared/Loader";
import BillingDisputeHelper from "../../../services/BillingDisputeService";
import BillingDisputeComment from './BillingDisputeComment';
import { useParams, useLocation } from 'react-router-dom';

const BillingDisputeDetails = () => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL }), []);
    const [disputeComments, setDisputeComments] = useState([]);
    const [disputeData, setDisputeData] = useState([]);
    const [displayAllComments, setDisplayAllComments] = useState(true);
    const [loader, setLoader] = useState(true);
    const [showAddCommentModal, setShowAddCommentModal] = useState(false);
    const [newComment, setNewComment] = useState("");
    const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
    const { caseId } = useParams();
    const location = useLocation();

    useEffect(() => {
        
        if(location.state.disputeData){
            setDisputeData(location.state.disputeData);
        }
    }, [location.state.disputeData]);

    const fetchCaseComments = useCallback(() => {
        setLoader(true);
        BillingDisputeHelper.getBillingDisputeComments(caseId).then(response => {
            logger.info(response?.data);
            if(response.data?.result !== "FAILED"){
                setDisputeComments(response?.data);
            }
            setLoader(false);
        }).catch(error => {
            logger.error(`fetchDisputeComment error: ${error}`);
            setLoader(false);
        })
    },[caseId, logger])

    useEffect(() => {
        fetchCaseComments();
    },[fetchCaseComments])

    const closeAddDisputeCommentsModal = () => {
        setShowAddCommentModal(false);
        setNewComment("");
    }

    const submitNewComment = () => {
        setShowAddCommentModal(false);
        if(newComment){
            setLoader(true);
            let commentData = {"caseId": caseId, "comments": newComment};
            BillingDisputeHelper.createDisputeComment(commentData).then(response => {
                logger.info(response?.data);
                setLoader(false);
                fetchCaseComments();
            }).catch(error => {
                setLoader(true);
                logger.info(`Error failed to create new comment: ${error}`);
            })
        } else {
            logger.info("No new comment");
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            <div className="midsection qm-upload abs z1">
                <div className="rel">
                    <div className="wrap">
                        <h1 className="page-title">{isEmployee? "Employee Billing Dispute Details" : "Billing Dispute Details"}</h1>
                        <div className="section">
                            <div className="content">
                            <div className="sub-hdr">
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={`${style.subSectionTitle} m-0`}>Details</h2>
								</div>
								<div className="sub-section">
                                    <div className={`row ${style.rowGap}`}>
                                        <div className="col-6">
                                            <ul className={style.detailList}>
                                                <li>Case Number</li>
                                                <li>{disputeData?.caseNumber}</li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className={style.detailList}>
                                                <li>Status</li>
                                                <li>{disputeData?.caseStatus}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`row ${style.rowGap}`}>
                                        <div className="col-6">
                                            <ul className={style.detailList}>
                                                <li>Email</li>
                                                <li>{disputeData?.contactEmail}</li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className={style.detailList}>
                                                <li>Account</li>
                                                <li>{disputeData?.account}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`row ${style.rowGap}`}>
                                        <div className="col-6">
                                            <ul className={style.detailList}>
                                                <li>Dispute Reason</li>
                                                <li>{disputeData?.disputeReason}</li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className={style.detailList}>
                                                <li>Dispute Amount</li>
                                                <li>{disputeData?.disputeAmount}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`row ${style.rowGap}`}>
                                        <div className="col-12">
                                            <ul className={style.descriptionList}>
                                                <li>Dispute Description</li>
                                                <li>{disputeData?.description}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`row ${style.rowGap}`}>
                                        <div className="col-12">
                                            <ul className={style.descriptionList}>
                                                <li>Dispute Details</li>
                                                <li>{disputeData?.descriptionDetail}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="sub-hdr">
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={`${style.subSectionTitle} m-0`}>Comments</h2>
								</div>
                                <div className={`row d-flex ml-3 ${style.rowGap}`}>
                                    {disputeComments?.length !== 0 ? 
                                        <div className="col-4">
                                            <button className="light-button" onClick={() => setDisplayAllComments(!displayAllComments)}>{displayAllComments ? "Hide Comments" : "Display All Comments"}</button>
                                        </div> : null
                                    }
                                    <div className="col-4">
                                        <button className="light-button" onClick={() => setShowAddCommentModal(true)}>Add Comment</button>
                                    </div>
								</div>
                                {showAddCommentModal ?
                                    <BillingDisputeComment caseNumber={disputeData?.caseNumber} hideAddCommentModal={() => closeAddDisputeCommentsModal()} addDisputeComment={() => submitNewComment()} newDisputeComment={(comment) => setNewComment(comment)}/> : null
                                }
                                <div className="sub-section">
                                    {disputeComments?.length === 0 && !loader ?
                                        <div className="row">
                                            <div className="row-4">
                                                <span>There are no comments related to this case. Please click 'Add Comment' if you wish to add one.</span>
                                            </div>
                                        </div> : null
                                    }
                                    {displayAllComments ? 
                                        <>
                                            {disputeComments?.map((comment, index) => (
                                                <div key={index}>
                                                    <div className={`row ${style.rowGap}`}>
                                                        <div className="col-6">
                                                            <ul className={style.detailList}>
                                                                <li>Date and Time</li>
                                                                <li>{comment?.createdDate}</li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-6">
                                                            <ul className={style.detailList}>
                                                                <li>User</li>
                                                                <li>{comment?.createdBy}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className={`row ${style.rowGap}`}>
                                                        <div className="col-12">
                                                            <ul className={style.commentList}>
                                                                <li>Note</li>
                                                                <li className={style.breakLine}>{comment?.commentBody}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}

export default BillingDisputeDetails;