import style from './OrderCreate.module.css';
import React, { useEffect, useState } from "react";
import QuoteHelper from '../../services/QuoteService';
import OrderHelper from '../../services/OrderService';
import { connect, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import {
	setOrderACNA,
	setOrderCustomerCircuit,
	setOrderCustomerPon,
	setOrderExpediteRequest,
	setOrderInitialTerm,
	setOrderLeasenetId,
	setOrderNewPrimaryContact,
	setOrderNewTechContact,
	setOrderPrimaryContact,
	setOrderPrimaryEmail,
	setOrderPrimaryPhone,
	setOrderRequestedServiceDate,
	setOrderTechContact,
	setOrderTechEmail,
	setOrderTechPhone
} from '../../redux/actions/orderCreateActions';
import Loader from '../shared/Loader';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CustomDatePicker from '../components/CustomDatePicker';

const OrderCustomerInfo = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const [contactOptions, setContactOptions] = useState([]);
	const [acnaOptions, setAcnaOptions] = useState([]);
	const [initalTermOptions, setInitalTermOptions] = useState([]);
	const [newPrimaryContactFlag, setNewPrimaryContactFlag] = useState(false);
	const [newTechContactFlag, setNewTechContactFlag] = useState(false);
	const [popupShow, setPopupShow] = useState(false);
	const [popupMsg, setPopupMsg] = useState('');
	const [validServiceDate, setValidServiceDate] = useState('');
	const selectedOrderContactInfo = useSelector(state => state.orderCreate.contactInfo);
	const selectedOrderGeneralInfo = useSelector(state => state.orderCreate.generalInfo);
	const selectedQuotedData = useSelector(state => state.orderCreate.quotedData);
	const { refId } = useParams();
	const [loader, setLoader] = useState(false);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);

	const shouldDisplayLeasenetID = selectedQuotedData.product_id === '15' && selectedQuotedData.la_conx === '1';

	useEffect(() => {
		if (isEmployee) {
			if (Number(selectedInternalMpid) > 0) {
				getContactOptions();
				getOrderACNAOptions();
				getOrderInitalTermOptions(refId);
				getServiceDate();
			}
		} else {
			getContactOptions();
			getOrderACNAOptions();
			getOrderInitalTermOptions(refId);
			getServiceDate();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEmployee, selectedInternalMpid]);

	useEffect(() => {
		if (selectedQuotedData?.q_cpt_activity === "R") {
			autoPopulateRenewalValues();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedQuotedData])

	const getContactOptions = () => {
		setLoader(true);
		QuoteHelper.getQuoteContact().then(resp => {
			logger.info(resp.data);
			setContactOptions(resp.data);
			setLoader(false);
		}).catch(error => {
			logger.error("Error Getting Contacts: " + error);
			setHasError(error);
			setLoader(false);
		})
	}

	const getOrderACNAOptions = () => {
		setLoader(true);
		OrderHelper.getOrderACNA().then(resp => {
			if (resp.data.length === 1) {
				props.updateOrderACNA(resp.data[0].id);
			}
			setAcnaOptions(resp.data);
			setLoader(false);
		}).catch(error => {
			logger.error("Getting ACNA Options " + error);
			setHasError(error);
			setLoader(false);
		})
	}

	const getOrderInitalTermOptions = () => {
		setLoader(true);
		OrderHelper.getOrderInitalTerm(refId, selectedQuotedData.product_id).then(resp => {
			logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
			if (resp.data.length === 1) {
				props.updateOrderInitialTerm(resp.data[0].id);
			}
			setInitalTermOptions(resp.data);
			setLoader(false);
		}).catch(error => {
			logger.error("Getting Inital Term Options: " + error);
			setHasError(error);
			setLoader(false);
		})
	}

	//Function to autofill data from the renewal quote
	const autoPopulateRenewalValues = () => {
		props.updateOrderPrimaryContact(selectedQuotedData.cp_contact_id);
		props.updateOrderPrimaryPhone(selectedQuotedData.cp_phone);
		props.updateOrderPrimayEmail(selectedQuotedData.cp_email);
		props.updateOrderTechContact(selectedQuotedData.cp_contact_id);
		props.updateOrderTechPhone(selectedQuotedData.cp_phone);
		props.updateOrderTechEmail(selectedQuotedData.cp_email);
	}

	const autoFillContact = (contactValue, type) => {
		setLoader(true);
		QuoteHelper.getQuoteContactInfo(contactValue).then(resp => {
			logger.info(resp.data);
			if (type === "orig_contact") {
				props.updateOrderPrimaryPhone(resp.data.phone);
				props.updateOrderPrimayEmail(resp.data.email);
			} else {
				props.updateOrderTechPhone(resp.data.phone);
				props.updateOrderTechEmail(resp.data.email);
			}
			setLoader(false);

		}).catch(error => {
			logger.error("Could Not AutoFill Contact: " + error);
			setHasError(error);
			setLoader(false);
		})

	}

	const contactHandler = (ev) => {
		let contact = ev.target.value;

		if (contact === 'new') {
			if (ev.target.id === "orig_contact") {
				props.updateOrderPrimaryContact(ev.target.value);
				setNewPrimaryContactFlag(true);
			} else {
				props.updateOrderTechContact(ev.target.value);
				setNewTechContactFlag(true);
			}
		} else {
			if (ev.target.id === "orig_contact") {
				props.updateOrderPrimaryContact(ev.target.value);
				autoFillContact(contact, "orig_contact");
			} else {
				props.updateOrderTechContact(ev.target.value);
				autoFillContact(contact, "tech_contact");

			}
		}
	}

	//Handler to create a new contact and add it to the redux store
	const newContactHandler = (ev) => {
		logger.info(encodeURIComponent(ev.target.value).replace(/%20/g, " "));
		switch (ev.target.id) {
			case "new_orig_contact":
				props.updateOrderNewPrimaryContact(ev.target.value);
				break;
			case "new_tech_contact":
				props.updateOrderNewTechContact(ev.target.value);
				break;
			default:
				break;
		}
	}

	const inputHandler = (ev) => {
		switch (ev.target.id) {
			case "cust_pon":
				props.updateOrderCustomerPon(ev.target.value);
				break;
			case "cust_circuit":
				props.updateOrderCustomerCircuit(ev.target.value);
				break;
			case "leasenet_id":
				props.updateOrderLeasenetId(ev.target.value);
				break;
			case "serv_date":
				props.updateOrderRequestService(ev.target.value);
				break;
			case "initial_term":
				props.updateOrderInitialTerm(ev.target.value);
				break;
			case "acna":
				props.updateOrderACNA(ev.target.value);
				break;
			case "orig_phone":
				props.updateOrderPrimaryPhone(ev.target.value);
				break;
			case "orig_email":
				props.updateOrderPrimayEmail(ev.target.value);
				break;
			case "tech_phone":
				props.updateOrderTechPhone(ev.target.value);
				break;
			case "tech_email":
				props.updateOrderTechEmail(ev.target.value);
				break;
			default:
				break;
		}
	}

	const expediteRequestHandler = (ev) => {
		if (ev.target.value === "Y") {
			setPopupShow(true);
			setPopupMsg("Additional fees may apply by requesting an expedite on this order.  Request of an expedite does not guarantee delivery on requested due date.");
			props.updateOrderExpediteRequest(ev.target.value);
		} else {
			setPopupShow(false);
			setPopupMsg('');
			props.updateOrderExpediteRequest(ev.target.value);
		}
	}

	const servDateRequestHandler = (ev) => {
		//For renewals the service date is equal to the renewal effective date which can be backdated with no limitations
		if (selectedQuotedData.q_cpt_activity === "R") {
			props.updateOrderRequestService(ev.target.value);
		} else {
			if (ev.target.value < validServiceDate) {
				setPopupShow(true);
				setPopupMsg("You have selected a date that falls outside of our projected Standard Interval range, we will make every effort to reach the selected date but it cannot be guaranteed. If you would like to insure this date, please select the expedite option. Please click Ok to confirm.");
				props.updateOrderRequestService(ev.target.value);
			} else {
				setPopupShow(false);
				setPopupMsg('');
				props.updateOrderRequestService(ev.target.value);
			}
		}

	}

	const getServiceDate = () => {
		setLoader(true);
		OrderHelper.getOrderServiceDate(selectedQuotedData.quote_id, selectedQuotedData.product_id, selectedQuotedData.circuit_id, refId, selectedQuotedData.q_cpt_activity).then(resp => {
			let serviceDate = resp.data;
			if (serviceDate === '0000-00-00') {
			  serviceDate = null
			}
			logger.info("Default Service Date: " + encodeURIComponent(resp.data).replace(/%20/g, " "));
			setValidServiceDate(serviceDate);
			props.updateOrderRequestService(serviceDate);
			setLoader(false);
		}).catch(error => {
			logger.error("getOrderServiceDate Error: " + error);
			setHasError(error);
			setLoader(false);
		})
	}

	const popupOk = () => {
		setPopupShow(false);
		setPopupMsg('');
	}
	const popupCancel = () => {
		setPopupShow(false);
		setPopupMsg('');
		props.updateOrderExpediteRequest("N");
	}


	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<><div className={style.section1}>
				<div className={`sub-hdr alt0 ${style.subHdrAlter} `}>
					<div className="nib">
						<div className="the-notch">
							<div className="notch-fg">
								<svg>
									<path
										d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
								</svg>
							</div>
						</div>
					</div>
					<h2 className={` ${style.sectionTitle} m-0 `}>Contacts</h2>
				</div>

				<div className="sub-section">
					<div className={` row ${style.rowGrp}`}>
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Primary Authorizing Contact<span className={style.mandatory}>*</span></label>
							{newPrimaryContactFlag ?
								<input type='text' className={style.inputFld} maxLength='108' id='new_orig_contact' name='new_orig_contact' onChange={newContactHandler} value={selectedOrderContactInfo.new_orig_contact}></input>
								:
								<select className={style.selectFld} onChange={contactHandler} id='orig_contact' name='orig_contact' value={selectedOrderContactInfo.orig_contact}>
									{contactOptions.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}
								</select>}
						</div>
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Primary Authorizing Phone<span className={style.mandatory}>*</span></label>
							<input type='text' className={style.inputFld} maxLength='10' id='orig_phone' name='orig_phone' onChange={inputHandler} value={selectedOrderContactInfo.orig_phone}></input>
						</div>
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Primary Authorizing Email<span className={style.mandatory}>*</span></label>
							<input type='text' className={style.inputFld} maxLength='118' id='orig_email' name='orig_email' onChange={inputHandler} value={selectedOrderContactInfo.orig_email} ></input>
						</div>
						{selectedQuotedData.q_cpt_activity !== "R" ?
							<>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Data Technical Contact<span className={style.mandatory}>*</span></label>
									{newTechContactFlag ?
										<input type='text' className={style.inputFld} maxLength='108' id='new_tech_contact' name='new_tech_contact' onChange={newContactHandler} value={selectedOrderContactInfo.new_tech_contact}></input>
										:
										<select className={style.selectFld} id='tech_contact' name='tech_contact' onChange={contactHandler} value={selectedOrderContactInfo.tech_contact}>
											{contactOptions.map((obj) => {
												return <option value={obj.id}>{obj.name}</option>
											})}
										</select>}
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Data Technical Phone<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} maxLength='10' id='tech_phone' name='tech_phone' onChange={inputHandler} value={selectedOrderContactInfo.tech_phone}></input>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Data Technical Email<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} maxLength='118' id='tech_email' name='tech_email' onChange={inputHandler} value={selectedOrderContactInfo.tech_email}></input>
								</div>
							</> : null
						}
					</div>
				</div></div>
				<div className={style.dividers}></div>
				<div className={style.section1}>
					<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
						<div className="nib">
							<div className="the-notch">
								<div className="notch-fg">
									<svg>
										<path
											d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
									</svg>
								</div>
							</div>
						</div>
						<h2 className={` ${style.sectionTitle} m-0 `}>General Order Information</h2>
					</div>

					<div className="sub-section">
						<div className={` row ${style.rowGrp}`}>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Customer Pon<span className={style.mandatory}>*</span></label>
								<input type='text' className={style.inputFld} maxLength='16' id='cust_pon' name='cust_pon' onChange={inputHandler} value={selectedOrderGeneralInfo.cust_pon}></input>
							</div>
							{/*Wholesale Broadband Does not need customer circuit, service date, or expedit request*/}
							{selectedQuotedData.product_id !== "23" ?
								<>
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>Customer Circuit</label>
										<input type='text' className={style.inputFld} maxLength='64' id='cust_circuit' name='cust_circuit' onChange={inputHandler} value={selectedOrderGeneralInfo.cust_circuit}></input>
									</div>
									{shouldDisplayLeasenetID && (
										<div className={`col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>Leasenet ID</label>
											<input type='text' className={style.inputFld} maxLength='15' id='leasenet_id' name='leasenet_id' onChange={inputHandler} value={selectedOrderGeneralInfo.leasenet_id}></input>
										</div>
									)}
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>{selectedQuotedData.q_cpt_activity === "R" ? "Renewal Effective Date" : "Requested Service Date"}<span className={style.mandatory}>*</span></label>
										<CustomDatePicker
											value={selectedOrderGeneralInfo.serv_date || null}  // Pass the current date value
											onChange={(newDate) => servDateRequestHandler({ target: { id: 'serv_date', value: newDate } })} // Handle date change
											id='serv_date'
											name='serv_date'
											className={style.dateFld}
										/>
									</div>
									{selectedQuotedData.q_cpt_activity !== "R" ?
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>Expedite Request<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='expedite_req' name='expedite_req' onChange={expediteRequestHandler} value={selectedOrderGeneralInfo.expedite_req}>
												<option value="N">NO</option>
												<option value="Y">YES</option>
											</select>
										</div> : null
									}
								</> : null
							}
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Initial Term<span>(months)</span><span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id='initial_term' name='initial_term' onChange={inputHandler} value={selectedOrderGeneralInfo.initial_term}>
									{initalTermOptions.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}
								</select>
							</div>
							{selectedQuotedData.product_id !== "23" ?
								<>
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>ACNA<span className={style.mandatory}>*</span></label>
										<select className={style.selectFld} id='acna' name='acna' onChange={inputHandler} value={selectedOrderGeneralInfo.acna}>
											<option value=""></option>
											{acnaOptions.map((obj) => {
												return <option value={obj.id}>{obj.name}</option>
											})}
										</select>
									</div>
								</> : null
							}
						</div>
					</div>
				</div>
				<div className={style.dividers}></div>
				{popupShow ?
					<div>
						<div className={`${style.overly}`}> </div>
						<div className="modal fade show d-block">
							<div className={` modal-dialog modal-dialog-centered modal-md`}>
								<div className="modal-content">
									<div className="modal-body">
										{popupMsg}
									</div>
									<div className="modal-footer d-block text-center">
										<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={popupOk}>Ok</button>
										<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={popupCancel}>Cancel</button>
									</div>

								</div>
							</div>
						</div>
					</div>
					: null
				}</>)}



		</>
	)

}

const mapDispatchToProps = {
	updateOrderPrimaryContact: setOrderPrimaryContact,
	updateOrderNewPrimaryContact: setOrderNewPrimaryContact,
	updateOrderPrimaryPhone: setOrderPrimaryPhone,
	updateOrderPrimayEmail: setOrderPrimaryEmail,
	updateOrderTechContact: setOrderTechContact,
	updateOrderNewTechContact: setOrderNewTechContact,
	updateOrderTechPhone: setOrderTechPhone,
	updateOrderTechEmail: setOrderTechEmail,
	updateOrderCustomerPon: setOrderCustomerPon,
	updateOrderCustomerCircuit: setOrderCustomerCircuit,
	updateOrderLeasenetId: setOrderLeasenetId,
	updateOrderRequestService: setOrderRequestedServiceDate,
	updateOrderExpediteRequest: setOrderExpediteRequest,
	updateOrderInitialTerm: setOrderInitialTerm,
	updateOrderACNA: setOrderACNA
}

export default connect(null, mapDispatchToProps)(OrderCustomerInfo);
