import { useState, useEffect, useMemo } from 'react';
import { ConsoleLogger } from '../../../logger/ConsoleLogger';
import style from "./BillingDispute.module.css";
import Loader from "../../shared/Loader";
import BillingDisputeHelper from "../../../services/BillingDisputeService";
import { useSelector } from 'react-redux';
import CustomDatePicker from '../../components/CustomDatePicker';
import ContactHelper from '../../../services/ContactService';
import CompanyDropdown from '../../shared/CompanyDropdown';
import CircuitListHelper from '../../../services/CircuitListService';
import { Redirect } from "react-router-dom";

const BillingDisputeCreate = () => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL }), []);
    const [loader, setLoader] = useState(false);
    const [disputeObj, setDisputeObj] = useState({});
    const [disputeErrorObj, setDisputeErrorObj] = useState({});
	const [contactOptions, setContactOptions] = useState([]);
    const [disputeMenuOptions, setDisputeMenuOptions] = useState([]);
    const [circuitOptions, setCircuitOptions] = useState([]);
    const [requiredMsg, setRequiredMsg] = useState("");
    const [disputeSuccessMsg, setDisputeSuccessMsg] = useState("");
    const [disputeErrorMsg, setDisputeErrorMsg] = useState("");
    const [disputeResult, setDisputeResult] = useState([]);
    
    //Redux store variables
    const selectedInternalMpid = useSelector(state => state.internal.company?.value);

    //Local storage variable
    const isEmployee = localStorage.getItem("isEmployeeLoggedIn");

    useEffect(() => {
		if (!isEmployee || (isEmployee && selectedInternalMpid)) {
			setLoader(true);
            logger.info("Calling getWinforceContacts");
			ContactHelper.getWinforceContacts().then(response => {
				logger.info(`Contact List: ${JSON.stringify(response.data.result)}`);

				// Filter out inactive contacts and prepare contact data
				const activeContacts = response.data.result
					.filter(contact => !contact.inactive_Contact__c)
					.map(contact => ({
						id: contact.id,
						name: `${contact.firstName} ${contact.lastName}`,
						phone: contact.phone,
						email: contact.email,
                        account: contact.accountId
					}))
					.sort((a, b) => a.name.localeCompare(b.name));

				setContactOptions(activeContacts);
				setLoader(false);
			}).catch(error => {
				logger.error("getWinforceContacts ERROR: " + error);
				setLoader(false);
			});
		}
	}, [logger, isEmployee, selectedInternalMpid]);

    useEffect(() => {
        if(!isEmployee || (isEmployee && selectedInternalMpid)){
            setLoader(true);
            logger.info("Calling getCircuitList");
            let circuitMenu = [];
            CircuitListHelper.getCircuitList().then(response => {
                logger.info(response.data);
                if (response.data !== "No circuits found") {
					circuitMenu = response.data;

				} 
				setCircuitOptions(circuitMenu);
                setLoader(false);
            }).catch(error => {
                setLoader(false);
                logger.error(`getCircuitList ERROR: ${error}`);
            })
        }
    },[logger, isEmployee, selectedInternalMpid])
    
    useEffect(() => {
        BillingDisputeHelper.getBillingDisputeMenu().then(response => {
            logger.info("getting billing dispute menu options");
            setDisputeMenuOptions(response?.data);
        }).catch(error => {
            logger.info(`getBillingDisputeMenu error : ${error}`);
        })
    },[logger])

    const disputeInputHandler = (event) => {
        let id = event.target.id;
		let value = event.target.value;
		let errorId = id + "Error";

        if (id === "contact") {
            logger.info("handling a contact selection")
			if (value !== "new") {
                logger.info("auto populating name, phone and contact")
                const selectedContact = contactOptions.find(contact => contact.id === value);
                if (selectedContact) {
                    logger.info(`selectedContact: ${JSON.stringify(selectedContact)}`);
                    setDisputeObj((prevState) => ({ ...prevState, "contact": value, "phone": selectedContact?.phone, "email": selectedContact?.email, "account": selectedContact?.account}));
                    setDisputeErrorObj((prevState) => ({ ...prevState, "contactError": "", "phoneError": "", "emailError": ""}));
                }
            }
		} else {
            setDisputeObj((prevState) => ({ ...prevState, [id]: value}));
            setDisputeErrorObj((prevState) => ({...prevState, [errorId]: ""}));
        }
		
    }

    //Validates all required fields
	const requiredFieldValidation = () => {
		let error = false;
		
		//Contact validation
		if (!disputeObj?.contact) { setDisputeErrorObj(prevError => ({ ...prevError, "contactError": "Contact is required!" })); error = true; }
		if (!disputeObj?.contact) { setDisputeErrorObj(prevError => ({ ...prevError, "phoneError": "Phone is required!" })); error = true; }
		if (!disputeObj?.contact) { setDisputeErrorObj(prevError => ({ ...prevError, "emailError": "Email is required!" })); error = true; }

        if (!disputeObj?.disputeAmount) { setDisputeErrorObj(prevError => ({ ...prevError, "disputeAmountError": "Dispute Amount is required!"})); error = true; }
        if (!disputeObj?.disputeReason) { setDisputeErrorObj(prevError => ({ ...prevError, "disputeReasonError": "Dispute Reason is required!"})); error = true; }
        if (!disputeObj?.disputeReasonDetail) { setDisputeErrorObj(prevError => ({ ...prevError, "disputeReasonDetailError": "Dispute Reason Detail is required!"})); error = true; }
		return error;
	}
    
    const clearDisputeForm = () => {
        logger.info("inside of clearDisputeForm");
        setDisputeObj({});
        setDisputeErrorObj({})
    }

    const submitDispute = () => {
        logger.info(`Submitting the following dispute data: ${JSON.stringify(disputeObj)}`);
        let error = requiredFieldValidation();
		if (error) {
			setRequiredMsg("Please verify all required fields are completed");
			return;
		}

        setLoader(true);
        BillingDisputeHelper.submitDispute(disputeObj).then(response => {
            logger.info(`submitDispute response: ${JSON.stringify(response.data)}`);
            if(response?.data?.result === "SUCCESS"){
                logger.info(`Successfully created dispute case ${response.data.caseId}`);
                setDisputeResult(response?.data);
                setDisputeSuccessMsg(`Successfully created dispute case ${response.data.caseId}`);
            } else {
                setDisputeErrorMsg(response?.data?.error);
            }
            setLoader(false);
        }).catch(error => {
            logger.error(`submitDispute ERROR: ${error}`);
        })

    }
    

    
    return (
        <>
            {loader ? <Loader /> : null}
            <div className="midsection qm-upload abs z1">
                <div className="rel">
                    <div className="wrap">
                        <h1 className="page-title">{isEmployee? "Employee Billing Dispute Create" : "Billing Dispute Create"}</h1>
                        {isEmployee ? <CompanyDropdown/> : null}
                        <div className="section">
                            <div className="content">
                                <div className="sub-hdr">
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={`${style.subSectionTitle} m-0`}>Contact</h2>
								</div>
                                <div className="sub-section">
                                    <div className={`row ${style.disputeRowGap} `}>
                                        <div className="col-4">
                                            <label className={style.disputeLabel}>Name<span className={style.disputeMandatory}>*</span></label>
                                                <select className={`form-control  ${style.disputeSelect} ${style.disputeRequired}`} id="contact" onChange={disputeInputHandler} value={disputeObj.contact}>
                                                    <option value=""></option>
                                                    {contactOptions?.map((obj, index) => (
                                                        <option key={index} value={obj.id}>{obj.name}</option>
                                                    ))}
                                                </select>
                                                {disputeErrorObj.contactError ?
                                                    <div className="invalid-feedback d-block">
                                                        {disputeErrorObj.contactError}
                                                    </div> : null
                                                }
                                        </div>
                                        <div className="col-4">
                                            <label className={style.disputeLabel}>Phone<span className={style.disputeMandatory}>*</span></label>
                                            <input type="text" className={style.disputeInput} id="phone" maxLength="10" onChange={disputeInputHandler} value={disputeObj.phone} disabled></input>
                                            {disputeErrorObj.phoneError ?
                                                <div className="invalid-feedback d-block">
                                                    {disputeErrorObj.phoneError}
                                                </div> : null
                                            }
                                        </div>
                                        <div className="col-4">
                                            <label className={style.disputeLabel}>Email<span className={style.disputeMandatory}>*</span></label>
                                            <input type="text" className={style.disputeInput} id="email" onChange={disputeInputHandler} value={disputeObj.email} disabled></input>
                                            {disputeErrorObj.emailError ?
                                                <div className="invalid-feedback d-block">
                                                    {disputeErrorObj.emailError}
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={style.disputeDividers}></div>
                                <div className="sub-hdr">
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={`${style.subSectionTitle} m-0`}>Dispute Details</h2>
								</div>
                                <div className="sub-section">
                                    <div className={`row ${style.disputeRowGap} `}>
                                        <div className="col-4">
                                            <label className={style.disputeLabel}>Disputed Invoice</label>
                                            <input type="text" className={style.disputeInput} id="disputedInvoice" maxLength="255" onChange={disputeInputHandler}></input>
                                        </div>
                                        <div className="col-4">
                                            <label className={style.disputeLabel}>Disputed Invoice Date</label>
                                            <CustomDatePicker
                                                value={disputeObj?.disputedDate}
                                                onChange={(newDate) => setDisputeObj((prevState) => ({ ...prevState, "disputedDate": newDate}))}
                                                id="disputedDate"
                                                name="disputeDate"
                                            />
                                        </div>
                                        <div className="col-4">
                                            <label className={style.disputeLabel}>Circuit ID / ANI</label>
                                            <select className={` form-control  ${style.disputeSelect} ${style.disputeRequired} `} id="circuitIdAni" onChange={disputeInputHandler} value={disputeObj?.circuitIdAni}>
                                                <option value=""></option>
                                                {circuitOptions?.map((obj, index) => (
                                                    <option key={index} value={obj.circuitId}>{obj.circuitId}</option>
                                                ))}         
                                            </select>
                                        </div>      
                                    </div>
                                    <div className={`row ${style.disputeRowGap} `}>
                                        <div className="col-4">
                                            <label className={style.disputeLabel}>Dispute Amount<span className={style.disputeMandatory}>*</span></label>
                                            <input type="text" className={style.disputeInput} id="disputeAmount" maxLength="17" onChange={disputeInputHandler} value={disputeObj?.disputeAmount}></input>
                                            {disputeErrorObj.disputeAmountError ?
                                                <div className="invalid-feedback d-block">
                                                    {disputeErrorObj.disputeAmountError}
                                                </div> : null
                                            }
                                        </div>
                                        <div className="col-4">
                                            <label className={style.disputeLabel}>Dispute Reason<span className={style.disputeMandatory}>*</span></label>
                                            <select className={` form-control  ${style.disputeSelect} ${style.disputeRequired} `} id="disputeReason" onChange={disputeInputHandler} value={disputeObj?.disputeReason}>
                                                <option></option>
                                                {Object.keys(disputeMenuOptions).map((reason, index) => (
                                                        <option key={index} value={reason}>{reason}</option>   
                                                ))}
                                            </select>
                                            {disputeErrorObj.disputeReasonError ?
                                                <div className="invalid-feedback d-block">
                                                    {disputeErrorObj.disputeReasonError}
                                                </div> : null
                                            }
                                        </div>
                                        {disputeObj?.disputeReason ?
                                            <div className="col-4">
                                                <label className={style.disputeLabel}>Dispute Reason Detail<span className={style.disputeMandatory}>*</span></label>
                                                <select className={` form-control  ${style.disputeSelect} ${style.disputeRequired} `} id="disputeReasonDetail" onChange={disputeInputHandler} value={disputeObj?.disputeReasonDetail}>
                                                    <option value=""></option>
                                                    {disputeMenuOptions[disputeObj?.disputeReason].map((detail, index) => (
                                                        <option key={index} value={detail}>{detail}</option>
                                                    ))}        
                                                </select>
                                                {disputeErrorObj.disputeReasonDetailError ?
                                                    <div className="invalid-feedback d-block">
                                                        {disputeErrorObj.disputeReasonDetailError}
                                                    </div> : null
                                                }
                                            </div> : null
                                        }
                                    </div>
                                    <div className={`row ${style.disputeRowGap} `}>
                                        <div className="col-12">
                                            <label className={style.disputeLabel}>Dispute Description</label>
                                            <textarea className={style.disputeTextArea} id="disputeDescription" maxLength="30000" onChange={disputeInputHandler} value={disputeObj?.disputeDescription}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className={style.disputeDividers}></div>
                                <div className={`row ${style.disputeRowGap}`}>
                                    <div className="col-12">
                                        <div className="text-center">
                                            {requiredMsg ?
												<p className="text-danger text-center mb-2">{requiredMsg}</p> : null
											}
                                            {disputeErrorMsg ?
												<p className="text-danger text-center mb-2">{disputeErrorMsg}</p> : null
											}
                                            {disputeSuccessMsg ?
                                                <>
                                                    <p className="text-success text-center mb-2">{disputeSuccessMsg}</p> 
                                                    <Redirect to={{pathname: `/Billing/Dispute/Details/${disputeResult?.id}`, state: {disputeData: disputeResult}}}/>
                                                </> : null
                                            }
                                            <button className={` ico-button light-button  ${style.disputeBtn} `} type="button" onClick={() => clearDisputeForm()}>
                                                Clear
                                                <svg className="ico-x">
                                                    <path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
                                                </svg>
                                            </button>
                                            <button className={` ico-button lhs light-button ${style.disputeBtn} ml-3 `} type="button" onClick={() => submitDispute()}>
                                                Submit
                                                <svg className="ico light-ico ico-arrow">
                                                    <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                                                </svg>
                                            </button> 
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}

export default BillingDisputeCreate;