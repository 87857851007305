import {
	ORDER_A_CUSTOMER_NAME,
	ORDER_A_DEMARCATION,
	ORDER_A_LOCATION_NOTES,
	ORDER_A_FIBER_TYPE,
	ORDER_A_HANDOFF,
	ORDER_A_LCON_EMAIL,
	ORDER_A_LCON_NAME,
	ORDER_A_LCON_PHONE,
	ORDER_ACNA,
	ORDER_ACTIVITY_REQUESTED,
	ORDER_ASN,
	ORDER_BGP,
	ORDER_BGP_COMM,
	ORDER_BGP_PREFIX,
	ORDER_BW,
	ORDER_CUSTOMER_ASSIGNED,
	ORDER_CUSTOMER_CIRCUIT,
	ORDER_CUSTOMER_PON,
	ORDER_DATA_TECH_CONTACT,
	ORDER_DATA_TECH_EMAIL,
	ORDER_DATA_TECH_PHONE,
	ORDER_EVCS,
	ORDER_CIRCUIT_LIST_COUNT,
	ORDER_CIRCUIT_SEARCH,
	ORDER_EXISTING_CIRCUIT,
	ORDER_MANUAL_CIRCUIT,
	ORDER_GAID,
	ORDER_EXPEDITE_REQUEST,
	ORDER_INITIAL_TERM,
	ORDER_JURISDICTIONAL_TRAFFIC,
	ORDER_LAN_REQUIREMENTS,
	ORDER_LAYER_2_PROTOCOL,
	ORDER_MOUNTING_EQUIPMENT,
	ORDER_MPLS_CIRCUIT,
	ORDER_MPLS_ROUTE,
	ORDER_MPLS_STATIC_IP,
	ORDER_MTU_SIZE,
	ORDER_NETWORK_NAME,
	ORDER_NEW_DATA_TECH_CONTACT,
	ORDER_NEW_PRIMARY_CONTACT,
	ORDER_NEW_VLAN,
	ORDER_NUMBER_OF_NNI,
	ORDER_NNI,
	ORDER_NNI_CIRCUIT_ID,
	ORDER_NNI_INTERCONNECTION_LOC,
	ORDER_NOTES,
	ORDER_NOTES_INTERNAL,
	ORDER_GENERAL_NOTES,
	ORDER_NUMBER_NNI_LOCATIONS,
	ORDER_OTHER_FIELD,
	ORDER_OWN_IP,
	ORDER_PREFIX,
	ORDER_PRIMARY_CONTACT,
	ORDER_PRIMARY_EMAIL,
	ORDER_PRIMARY_PHONE,
	ORDER_QOS,
	ORDER_QTY,
	ORDER_RAMP_UP,
	ORDER_REMOTE_ASN,
	ORDER_REMOVE_OVERRRIDE,
	ORDER_REMOVE_VRF_OVERRIDE,
	ORDER_REQUESTED_SERVICE,
	ORDER_SERVICE_FORM,
	ORDER_STATIC_IP,
	ORDER_TERMS_CONDITIONS,
	ORDER_UPLOAD_TYPE,
	ORDER_VLAN,
	ORDER_WAN_IP,
	ORDER_WAN_REQUIREMENTS,
	ORDER_WAN_SFP,
	ORDER_Z_CUSTOMER_NAME,
	ORDER_Z_DEMARCATION,
	ORDER_Z_LOCATION_NOTES,
	ORDER_Z_FIBER_TYPE,
	ORDER_Z_HANDOFF,
	ORDER_Z_LCON_EMAIL,
	ORDER_Z_LCON_NAME,
	ORDER_Z_LCON_PHONE,
	QUOTED_DATA,
	RESET_ACTION,
	ORDER_LEASENET_ID
} from "../actions/types/actionTypes"


const initialState = {
	quotedData: {},
	contactInfo: {
		orig_contact: "",
		new_orig_contact: "",
		orig_phone: "",
		orig_email: "",
		tech_contact: "",
		new_tech_contact: "",
		tech_phone: "",
		tech_email: ""
	},
	generalInfo: {
		cust_pon: "",
		cust_circuit: "",
		leasenet_id: "",
		serv_date: "",
		expedite_req: "N",
		initial_term: "",
		acna: ""
	},
	serviceInfo: {
		circuitListCount: "",
		circuitSearch: "",
		cpt_activity: "",
		order_qty: 1,
		bandwidth: "",
		ex_circuit: "",
		manual_circuit: "",
		gaid: "",
		mounting_equip: "",
		wan_sfp: ""
	},
	locA: {
		a_cp_name: "",
		a_lcon_name: "",
		a_lcon_phone: "",
		a_lcon_email: "",
		a_handoff: "",
		a_dem_pt: "",
		a_remarks: "",
		a_fiber_conn: "",
		numberNNI: ""
	},
	locZ: {
		z_cp_name: "",
		z_lcon_name: "",
		z_lcon_phone: "",
		z_lcon_email: "",
		z_handoff: "",
		z_dem_pt: "",
		z_remarks: "",
		z_fiber_conn: ""
	},
	productDetails: {
		wan_id: "",
		lan_id: "",
		ips: "",
		rampup: false,
		jurisdiction: "",
		ceMtu: "",
		mtu_other: "",
		ceVlan: "",
		vlan_customer: "",
		nni_circuit_id: "",
		eline_evc: "",
		eline_l2cp: "",
		interNNi: "",
		newVlan: false
	},
	vrfDetails: {
		vrf_conn: 1,
		nni: "",
		mpls_circuit: "",
		override: "",
		bgp: "",
		asn: "",
		prefix: "",
		network_name: ""
	},
	mplsRouteDetails: {
		mpls_route: "",
		own_ip: "N",
		bgp_prefix: 50,
		remove_override: "",
		bgp_comm: "",
		remote_asn: 65000,
		qos: "",
		mpls_static_ip: "",
		wan_ip: ""
	},
	orderReview: {
		upload_type: "",
		notes: "",
		notes_internal: "1",
		general_notes: "",
		conditions: false,
		generateServiceOrder: false
	}
};


const orderCreateReducer = (state = initialState, action) => {
	switch (action.type) {
		case QUOTED_DATA:
			return {
				...state,
				quotedData: action.payload
			}
		case ORDER_PRIMARY_CONTACT:
			return {
				...state,
				contactInfo: {
					...state.contactInfo,
					orig_contact: action.payload
				}
			}
		case ORDER_NEW_PRIMARY_CONTACT:
			return {
				...state,
				contactInfo: {
					...state.contactInfo,
					new_orig_contact: action.payload
				}
			}
		case ORDER_PRIMARY_PHONE:
			return {
				...state,
				contactInfo: {
					...state.contactInfo,
					orig_phone: action.payload
				}
			}
		case ORDER_PRIMARY_EMAIL:
			return {
				...state,
				contactInfo: {
					...state.contactInfo,
					orig_email: action.payload
				}
			}
		case ORDER_DATA_TECH_CONTACT:
			return {
				...state,
				contactInfo: {
					...state.contactInfo,
					tech_contact: action.payload
				}
			}
		case ORDER_NEW_DATA_TECH_CONTACT:
			return {
				...state,
				contactInfo: {
					...state.contactInfo,
					new_tech_contact: action.payload
				}
			}

		case ORDER_DATA_TECH_PHONE:
			return {
				...state,
				contactInfo: {
					...state.contactInfo,
					tech_phone: action.payload
				}
			}
		case ORDER_DATA_TECH_EMAIL:
			return {
				...state,
				contactInfo: {
					...state.contactInfo,
					tech_email: action.payload
				}
			}
		case ORDER_CUSTOMER_PON:
			return {
				...state,
				generalInfo: {
					...state.generalInfo,
					cust_pon: action.payload
				}
			}
		case ORDER_CUSTOMER_CIRCUIT:
			return {
				...state,
				generalInfo: {
					...state.generalInfo,
					cust_circuit: action.payload
				}
			}
		case ORDER_LEASENET_ID:
			return {
				...state,
				generalInfo: {
					...state.generalInfo,
					leasenet_id: action.payload
				}
			}
		case ORDER_REQUESTED_SERVICE:
			return {
				...state,
				generalInfo: {
					...state.generalInfo,
					serv_date: action.payload
				}
			}
		case ORDER_EXPEDITE_REQUEST:
			return {
				...state,
				generalInfo: {
					...state.generalInfo,
					expedite_req: action.payload
				}
			}
		case ORDER_INITIAL_TERM:
			return {
				...state,
				generalInfo: {
					...state.generalInfo,
					initial_term: action.payload
				}
			}
		case ORDER_ACNA:
			return {
				...state,
				generalInfo: {
					...state.generalInfo,
					acna: action.payload
				}
			}
		case ORDER_ACTIVITY_REQUESTED:
			return {
				...state,
				serviceInfo: {
					...state.serviceInfo,
					cpt_activity: action.payload
				}
			}
		case ORDER_QTY:
			return {
				...state,
				serviceInfo: {
					...state.serviceInfo,
					order_qty: action.payload
				}
			}
		case ORDER_BW:
			return {
				...state,
				serviceInfo: {
					...state.serviceInfo,
					bandwidth: action.payload
				}
			}
		case ORDER_CIRCUIT_LIST_COUNT:
			return {
				...state,
				serviceInfo: {
					...state.serviceInfo,
					circuitListCount: action.payload
				}
			}
		case ORDER_CIRCUIT_SEARCH:
			return {
				...state,
				serviceInfo: {
					...state.serviceInfo,
					circuitSearch: action.payload
				}
			}
		case ORDER_EXISTING_CIRCUIT:
			return {
				...state,
				serviceInfo: {
					...state.serviceInfo,
					ex_circuit: {
						...state.serviceInfo.ex_circuit,
						[action.index]: action.payload
					}
				}
			}
		case ORDER_MANUAL_CIRCUIT:
			return {
				...state,
				serviceInfo: {
					...state.serviceInfo,
					manual_circuit: {
						...state.serviceInfo.manual_circuit,
						[action.index]: action.payload
					}
				}
			}
		case ORDER_GAID:
			return {
				...state,
				serviceInfo: {
					...state.serviceInfo,
					gaid: action.payload
				}
			}
		case ORDER_A_CUSTOMER_NAME:
			return {
				...state,
				locA: {
					...state.locA,
					a_cp_name: action.payload
				}
			}
		case ORDER_A_LCON_NAME:
			return {
				...state,
				locA: {
					...state.locA,
					a_lcon_name: action.payload
				}
			}
		case ORDER_A_LCON_PHONE:
			return {
				...state,
				locA: {
					...state.locA,
					a_lcon_phone: action.payload
				}
			}
		case ORDER_A_LCON_EMAIL:
			return {
				...state,
				locA: {
					...state.locA,
					a_lcon_email: action.payload
				}
			}

		case ORDER_A_HANDOFF:
			return {
				...state,
				locA: {
					...state.locA,
					a_handoff: action.payload
				}
			}
		case ORDER_A_DEMARCATION:
			return {
				...state,
				locA: {
					...state.locA,
					a_dem_pt: action.payload
				}
			}
		case ORDER_A_LOCATION_NOTES:
			return {
				...state,
				locA: {
					...state.locA,
					a_remarks: action.payload
				}
			}
		case ORDER_A_FIBER_TYPE:
			return {
				...state,
				locA: {
					...state.locA,
					a_fiber_conn: action.payload
				}
			}
		case ORDER_Z_CUSTOMER_NAME:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_cp_name: action.payload
				}
			}
		case ORDER_Z_LCON_NAME:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_lcon_name: action.payload
				}
			}
		case ORDER_Z_LCON_PHONE:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_lcon_phone: action.payload
				}
			}
		case ORDER_Z_LCON_EMAIL:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_lcon_email: action.payload
				}
			}

		case ORDER_Z_HANDOFF:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_handoff: action.payload
				}
			}
		case ORDER_Z_DEMARCATION:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_dem_pt: action.payload
				}
			}
		case ORDER_Z_LOCATION_NOTES:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_remarks: action.payload
				}
			}
		case ORDER_Z_FIBER_TYPE:
			return {
				...state,
				locZ: {
					...state.locZ,
					z_fiber_conn: action.payload
				}
			}
		case ORDER_WAN_REQUIREMENTS:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					wan_id: action.payload
				}
			}
		case ORDER_LAN_REQUIREMENTS:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					lan_id: action.payload
				}
			}
		case ORDER_STATIC_IP:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					ips: action.payload
				}
			}
		case ORDER_UPLOAD_TYPE:
			return {
				...state,
				orderReview: {
					...state.orderReview,
					upload_type: action.payload
				}
			}
		case ORDER_NOTES:
			return {
				...state,
				orderReview: {
					...state.orderReview,
					notes: action.payload
				}
			}
		case ORDER_NOTES_INTERNAL:
			return {
				...state,
				orderReview: {
					...state.orderReview,
					notes_internal: action.payload

				}
			}
		case ORDER_GENERAL_NOTES:
			return {
				...state,
				orderReview: {
					...state.orderReview,
					general_notes: action.payload
				}
			}
		case ORDER_TERMS_CONDITIONS:
			return {
				...state,
				orderReview: {
					...state.orderReview,
					conditions: action.payload

				}
			}
		case ORDER_RAMP_UP:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					rampup: action.payload
				}
			}
		case ORDER_JURISDICTIONAL_TRAFFIC:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					jurisdiction: action.payload
				}
			}
		case ORDER_MOUNTING_EQUIPMENT:
			return {
				...state,
				serviceInfo: {
					...state.serviceInfo,
					mounting_equip: action.payload
				}
			}
		case ORDER_WAN_SFP:
			return {
				...state,
				serviceInfo: {
					...state.serviceInfo,
					wan_sfp: action.payload
				}
			}
		case ORDER_MTU_SIZE:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					ceMtu: action.payload
				}
			}
		case ORDER_OTHER_FIELD:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					mtu_other: action.payload
				}
			}
		case ORDER_VLAN:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					ceVlan: {
						...state.productDetails.ceVlan,
						[action.index]: action.payload
					}
				}
			}
		case ORDER_CUSTOMER_ASSIGNED:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					vlan_customer: action.payload
				}
			}
		case ORDER_NNI_CIRCUIT_ID:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					nni_circuit_id: action.payload
				}
			}
		case ORDER_EVCS:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					eline_evc: action.payload
				}
			}
		case ORDER_LAYER_2_PROTOCOL:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					eline_l2cp: action.payload
				}
			}
		case ORDER_NNI_INTERCONNECTION_LOC:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					interNNi: action.payload
				}
			}
		case ORDER_NUMBER_NNI_LOCATIONS:
			return {
				...state,
				locA: {
					...state.locA,
					numberNNI: action.payload
				}
			}
		case ORDER_NEW_VLAN:
			return {
				...state,
				productDetails: {
					...state.productDetails,
					newVlan: {
						...state.productDetails.newVlan,
						[action.index]: action.payload
					}
				}
			}
		case ORDER_NUMBER_OF_NNI:
			return {
				...state,
				vrfDetails: {
					...state.vrfDetails,
					vrf_conn: action.payload
				}
			}
		case ORDER_NNI:
			return {
				...state,
				vrfDetails: {
					...state.vrfDetails,
					nni: {
						...state.vrfDetails.nni,
						[action.index]: action.payload
					}
				}

			}

		case ORDER_MPLS_CIRCUIT:
			return {
				...state,
				vrfDetails: {
					...state.vrfDetails,
					mpls_circuit: {
						...state.vrfDetails.mpls_circuit,
						[action.index]: action.payload
					}
				}
			}
		case ORDER_REMOVE_VRF_OVERRIDE:
			return {
				...state,
				vrfDetails: {
					...state.vrfDetails,
					override: {
						...state.vrfDetails.override,
						[action.index]: action.payload
					}
				}
			}
		case ORDER_BGP:
			return {
				...state,
				vrfDetails: {
					...state.vrfDetails,
					bgp: {
						...state.vrfDetails.bgp,
						[action.index]: action.payload
					}
				}
			}
		case ORDER_ASN:
			return {
				...state,
				vrfDetails: {
					...state.vrfDetails,
					asn: {
						...state.vrfDetails.asn,
						[action.index]: action.payload
					}
				}
			}
		case ORDER_PREFIX:
			return {
				...state,
				vrfDetails: {
					...state.vrfDetails,
					prefix: {
						...state.vrfDetails.prefix,
						[action.index]: action.payload
					}
				}
			}
		case ORDER_NETWORK_NAME:
			return {
				...state,
				vrfDetails: {
					...state.vrfDetails,
					network_name: {
						...state.vrfDetails.network_name,
						[action.index]: action.payload
					}
				}
			}
		case ORDER_MPLS_ROUTE:
			return {
				...state,
				mplsRouteDetails: {
					...state.mplsRouteDetails,
					mpls_route: action.payload
				}
			}
		case ORDER_OWN_IP:
			return {
				...state,
				mplsRouteDetails: {
					...state.mplsRouteDetails,
					own_ip: action.payload
				}
			}
		case ORDER_BGP_PREFIX:
			return {
				...state,
				mplsRouteDetails: {
					...state.mplsRouteDetails,
					bgp_prefix: action.payload
				}
			}
		case ORDER_REMOVE_OVERRRIDE:
			return {
				...state,
				mplsRouteDetails: {
					...state.mplsRouteDetails,
					remove_override: action.payload
				}
			}
		case ORDER_BGP_COMM:
			return {
				...state,
				mplsRouteDetails: {
					...state.mplsRouteDetails,
					bgp_comm: action.payload
				}
			}
		case ORDER_REMOTE_ASN:
			return {
				...state,
				mplsRouteDetails: {
					...state.mplsRouteDetails,
					remote_asn: action.payload
				}
			}
		case ORDER_QOS:
			return {
				...state,
				mplsRouteDetails: {
					...state.mplsRouteDetails,
					qos: action.payload
				}
			}
		case ORDER_MPLS_STATIC_IP:
			return {
				...state,
				mplsRouteDetails: {
					...state.mplsRouteDetails,
					mpls_static_ip: action.payload
				}
			}
		case ORDER_WAN_IP:
			return {
				...state,
				mplsRouteDetails: {
					...state.mplsRouteDetails,
					wan_ip: action.payload
				}
			}
		case ORDER_SERVICE_FORM:
			return {
				...state,
				orderReview: {
					...state.orderReview,
					generateServiceOrder: action.payload
				}
			}
		case RESET_ACTION:
			return initialState;
		default:
			return state
	}
}
export default orderCreateReducer;
