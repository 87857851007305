import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ExportXLSX } from '../../utils/ExportXLSX';
import style from "../quote/QuoteSummary.module.css";
import ReportHelper from '../../services/ReportService';
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import CustomTableComponent from '../components/tables/CustomTableComponent'; // Import the custom table component
import ReportOptions from './ReportOptions'; // Import the report options component

const AeNamQuoteReport = ({ selectedId }) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [loader, setLoader] = useState(false);
    const [quoteSummaryData, setQuoteSummaryData] = useState([]);
    const [reportOptionOpened, setReportOptionOpened] = useState(true);
    let today = new Date();
    let todayTimestamp = new Date().setDate(today.getDate());
    let formatToday = new Date(todayTimestamp).toISOString().substring(0, 10);
    let timestamp = new Date().setDate(today.getDate() - 90);
    let past90Days = new Date(timestamp).toISOString().substring(0, 10);
    const [dateFrom, setDateFrom] = useState(past90Days); // Default to past 90 days
    const [dateTo, setDateTo] = useState(formatToday);    // Default to today
    const [limit, setLimit] = useState(1000);             // Default limit to 1000
    const [product, setProduct] = useState('all');        // Default product to 'all'
    const [excludeApiQuotes, setExcludeApiQuotes] = useState(true); // Default to exclude API quotes
    const [errorMSG, setErrorMSG] = useState('');
    const [pageSize, setPageSize] = useState(25);
    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    const columns = [
        {
            field: 'Company',
            headerName: 'Company',
            flex: 1,
            ssortingOrder: ['desc', 'asc']
        },
        {
            field: 'Product',
            headerName: 'Product',
            flex: 1,
            sortingOrder: ['desc', 'asc'],
            renderCell: (params) => quoteFormater(params),
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'RefId',
            headerName: 'Ref ID / ICB Case',
            flex: 1,
            sortingOrder: ['desc', 'asc'],
            renderCell: (params) => icbFormater(params),
        },
        {
            field: 'QuoteName',
            headerName: 'Quote Name',
            flex: 1,
            sortingOrder: ['desc', 'asc'],
        },
        {
            field: 'Requested',
            headerName: 'Requested Date',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'Ordered',
            headerName: 'Ordered Date',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'Requester',
            headerName: 'Requester',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        }
    ];

    const quoteFormater = (params) => {
        let row = params.row;
        let URL = `/Quote/View/${row.RefId}`;
        return (
            <>
                <NavLink className={style.quoteLink} to={URL} activeClassName="active">{row.Product}</NavLink>
                {row.filterList ?
                    <div>
                        {
                            row.filterList.map((i, e) =>
                                <span className={style.filterList} key={i}>{i}</span>
                            )
                        }
                    </div>
                    : null}
            </>
        );
    };

    const icbFormater = (params) => {
        let row = params.row;
        let URL = row.icbcase_url !== null ? `${row.icbcase_url}` : null;
        return (
            <>
                <div>
                    <span className={style.filterList}>{row.RefId}</span>
                </div>
                {URL ? <li><a href={URL}>{row.icb_case}</a></li> : null}
            </>
        );
    };

    const reportOption = () => {
        setReportOptionOpened(!reportOptionOpened);
    };

    const reloadSummary = () => {
        logger.info(`dateFrom: ${dateFrom}, dateTo: ${dateTo}, limit: ${limit}, product: ${product}, excludeApiQuotes: ${excludeApiQuotes}, selectedId: ${selectedId}`);
        getQuoteSummaryData(dateFrom, dateTo, limit, product, excludeApiQuotes, selectedId);
    };

    const getQuoteSummaryData = (dateFrom, dateTo, limit, product, excludeApiQuotes, selectedId) => {
        setErrorMSG('');
        setLoader(true);
        setQuoteSummaryData([]);
        ReportHelper.getAeNamReports('aeNamQuoteSummary', selectedId, dateFrom, dateTo, limit, product, excludeApiQuotes).then((response) => {
            logger.info(`getQuoteData response: ${JSON.stringify(response)}`);
            if (response.data.result === "SUCCESS") {
                setQuoteSummaryData(response?.data?.data || []);
            } else {
                setErrorMSG(response.data?.error);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            logger.error(`getQuoteData Error: ${error}`);
            setErrorMSG(error);
        });
    };

    useEffect(() => {
        logger.info("In useEffect about to call quote summary for selectedId: " + selectedId);
        if (selectedId) {
            getQuoteSummaryData(dateFrom, dateTo, limit, product, excludeApiQuotes, selectedId);
        }
    }, [selectedId]);

    return (
        <div className={`content ${style.contentQueryPage} `}>
            {loader ? <Loader /> : null}
            <>
                <div className={style.filterWrap}>
                    <div>
                        <button className={`ico-button light-button`}>
                            <ExportXLSX
                                label="Download Summary"
                                csvData={quoteSummaryData}
                                fileName={`Quote_Summary_${(() => {
                                    const currentDate = new Date();
                                    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                                    const day = String(currentDate.getDate()).padStart(2, '0');
                                    const year = currentDate.getFullYear();
                                    return `${month}_${day}_${year}`;
                                })()}`}
                            />
                        </button>
                    </div>
                    <div className="expander" collapsed-id="icon-content">
                        <svg className="ico-link light-ico ico-add" onClick={reportOption}>
                            <path d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
                        </svg>
                    </div>
                </div>
                {reportOptionOpened ? (
                    <ReportOptions
                        dateFrom={dateFrom}
                        setDateFrom={setDateFrom}
                        dateTo={dateTo}
                        setDateTo={setDateTo}
                        limit={limit}
                        setLimit={setLimit}
                        product={product}
                        setProduct={setProduct}
                        excludeApiQuotes={excludeApiQuotes}
                        setExcludeApiQuotes={setExcludeApiQuotes}
                        reloadSummary={reloadSummary}
                    />
                ) : null}
                <div className="rel b mt-3"></div>
                <div className={`${style.tableWrap} tableWrap quoteSummaryTableWrap`}>
                    {quoteSummaryData.length === 0 ? (
                        <p className="text-center my-4">{errorMSG}</p>
                    ) : (
                        <div className={`rel pagination-table page-list my-3 `}>
                            <CustomTableComponent
                                rows={quoteSummaryData}
                                columns={columns}
                                pageSize={pageSize}
                                onPageSizeChange={(newSize) => setPageSize(newSize)}
                                filterModel={filterModel}
                                onFilterModelChange={(model) => setFilterModel(model)}
                                rowIdField="RefId"
                            />
                        </div>
                    )}
                </div>
            </>
        </div>
    );
}

export default AeNamQuoteReport;