/**
 * Created by e0173131 on 1/20/2021.
 */
import { useState } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import HomeData from '../../../services/HomeServices';
import Loader from '../../shared/Loader';

const TabsOrderManager = () => {
	const [refIdVal, setRefIdVal] = useState("");
	const [searchByIdError, setSearchByIdError] = useState("");
	const [quoteRedirect, setQuoteRedirect] = useState(false);
	const [orderRedirect, setOrderRedirect] = useState(false);
	const [orderCreateRedirect, setOrderCreateRedirect] = useState(false);
	const [orderSummaryRedirect, setOrderSummaryRedirect] = useState(false);
	const [orderPonVal, setOrderPonVal] = useState("");
	const [loader, setLoader] = useState(false);

	const searchHandler = async (ev) => {
		setRefIdVal(ev.target.value.toUpperCase());
		setSearchByIdError("");
	}

	/**************************************************************************
Function to validate the ref id entered before redirecting to Quote / Order View
***************************************************************************/
	const searchId = (ev) => {
		ev.preventDefault();
		setSearchByIdError(null);
		setLoader(true);
		if (refIdVal?.length === 0) {
			setSearchByIdError('Windstream Reference ID required.');
			setLoader(false);
		} else {
			HomeData.validateId('order', refIdVal).then((resp) => {
				console.log('RESPONSE from validateId search:' + JSON.stringify(resp.data));
				if (resp.data.result === 'SUCCESS') {
					setRefIdVal(resp.data.refId);
					if (resp.data.type === 'quote') {
						setRefIdVal(resp.data.refId);
						setQuoteRedirect(true);
					} else if (resp.data.type === 'order_summary') {
						setOrderPonVal(resp.data.pon);
						setOrderSummaryRedirect(true);
					} else if (resp.data.type === 'order_create') {
						setOrderCreateRedirect(true);
					} else {
						setOrderRedirect(true);
					}
				} else if (resp.data.result === 'FAIL') {
					setSearchByIdError(resp.data.error);
				} else {
					setSearchByIdError("No Quote or Order found.");
				}
				setLoader(false);
			});
		}
	}

	return (
		<>
			{loader ? <Loader /> : null}
			<div className="tab-nav cf d-flex align-items-bottom">
				<NavLink className="tab-opt qm-summary" to="/Order/Summary" activeClassName="active">
					<span>Summary</span>
				</NavLink>
				<div className="input-field dark">
					<input type='text' name="quoteRefId" id='quoteRefId' onChange={searchHandler} placeholder='Quick Search by ID' />
					{searchByIdError?.length > 0 ?
						<p className='text-danger small'>{searchByIdError}</p> : null
					}
				</div>
				<button className="quote-summary-search border-0" onClick={searchId}></button>
			</div>
			{quoteRedirect ?
				<Redirect to={`/Quote/View/${refIdVal}`} /> : null
			}
			{orderRedirect ?
				<Redirect to={`/Order/View/${refIdVal}`} /> : null
			}
			{orderCreateRedirect ?
				<Redirect to={`/Order/Create/${refIdVal}`} /> : null
			}
			{orderSummaryRedirect ?
		 		<Redirect to={`/Order/Summary/${orderPonVal}`} /> : null
		 	}
		</>
	);
}
export default TabsOrderManager;
