import { useEffect, useState, useMemo } from "react";
import Footer from '../shared/Footer';
import { AlertWindow } from './AlertWindow';
import { useSelector } from 'react-redux';
import HomeData from '../../services/HomeServices';
import Loader from "../shared/Loader";
import { Link, Redirect } from "react-router-dom";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import style from "../employee/EmployeeHome.module.css";

const Home = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const fetchStoreAlert = useSelector(state => state.isShowAlertHome);
	const [hasError, setHasError] = useState(null);
	const [avloader, setAvLoader] = useState(false); // AV loader
	const [qData, setQData] = useState('');      // quote data
	const [stateData, setStateList] = useState([]); // build states list
	const [addressErrorObj, setAddressErrorObj] = useState({}); // address error checks
	const [avResults, setAvResults] = useState(''); // (AV) address vlaidator results
	const [showAvForm, setShowAvForm] = useState(true); // AV input form
	const [showAvResults, setShowAvResults] = useState(false);
	const [addName, setAddName] = useState("");
	const [addCity, setCity] = useState("");
	const [addState, setState] = useState("");
	const [addZip, setZip] = useState("");
	const [quoteRedirect, setQuoteRedirect] = useState(false);
	const [quoteRefIdVal, setQuoteRefIdVal] = useState("");
	const [orderSummaryRedirect, setOrderSummaryRedirect] = useState(false);
	const [orderPonVal, setOrderPonVal] = useState("");
	const [orderRedirect, setOrderRedirect] = useState(false);
	const [orderCreateRedirect, setOrderCreateRedirect] = useState(false);
	const [orderRefIdVal, setOrderRefIdVal] = useState("");
	const [incidentRedirect, setIncidentRedirect] = useState(false);
	const [incidentIdVal, setIncidentIdVal] = useState("");
	const [searchByIdError, setSearchByIdError] = useState("");

	const google = window.google = window.google ? window.google : {};

	const addressValues = {
		address: addName,
		city: addCity,
		state: addState,
		zip: addZip
	};

	var autocomplete;

	function initAutocomplete() {
		// Create the autocomplete object, restricting the search to geographical
		// location types.
		autocomplete = new google.maps.places.Autocomplete(
			(document.getElementById('autocomplete')), {
			types: ['geocode']
		});

		// When the user selects an address from the dropdown, populate the address
		// fields in the form.
		google.maps.event.addListener(autocomplete, 'place_changed', function () {
			fillInAddress(autocomplete);
		});
	}

	function fillInAddress(ac) {
		// Get the place details from the autocomplete object.
		var place = ac.getPlace();
		setAddName(place.formatted_address)
		for (var x = 0; x < place.address_components.length; x++) {
			switch (place.address_components[x].types[0]) {
				case 'locality':
					setCity(place.address_components[x].short_name);
					break;
				case 'administrative_area_level_1':
					setState(place.address_components[x].short_name);
					break;
				case 'postal_code':
					setZip(place.address_components[x].short_name);
					break;
				default:
					break;
			}
		}
		logger.info(place);
		return place;
	}

	const addressHandler = (ev) => {
		switch (ev.target.id) {
			case 'autocomplete':
				setAddName(ev.target.value)
				break;
			case 'city':
				setCity(ev.target.value)
				break;
			case 'state':
				setState(ev.target.value)
				break;
			case 'zip':
				setZip(ev.target.value)
				break;
			default:
				break;
		}

	}

	const searchHandler = async (ev) => {
		logger.info(ev.target.id + ' : ' + ev.target.value.toUpperCase());
		setSearchByIdError('');
		switch (ev.target.id) {
			case 'quoteRefId':
				setQuoteRefIdVal(ev.target.value.toUpperCase())
				break;
			case 'orderRefId':
				setOrderRefIdVal(ev.target.value.toUpperCase())
				break;
			case 'incidentId':
				setIncidentIdVal(ev.target.value)
				break;
			default:
				break;
		}

	}

	useEffect(() => {
		/*We should only calls these service calls if the Customer is logged in*/
		if (localStorage.getItem("isLoggedIn")) {
			/*Getting Recent Quote for Widget*/
			HomeData.getRecentQuote().then((response) => {
				logger.info(`Getting Recent Quote for Customer Home`);
				setQData(response.data[0]);
			}).catch((error) => {
				logger.error("getRecentQuote ERROR: " + error)
				setHasError(error);
			});

			/*Getting list of states for AV Widget*/
			HomeData.getStateList().then(resp => {
				logger.info(`Getting States on Customer Home`);
				setStateList(resp.data);
			}).catch((error) => {
				logger.error("getStates ERROR: " + error)
				setHasError(error);
			})
		}
		/*Adding this in to prevent an employee from ever getting to the customer home page and an infinite loading screen*/
		if (localStorage.getItem("isEmployeeLoggedIn")) {
			localStorage.setItem("dualLogin", 1);
			window.location = "/Employee/Home";
		}
	}, [logger]);

	useEffect(() => {
		initAutocomplete();
	}, [addName]);

	useEffect(() => {
		setAddressErrorObj({});
	}, [addName, addCity, addCity, addZip])

	useEffect(() => {
		const checkWidget = () => {
			let marketing = document.getElementById("marketing");
			let wgtContent = document.getElementById("wgt-content");

			if (props.widgetStatus) {
				marketing.before(wgtContent);
			} else {
				marketing.after(wgtContent);
			}
		}
		checkWidget();
	}, [props.widgetStatus]);

	// This is called when clicking to validate Address
	const validateAddress = () => {
		let error = false;
		if (addressValues?.address === "") { setAddressErrorObj(prevAddError => ({ ...prevAddError, address: "Address is required" })); error = true; }
		if (addressValues?.city === "") { setAddressErrorObj(prevAddError => ({ ...prevAddError, city: "City is required" })); error = true; }
		if (addressValues?.state === "") { setAddressErrorObj(prevAddError => ({ ...prevAddError, state: "State is required" })); error = true; }
		if (addressValues?.zip === "") { setAddressErrorObj(prevAddError => ({ ...prevAddError, zip: "Zip is required" })); error = true; }

		if (error) return;
		setAvLoader(true);
		HomeData.getAvResults(addressValues).then((resp) => {
			if (resp.data) {
				setAvLoader(false);
				setShowAvForm(false); // Hide form and show result
				setAvResults(resp.data[0]);
				setShowAvResults(true);
			} else {
				logger.error("No response data!");
				setAvLoader(false);
				setShowAvResults(false);
			}
		}).catch(error => {
			logger.error("getAvResults ERROR: " + error)
			setAvLoader(false);
			setShowAvResults(false);
			setHasError(error);
		})
	};

	const searchAgain = async () => { // button to search a new address in AV
		setShowAvResults(false);
		setShowAvForm(true); // Hide form and show result
		setAddName("");
		setCity("");
		setState("");
		setZip("");
	};

	// This is called when clicking to search for Quote by RefId
	const searchQuote = e => {
		e.preventDefault();
		if (quoteRefIdVal?.length === 0) {
			setSearchByIdError('Windstream Reference ID required.');
		} else {
			HomeData.validateId('quote', quoteRefIdVal).then((resp) => {
				logger.info('RESPONSE from quote search:' + JSON.stringify(resp.data));
				if (resp.data.result === 'SUCCESS') {
					if (resp.data.type === 'order') {
						setOrderRefIdVal(resp.data.refId);
						setOrderRedirect(true);
					} else {
						setQuoteRedirect(true);
					}
				} else if (resp.data.result === 'FAIL') {
					setSearchByIdError(resp.data.error);
				} else {
					setSearchByIdError("Quote not found.");
				}
			});
		}
	}

	// This is called when clicking to search for Order by RefId or PON
	const searchOrder = e => {
		setAvLoader(true);
		e.preventDefault();
		if (orderRefIdVal?.length === 0) {
			setSearchByIdError('Windstream Reference ID or PON required.');
		} else {
			HomeData.validateId('order', orderRefIdVal).then((resp) => {
				setAvLoader(false);
				logger.info('RESPONSE from order search:' + JSON.stringify(resp.data));
				if (resp.data.result === 'SUCCESS') {
					logger.info("TYPE: " + resp.data.type);
					setOrderRefIdVal(resp.data.refId);
					if (resp.data.type === 'quote') {
						setQuoteRefIdVal(resp.data.refId);
						setQuoteRedirect(true);
					} else if (resp.data.type === 'order_summary') {
						logger.info("Setting redirect to summary to true");
						setOrderSummaryRedirect(true);
						setOrderPonVal(resp.data.pon);
					} else if (resp.data.type === 'order_create') {
						setOrderCreateRedirect(true);
					} else {
						setOrderRedirect(true);
					}
				} else if (resp.data.result === 'FAIL') {
					setSearchByIdError(resp.data.error);
				} else {
					setSearchByIdError("Order not found.");
				}
			});
		}
	}

	// This is called when clicking to search for Order by RefId
	const searchIncident = e => {
		e.preventDefault();
		if (incidentIdVal?.length === 0) {
			setSearchByIdError('Incident ID required.');
		} else {
			HomeData.validateId('incident', incidentIdVal).then((resp) => {
				if (resp.data.result === 'SUCCESS') {
					setIncidentRedirect(true);
				} else if (resp.data.result === 'FAIL') {
					setSearchByIdError(resp.data.error);
				} else {
					setSearchByIdError("Incident not found.");
				}
			});
		}
	}

	if (quoteRedirect) {
		return <Redirect to={`/Quote/View/${quoteRefIdVal}`} />
	}
	if (orderRedirect) {
		return <Redirect to={`/Order/View/${orderRefIdVal}`} />
	}
	if (orderCreateRedirect) {
		return <Redirect to={`/Order/Create/${orderRefIdVal}`} />
	}
	if (orderSummaryRedirect) {
		return <Redirect to={`/Order/Summary/${orderPonVal}`} />
	}
	if (incidentRedirect) {
		return <Redirect to={`/Network/TicketDetails/${incidentIdVal}`} />
	}

	return (
		<div>
			{hasError && (
				<HTTPErrorHandler error={hasError}></HTTPErrorHandler>
			)}
			{!hasError && (
				<div className="midsection home abs z1">
					<div className="rel">
						{/* <GridFilter/> */}
						<div className="wrap">
							<div className="page-title">
								<img alt="iconnect-logo" src="../../images/iconnect-logo-light-tag.png" />
							</div>

							{fetchStoreAlert.isShow ? <AlertWindow /> : ''}

							<div className="marketing" id="marketing">
								<a href="/Quote/RouteCreator" className="promo">
									<img alt={""} className="rel"
										src={window.location.href + "images/RouteCreator-banner-500x700.png"} />
									<div className="go-internal">
										<svg className="ico ico-link ico-arrow size-m">
											<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z" />
										</svg>
									</div>
									<div className="ux-ring">&nbsp;</div>
								</a>
								<a rel={"noreferrer"} href="https://www.windstreamwholesale.com/network-expansion/"
									className="promo" target="_blank">
									<img alt={""} className="rel"
										src={window.location.href + "images/interactivemap-center-500x700.png"} />
									<div className="go-external">
										<svg className="ico ico-link ico-external">
											<path
												d="M28,0V2H45L14.11,31.93l1.48,1.48L46,4V19h2V0ZM0,9V47H39V19l-2,2V45H2V11H26l2-2Z" />
										</svg>
									</div>
									<div className="ux-ring">&nbsp;</div>
								</a>
								<a rel={"noreferrer"}
									href="https://www.windstreamwholesale.com/windstream-wholesale-colt-technology-services-nokia-join-forces-to-complete-800gbe-optical/"
									className="promo"
									target="_blank">
									<img alt={""} className="rel"
										src={window.location.href + "images/800G-banner-500x700.png"} />
									<div className="go-external">
										<svg className="ico ico-link ico-external">
											<path
												d="M28,0V2H45L14.11,31.93l1.48,1.48L46,4V19h2V0ZM0,9V47H39V19l-2,2V45H2V11H26l2-2Z" />
										</svg>
									</div>
									<div className="ux-ring">&nbsp;</div>
								</a>
							</div>
							<div className="wgt-content cf" id="wgt-content">
								<div className="sub-tile">
									<div className="tile-hdr">
										<div className="sub-filters">
											<div>
												<Link to={`/Quote/View/${qData.refId}`} target="_self">
													<svg className="ico ico-link ico-arrow size-s">
														<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z" />
													</svg>
												</Link>
											</div>
										</div>
										<h2>Recent Quote</h2>
									</div>

									{qData ?

										<div className="tile-content wgt-recent-quote">
											<h2>{qData.product}</h2>
											<div className="table w100">
												<div className="table-row">
													<div className="cell text-grey">
														Reference ID:
													</div>
													<div className="cell">
														<p>{qData.refId}</p>
													</div>
												</div>
												<div className="table-row">
													<div className="cell text-grey">
														Requested Date:
													</div>
													<div className="cell">
														<p>{qData.requestedDate}</p>
													</div>
												</div>
												<div className="table-row">
													<div className="cell text-grey">
														Valid Until:
													</div>
													<div className="cell">
														<p>{qData.validUntil}</p>
													</div>
												</div>
												<div className="table-row">
													<div className="cell text-grey">
														{qData.locA}
													</div>
													<div className="cell text-grey">
														{qData.locZ}
													</div>
												</div>
												<div className="table-row">
													<div className="cell">
														<p>{qData.locAadd2 ?
															<p>{qData.locAadd1}<br></br>{qData.locAadd2}<br></br>{qData.locAadd3}
															</p> : <p>{qData.locAadd1}<br></br>{qData.locAadd3}</p>}</p>
													</div>
													<div className="cell">
														<p>{qData.locZadd2 ?
															<p>{qData.locZadd1}<br></br>{qData.locZadd2}<br></br>{qData.locZadd3}
															</p> : <p>{qData.locZadd1}<br></br>{qData.locZadd3}</p>}</p>
													</div>
												</div>
											</div>
										</div>
										: <Loader />
									}
								</div>

								<div className="sub-tile wgt-validator">
									<div className="tile-hdr">
										<h2>Address Validator</h2>
									</div>
									<div className="tile-content">
										{showAvForm ?
											<div>
												<div className={`input-field ${style.addressInputField} dark w90 mb-0`}>
													<span labelFor="address">Address</span>
													<input className={`${addressErrorObj?.address ? style.errorCheckInput : ''}`} id="autocomplete" placeholder="Enter Address" value={addName} onChange={addressHandler} />
													{addressErrorObj?.address ?
														<div className="invalid-feedback d-block mt-0">
															{addressErrorObj.address}
														</div> : null
													}
												</div>
												<div className={`input-field ${style.addressInputField} dark w70  ${addressErrorObj?.address ? `mt-0 mb-0` : ''}`}>
													<span labelFor="city">City</span>
													<input className={`${addressErrorObj?.city ? style.errorCheckInput : ''}`} id="city" placeholder="Enter City" value={addCity} onChange={addressHandler} />
													{addressErrorObj?.city ?
														<div className="invalid-feedback d-block mt-0">
															{addressErrorObj.city}
														</div> : null
													}
												</div>
												<select className={`w50 nice-select dark-select  ${addressErrorObj?.state ? `${style.errorCheckInput} mb-0 mt-0` : ''}`} id="state" value={addState} onChange={addressHandler}>
													{stateData?.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}
												</select>
												{addressErrorObj?.state ?
													<div className="invalid-feedback d-block mt-0">
														{addressErrorObj.state}
													</div> : null
												}
												<div className={`input-field ${style.addressInputField} dark w40 zip-input ${addressErrorObj?.state ? `mt-0 mb-0` : ''}`}>
													<span labelFor="zip">Zip</span>
													<input className={`${addressErrorObj?.zip ? style.errorCheckInput : ''}`} id="zip" placeholder="Enter Zip" value={addZip} onChange={addressHandler} />
													{addressErrorObj?.zip ?
														<div className="invalid-feedback d-block mt-0">
															{addressErrorObj.zip}
														</div> : null
													}
												</div>
												<div className="validator-search ico-link">
													<button type="submit" onClick={validateAddress}>
														<div className="rel">
															<svg className="ico pos-center">
																<path
																	d="M28.73,2.65A12.61,12.61,0,0,1,33.11,7.1a12.31,12.31,0,0,1,0,12.38,12.14,12.14,0,0,1-4.38,4.45,11.7,11.7,0,0,1-6.19,1.74A12.21,12.21,0,0,1,12,19.51a12.08,12.08,0,0,1-1.66-6.2A12.3,12.3,0,0,1,12,7.07,12.28,12.28,0,0,1,22.54,1a11.94,11.94,0,0,1,6.19,1.7Zm-.92,19.62a10.2,10.2,0,0,0,3.74-3.78,10.54,10.54,0,0,0,0-10.6,10.2,10.2,0,0,0-3.74-3.78,10.24,10.24,0,0,0-10.54,0,10.05,10.05,0,0,0-3.74,3.78,10.54,10.54,0,0,0,0,10.6,10.48,10.48,0,0,0,9,5.24,10.14,10.14,0,0,0,5.27-1.46ZM29.88,9a8.46,8.46,0,0,0-3.11-3.09c-1.3-.75-4.55-.83-4.55-.83l1,2a5.64,5.64,0,0,1,2.7.46,6.49,6.49,0,0,1,2.43,2.42,11.11,11.11,0,0,1,.87,3.12l2,1v-1A18,18,0,0,0,29.88,9ZM11.22,23.05l-10,10v1l1,1,10-10v-2Z" />
															</svg>
														</div>
													</button>
												</div>
											</div>
											: null}
										{avloader ? <Loader /> : null}

										{showAvResults ?

											<div className="validator-results">
												<div className="table w100">
													<div className="table-row">
														<div className="cell w35 text-grey">
															Address:
														</div>
														<div className="cell">
															<p>{avResults.address}<a href="/"
																onClick={searchAgain}
																className="top fr">Search
																Again</a><br></br>{avResults.cityStateZip}</p>
														</div>
													</div>
													<div className="table-row">
														<div className="cell text-grey">
															Valid:
														</div>
														<div className="cell">
															<p>{avResults.valid}</p>
														</div>
													</div>
													<div className="table-row">
														<div className="cell text-grey">
															Reliability:
														</div>
														<div className="cell">
															<p>{avResults.reliability}</p>
														</div>
													</div>
													<div className="table-row">
														<div className="cell text-grey">
															Percent Match:
														</div>
														<div className="cell">
															<p>{avResults.percentMatch}</p>
														</div>
													</div>
													<div className="table-row">
														<div className="cell text-grey">
															On-Net:
														</div>
														<div className="cell">
															<p>{avResults.onNet}</p>
														</div>
													</div>
												</div>
											</div> /* Closing div for AV results */

											: null} {/* Closing display condition for AV results */}
									</div>
								</div>

								<div className="sub-tile">
									<div className="tile-hdr">
										<h2>Search by ID</h2>
									</div>
									<div className="tile-content wgt-news">
										<div className="os">
											<div>
												<p className='text-danger small'>{searchByIdError}</p>
											</div>
											<div className="news-entry">
												<div className="nib">
													<div className="the-notch">
														<div className="notch-fg">
															<svg>
																<path
																	d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z" />
															</svg>
														</div>
														<div className="notch-bg">
															<svg>
																<path
																	d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z" />
															</svg>
														</div>
													</div>
												</div>


												<div>
													<form onSubmit={searchQuote}>
														<div className="table w100">
															<div className="table-row input-field dark">
																<div className="cell w70 align-top">
																	<span labelFor="quoteRefId">Quote</span>
																	<input name="quoteRefId" id='quoteRefId'
																		value={quoteRefIdVal}
																		onChange={searchHandler}
																		placeholder="Enter Reference ID"
																		type="text"
																		maxLength="8"
																	/>
																</div>
																<div className="cell fl align-top">
																	<button type="submit">
																		<div>
																			<svg
																				className="ico ico-link ico-arrow size-s">
																				<path
																					d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z" />
																			</svg>
																		</div>
																	</button>
																</div>
															</div>
														</div>
													</form>
												</div>
											</div>
											<div className="news-entry">
												<div className="nib">
													<div className="the-notch">
														<div className="notch-fg">
															<svg>
																<path
																	d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z" />
															</svg>
														</div>
														<div className="notch-bg">
															<svg>
																<path
																	d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z" />
															</svg>
														</div>
													</div>
												</div>
												<div>
													<form onSubmit={searchOrder}>
														<div className="table w100">
															<div className="table-row input-field dark">
																<div className="cell w70 align-top">
																	<span labelFor="quoteRefId">Order</span>
																	<input name="orderRefId" id='orderRefId'
																		value={orderRefIdVal}
																		onChange={searchHandler}
																		placeholder="Enter Reference ID or PON"
																		type="text"
																		maxLength="16"
																	/>

																</div>
																<div className="cell fl align-top">
																	<button type="submit">
																		<div>
																			<svg
																				className="ico ico-link ico-arrow size-s">
																				<path
																					d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z" />
																			</svg>
																		</div>
																	</button>
																</div>
															</div>
														</div>
													</form>
												</div>
											</div>
											<div className="news-entry">
												<div className="nib">
													<div className="the-notch">
														<div className="notch-fg">
															<svg>
																<path
																	d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z" />
															</svg>
														</div>
														<div className="notch-bg">
															<svg>
																<path
																	d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z" />
															</svg>
														</div>
													</div>
												</div>
												<div>
													<form onSubmit={searchIncident}>
														<div className="table w100">
															<div className="table-row input-field dark">
																<div className="cell w70 align-top">
																	<span labelFor="incidentId">Ticket</span>
																	<input name="incidentId" id='incidentId'
																		value={incidentIdVal}
																		onChange={searchHandler}
																		placeholder="Enter Ticket Number"
																		type="text" />

																</div>
																<div className="cell fl align-top">
																	<button type="submit">
																		<div>
																			<svg
																				className="ico ico-link ico-arrow size-s">
																				<path
																					d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z" />
																			</svg>
																		</div>
																	</button>
																</div>
															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			)}
		</div>
	);
}
export default Home;
