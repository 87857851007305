import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import ViewportWarning from "../shared/ViewportWarning";
import style from "../quote/QuoteCreate.module.css";
import ContactHelper from '../../services/ContactService';
import SupportHelper from "../../services/SupportService";
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CompanyDropdown from '../shared/CompanyDropdown';
import Popup from "../shared/Popup";

const RfoRcaRequestForm = (props) => {
	const location = useLocation();
	const { wcaseNumber } = location.state || {};
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [loader, setLoader] = useState(false);
	const [contactArray, setContactArray] = useState([]);
	const [newContactFlag, setNewContactFlag] = useState(false);
	const [contact, setContact] = useState('');
	const [name, setName] = useState('');
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [email, setEmail] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);
	const [validationErr, setValidationErr] = useState('');
	const [windstreamCase, setWindstreamCase] = useState(wcaseNumber || '');
	const [customerInternalCase, setCustomerInternalCase] = useState('');
	const [emailFields, setEmailFields] = useState([{ id: 1, email: '' }]);
	const [requestType, setRequestType] = useState('');
	const [questions, setQuestions] = useState('');
	const [adoNumber, setAdoNumber] = useState('');
	const [successRequestType, setSuccessRequestType] = useState('');
	const [validationMessage, setValidationMessage] = useState('');
	const [isCaseValid, setIsCaseValid] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [popUpMessage, setPopupMessage] = useState("");
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const customerName = useSelector(state =>
		isEmployee ? state.internal?.company?.label : state.userReducer?.user?.pendo?.name
	);

	const formFields = [
		{ id: 1, labelName: "Requestor Full Name", code: "contact", value: contact, required: true },
		{ id: 2, labelName: "Requestor Phone #", code: "phoneNumber", value: phoneNumber, required: true },
		{ id: 3, labelName: "Requestor Email", code: "email", value: email, required: true },
		{ id: 4, labelName: "Windstream Case #", code: "windstreamCase", value: windstreamCase, required: true },
		{ id: 5, labelName: "Customer Internal Case # (If Applicable)", code: "customerInternalCase", value: customerInternalCase, required: false },
		{ id: 6, labelName: "Customer/Account Name", code: "customerName", value: customerName, required: true },
		{ id: 7, labelName: "RFO / RCA", code: "requestType", value: requestType, required: true },
		{ id: 8, labelName: "Are there any specific questions or concerns for us to address within the RFO/RCA?", code: "questions", value: questions, required: false },
	];

	useEffect(() => {
		logger.info('wcaseNumber from location.state:', wcaseNumber);
		setWindstreamCase(wcaseNumber || '');
	}, [wcaseNumber]);

	useEffect(() => {
		if (!isEmployee || (isEmployee && selectedInternalMpid)) {
			setLoader(true);
			ContactHelper.getWinforceContacts().then(response => {
				// Filter out inactive contacts and prepare contact data
				const activeContacts = response.data.result
					.filter(contact => !contact.inactive_Contact__c)
					.map(contact => ({
						id: contact.id,
						name: `${contact.firstName} ${contact.lastName}`,
						phone: contact.phone,
						email: contact.email,
					}))
					.sort((a, b) => a.name.localeCompare(b.name));

				// Append "Create New Contact" option
				activeContacts.push({ id: "new", name: "Create New Contact" });

				setContactArray(activeContacts);
				setLoader(false);
			}).catch(error => {
				logger.error("getWinforceContacts ERROR: " + error);
				setHasError(error);
				setLoader(false);
			});
		}
	}, [logger, isEmployee, selectedInternalMpid]);

	useEffect(() => {
		if (windstreamCase.length === 8 && !isNaN(windstreamCase)) {
			// Check if the Windstream Case number is 08000000 or higher
			if (parseInt(windstreamCase, 10) < 8000000) {
				setPopupMessage("We have received your request for a Reason for Outage (RFO) or Root Cause Analysis (RCA). However, it appears that the case number you provided is associated with a case that is older than one year. Unfortunately, we are unable to process RFO/RCA requests for cases beyond this timeframe. Please review your records and provide a more recent case number, or contact your account or service manager, or the Wholesale Service Assurance Team at 844-946-2662 if there is anything else we can assist you with.");
				setShowPopup(true);
			} else {
				SupportHelper.verifyWindstreamCase(windstreamCase)
					.then(response => {
						logger.info("Verify Windstream Case Result: " + JSON.stringify(response.data));
						if (response.data.result === 'SUCCESS') {
							setValidationMessage('Valid case number.');
							setIsCaseValid(true);
						} else {
							if (response.data && response.data.message) {
								setPopupMessage(response.data.message);
								setShowPopup(true);
							}
							setValidationMessage(response.data.error);
							setIsCaseValid(false);
						}
					})
					.catch(error => {
						setValidationMessage('Error validating case number.');
						setIsCaseValid(false);
					});
			}
		} else if (windstreamCase.length === 8) {
			setValidationMessage('Case number must be numeric.');
			setIsCaseValid(false);
		} else {
			setValidationMessage('');
			setIsCaseValid(false);
		}
	}, [windstreamCase]);

	const inputChange = (ev) => {
		setValidationErr('');
		switch (ev.target.id) {
			case "fname":
				setFname(ev.target.value);
				setName(`${ev.target.value} ${lname}`);
				break;
			case "lname":
				setLname(ev.target.value);
				setName(`${fname} ${ev.target.value}`);
				break;
			case "name":
				setName(ev.target.value);
				break;
			case "phoneNumber":
				setPhoneNumber(ev.target.value);
				break;
			case "email":
				setEmail(ev.target.value);
				break;
			case "windstreamCase":
				setValidationErr('');
				setWindstreamCase(ev.target.value);
				break;
			case "customerInternalCase":
				setCustomerInternalCase(ev.target.value);
				break;
			case "questions":
				setQuestions(ev.target.value);
				break;
			default:
				break;
		}
	};

	const contactHandler = (ev) => {
		const selectedId = ev.target.value;
		if (selectedId === "new") {
			setNewContactFlag(true);
			setName('');
			setPhoneNumber('');
			setEmail('');
		} else if (selectedId === "") {
			// Handle the blank selection
			setNewContactFlag(false);
			setName('');
			setPhoneNumber('');
			setEmail('');
		} else {
			setNewContactFlag(false);
			const selectedContact = contactArray.find(contact => contact.id === selectedId);
			if (selectedContact) {
				setName(selectedContact.name);
				setPhoneNumber(selectedContact.phone);
				setEmail(selectedContact.email);
			}
		}
		setContact(selectedId);
	};

	const handleEmailChange = (index, value) => {
		const newEmailFields = [...emailFields];
		newEmailFields[index] = { ...newEmailFields[index], email: value };
		setEmailFields(newEmailFields);
	};

	const addEmailField = () => {
		if (emailFields.length < 10) {
			setEmailFields([...emailFields, { id: emailFields.length + 1, email: '' }]);
		}
	};

	const validateFields = () => {
		let errMsg = '';
		let validCheck = 'success';

		if (windstreamCase.length !== 8 || isNaN(windstreamCase)) {
			errMsg += "Windstream Case # must be 8 numeric characters. ";
			validCheck = 'error';
		}

		if (!isCaseValid) {
			errMsg += "Windstream Case # must be validated before submitting. ";
			validCheck = 'error';
		}

		if (customerInternalCase.length > 20 || !/^[a-zA-Z0-9]*$/.test(customerInternalCase)) {
			errMsg += "Customer Internal Case # (If Applicable) must be up to 20 alphanumeric characters. ";
			validCheck = 'error';
		}

		let findRequired = formFields.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);
		if (!!findRequired) {
			errMsg += "Please enter the required fields. ";
			if (!contact) errMsg += "Name is required. ";
			if (contact === "new" && !name) errMsg += "Name is required. ";
			if (!email) errMsg += "Email is required. ";
			if (!phoneNumber) errMsg += "Phone number is required. ";
			validCheck = 'error';
		}

		return { validCheck, errMsg };
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const { validCheck, errMsg } = validateFields();

		if (validCheck === "error") {
			setValidationErr(errMsg);
			setIsError(true);
		} else {
			setLoader(true);

			if (contact === "new") {
				// Set name using fname and lname
				const fullName = `${fname} ${lname}`;
				setName(fullName);

				// Data for the new contact
				const newContactData = {
					firstName: fname,
					lastName: lname,
					phone: phoneNumber,
					email: email,
				};
				const selectedRoles = 'Additional Ticketing';
				const action = 'createContact';
				const gaid = ''; // Do not collect GAID on this page

				ContactHelper.updateContactData(newContactData, selectedRoles, action, gaid)
					.then(response => {
						logger.info('New contact created successfully.');
						setIsSuccess(true);
						setLoader(false);
					})
					.catch(error => {
						logger.error("Error creating new contact: " + error);
						setValidationErr("Error creating new contact: " + error);
						setIsError(true);
						setLoader(false);
					});
			} else {
				const values = {
					"contact": name,
					"new_contact": contact === "new" ? name : null,
					"phone": phoneNumber,
					"email": email,
					"windstreamCase": windstreamCase,
					"customerInternalCase": customerInternalCase,
					"customerName": customerName,
					"emailsToSend": emailFields.map(field => field.email),
					"requestType": requestType,
					"questions": questions
				};
				logger.info(JSON.stringify(values));

				SupportHelper.RfoRcaRequest(values)
					.then(resp => {
						logger.info(resp.data.result);
						if (resp.data.result === "SUCCESS") {
							setAdoNumber(resp.data.adoNumber);
							setSuccessRequestType(resp.data.requestType);
							setIsSuccess(true);
						} else if (resp.data.result === "ERROR") {
							setValidationErr("Error: " + resp.data.error);
							setIsError(true);
						}
						setLoader(false);
					})
					.catch(error => {
						logger.error("Upload ERROR: " + error);
						setValidationErr("Error... " + error);
						setIsError(true);
						setLoader(false);
					});
			}
		}
	};

	if (isSuccess) {
		return <Redirect to={{
			pathname: "/Support/RfoRcaRequestSuccess",
			state: { requestType: successRequestType, adoNumber: adoNumber }
		}} />;
	}

	const closePopup = () => {
		setShowPopup(false);
	};


	return (
		<div className="midsection qm-upload abs z1">
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			<div className="rel">
				<ViewportWarning />
				<div className="wrap">
					<h1 className="page-title">{isEmployee ? "Employee RFO/RCA Request Form" : "RFO/RCA Request Form"}</h1>
					{isEmployee ? <CompanyDropdown /> : null}
					<div className="section">
						<div className={`content ${style.contentQueryPage}`}>
							<form>
								<div className={`row `}>
									<div className="col-12">
										<p className={` ${style.lableTextS} ${style.textBlack} `}><span className={style.mandatory}>*</span> Denotes a required field</p>
									</div>
								</div>
								<div className={`sub-hdr alt ${style.subHeader} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={style.sectionTitle}>Project Details</h2>
								</div>
								<div className={`row ${style.rowGrp}`}>
									{newContactFlag ?
										<>
											<div className="col-2">
												<label className={style.labelSelectRequiredFld}>Requestor First Name <span className={style.mandatory}>*</span></label>
												{newContactFlag &&
													<input type="text" id="fname" className={style.inputFld} onChange={inputChange} value={fname}></input>
												}
											</div>
											<div className="col-2">
												<label className={style.labelSelectRequiredFld}>Requestor Last Name <span className={style.mandatory}>*</span></label>
												{newContactFlag &&
													<input type="text" id="lname" className={style.inputFld} onChange={inputChange} value={lname}></input>
												}
											</div>
										</>
										:
										<>
											<div className="col-4">
												<label className={style.labelSelectRequiredFld}> Requestor Full Name <span className={style.mandatory}>*</span> </label>
												<select className={`form-control ${style.selectFld} ${style.requiredFld}`} id="contact" value={contact} onChange={contactHandler}>
													<option value=""></option>
													{contactArray.map((obj) => {
														return <option key={obj.id} value={obj.id}>{obj.name}</option>
													})}
												</select>
											</div>
										</>
									}
									<div className="col-4">
										<label className={style.lableTextS}>Requestor Phone # <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} maxLength="10" id="phoneNumber" onChange={inputChange} value={phoneNumber}></input>
									</div>
									<div className="col-4">
										<label className={style.lableTextS}>Requestor Email <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id="email" onChange={inputChange} value={email}></input>
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-4">
										<label className={style.lableTextS}>Windstream Case # <span className={style.mandatory}>*</span> <br />
											<span className={style.smallText}>Please ensure that your case has been resolved prior to submitting an RFO/RCA request</span></label>
										<input type="text" className={style.inputFld} maxLength="8" id="windstreamCase" onChange={inputChange} value={windstreamCase}></input>
										{validationMessage && (
											<div className={`mt-2 ${isCaseValid ? 'text-success' : 'text-danger'}`}>
												<small>{validationMessage}</small>
											</div>
										)}
									</div>
									<div className="col-4">
										<label className={style.lableTextS}>Customer Internal Case # (If Applicable)</label>
										<input type="text" className={style.inputFld} maxLength="20" id="customerInternalCase" onChange={inputChange} value={customerInternalCase}></input>
									</div>
									<div className="col-4">
										<label className={style.lableTextS}>Customer/Account Name <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id="customerName" onChange={inputChange} value={customerName} disabled></input>
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-4">
										<label className={style.lableTextS}>Email Address to send the completed RFO/RCA to when completed <span className={style.mandatory}>*</span></label>
										{emailFields.map((field, index) => (
											<div key={field.id}>
												<input type="text"
													className={style.inputFld}
													placeholder="Enter email address"
													value={field.email}
													onChange={(e) => handleEmailChange(index, e.target.value)}
												/>
											</div>
										))}
										<button type="button" onClick={addEmailField} style={{ marginTop: '10px', paddingRight: '15px' }}>
											<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
										</button>
										<span className={style.smallText}>Please click plus symbol to add additional email addresses.</span>
									</div>
									<div className="col-4">
										<label className={style.lableTextS}>RFO / RCA <span className={style.mandatory}>*</span></label>
										<div>
											<label>
												<input type="radio" name="requestType" value="RFO" onChange={(e) => setRequestType(e.target.value)} />
												<span title="Request For Outage (RFO)">RFO</span>
											</label>
											<label style={{ marginLeft: '10px' }}>
												<input type="radio" name="requestType" value="RCA" onChange={(e) => setRequestType(e.target.value)} />
												<span title="Root Cause Analysis (RCA)">RCA</span>
											</label>
										</div>
										<span className={style.smallText}>Please Note there is a 5 business day turnaround for an RFO and a 10 Business day turnaround for an RCA</span>
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-12">
										<label className={style.lableTextS}>Are there any specific questions or concerns for us to address within the RFO/RCA?</label>
										<textarea className={style.textareaFld} id="questions" onChange={inputChange} value={questions} maxLength="1000"></textarea>
									</div>
								</div>
								<div className={style.dividers}></div>
								<div className="text-center mt-5">
									<p className={isError ? "text-danger" : "text-success"}>{validationErr}</p>
								</div>
								<div className={"row my-5 rel"}>
									<div className={"col-12 text-center"}>
										<button className={`ico-button lhs light-button ${style.nextBtn}`} type="button" onClick={handleSubmit}>
											Submit
											<svg className="ico light-ico ico-arrow">
												<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
											</svg>
										</button>
									</div>
								</div>
							</form>
							<Popup message={popUpMessage} show={showPopup} onClose={closePopup} useOkButton={true}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RfoRcaRequestForm;