import React, { useEffect  } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import style from "../quote/QuoteCreate.module.css";

const RfoRcaRequestFormSuccess = () => {
    const location = useLocation();
    const history = useHistory();

    // Access requestType and adoNumber from location.state
    const requestType = location.state?.requestType;
    const adoNumber = location.state?.adoNumber;

    // Check if requestType and adoNumber are present, otherwise redirect
    useEffect(() => {
        if (!requestType || !adoNumber) {
            // Redirect to the form page if parameters are missing
            history.push("/Support/RfoRcaRequestForm");
        }
    }, [requestType, adoNumber, history]);

    const handleButtonClick = () => {
        history.push("/Support/RfoRcaRequestForm");
    };

    return (
        <div className="midsection qm-upload abs z1">
            <div className="rel">
                <div className="wrap">
                    <h1 className="page-title">RFO/RCA Request Successfully Submitted</h1>
                    <div className="section">
                        {/* <TabsQuoteManager/> */}
                        <div className={`content ${style.contentQueryPage}`}>
                            <div className={`${style.confirmationMessage} ${style.textCenter} ${style.whiteBackground}`}>
                                <h2>Request Submitted Successfully</h2>
                                <p>Your {requestType} request has been received and will be reviewed. Request# {adoNumber}</p>
                                <p>As a reminder:</p>
                                <p>Please note there is a 5 business day turnaround for an RFO</p>
                                <p>Please note there is a 10 business day turnaround for an RCA</p>
                                <button className={`ico-button lhs light-button ${style.nextBtn}`} type="button" onClick={handleButtonClick}>
                                    Submit Another Request
                                    <svg className="ico light-ico ico-arrow">
                                        <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RfoRcaRequestFormSuccess;