import style from "./QuoteCreate.module.css";
import { useCallback, useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import DIAPopHelper from "../../services/DIAPopService";
import DIAPremHelper from "../../services/DIAPremService";
import PremAddress from "./PremAddress";
import Term from "./Term"
import {
	setQuoteProduct,
	setQuoteAddBW1,
	setQuoteAddBW2,
	setQuoteBurstBilling1,
	setQuoteBurstBilling2,
	setQuoteBurstBilling3,
	setQuoteBW,
	setQuoteCAMQty,
	setQuoteCommitBw1,
	setQuoteCommitBw2,
	setQuoteCommitBw3,
	setQuoteDDOS,
	setQuoteDDOSType,
	setQuoteIps,
	setQuotePopLocation,
	setQuoteQty,
	setQuoteCPEType,
	setQuoteInternetRouter,
} from '../../redux/actions/quoteActions';
import Select from 'react-select';
import Loader from '../shared/Loader';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import VendorExclusion from './VendorExclusion';

const commit10GBBWOptions = [
	{ "id": "", "name": "Select Bandwidth" },
	{ "id": 510, "name": "1 GB" },
	{ "id": 512, "name": "2 GB" },
	{ "id": 515, "name": "3 GB" },
	{ "id": 517, "name": "4 GB" },
	{ "id": 519, "name": "5 GB" },
	{ "id": 521, "name": "6 GB" },
	{ "id": 523, "name": "7 GB" },
	{ "id": 525, "name": "8 GB" },
	{ "id": 527, "name": "9 GB" }];

const commit100GBBWOptions = [
	{ "id": "", "name": "Select Bandwidth" },
	{ "id": 530, "name": "10 GB" },
	{ "id": 533, "name": "20 GB" },
	{ "id": 537, "name": "30 GB" },
	{ "id": 540, "name": "40 GB" },
	{ "id": 555, "name": "50 GB" },
	{ "id": 565, "name": "60 GB" },
	{ "id": 575, "name": "70 GB" },
	{ "id": 585, "name": "80 GB" },
	{ "id": 595, "name": "90 GB" }];


const Internet = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [popLocationOptions, setPopLocationOptions] = useState([]);
	const [popBwOptions, setPopBwOptions] = useState([]);
	const [popIpOptions, setPopIpOptions] = useState([]);
	const [premBwOptions, setPremBwOptions] = useState([]);
	const [premIpOptions, setPremIpOptions] = useState([]);
	const [bandwidthAdded, setBandwidthAdded] = useState(false);
	const [bandwidthCount, setBandwidthCount] = useState(0);
	const [loader, setLoader] = useState(false);
	const selectedInternetValues = useSelector(state => state.quote.quoteValues);
	const selectedLecType = useSelector(state => state.quoteLocA.locA.a_lec_type);

	useEffect(() => {
		if (props.stepCode === 3) {
			if (selectedInternetValues.bw1 !== "") {
				setBandwidthCount(1);
			}
			if (selectedInternetValues.bw2 !== "") {
				setBandwidthCount(2);
			}
		}
	}, [props.stepCode, selectedInternetValues.bw1, selectedInternetValues.bw2])


	const getDIAPopLocations = useCallback(() => {
		if (props.stepCode === 2) {
			setLoader(true);
			DIAPopHelper.getPopLocations().then(resp => {
				logger.info(resp.data);
				setPopLocationOptions(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting Pop Locations: " + error);
				setHasError(error);
				setLoader(false);
			})
		}
	}, [logger, props.stepCode])

	const getDIAPremIPs = useCallback(() => {
		if (props.stepCode === 3 && props.section === "productConfig") {
			DIAPremHelper.getPremIPs(selectedInternetValues.circuit).then(resp => {
				logger.info(resp.data);
				setPremIpOptions(resp.data);
			}).catch(error => {
				logger.error("Getting DIA Prem IPs: " + error);
				setHasError(error);
			})
		}

	}, [logger, props.section, props.stepCode, selectedInternetValues.circuit])

	const getDIAPremBW = useCallback(() => {
		if (props.stepCode === 3 && props.section === "bandwidth") {
			DIAPremHelper.getPremBW(selectedInternetValues.circuit).then(resp => {
				logger.info(resp.data);
				setPremBwOptions(resp.data);
			}).catch(error => {
				logger.error("Getting DIA Prem Bandwidths: " + error);
				setHasError(error);
			})
		}
	}, [logger, props.section, props.stepCode, selectedInternetValues.circuit])

	const getDIAPopBW = useCallback(() => {
		if (props.stepCode === 3 && props.section === "bandwidth") {
			setLoader(true);
			DIAPopHelper.getPopBW().then(resp => {
				logger.info(resp.data);
				setPopBwOptions(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting DIA POP Bandwidths: " + error);
				setHasError(error);
				setLoader(false);
			})
		}
	}, [logger, props.section, props.stepCode])

	const getDIAPopIPs = useCallback(() => {
		if (props.stepCode === 3 && props.section === "productConfig") {
			setLoader(true);
			DIAPopHelper.getPopIPs().then(resp => {
				logger.info(resp.data);
				setPopIpOptions(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting DIA Pop IPs: " + error);
				setHasError(error);
				setLoader(false);
			})
		}

	}, [logger, props.section, props.stepCode])

	useEffect(() => {
		getDIAPopLocations();
		getDIAPopBW();
		getDIAPopIPs();
		getDIAPremBW();
		getDIAPremIPs();
	}, [getDIAPopBW, getDIAPopIPs, getDIAPopLocations, getDIAPremBW, getDIAPremIPs])


	const popLocHandler = (ev) => {
		let popVal = '';
		if (!ev) {
			popVal = ''
		} else {
			popVal = ev;
		}
		props.updateInternetLocation(popVal);
	}

	const addBandwidth = () => {
		//check the first bandwidth and determine if the additional bandwidth should prem or pop

		setBandwidthAdded(!bandwidthAdded);
		setBandwidthCount(bandwidthCount < 3 ? bandwidthCount + 1 : bandwidthCount)
	}

	const removeBandwidth = () => {
		let counter = bandwidthCount - 1;
		setBandwidthCount(bandwidthCount - 1);
		if (counter === 0) {
			props.updateInternetAddBw1("");
		}
		if (counter === 1) {
			props.updateInternetAddBw2("");
		}
	}

	const inputChange = (ev) => {
		switch (ev.target.id) {
			case "bw":
				props.updateInternetBw(ev.target.value);
				/* This needs to be determined in PHP ADO #856563
				//Internet is either DIA POP OR PREM based on the bandwidth
				if (ev.target.value > 505) {
					//update the product in the store to DIA POP
					props.updateInternetProduct(10);
				} else {
					//update the product in the store to DIA PREM
					props.updateInternetProduct(11);
				}
				*/
				if (ev.target.value < 270) {
					props.updateInternetDDOS("0");
					document.getElementById("ddos").disabled = true;
				} else {
					document.getElementById("ddos").disabled = false;
				}
				break;
			case "bw1":
				props.updateInternetAddBw1(ev.target.value);
				if (ev.target.value < 270 || selectedInternetValues.bw < 270) {
					props.updateInternetDDOS("0");
					document.getElementById("ddos").disabled = true;
				} else {
					document.getElementById("ddos").disabled = false;
				}
				break;
			case "bw2":
				props.updateInternetAddBw2(ev.target.value);
				if (ev.target.value < 270 || selectedInternetValues.bw < 270 || selectedInternetValues.bw1 < 270) {
					props.updateInternetDDOS("0");
					document.getElementById("ddos").disabled = true;
				} else {
					document.getElementById("ddos").disabled = false;
				}
				break;
			case "qty":
				props.updateInternetQty(ev.target.value);
				break;
			case "ips":
				props.updateInternetIPs(ev.target.value);
				break;
			case "ddos":
				props.updateInternetDDOS(ev.target.value);
				break;
			case "cam_qty":
				props.updateInternetCAMQty(ev.target.value);
				break;
			case "ddos_type":
				props.updateInternetDDOSType(ev.target.value);
				break;
			case "burstbilling1":
				props.updateInternetBurstBilling1(ev.target.value);
				break;
			case "commitbw1":
				props.updateInternetCommitBw1(ev.target.value);
				break;
			case "burstbilling2":
				props.updateInternetBurstBilling2(ev.target.value);
				break;
			case "commitbw2":
				props.updateInternetCommitBw2(ev.target.value);
				break;
			case "burstbilling3":
				props.updateInternetBurstBilling3(ev.target.value);
				break;
			case "commitbw3":
				props.updateInternetCommitBw3(ev.target.value);
				break;
			case "cpe_type":
				props.updateInternetCPEType(ev.target.value);
				if (ev.target.value === "2") {
					props.updateInternetRouter("0");
					document.getElementById("router_service").disabled = true;
				} else {
					document.getElementById("router_service").disabled = false;
					props.updateInternetRouter("1");
				}
				break;
			case "router_service":
				props.updateInternetRouter(ev.target.value);
				break;
			default:
				break;
		}
	}

	const customFilter = (option, searchText) => {
		if (option.value.toString().includes("99") ||
			option.label.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
			option.value.toString().toLowerCase().includes(searchText.toString().toLowerCase())
		) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<>
				{props.stepCode === 2 ?
					<>
						<div className={`row ${style.rowGrp}`}>
							<div className="col-6">
								<label className={style.labelSelectRequiredFld}>POP Location<span
									className={style.mandatory}>*</span> </label>
								<Select
									defaultValue={selectedInternetValues.popLoc}
									onChange={popLocHandler}
									options={popLocationOptions.map((item) => {
										return { value: item.id, label: item.name }
									})}
									filterOption={customFilter}
									isSearchable
									isClearable
									noOptionsMessage={() => 'Loading DIA POP Locations'}
								/>
							</div>
						</div>
						{/*if other is selected than take the user input*/}
						{selectedInternetValues.popLoc.value === '99' || selectedInternetValues.popLoc.value === 99 ?
							<PremAddress></PremAddress> : null
						}
					</>
					: null
				}
				{(props.stepCode === 3) && (props.section === "term") ?
					<Term /> : null
				}
				{(props.stepCode === 3) && (props.section === "bandwidth") ?
					<>
						<div className={style.bandwidthWrap}>
							<div className={`row ${style.rowGrp} `}>
								<div className="col-6 col-sm-4 col-md-3">
									<label className={style.labelSelectRequiredFld}>Bandwidth <span
										className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw"
										onChange={inputChange} value={selectedInternetValues.bw}>
										{premBwOptions.concat(popBwOptions.slice(1)).map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>
								</div>
								{selectedInternetValues.bw === "530" || selectedInternetValues.bw === "600" ?
									<div className="col-5 col-sm-4 col-md-3 rel">
										<div className="d-flex">
											<div className={style.w_90}>
												<label className={style.labelSelectRequiredFld}>Burstable Billing?<span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="burstbilling1" onChange={inputChange} value={selectedInternetValues.burstbilling1} >
													<option disabled="">-</option>
													<option value="0">NO</option>
													<option value="1">YES</option>
												</select>
											</div>
										</div>
									</div>
									: null}
								{selectedInternetValues.burstbilling1 === "1" ?
									<div className="col-5 col-sm-4 col-md-3 rel">
										<div className="d-flex">
											<div className={style.w_90}>
												<label className={style.labelSelectRequiredFld}>Commit<span className={style.mandatory}>*</span></label>
												<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="commitbw1" onChange={inputChange} value={selectedInternetValues.commitbw1} >
													{selectedInternetValues.bw === "530" ?
														<>
															{commit10GBBWOptions.map((obj) => {
																return <option value={obj.id}>{obj.name}</option>
															})}</> : null}
													{selectedInternetValues.bw === "600" ?
														<>
															{commit100GBBWOptions.map((obj) => {
																return <option value={obj.id}>{obj.name}</option>
															})}</> : null}

												</select>
											</div>
										</div>
									</div>
									: null}



								<div className="col-1 col-sm-1 col-md-1 align-self-center clearfix">
									<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `} onClick={addBandwidth}>
										<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
									</div>
								</div>
							</div>
						</div>
						{bandwidthCount >= 1 ?
							<div className={style.bandwidthWrap}>
								<div className={`row ${style.rowGrp} `}>
									<div className="col-6 col-sm-4 col-md-3">
										<label className={style.labelSelectRequiredFld}>Additional Bandwidth</label>
										<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw1"
											onChange={inputChange} value={selectedInternetValues.bw1}>
											{Number(selectedInternetValues.bw) > 505 ?
												<>
													{popBwOptions.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}</> : null}
											{Number(selectedInternetValues.bw) <= 505 ?
												<>
													{premBwOptions.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}</> : null}
										</select>
									</div>
									{selectedInternetValues.bw1 === "530" || selectedInternetValues.bw1 === "600" ?
										<div className="col-5 col-sm-4 col-md-3 rel">
											<div className="d-flex">
												<div className={style.w_90}>
													<label className={style.labelSelectRequiredFld}>Burstable Billing?<span className={style.mandatory}>*</span></label>
													<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="burstbilling2" onChange={inputChange} value={selectedInternetValues.burstbilling2} >
														<option disabled="">-</option>
														<option value="0">NO</option>
														<option value="1">YES</option>
													</select>
												</div>
											</div>
										</div>
										: null}
									{selectedInternetValues.burstbilling2 === "1" ?
										<div className="col-5 col-sm-4 col-md-3 rel">
											<div className="d-flex">
												<div className={style.w_90}>
													<label className={style.labelSelectRequiredFld}>Commit<span className={style.mandatory}>*</span></label>
													<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="commitbw2" onChange={inputChange} value={selectedInternetValues.commitbw2} >
														{selectedInternetValues.bw1 === "530" ?
															<>
																{commit10GBBWOptions.map((obj) => {
																	return <option value={obj.id}>{obj.name}</option>
																})}</> : null}
														{selectedInternetValues.bw1 === "600" ?
															<>
																{commit100GBBWOptions.map((obj) => {
																	return <option value={obj.id}>{obj.name}</option>
																})}</> : null}

													</select>
												</div>
											</div>
										</div>
										: null}
									<div className={`col-1 col-sm-1 col-md-1 align-self-center clearfix ${style.svgWarp} `}>
										<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>
											<svg className="ico-plus" onClick={addBandwidth}><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
											<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
												<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
      C8.27,265.1,8.27,243.76,8.27,222.43z"/>
											</svg>
										</div>
									</div> </div> </div> : null}
						{bandwidthCount >= 2 ?
							<div className={style.bandwidthWrap}>
								<div className={`row ${style.rowGrp} `}>
									<div className="col-6 col-sm-4 col-md-3">
										<label className={style.labelSelectRequiredFld}>Additional Bandwidth</label>
										<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw2"
											onChange={inputChange} value={selectedInternetValues.bw2}>
											{Number(selectedInternetValues.bw) > 505 ?
												<>
													{popBwOptions.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}</> : null}
											{Number(selectedInternetValues.bw) <= 505 ?
												<>
													{premBwOptions.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}</> : null}
										</select>
									</div>
									{selectedInternetValues.bw2 === "530" || selectedInternetValues.bw2 === "600" ?
										<div className="col-5 col-sm-4 col-md-3 rel">
											<div className="d-flex">
												<div className={style.w_90}>
													<label className={style.labelSelectRequiredFld}>Burstable Billing?<span className={style.mandatory}>*</span></label>
													<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="burstbilling3" onChange={inputChange} value={selectedInternetValues.burstbilling3} >
														<option disabled="">-</option>
														<option value="0">NO</option>
														<option value="1">YES</option>
													</select>
												</div>
											</div>
										</div>
										: null}
									{selectedInternetValues.burstbilling3 === "1" ?
										<div className="col-5 col-sm-4 col-md-3 rel">
											<div className="d-flex">
												<div className={style.w_90}>
													<label className={style.labelSelectRequiredFld}>Commit<span className={style.mandatory}>*</span></label>
													<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="commitbw3" onChange={inputChange} value={selectedInternetValues.commitbw3} >
														{selectedInternetValues.bw2 === "530" ?
															<>
																{commit10GBBWOptions.map((obj) => {
																	return <option value={obj.id}>{obj.name}</option>
																})}</> : null}
														{selectedInternetValues.bw2 === "600" ?
															<>
																{commit100GBBWOptions.map((obj) => {
																	return <option value={obj.id}>{obj.name}</option>
																})}</> : null}

													</select>
												</div>
											</div>
										</div>
										: null}
									<div className={`col-1 col-sm-1 col-md-1 align-self-center clearfix ${style.svgWarp} `}>
										<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>

											<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
												<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
C8.27,265.1,8.27,243.76,8.27,222.43z"/>
											</svg>
										</div>
									</div> </div> </div> : null}
					</> : null
				}
				{/* IF NO BANDWIDTH IS SELECTED DO NOT SHOW CONFIGURATION */}
				{(props.stepCode === 3) && (props.section === "productConfig") && selectedInternetValues.bw === "" ? null :
					<>
						{/* DIA POP CONFIGURATION */}
						{(props.stepCode === 3) && (props.section === "productConfig") && (Number(selectedInternetValues.bw) > 505) ?
							<>
								<div className="col-12 col-sm-4 col-md-3 mb-5">
									<label className={style.labelSelectRequiredFld}>Quantity <span className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='qty' onChange={inputChange}>
										<option disabled>-</option>
										<option value="1">1</option>
									</select>
								</div>
								<div className="col-5 col-sm-4 col-md-3 rel mb-5">
									<label className={style.labelSelectRequiredFld}> IP addresses <span className={style.mandatory}>*</span></label>
									<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='ips' onChange={inputChange} value={selectedInternetValues.ips}>
										{popIpOptions.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>
								</div>
								{/* ADO-833379 Remove DDOS Mitigation */}
								{/* <div className="col-5 col-sm-4 col-md-3 rel mb-5">
									<label className={style.labelSelectRequiredFld}> DDOS Mitigation <span className={style.mandatory}>*</span></label>
									<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='ddos' onChange={inputChange} value={selectedInternetValues.ddos}>
										<option value="" disabled>-</option>
										<option value="0">No</option>
										<option value="1">Yes</option>
									</select>
								</div>
								{selectedInternetValues.ddos === "1" ?
									<>
										<div className="col-5 col-sm-4 col-md-3 rel mb-5">
											<label className={style.labelSelectRequiredFld}> Critical Asset Monitoring</label>
											<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="cam_qty" onChange={inputChange} value={selectedInternetValues.cam_qty}>
												<option value="" disabled>-</option>
												<option value="">N/A</option>
												{[...Array(10)].map((x, i) =>
													<option value={i + 1}>{i + 1}</option>
												)}
											</select>
										</div>
										<div className="col-5 col-sm-4 col-md-3 rel mb-5">
											<label className={style.labelSelectRequiredFld}> DDOS pricing option <span className={style.mandatory}>*</span></label>
											<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="ddos_type" onChange={inputChange} value={selectedInternetValues.ddos_type}>
												<option value="" disabled>-</option>
												<option value='1'>DDoS Assurance</option>
												<option value='2'>DDoS Unlimated</option>
											</select>
										</div></> : null} */}
							</> : null}
						{/* DIA PREM CONFIGURATION */}
						{(props.stepCode === 3) && (props.section === "productConfig") && (Number(selectedInternetValues.bw) <= 505) ?
							<>
								<div className="col-12 col-sm-4 col-md-3 mb-5">
									<label className={style.labelSelectRequiredFld}>Quantity <span className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='qty' onChange={inputChange} value={selectedInternetValues.qty}>
										<option value="">-</option>
										<option value="1">1</option>
									</select>
								</div>
								{/*ADO-852308 ILEC doesn't allow for the selection of CPE Provider or route service
								{selectedInternetValues.circuit !== 14 && selectedLecType !== "ILEC" ?
									<>
										<div className="col-5 col-sm-4 col-md-3 rel mb-5">
											<label className={style.labelSelectRequiredFld}> CPE Provider <span className={style.mandatory}>*</span></label>
											<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='cpe_type' onChange={inputChange} value={selectedInternetValues.cpe_type}>
												<option value="" disabled>-</option>
												<option value="1">Windstream Supplied CPE</option>
												<option value="2">Customer Supplied CPE</option>
											</select>
										</div>
										<div className="col-5 col-sm-4 col-md-3 rel mb-5">
											<label className={style.labelSelectRequiredFld}> Selected Internet Router Service <span className={style.mandatory}>*</span></label>
											<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='router_service' onChange={inputChange} value={selectedInternetValues.router_service}>
												{selectedInternetValues.cpe_type === "2" ?
													<option value="0">N/A</option> :
													<>
														<option value="" disabled>-</option>
														<option value="0">N/A</option>
														<option value="1">Maintained Router</option>
													</>
												}
											</select>
										</div>
									</>
									: null}
								*/}
								{selectedInternetValues.circuit === 6 ?
									<>
										<div className="col-5 col-sm-4 col-md-3 rel mb-5">
											<label className={style.labelSelectRequiredFld}> IP addresses <span className={style.mandatory}>*</span></label>
											<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='ips' onChange={inputChange} value={selectedInternetValues.ips}>
												{premIpOptions.map((obj) => {
													return <option value={obj.id}>{obj.name}</option>
												})}
											</select>
										</div>
										{/* ADO-833379 Remove DDOS Mitigation */}
										{/* <div className="col-5 col-sm-4 col-md-3 rel mb-5">
											<label className={style.labelSelectRequiredFld}> DDOS Mitigation <span className={style.mandatory}>*</span></label>
											<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='ddos' onChange={inputChange} value={selectedInternetValues.ddos}>
												<option value="" disabled>-</option>
												<option value="0">No</option>
												<option value="1">Yes</option>
											</select>
										</div>
										{selectedInternetValues.ddos === "1" ?
											<>
												<div className="col-5 col-sm-4 col-md-3 rel mb-5">
													<label className={style.labelSelectRequiredFld}> Critical Asset Monitoring</label>
													<select defaultValue={'-'} className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="cam_qty" onChange={inputChange}>
														<option value="" disabled>-</option>
														<option value="">N/A</option>
														{[...Array(10)].map((x, i) =>
															<option value={i + 1}>{i + 1}</option>
														)}
													</select>
												</div>
												<div className="col-5 col-sm-4 col-md-3 rel mb-5">
													<label className={style.labelSelectRequiredFld}> DDOS pricing option <span className={style.mandatory}>*</span></label>
													<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="ddos_type" onChange={inputChange}>
														<option value="">-</option>
														<option value='1'>DDoS Assurance</option>
														<option value='2'>DDoS Unlimated</option>
													</select>
												</div>
											</> : null} */}
									</> : null}
							</> : null}

					</>}
				{/* IF NO BANDWIDTH IS SELECTED DO NOT SHOW OPTIONS */}
				{(props.stepCode === 3) && (props.section === "Options") && selectedInternetValues.bw === "" ? null :
					<>
						{/* DIA PREM OPTIONS */}
						{(props.stepCode === 3) && (props.section === "Options") && (Number(selectedInternetValues.bw) <= 505) ?
							<>
								<div className={` sub-hdr alt3 ${style.subHeader} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={style.sectionTitle}>Options</h2>
								</div>
								<VendorExclusion />
							</> : null}
					</>}
			</>)}
		</>
	)


}


const mapDispatchToProps = {
	updateInternetLocation: setQuotePopLocation,
	updateInternetProduct: setQuoteProduct,
	updateInternetBw: setQuoteBW,
	updateInternetAddBw1: setQuoteAddBW1,
	updateInternetAddBw2: setQuoteAddBW2,
	updateInternetQty: setQuoteQty,
	updateInternetIPs: setQuoteIps,
	updateInternetDDOS: setQuoteDDOS,
	updateInternetCAMQty: setQuoteCAMQty,
	updateInternetDDOSType: setQuoteDDOSType,
	updateInternetBurstBilling1: setQuoteBurstBilling1,
	updateInternetCommitBw1: setQuoteCommitBw1,
	updateInternetBurstBilling2: setQuoteBurstBilling2,
	updateInternetCommitBw2: setQuoteCommitBw2,
	updateInternetBurstBilling3: setQuoteBurstBilling3,
	updateInternetCommitBw3: setQuoteCommitBw3,

	//DIA PREM OPTIONS
	updateInternetCPEType: setQuoteCPEType,
	updateInternetRouter: setQuoteInternetRouter
}

export default connect(null, mapDispatchToProps)(Internet);
