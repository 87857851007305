import { useSelector } from 'react-redux';

const TabsEmployee = (props) => {
    const profileChangeTabFromStore = useSelector(state => state.employeeProfile?.profile?.selectedProfileTab);
    const selectedMpidFromStore = useSelector(state => state.internal.company);
    const agentFlag = useSelector(state => state.employeeProfile?.profile?.agent_flag);
    const selectedUser = useSelector(state => state.userReducer.user.webaccess);

    const navClick = (link) => {
        props.handleNav(link);
    }

    if (Number(selectedUser?.TRANS_ORDER_STAFF) <= 0) {
        // If orderStaffPermission is not greater than 0, return null to not render any tabs
        return null;
    }


    return (
        <div className="tab-nav cf">
            <a href className={`tab-opt ${profileChangeTabFromStore === 'Profile' ? 'active' : ''}`} onClick={() => { navClick('Profile') }}>
                <span>Profile</span>
            </a>
            {selectedMpidFromStore?.value && selectedMpidFromStore?.value !== 'new' ?
                <>
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'Accounts' ? 'active' : ''}`} onClick={() => { navClick('Accounts') }}>
                        <span>Accounts</span>
                    </a>
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'Products' ? 'active' : ''}`} onClick={() => { navClick('Products') }}>
                        <span>Products</span>
                    </a>
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'NNIs' ? 'active' : ''}`} onClick={() => { navClick('NNIs') }}>
                        <span>NNIs</span>
                    </a>
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'API' ? 'active' : ''}`} onClick={() => { navClick('API') }}>
                        <span>API</span>
                    </a>
                    {/* Check userAdminPermissions before allowing the Add User tab */}
                    {Number(selectedUser?.UserAdmin) > 1 && (
                        <>
                            <a href className={`tab-opt ${profileChangeTabFromStore === 'ADD_USER' ? 'active' : ''}`} onClick={() => { navClick('ADD_USER') }}>
                                <span>Add User</span>
                            </a>
                            {profileChangeTabFromStore === "EDIT_USER" ? (
                                <a href className={`tab-opt ${profileChangeTabFromStore === 'EDIT_USER' ? 'active' : ''}`}>
                                    <span>Edit User</span>
                                </a>
                            ) : null}
                        </>
                    )}
                    {agentFlag === '1' ? (
                        <a href className={`tab-opt ${profileChangeTabFromStore === 'AGENT' ? 'active' : ''}`}>
                            <span>Agent</span>
                        </a>
                    ) : null}
                    {agentFlag === '2' ? (
                        <a href className={`tab-opt ${profileChangeTabFromStore === 'AGENT_CUSTOMER' ? 'active' : ''}`}>
                            <span>Agent Customer</span>
                        </a>
                    ) : null}
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'History' ? 'active' : ''}`} onClick={() => { navClick('History') }}>
                        <span>History</span>
                    </a>
                </>
                : null}
        </div>
    );
}

export default TabsEmployee;