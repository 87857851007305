import React, { useState, useEffect, useMemo, useCallback } from 'react';
import HomeData from '../../services/HomeServices';
import Loader from '../shared/Loader';
import styles from './NotificationUpdater.module.css';
import NotificationUpdaterTabs from './NotificationUpdaterTabs';
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import InternalSuperHelper from '../../services/InternalQuoteService';
import { ConsoleLogger } from "../../logger/ConsoleLogger";

const NotificationUpdater = () => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL }), []);
    const [activeTab, setActiveTab] = useState('information');
    const [messages, setMessages] = useState([]);
    const [loader, setLoader] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const [copyMessage, setCopyMessage] = useState(false);
    const [notificationTypes, setNotificationTypes] = useState([]);
    const [superPermissionFlag, setSuperPermissionFlag] = useState(false);

    const initialMessageData = {
        note_uid: '',
        type_id: 0,
        type: '',
        title: '',
        message: '',
        employee: 1,
        customer: 1,
        disabled: 0,
        start: '',
        stop: '',
        action: ''
    };

    const [messageData, setMessageData] = useState(initialMessageData);

    useEffect(() => {
        InternalSuperHelper.superPermissionCheck()
            .then(response => {
                if (Number(response.data) === 1) {
                    setSuperPermissionFlag(true);
                }
            })
            .catch(error => {
                logger.error(`Error getting superPermissionCheck: ${error}`);
            });
    }, [logger]);

    useEffect(() => {
        if (superPermissionFlag) {
            HomeData.getNotificationTypes()
                .then((resp) => {
                    setNotificationTypes(resp.data);
                })
                .catch((error) => {
                    logger.error("getNotificationTypes ERROR: " + error);
                });
        }
    }, [logger, superPermissionFlag]);

    const loadMessages = useCallback(() => {
        if (superPermissionFlag) {
            setLoader(true);
            HomeData.getMessages()
                .then((response) => {
                    const messageArray = Object.values(response.data).map((message) => ({
                        ...message,
                        id: message.note_uid,
                        type_id: parseInt(message.type_id, 10),
                        employee: parseInt(message.employee, 10),
                        customer: parseInt(message.customer, 10),
                        disabled: parseInt(message.disabled, 10)
                    }));
                    setMessages(messageArray);
                    logger.info(`getMessages: ${JSON.stringify(messageArray)}`);
                })
                .catch((error) => {
                    logger.error("getMessages ERROR: " + error);
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    }, [logger, superPermissionFlag]);

    useEffect(() => {
        if (superPermissionFlag) {
            loadMessages();
        }
    }, [loadMessages, superPermissionFlag]);

    useEffect(() => {
        if (superPermissionFlag) {
            if (activeTab === 'information') {
                loadMessages();
            } else if (activeTab === 'message') {
                if (!copyMessage) {
                    setIsUpdating(false);
                    handleClear();
                } else {
                    setCopyMessage(false);
                }
            }
        }
    }, [activeTab, loadMessages, superPermissionFlag]);
    const handleEditMessage = (message) => {
        const now = new Date();
        const stopDate = new Date(message.stop);
        const notificationTypeName = notificationTypes.find(type => type.id === message.type_id)?.name || '';

        let newMessageData = {
            ...message,
            type: notificationTypeName,
            type_id: message.type_id
        };

        if (stopDate < now) {
            newMessageData = {
                ...newMessageData,
                start: '',
                stop: '',
                action: 'Copy'
            };
        } else {
            newMessageData.action = 'Edit';
        }

        setMessageData(newMessageData);
        setIsUpdating(stopDate >= now);
        setCopyMessage(true);
        setActiveTab('message');
        setResponseMessage(''); // Clear response message on edit
    };

    const columns = [
        { field: 'type', headerName: 'Type', flex: 1 },
        { field: 'title', headerName: 'Title', flex: 1 },
        { field: 'message', headerName: 'Message', flex: 2 },
        { field: 'start', headerName: 'Start Date', flex: 1 },
        { field: 'stop', headerName: 'End Date', flex: 1 },
        {
            field: 'employee',
            headerName: 'Employee',
            flex: 0.5,
            renderCell: (params) => (params.value === 1 ? 'Yes' : 'No')
        },
        {
            field: 'customer',
            headerName: 'Customer',
            flex: 0.5,
            renderCell: (params) => (params.value === 1 ? 'Yes' : 'No')
        },
        {
            field: 'disabled',
            headerName: 'Disabled',
            flex: 0.5,
            renderCell: (params) => (params.value === 1 ? 'Yes' : 'No')
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <button onClick={() => handleEditMessage(params.row)} className="editButton">
                    {params.row.action === 'Edit' ? (
                        <svg className="ico" width="50" height="50" viewBox="0 0 50 50">
                            <path d="M1.438 16.875l5.688 5.689-7.126 1.436 1.438-7.125zm22.562-11.186l-15.46 15.46-5.688-5.689 15.459-15.46 5.689 5.689zm-4.839-2.017l-.849-.849-12.614 12.599.85.849 12.613-12.599z" />
                        </svg>
                    ) : (
                        <svg className="ico" width="50" height="50" viewBox="0 0 50 50">
                            <path d="M33,21V35H15V8h9l9,9v2H22V13h2v4h7l-7-7H17V33H31V21ZM10,12V6h2v4h2v2H10Zm2-9H5V26h8v2H3V1h9l6,6H16Z"></path>
                        </svg>
                    )}
                </button>
            )
        }
    ];

    const reloadMessages = async () => {
        try {
            const messages = await HomeData.getMessages();
            setMessages(messages.data); // Assuming you have a state to hold messages
        } catch (error) {
            console.error('Failed to reload messages:', error);
        }
    };

    const handleClear = () => {
        setResponseMessage('');
        setIsUpdating(false);
        setMessageData(initialMessageData);
    };

    const handleMessageSubmit = async (event) => {
        setResponseMessage('');
        event.preventDefault();

        if (!messageData.type_id) {
            setResponseMessage('Message type is required!');
            return;
        }
        if (!messageData.employee && !messageData.customer) {
            setResponseMessage('Target audience is required!');
            return;
        }
        if (!messageData.message) {
            setResponseMessage('Message is required!');
            return;
        }
        if (!messageData.start || !messageData.stop) {
            setResponseMessage('Start and stop date and time are required!');
            return;
        }

        setLoader(true);
        setResponseMessage('');
        const submitJSON = {
            action: isUpdating ? "updateMessage" : "setMessage",
            note_uid: messageData.note_uid || '',
            type_id: messageData.type_id,
            title: messageData.title,
            message: messageData.message,
            employee: messageData.employee,
            customer: messageData.customer,
            disabled: messageData.disabled || false,
            start: messageData.start,
            stop: messageData.stop
        };
        try {
            logger.info('submitJSON: ' + JSON.stringify(submitJSON));
            const response = await HomeData.updateNotification(submitJSON);
            logger.info('updateNotification response: ' + JSON.stringify(response));
            setResponseMessage(`${response.data.message}`);
        } catch (error) {
            setResponseMessage(`Error: ${error.message}`);
        } finally {
            setLoader(false);
            reloadMessages(); // Reload messages after insert/update
            setActiveTab('information');
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div className="midsection qm-upload abs z1">
                <div className="rel">
                    <div className="wrap">
                        <h1 className="page-title">Home Page Alerts and Messages</h1>
                        <div className="section">
                            <NotificationUpdaterTabs activeTab={activeTab} setActiveTab={setActiveTab} />
                            {activeTab === 'information' && (
                                <div className={`content ${styles.HomeUpdaterContent}`}>
                                    <StripedDataGrid
                                        autoHeight
                                        theme={CustomTheme}
                                        rows={messages}
                                        columns={columns}
                                        pageSize={25}
                                        rowsPerPageOptions={[25, 50, 100]}
                                        pagination
                                        getRowId={(row) => row.id}
                                        slots={{ pagination: TablePagination }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            )}
                            {activeTab === 'message' && (
                                <div className={`content ${styles.HomeUpdaterContent}`}>
                                    <div className="sub-section">
                                        <form onSubmit={handleMessageSubmit}>
                                            {isUpdating && (
                                                <div className={`${styles.HomeUpdaterRowGrp}`}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={messageData.disabled}
                                                            onChange={e => setMessageData({ ...messageData, disabled: e.target.checked })}
                                                        />
                                                        Disable Message
                                                    </label>
                                                </div>
                                            )}
                                            <div className={`${styles.HomeUpdaterRowGrp}`}>
                                                <label className={styles.HomeUpdaterLabelTextS}>Type <span className={styles.HomeUpdaterMandatory}>*</span></label>
                                                <select
                                                    className={styles.HomeUpdaterInputFld}
                                                    value={messageData.type_id}
                                                    onChange={e => setMessageData({ ...messageData, type_id: e.target.value })}
                                                >
                                                    <option value="">Select Type</option>
                                                    {notificationTypes.map((type) => (
                                                        <option key={type.id} value={type.id}>{type.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className={`${styles.HomeUpdaterRowGrp}`}>
                                                <label className={styles.HomeUpdaterLabelTextS}>Target Audience</label>
                                                <div className={styles.checkboxGroup}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={messageData.employee}
                                                            onChange={e => setMessageData({ ...messageData, employee: e.target.checked })}
                                                        />
                                                        Employee
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={messageData.customer}
                                                            onChange={e => setMessageData({ ...messageData, customer: e.target.checked })}
                                                        />
                                                        Customer
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={`${styles.HomeUpdaterRowGrp}`}>
                                                <label className={styles.HomeUpdaterLabelTextS}>Title </label>
                                                <input
                                                    type="text"
                                                    className={styles.HomeUpdaterInputFld}
                                                    value={messageData.title}
                                                    onChange={e => setMessageData({ ...messageData, title: e.target.value })}
                                                />
                                            </div>
                                            <div className={`${styles.HomeUpdaterRowGrp}`}>
                                                <label className={styles.HomeUpdaterLabelTextS}>Message <span className={styles.HomeUpdaterMandatory}>*</span></label>
                                                <textarea
                                                    spellCheck="true"
                                                    className={styles.textareaFld}
                                                    value={messageData.message}
                                                    onChange={e => setMessageData({ ...messageData, message: e.target.value })}
                                                />
                                            </div>
                                            <div className={`${styles.HomeUpdaterRowGrp} ${styles.flexRow}`}>
                                                <div className="col-6">
                                                    <label className={styles.HomeUpdaterLabelTextS}>Start Date and Time <span className={styles.HomeUpdaterMandatory}>*</span></label>
                                                    <input
                                                        type="datetime-local"
                                                        className={styles.HomeUpdaterInputFld}
                                                        value={messageData.start}
                                                        onChange={e => setMessageData({ ...messageData, start: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label className={styles.HomeUpdaterLabelTextS}>End Date and Time <span className={styles.HomeUpdaterMandatory}>*</span></label>
                                                    <input
                                                        type="datetime-local"
                                                        className={styles.HomeUpdaterInputFld}
                                                        value={messageData.stop}
                                                        onChange={e => setMessageData({ ...messageData, stop: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`${styles.ButtonRowGrp} ${styles.center}`}>
                                                <button
                                                    type="button"
                                                    className={`ico-button light-button ${styles.buttonSpacing}`}
                                                    onClick={handleClear}
                                                >
                                                    Clear
                                                    <svg className="ico-x">
                                                        <path
                                                            d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
                                                    </svg>
                                                </button>
                                                <button
                                                    type="submit"
                                                    className={`ico-button light-button`}
                                                >
                                                    {isUpdating ? 'Update Message' : 'Submit Message'}
                                                    <svg className="ico light-ico ico-arrow">
                                                        <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            {responseMessage && (
                                                <div className={styles.HomeUpdaterResponseMessage}>
                                                    {responseMessage}
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotificationUpdater;