import axios from "./axios/customAxios";

const getAllCircuitDemoIds = async () => {
    return await axios.get(window.location.origin + '/assets/json/akamaiCircuitListDemo.json');
}

const getAllCircuitIds = async () => {
	return await axios.get(`${process.env.REACT_APP_API_URL}/ni/getAllCircuitIds.php`);
}

const getIconNetworkJson = async () => {
	return await axios.get(window.location.origin + '/assets/json/iconNetwork.geojson');
}

const getLongHaulNetworkJson = async () => {
    return await axios.get(window.location.origin + '/assets/json/longhaulNetwork.geojson');
}

const getRegionalNetworkJson = async () => {
    return await axios.get(window.location.origin + '/assets/json/regionalNetwork.geojson');
}

const getIlecExchangesJson = async () => {
    return await axios.get(window.location.origin + '/assets/json/ilecExchanges.geojson');
}

const getLeafletDemoJson = async () => {
    return await axios.get(window.location.origin + '/assets/json/akamaiLeafletDemo.json');
}

const getLeafletJsonData = async (commsId, designIds) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/ni/getLeafletJson.php?comms_id=${commsId}&designIds=${JSON.stringify(designIds)}`);
}

const getAdditionalCircuitData = async (designIds) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/ni/getAdditionalCircuitInfo.php?designId=${JSON.stringify(designIds)}`);
}

const getFullCircuitPath = async (designId) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/ni/getFullCircuitPath.php?designId=${designId}`);
}

const getNetworkViewerCircuits = async () => {
	return await axios.get(`${process.env.REACT_APP_API_URL}/ni/getNetworkViewerCircuits.php`);
}

const getNetworkOutageData = async (period) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/ni/getNetworkOutages.php?period=${period}`);
}

const getLatLong = async (address) => {
    // Construct the URL
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyCOEcpv2VdFlr1df956vRkCGYec5dKPWuI`;
    return await axios.get(url,{withCredentials: false});
}

const getKmzLink = async(designId) => {
    const kmzPathURL = `${process.env.REACT_APP_API_URL}/getKmzLink.php?designId=${designId}&action=kmz`;
    return await axios.get(kmzPathURL);
}

const getAllCircuitsKmz = async (commsId) => {
	const allCircuitKmzURL = `${process.env.REACT_APP_API_URL}/getFiberMappingToken.php?action=allKmz&commsId=${commsId}`;
	return await axios.get(allCircuitKmzURL);
}


const NetworkViewerHelper = {
    getAllCircuitDemoIds,
    getAllCircuitIds,
    getIconNetworkJson,
    getLongHaulNetworkJson,
    getRegionalNetworkJson,
    getIlecExchangesJson,
    getLeafletDemoJson,
    getLeafletJsonData,
    getNetworkViewerCircuits,
    getNetworkOutageData,
    getLatLong,
    getKmzLink,
    getAllCircuitsKmz,
    getAdditionalCircuitData,
    getFullCircuitPath
}


export default NetworkViewerHelper;
