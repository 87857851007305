import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import CustomToolbar from '../components/tables/CustomToolbar';
import XLSXExport from "../../utils/XLSXExport";
import CompanyDropdown from '../shared/CompanyDropdown';
import SupportHelper from "../../services/SupportService";
import Loader from '../shared/Loader';

const RfoRcaSummary = () => {
    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const isEmployee = localStorage.getItem('isEmployeeLoggedIn') === 'true';
    const isCustomer = localStorage.getItem('isLoggedIn') === 'true';
    const selectedInternalMpid = useSelector(state => state.internal.company?.value);

    useEffect(() => {
        const shouldFetchData = isCustomer || (isEmployee && !!selectedInternalMpid);

        if (shouldFetchData) {
            setErrorMsg('');
            setLoader(true);
            SupportHelper.getRfoRcaSummary()
                .then(resp => {
                    if (resp.data.result === "SUCCESS") {
                        setRows(resp.data.data);
                    } else if (resp.data.result === "ERROR") {
                        setErrorMsg(`Error: ${resp.data.error}`);
                    }
                    setLoader(false);
                })
                .catch(error => {
                    console.error("Fetch ERROR:", error);
                    setErrorMsg("An error occurred while fetching data.");
                    setLoader(false);
                });
        }
    }, [isCustomer, isEmployee, selectedInternalMpid]);

    const columns = [
        {
            field: 'ado',
            headerName: 'ADO#',
            flex: 0.5,
            renderCell: (params) => (
                <Link to={{
                    pathname: `/Support/RfoRcaDetails/${params.value}`,
                    state: params.row // Pass the entire row data
                }}>
                    {params.value}
                </Link>
            ),
        },
        { field: 'winCase', headerName: 'WIN Case#', flex: 0.5 },
        { field: 'daysToComplete', headerName: 'Days to Complete', flex: 0.5 },
        { field: 'openDate', headerName: 'Open Date', flex: 0.5 },
        { field: 'closeDate', headerName: 'Close Date', flex: 0.5 },
        { field: 'requestorName', headerName: 'Requestor', flex: 0.5 },
        { field: 'status', headerName: 'Status', flex: 0.5 },
    ];

    const formattedDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).replaceAll('/', '-');

    const XLSXHeaders = ["ADO #", "WIN Case#", "Days to Complete", "Open Date", "Close Date", "Status", "Request Type", "Customer/Account Name", "Requestor", "Requestor Callback", "Requestor Email", "Customer Internal Case #", "Emails sent when Completed", "Specific Questions"];

    return (
        <div className={`midsection qm-upload abs z1`}>
            {loader && <Loader />}
            <div className="wrap">
                <h1 className="page-title">{isEmployee ? 'Employee RFO/RCA Summary' : 'RFO/RCA Summary'}</h1>
                {isEmployee && <CompanyDropdown />}
                <div className="section">
                    <div className="content">
                        {errorMsg.length ? (
                            <div className="d-flex justify-content-center mb-5">
                                <p className="text-danger">{errorMsg}</p>
                            </div>
                        ) : (
                            <>

                                <div className="text-right mb-1">
                                    <button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, rows, `RFO_RCA_Summary_${formattedDate}.xlsx`)}>
                                        <span><strong>Export</strong></span>
                                        <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
                                            <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
                                                transform="translate(-1 -1)">
                                            </path>
                                        </svg>
                                    </button>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <StripedDataGrid
                                        autoHeight
                                        theme={CustomTheme}
                                        rows={rows}
                                        rowSelection={false}
                                        getRowId={(row) => row.ado}
                                        columns={columns}
                                        filterModel={filterModel}
                                        onFilterModelChange={(model) => setFilterModel(model)}
                                        pageSize={pageSize}
                                        rowsPerPageOptions={[]}
                                        pageSizeOptions={[]}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        slots={{
                                            pagination: TablePagination,
                                            toolbar: CustomToolbar,
                                        }}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 25,
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RfoRcaSummary;