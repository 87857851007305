import axios from "./axios/customAxios";

const getProspectLocations = async (addressLine) => {
	const prospectLocationURL = `${process.env.REACT_APP_API_URL}/tools/prospect/getProspectData.php?action=locations&addressLine=${addressLine}`;
    return await axios.get(prospectLocationURL);
}


const fetchProspectingData = async (address) => {
    const prospectListURL = `${process.env.REACT_APP_API_URL}/tools/prospect/getProspectData.php?action=list&addressLine=${encodeURIComponent(JSON.stringify(address))}`;
    return await axios.get(prospectListURL);
}

const ProspectHelper = {
    getProspectLocations,
    fetchProspectingData
}

export default ProspectHelper;