import axios from "./axios/customAxios";

const localURL = "iconnect-local.windstreamwholesale.com";
const devURL = "iconnect-dev.windstreamwholesale.com";

const getBillingDisputeList = async () => {
    const disputeListURL = `${process.env.REACT_APP_API_URL}/billing/getBillingDisputes.php?action=list`;
    return await axios.get(disputeListURL);
}

const getBillingDisputeComments = async (caseId) => {
    const disputeCommentsURL = `${process.env.REACT_APP_API_URL}/billing/getBillingDisputes.php?action=comments&caseId=${caseId}`;
    return await axios.get(disputeCommentsURL);
}

const getBillingDisputeMenu = async () => {
    const disputeMenuURL = `${process.env.REACT_APP_API_URL}/billing/getBillingDisputeMenu.php`;
    return await axios.get(disputeMenuURL)
}

const createDisputeComment = async (commentData) => {
    let createDisputeCommentURL = "";
    if(window.location.hostname === localURL || window.location.hostname === devURL){
		createDisputeCommentURL  = `${process.env.REACT_APP_API_URL}/billing/createDisputeComment.php?commentData=${encodeURIComponent(JSON.stringify(commentData))}`;
		return await axios.get(createDisputeCommentURL);
	} else {
		createDisputeCommentURL = `${process.env.REACT_APP_API_URL}/billing/createDisputeComment.php`;
		return await axios.post(createDisputeCommentURL, JSON.stringify(commentData));
	}
}

const submitDispute = async (disputeData) => {
    let submitDisputeURL = "";
    if(window.location.hostname === localURL || window.location.hostname === devURL){
		submitDisputeURL  = `${process.env.REACT_APP_API_URL}/billing/billingDisputeSubmit.php?disputeData=${encodeURIComponent(JSON.stringify(disputeData))}`;
		return await axios.get(submitDisputeURL);
	} else {
		submitDisputeURL  = `${process.env.REACT_APP_API_URL}/billing/billingDisputeSubmit.php`;
		return await axios.post(submitDisputeURL, JSON.stringify(disputeData));
	}
}

const BillingDisputeHelper = {
    getBillingDisputeList,
    getBillingDisputeComments,
    getBillingDisputeMenu,
    createDisputeComment,
    submitDispute
}

export default BillingDisputeHelper;
