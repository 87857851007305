import React, { useCallback, useEffect, useState, useMemo } from 'react';
import ViewportWarning from "../shared/ViewportWarning";
import TabsOrderManager from "./includes/TabsOrderManager";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import XLSXExport from '../../utils/XLSXExport';
import { NavLink } from 'react-router-dom';
import style from "./OrderSummary.module.css";
import OrderHelper from '../../services/OrderService';
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import { useSelector } from 'react-redux';
import CompanyDropdown from '../shared/CompanyDropdown';
import { useParams } from "react-router-dom";

const OrderSummary = () => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [loader, setLoader] = useState(false);
	const [orderedData, setOrderedData] = useState([]);
	//const [days, setDays] = useState(90);
	let today = new Date();
	let todayTimestamp = new Date().setDate(today.getDate());
	let formatToday = new Date(todayTimestamp).toISOString().substring(0, 10)
	let timestamp = new Date().setDate(today.getDate() - 90);
	let past90Days = new Date(timestamp).toISOString().substring(0, 10)
	//const formatToday = today.toLocaleDateString();
	//const formattedDate = past90Days.toLocaleDateString(); // format the date as a string
	const [dateFrom, setDateFrom] = useState(past90Days);
	const [dateTo, setDateTo] = useState(formatToday);
	const [limit, setLimit] = useState(100);
	const [product, setProduct] = useState('all');
	const [activityType, setActivityType] = useState('all');
	const [reportOptionOpened, setReportOptionOpened] = useState(true);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const [errorMSG, setErrorMSG] = useState('');
	const { custPon } = useParams();
	const XLSXHeaders = [
		"Company",
		"Product",
		"Circuit",
		"Bandwidth",
		"RefId",
		"Requested",
		"Ordered",
		"Status",
		"PON",
		"OrderName",
		"Customer",
		"ICBCase",
		"OrderType",
		"Quantity",
		"Term",
		"LocationA",
		"LocationZ",
		"MRC",
		"NRC",
		"NAM"
	];
	const columns = [
		{
			dataField: 'Company',
			text: '',
			filter: textFilter({
				placeholder: 'Company',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'Product',
			text: '',
			classes: "",
			filter: textFilter({
				placeholder: 'Product',
				className: 'placeHolderInput'
			}),
			formatter: (cell, row) => fieldFormater(cell, row)
		},
		{
			dataField: 'Status',
			text: '',
			filter: textFilter({
				placeholder: 'Status',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'PON',
			text: '',
			filter: textFilter({
				placeholder: 'Customer PON',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'OrderName',
			text: '',
			filter: textFilter({
				placeholder: 'Order Name',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'OrderType',
			text: '',
			filter: textFilter({
				placeholder: 'Order Type',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'Requested',
			text: '',
			filter: textFilter({
				placeholder: 'Requested Date',
				className: 'placeHolderInput'
			}),
		},
		{
			dataField: 'Ordered',
			text: '',
			filter: textFilter({
				placeholder: 'Ordered Date',
				className: 'placeHolderInput'
			}),
		}
	];


	function fieldFormater(cell, row) {
		//console.log('Row data:', row); // Log the row data for debugging
		let URL = "";
		if (row.Status === "Saved") {
			URL = `/Order/Create/${row.RefId}`;
		} else {
			URL = `/Order/View/${row.RefId}`;
		}
		return (
			<>
				<NavLink className={style.quoteLink} to={URL} activeClassName="active">{row.Product}</NavLink>
				{/* <a href className={style.quoteLink}>{row.quoteName}</a> */}
				<div>
					{
						row.filterList && row.filterList.map((i, e) =>
							<span className={style.filterList} key={i}>{i}</span>
						)
					}
				</div>
			</>
		)
	}

	function reportOption() {
		setReportOptionOpened(!reportOptionOpened);
	}

	const handleDateUpdate = (event) => {
		let id = event.target.id;
		let value = event.target.value;
		switch (id) {
			case "dateFrom":
				setDateFrom(value);
				break;
			case "dateTo":
				setDateTo(value);
				break;
			default:
				break;
		}
	}

	function handleActivityUpdate(event) {
		let activity_type = event.target.value;
		setActivityType(activity_type);
	}

	function handleLimitUpdate(ev) {
		let limit = ev.target.value;
		setLimit(limit);
	}

	function handleProductUpdate(ev) {
		let product = ev.target.value;
		setProduct(product);
	}

	const reloadSummary = () => {
		fetchOrderSummary(dateFrom, dateTo, limit, product, activityType, custPon);
	}


	const fetchOrderSummary = useCallback((dateFrom, dateTo, limit, product, activityType, custPon) => {
		setErrorMSG('');
		setLoader(true);
		setOrderedData([]);
		if (custPon) { setReportOptionOpened(false); }
		OrderHelper.getOrderData(dateFrom, dateTo, 'OrderSummary', limit, product, activityType, custPon).then((response) => {
			if (response.data.result === "SUCCESS") {
				logger.info(`getOrderSummaryData: ${JSON.stringify(response.data)}`);
				setOrderedData(response?.data?.data);
			} else {
				logger.error('getOrderData Error: ' + response.data.error);
				setErrorMSG(response.data.error);
			}
			setLoader(false);
		}).catch(error => {
			setLoader(false);
			logger.error('getOrderData Error: ' + error);
			setHasError(error);
		});
	}, [logger]);

	const handleDownloadClick = () => {
			fetchOrderDownload();
	};

	const fetchOrderDownload = useCallback(() => {
		setErrorMSG('');
		setLoader(true);
		OrderHelper.getOrderData(dateFrom, dateTo, 'OrderSummaryDownload', limit, product, activityType, custPon).then((response) => {
			if (response.data.result === "SUCCESS") {
				logger.error('getOrderData(download): ' + JSON.stringify(response?.data?.data));
				exportData(response?.data?.data);
			} else {
				logger.error('getOrderData(download) Error: ' + response.data.error);
				setErrorMSG(response.data.error);
			}
			setLoader(false);
		})
			.catch(error => {
				setLoader(false);
				logger.error('getOrderDownloadData Error: ' + error);
				setErrorMSG('Error fetching data');
			});
	}, [activityType, custPon, dateFrom, dateTo, limit, product]);

	const exportData = (data) => {
		logger.info('exportData: orderedDownloadData:', data);
		if (data?.length > 0) {
			XLSXExport.exportToExcel(XLSXHeaders, data, `Order_Summary_${(() => {
				const currentDate = new Date();
				const month = String(currentDate.getMonth() + 1).padStart(2, '0');
				const day = String(currentDate.getDate()).padStart(2, '0');
				const year = currentDate.getFullYear();
				return `${month}_${day}_${year}`;
			})()}`);
		} else {
			logger.error('No data available for download');
		}
	};
	useEffect(() => {
		//Internal users need to select a mpid before fetching the order summary data
		if (isEmployee) {
			if (selectedInternalMpid || custPon) {
				fetchOrderSummary(dateFrom, dateTo, limit, product, activityType, custPon);
			}
		} else {
			fetchOrderSummary(dateFrom, dateTo, limit, product, activityType, custPon);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedInternalMpid, custPon]);

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			<div className="midsection order-summary light-theme abs z1">
				{loader ? <Loader /> : null}
				<div className="rel">
					<ViewportWarning />
					<div className="wrap">
						<h1 className="page-title">{isEmployee ? "Employee Order Manager" : "Order Manager"}</h1>
						{isEmployee ? <CompanyDropdown area={"Summary"} /> : null}
						{!hasError && (<div className="section orderSummaryPage">
							<TabsOrderManager />
							<div className={`content ${style.contentOrderPage} `}>
								<div className={`sub-hdr alt1 ${style.subHdrAlter}`}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={style.sectionTitle}>Submitted Requests</h2>
								</div>
								<p className={`text-center my-4 text-danger`}>{errorMSG}</p>
								{/* <GridFilter /> */}
								<div>
									{/* <GridFilter /> */}
									<div className={style.filterWrap}>
										<div>
											<button className="ico-button light-button" onClick={handleDownloadClick}>
												Download Order Summary
											</button>
											<div className="expander" collapsed-id="icon-content">
												<svg className="ico-link light-ico ico-add" onClick={reportOption}>
													<path d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
												</svg>
											</div>
										</div>
									</div>
								</div>
								{reportOptionOpened ?
									<div className={`row ${style.reportOptionWrap} `}>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Results</h4>
											<label className={style.labelText}>Date From</label>
											<input type='date' min="2015-01-01" className={style.dateFld} id='dateFrom' name='dateFrom' onChange={handleDateUpdate} value={dateFrom}></input>
											<label className={style.labelText}>Date To</label>
											<input type='date' min="2015-01-01" className={style.dateFld} id='dateTo' name='dateTo' onChange={handleDateUpdate} value={dateTo}></input>
										</div>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Product(s)</h4>
											<div className={style.paramsList}>
												<select className={`form-control  ${style.selectFld}`} name="product" value={product} onChange={handleProductUpdate}>
													<option value='all'>All Products</option>
													<option value='1'>Wave</option>
													<option value='10'>DIA POP</option>
													<option value='11'>DIA Prem</option>
													<option value='23'>Wholesale Broadband</option>
													<option value='6'>MPLS AGG Tail</option>
													<option value='13'>MPLS + Internet</option>
													<option value='7'>MPLS NNI</option>
													<option value='19'>E-Line</option>
													<option value='20'>E-Access</option>
													<option value='22'>E-Access NNI</option>
													<option value='15'>Colocation</option>
													<option value='29'>OfficeSuite White Label</option>
													<option value='32'>Dark Fiber</option>
												</select>
											</div>
											<h4>Limit</h4>
											<div className={style.paramsList}>
												<select className={`form-control  ${style.selectFld}`} name="limit" value={limit} onChange={handleLimitUpdate}>
													<option value='100'>100 Orders</option>
													<option value='500'>500 Orders</option>
													<option value='1000'>1000 Orders</option>
													<option value='5000'>5000 Orders</option>
													<option value='all'>All Orders</option>
												</select>
											</div>
										</div>
										<div className={` col-md-4 ${style.reportFilter} `}>
											<h4>Activity Requested(s)</h4>
											<div className={style.paramsList}>
												<select className={`form-control  ${style.selectFld}`} name="activity_type" value={activityType} onChange={handleActivityUpdate}>
													<option value='all'>All Activity Types</option>
													<option value='N'>New</option>
													<option value='A'>Add</option>
													<option value='C'>Change</option>
													<option value='D'>Disconnect</option>
													<option value='R'>Renewals</option>
												</select>
											</div>
											<div className={style.paramsList}>
												<button className={`light-button ${style.applyBtn}`} onClick={reloadSummary}>
													Apply
												</button>
											</div>
										</div>
									</div>
									: null}

								<div className="rel b mt-3">
								</div>

								<div className="quoteSummaryTableWrap">
									<div className="rel pagination-table page-list my-3">
										<BootstrapTable id="quoteSummaryTable"
											keyField='id'
											data={orderedData}
											columns={columns}
											striped
											hover
											condensed
											bordered={false}
											pagination={paginationFactory({
												sizePerPage: 10
											})}
											filter={filterFactory()}
											loading={true}
											className="mb-0"
										/>
									</div>
								</div>
							</div>
						</div>)}
					</div>
				</div>
			</div>
		</>);
}

export default OrderSummary;