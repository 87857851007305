import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../../utils/CustomBotpress';
import { ConsoleLogger } from '../../logger/ConsoleLogger';

const Chatbot = () => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL });
    const jwt = useSelector(state => state.userReducer?.user?.jwt);
    const expTime = useSelector(state => state.userReducer?.user?.expTime);
    const employeeName = localStorage.getItem("employeeName")?.replace(/\+/g, ' ');

    useEffect(() => {
        logger.info("Inside of first useEffect to initialize chatbot on login and after getting the JWT");
        if(jwt){
            initializeChatBot();
        }
    },[jwt])

    useEffect(() => {
        logger.info("Inside of second useEffect in Chatbot.js which handles the offline and online events preventing any connection issues with botpress");
        const handleOffline = () => {
            logger.info("Botpress connection lost offline event trigged removing chatbot widget");
          // Remove existing widget if it exists
          const existingWidget = document.getElementById("bp-web-widget");
          if (existingWidget) {
              logger.info("Removing existing botpress widget");
              existingWidget.remove();
          }
        };
    
        const handleOnline = () => {
            logger.info("Online event triggered connect and initialize connection to chatbot.");
            if (jwt) {
                initializeChatBot();
            }
        }
    
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);
    
        return () => {
          window.removeEventListener('offline', handleOffline);
          window.removeEventListener('online', handleOnline);
        };
    }, [jwt]);


    //Initialize Botpress Chatbot Widget
    const initializeChatBot = () => {
        try {
            // Remove existing widget if it exists
            const existingWidget = document.getElementById("bp-web-widget");
            if (existingWidget) {
                logger.info("Removing existing botpress widget");
                existingWidget.remove();
            }

            logger.info("Initialize Botpress");
            logger.info(`employeeName that is going to be sent to botpress: ${employeeName}`);
            logger.info(`expTime that is going to be sent to botpress: ${expTime}`);

            // Initialize Botpress
            window.botpressWebChat.init({
                botName: "iConnect Digital Assistant",
                botId: "36205362-3409-48d9-9038-822f8d183f63",
                hostUrl: "https://cdn.botpress.cloud/webchat/v1",
                messagingUrl: "https://messaging.botpress.cloud",
                clientId: "36205362-3409-48d9-9038-822f8d183f63",
                stylesheet: "https://webchat-styler-css.botpress.app/prod/code/b8426d8b-d532-458c-b93a-51557277ccb8/v66722/style.css",
                avatarUrl: "https://files.bpcontent.cloud/2024/10/09/19/20241009191949-9TAT6EN0.png",
                showTimestamp: true,
                enableConversationDeletion: true,
                showCloseButton: true,
                userData: {
                    name: employeeName,
                    jwt: jwt,
                    expTime: expTime.toString()
                }
            });

            logger.info('Botpress initialized successfully');
        } catch (error) {
            logger.error(`Error initializing Botpress: ${error}`);
        }
    }

    return <></>
}

export default Chatbot;