/*************************************
Order Supplement Componenet
This component gets called when an order
is able to be supped. It uses service
calls found in QuoteService.js and
OrderService.js to populate the contact
dropdown options, autofill contact, and
to submit the sup order

 *************************************/

import {useState} from "react";
import style from './OrderCreate.module.css';
import QuoteHelper from '../../services/QuoteService';
import OrderHelper from '../../services/OrderService';
import Loader from '../shared/Loader';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CustomDatePicker from '../components/CustomDatePicker';

const OrderSup = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const [supType, setSupType] = useState("");
	const [contactOptions, setContactOptions] = useState([]);
	const [newPrimaryContactFlag, setNewPrimaryContactFlag] = useState(false);
	const [newTechContactFlag, setNewTechContactFlag] = useState(false);
	const [primaryContact, setPrimaryContact] = useState("");
	const [newPrimaryContact, setNewPrimaryContact] = useState("");
	const [primaryEmail, setPrimaryEmail] = useState("");
	const [primaryPhone, setPrimaryPhone] = useState("");
	const [techContact, setTechContact] = useState("");
	const [newTechContact, setNewTechContact] = useState("");
	const [techEmail, setTechEmail] = useState("");
	const [techPhone, setTechPhone] = useState("");
	const [customerCircuit, setCustomerCircuit] = useState("");
	const [notes, setNotes] = useState("");
	const [loader, setLoader] = useState(false);
	const [cancelReason, setCancelReason] = useState("");
	const [serviceDate, setServiceDate] = useState("");
	const [validServiceDate, setValidServiceDate] = useState("");
	const [expedite, setExpedite] = useState("N");
	const [popupShow, setPopupShow] = useState(false);
	const [popupMsg, setPopupMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("");

	const supFields = [
		{ id: 0, labelName: "Supplement Type", code: "supp", value: supType, required: true },
		{ id: 1, labelName: "Cancel Reason", code: "cancel_reason", value: cancelReason, required: false },
		{ id: 2, labelName: "Requested Service Date", code: "serv_date", value: serviceDate, required: false },
		{ id: 3, labelName: "Expedite", code: "exp", value: expedite, required: false },
		{ id: 4, labelName: "Primary Contact", code: "primaryName", value: primaryContact, required: false },
		{ id: 5, labelName: "Primary Phone", code: "primaryPhone", value: primaryPhone, required: false },
		{ id: 6, labelName: "Primary Email", code: "primaryEmail", value: primaryEmail, required: false },
		{ id: 7, labelName: "Tech Contact", code: "techName", value: techContact, required: false },
		{ id: 8, labelName: "Tech Phone", code: "techPhone", value: techPhone, required: false },
		{ id: 9, labelName: "Tech Email", code: "techEmail", value: techEmail, required: false }
	];

	const getContactOptions = () => {
		setLoader(true);
		QuoteHelper.getQuoteContact().then(resp => {
			logger.info("Contacts: " + resp.data);
			setContactOptions(resp.data);
			setLoader(false);
		}).catch(error => {
			logger.error("Getting Contacts Error: " + error);
			setHasError(error);
			setLoader(false);
		})
	}

	const getServiceDate = () => {
		setLoader(true);
		OrderHelper.getOrderServiceDate(props.orderData.quote_id, props.orderData.product_id, props.orderData.circuit_id).then(resp => {
			logger.info("Service Date: " + encodeURIComponent(resp.data).replace(/%20/g, " "));
			setValidServiceDate(resp.data)
			setServiceDate(resp.data)
			setLoader(false);
		}).catch(error => {
			logger.error("Getting Service Date Error: " + error);
			setHasError(error);
			setLoader(false);
		})
	}

	const contactHandler = (ev) => {
		let contact = ev.target.value;
		if (contact === 'new') {
			if (ev.target.id === "orig_contact") {
				setPrimaryContact(ev.target.value)
				setNewPrimaryContactFlag(true);
			} else {
				setTechContact(ev.target.value);
				setNewTechContactFlag(true);
			}
		} else {
			if (ev.target.id === "orig_contact") {
				setPrimaryContact(ev.target.value);
				autoFillContact(contact, "orig_contact");
			} else {
				setTechContact(ev.target.value);
				autoFillContact(contact, "tech_contact");
			}
		}
	}

	const autoFillContact = (contactValue, type) => {
		setLoader(true);
		QuoteHelper.getQuoteContactInfo(contactValue).then(resp => {
			if (type === "orig_contact") {
				setPrimaryPhone(resp.data.phone);
				setPrimaryEmail(resp.data.email);
			} else {
				setTechPhone(resp.data.phone);
				setTechEmail(resp.data.email);
			}
			setLoader(false);

		}).catch(error => {
			logger.error("Error Autofilling contact: " + error);
			setHasError(error);
			setLoader(false);
		})

	}

	const newContactHandler = (ev) => {
		switch (ev.target.id) {
			case "new_orig_contact":
				setNewPrimaryContact(ev.target.value);
				break;
			case "new_tech_contact":
				setNewTechContact(ev.target.value);
				break;
			default:
				break;
		}
	}

	const servDateRequestHandler = (ev) => {
		if (ev.target.value < validServiceDate) {
			setPopupShow(true);
			setPopupMsg("You have selected a date that falls outside of our projected Standard Interval range, we will make every effort to reach the selected date but it cannot be guaranteed. If you would like to insure this date, please select the expedite option. Please click Ok to confirm.");
			setServiceDate(ev.target.value);
		} else {
			setPopupShow(false);
			setPopupMsg("");
			setServiceDate(ev.target.value);
		}
	}

	const expediteRequestHandler = (ev) => {
		if (ev.target.value === "Y") {
			setPopupShow(true);
			setPopupMsg("Additional fees may apply by requesting an expedite on this order.  Request of an expedite does not guarantee delivery on requested due date.");
			setExpedite(ev.target.value);
		} else {
			setPopupShow(false);
			setPopupMsg("");
			setExpedite(ev.target.value);
		}
	}

	const popupOk = () => {
		setPopupShow(false);
		setPopupMsg("");
	}

	const popupCancel = () => {
		setPopupShow(false);
		setPopupMsg("");
		setExpedite("N");
	}

	const supvalidation = () => {
		let findRequired = null;
		if (supType === "1") {
			supFields[1].required = true;
		} else if (supType === "2") {
			supFields[2].required = true;
			supFields[3].required = true;
		} else {
			for (let index = 4; index < supFields.length; index++) {
				supFields[index].required = true;
			}
		}

		findRequired = supFields.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);

		if (findRequired) {
			setErrorMsg('Please verify all required fields are completed.');
			return;
		} else {
			setErrorMsg("");
		}
	}

	const supplementHandler = (ev) => {
		switch (ev.target.id) {
			case "sup_req":
				setSupType(ev.target.value);
				setErrorMsg("");
				if (ev.target.value === "2") {
					getServiceDate();
				} else if (ev.target.value === "4") {
					getContactOptions();
				}
				if (ev.target.value !== "1") {
					setCancelReason("");
				} else if (ev.target.value !== "2") {
					setServiceDate("");
					setValidServiceDate("");
					setExpedite("N");
				} else if (ev.target.value !== "4") {
					setNewPrimaryContactFlag(false);
					setNewTechContactFlag(false);
					setPrimaryContact("");
					setNewPrimaryContact("");
					setPrimaryEmail("");
					setPrimaryPhone("");
					setTechContact("");
					setNewTechContact("");
					setTechEmail("");
					setTechPhone("");
					setCustomerCircuit("");
					setNotes("");
				}
				break;
			case "cancel_reason":
				setCancelReason(ev.target.value);
				break;
			case "orig_phone":
				setPrimaryPhone(ev.target.value);
				break;
			case "orig_email":
				setPrimaryEmail(ev.target.value);
				break;
			case "tech_phone":
				setTechPhone(ev.target.value);
				break;
			case "tech_email":
				setTechEmail(ev.target.value);
				break;
			case "cust_circuit":
				setCustomerCircuit(ev.target.value);
				break;
			case "notes":
				setNotes(ev.target.value);
				break;
			default:
				break;
		}
	}

	const supSubmit = () => {
		setLoader(true);
		supvalidation();
		let supJson = {
			"ref_id": props.orderData.ref_id,
			"product": props.orderData.product_id,
			"supp": supType,
			"cancel_reason": cancelReason,
			"svc_date": serviceDate,
			"exp": expedite,
			"sup_notes": notes,
			"orig_contact": primaryContact,
			"new_orig_contact": newPrimaryContact,
			"orig_phone": primaryPhone,
			"orig_email": primaryEmail,
			"tech_contact": techContact,
			"new_tech_contact": newTechContact,
			"tech_phone": techPhone,
			"tech_email": techEmail,
			"cust_circuit": customerCircuit
		}

		logger.info("SUP SUBMISSION: " + JSON.stringify(supJson));

		OrderHelper.createSup(supJson).then(resp => {
			setLoader(false);
			logger.info("Response from OrderSupp.php: " + encodeURIComponent(resp.data).replace(/%20/g, " "));
			if (resp.data.result === "SUCCESS") {
				logger.info("SUP ORDER WAS SUCCESSFUL");
				window.location.reload(false);
			} else {
				logger.error("Error with sup submission");
				setLoader(false);
				setErrorMsg("FAIL TO SUBMIT SUP ORDER")
			}
		}).catch(error => {
			logger.error("Error with submitting Supplement Order " + error);
			setHasError(error);
			setLoader(false);
		})

	}

	return (
		<>
		{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<><div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
				<div className="nib">
					<div className="the-notch">
						<div className="notch-fg">
							<svg>
								<path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
							</svg>
						</div>
					</div>
				</div>
				<h2 className={` ${style.sectionTitle} m-0 `}>Supplements</h2>
			</div>
			<div className="sub-section">
				<div className={` row ${style.rowGrp}`}>
					<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
						<label className={style.labelText}>Supplement Type<span className={style.mandatory}>*</span></label>
						<select className={style.selectFld} id='sup_req' name='sup_req' onChange={supplementHandler} value={supType}>
							<option>Select Request Type</option>
							<option value="1">Cancel Order</option>
							<option value="2">Change Requested Due Date</option>
							<option value="4">Update Order Contacts</option>
						</select>
					</div>
					{supType === "1" ?
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Cancel Reason<span className={style.mandatory}>*</span></label>
							<select className={style.selectFld} id='cancel_reason' name='cancel_reason' onChange={supplementHandler} value={cancelReason}>
								<option></option>
								<option value="1">End User business moved to new location</option>
								<option value="2">End User business created ceased trade</option>
								<option value="3">End User requested different carrier</option>
								<option value="4">End User cancelled order</option>
								<option value="5">Moved End User to alternate carrier</option>
							</select>
						</div> : null}
					{supType === "2" ?
							<>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Requested Service Date<span className={style.mandatory}>*</span></label>
									<CustomDatePicker
										value={serviceDate} // Use the current service date value
										onChange={(newDate) => servDateRequestHandler({ target: { id: 'serv_date', value: newDate } })} // Handle date change
										id='serv_date'
										name='serv_date'
										className={style.dateFld}
									/>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Expedite Request<span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id='expedite_req' name='expedite_req' onChange={expediteRequestHandler} value={expedite}>
									<option value="N">NO</option>
									<option value="Y">YES</option>
								</select>
							</div>
						</>
						: null}
				</div>
			</div>
			{supType === "4" ?
				<>
					<div className="sub-section">
						<div className={` row ${style.rowGrp}`}>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Primary Authorizing Contact<span className={style.mandatory}>*</span></label>
								{newPrimaryContactFlag ?
									<input type='text' className={style.inputFld} maxLength='108' id='new_orig_contact' name='new_orig_contact' onChange={newContactHandler} value={newPrimaryContact}></input>
									:
									<select className={style.selectFld} onChange={contactHandler} id='orig_contact' name='orig_contact' value={primaryContact}>
										{contactOptions.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>}
							</div>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Primary Authorizing Phone<span className={style.mandatory}>*</span></label>
								<input type='text' className={style.inputFld} maxLength='10' id='orig_phone' name='orig_phone' onChange={supplementHandler} value={primaryPhone}></input>
							</div>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Primary Authorizing Email<span className={style.mandatory}>*</span></label>
								<input type='text' className={style.inputFld} maxLength='118' id='orig_email' name='orig_email' onChange={supplementHandler} value={primaryEmail}></input>
							</div>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Data Technical Contact<span className={style.mandatory}>*</span></label>
								{newTechContactFlag ?
									<input type='text' className={style.inputFld} maxLength='108' id='new_tech_contact' name='new_tech_contact' onChange={newContactHandler} value={newTechContact}></input>
									:
									<select className={style.selectFld} id='tech_contact' name='tech_contact' onChange={contactHandler} value={techContact}>
										{contactOptions.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>}
							</div>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Data Technical Phone<span className={style.mandatory}>*</span></label>
								<input type='text' className={style.inputFld} maxLength='10' id='tech_phone' name='tech_phone' onChange={supplementHandler} value={techPhone}></input>
							</div>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Data Technical Email<span className={style.mandatory}>*</span></label>
								<input type='text' className={style.inputFld} maxLength='118' id='tech_email' name='tech_email' onChange={supplementHandler} value={techEmail}></input>
							</div>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Customer Circuit #</label>
								<input type='text' className={style.inputFld} maxLength='63' id='cust_circuit' name='cust_circuit' onChange={supplementHandler} value={customerCircuit}></input>
							</div>
						</div>
					</div>
					<div className="sub-section">
						<div className={` row ${style.rowGrp}`}>
							<div className={` col-12 col-sm-6 col-md-8 ${style.colGrp}`}>
								<label className={style.labelText}>Notes</label>
								<textarea className={style.textAreaFld} id='notes' name='notes' onChange={supplementHandler} value={notes}></textarea>
							</div>
						</div>
					</div>
				</>
				: null
			}

			<div className="text-center">
				<p className='text-danger mt-3'>{errorMsg}</p>
			</div>

			<div className={` row ${style.rowGrp}`}>
				<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
					<button type="submit" className={style.supBtn} onClick={supSubmit}>SUPPLEMENT ORDER</button>
				</div>
			</div>
			{popupShow ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-md`}>
							<div className="modal-content">
								<div className="modal-body">
									{popupMsg}
								</div>
								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={popupOk}>Ok</button>
									<button type="button" className="btn-style btn-cancel" data-dismiss="modal" onClick={popupCancel}>Cancel</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				: null
			}</>)}
		</>
	)
}


export default OrderSup;
