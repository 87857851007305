import React, { useEffect, useCallback, useState, useMemo } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HomeData from "../../services/HomeServices";
import style from "./POPCreator.module.css";
import POPService from "../../services/POPService";
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import {
	setPopUid,
	setPopMpid,
	setPopTier,
	setPopCswc,
	setPopLocationId,
	setPopCity,
	setPopState,
	setPopCountry,
	setPopZip,
	setPopNpanxx,
	setPopVcoord,
	setPopAvCswc,
	setPopAddress,
	setPopOcn,
	setPopLata,
	setPopSwitchClli,
	setPopHcoord,
	setPopValidated,
	setPopCounty,
	setPopGeocode,
	setPopLatitude,
	setPopLongitude,
	setPopRatecenter,
	setPopSano,
	setPopSapr,
	setPopSasd,
	setPopSasf,
	setPopSasn,
	setPopSass,
	setPopSath,
	setPopLecType,
	setPopUnitiMla,
	setPopNewLocType,
	setPopLd1,
	setPopLd2,
	setPopLd3,
	setPopLv1,
	setPopLv2,
	setPopLv3,
	setPopProvider,
	setPopIcon,
	setPopWave,
	setPopWaveXc,
	setPopLandlord,
	setPopDci,
	setPopWaveTier,
	setPopWaveClass,
	setPopWaveType,
	setPopWaveMetroArea,
	setPopWaveLcn,
	setPopG10,
	setPopG100,
	setPopG400,
	setPopG800,
	setPopElineEnet,
	setPopEthernet,
	setPopColo,
	setPopColoTier,
	setPopComments,
	setPopActive
} from '../../redux/actions/POPActions';

const POPCreator = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL }), []);
	const [addressObj, setAddressObj] = useState({ "address": "", "city": "", "state": "", "zip": "", "floor": "", "cswc": "" });
	const [addressErrorObj, setAddressErrorObj] = useState({ "addressError": "", "cityError": "", "stateError": "", "zipError": "", "cswcError": "" });
	const [stateList, setStateList] = useState([]);
	const [loader, setLoader] = useState(false);
	const [errorMSG, setErrorMSG] = useState('');
	const history = useHistory();
	const google = window.google = window.google ? window.google : {};

	const fillInAddress = useCallback((ac) => {
		var place = ac.getPlace();
		setAddressObj(prevAdd => ({ ...prevAdd, "address": place.formatted_address }));
		for (let x = 0; x < place.address_components.length; x++) {
			switch (place.address_components[x].types[0]) {
				case 'locality':
					setAddressObj(prevAdd => ({ ...prevAdd, "city": place.address_components[x].short_name }));
					break;
				case 'administrative_area_level_1':
					setAddressObj(prevAdd => ({ ...prevAdd, "state": place.address_components[x].short_name }));
					break;
				case 'postal_code':
					setAddressObj(prevAdd => ({ ...prevAdd, "zip": place.address_components[x].short_name }));
					break;
				default:
					break;
			}
		}
		return place;
	}, [])

	const initAutocomplete = useCallback(() => {
		const autocomplete = new google.maps.places.Autocomplete((document.getElementById('address')), { types: ['geocode'] });
		google.maps.event.addListener(autocomplete, 'place_changed', function () {
			fillInAddress(autocomplete);
		});
	}, [fillInAddress, google.maps.event, google.maps.places.Autocomplete])

	useEffect(() => {
		// Get the list of all the states for AV form
		HomeData.getStateList().then(resp => {
			setStateList(resp.data);
		}).catch((error) => {
			logger.error(`getStateList Error: ${error}`);
		});
		initAutocomplete();
	}, [initAutocomplete, logger]);

	const clearAddress = () => {
		setAddressObj({ "address": "", "city": "", "state": "", "zip": "", "floor": "", "cswc": "" });
		setAddressErrorObj({ "addressError": "", "cityError": "", "stateError": "", "zipError": "", "cswcError": "" });
	};

	const validate = () => {
		// Clear existing error messages
		setAddressErrorObj({ "addressError": "", "cityError": "", "stateError": "", "zipError": "", "cswcError": "" });

		// Validation Check
		if (!addressObj.address) setAddressErrorObj(prevAddError => ({ ...prevAddError, "addressError": "Address is required" }));
		if (!addressObj.city) setAddressErrorObj(prevAddError => ({ ...prevAddError, "cityError": "City is required" }));
		if (!addressObj.state) setAddressErrorObj(prevAddError => ({ ...prevAddError, "stateError": "State is required" }));
		if (!addressObj.zip) setAddressErrorObj(prevAddError => ({ ...prevAddError, "zipError": "Zip is required" }));
		if (!addressObj.cswc) setAddressErrorObj(prevAddError => ({ ...prevAddError, "cswcError": "Building CLLI is required" }));

		if (addressObj.address && addressObj.city && addressObj.state && addressObj.zip && addressObj.cswc) {
			setLoader(true);
			logger.info(`Address to Validate: ${JSON.stringify(addressObj)}`);
			POPService.createPOP(addressObj).then(response => {
				logger.info(`POP Address Validator Response: ${JSON.stringify(response?.data)}`);
				const result = response?.data?.result;
				logger.info(`RESULT:` + result);
				if (result === "CREATE") {
					const data = response?.data?.data[0];
					// Dispatch actions to update the Redux store with validated address data
					props.cswc(data?.cswc);
					props.validated(data?.validated);
					props.address(data?.address);
					props.av_cswc(data?.av_cswc);
					props.state(data?.state);
					props.city(data?.city);
					props.county(data?.county);
					props.country(data?.country);
					props.zip(data?.zip);
					props.latitude(data?.latitude);
					props.longitude(data?.longitude);
					props.vcoord(data?.vcoord);
					props.hcoord(data?.hcoord);
					props.geocode(data?.geocode);
					props.lata(data?.lata);
					props.ratecenter(data?.ratecenter);
					props.switch_clli(data?.switch_clli);
					props.ocn(data?.ocn);
					props.npanxx(data?.npanxx);

					// Address components
					props.sano(data?.sano);
					props.sasd(data?.sasd);
					props.sasn(data?.sasn);
					props.sath(data?.sath);

					// Clearing values not returned by validator
					props.tier('');
					props.location_id('');
					props.sapr('');
					props.sasf('');
					props.sass('');
					props.lec_type('');
					props.uniti_mla('');
					props.new_loc_type('');
					props.ld1('');
					props.ld2('');
					props.ld3('');
					props.lv1('');
					props.lv2('');
					props.lv3('');
					props.provider('');
					props.icon('');
					props.wave('');
					props.wave_xc('');
					props.landlord('');
					props.dci('');
					props.wave_tier('');
					props.wave_class('');
					props.wave_type('');
					props.wave_metro_area('');
					props.wave_lcn('');
					props.g10('');
					props.g100('');
					props.g400('');
					props.g800('');
					props.eline_enet('');
					props.ethernet('');
					props.colo('');
					props.colo_tier('');
					props.comments('');
					props.active('1'); // default to active
					// Redirect to POPEditor after successful validation
					history.push({
						pathname: '/POPEditor/NEW',
					});
				} else if (result === "DUPLICATE") {
					const data = response?.data?.data;
					logger.info("DUPLICATE result, redirect to viewer");
					history.push({
						pathname: '/POPViewer',
						state: { duplicateData: data }
					});
				} else if (result === "FAIL") {
					logger.error("Validation failed: Address did not validate.");
					setErrorMSG("Address did not validate.");
				}
				setLoader(false);
			})
			.catch(error => {
				logger.error(`validateAddress ERROR: ${error}`);
				setAddressErrorObj(prevAddError => ({ ...prevAddError, "addressError": 'Failed to validate address. Please try again.' }));
				setLoader(false);
			});
		}
	};
	// input handler for all of the required fields
	const addressInputHandler = (event) => {
		let id = event.target.id;
		let errorId = id + "Error";
		setAddressObj(prevAdd => ({ ...prevAdd, [id]: event.target.value }));
		setAddressErrorObj(prevAdd => ({ ...prevAdd, [errorId]: "" }));
	};

	return (
		<>
			{loader ? <Loader /> : null}
			<div className="midsection qm-upload abs z1">
				<div className="rel">
					<div className="wrap">
						<h1 className="page-title">Create POP</h1>
						<div className="section">
							<div className={`content ${style.contentQueryPage}`}>
								<div className={`row `}>
									<div className="col-12">
										<p className={` ${style.lableTextS} ${style.textBlack} `}>
											<span className={style.mandatory}>*</span> Denotes a required field</p>
									</div>
								</div>
								{errorMSG?.length ?
									<div style={{ whiteSpace: 'pre-wrap' }}>
										<p className="text-danger text-center" dangerouslySetInnerHTML={{ __html: errorMSG }} />
									</div> : null}
								<div className={`sub-hdr alt ${style.subHeader} `}>
									<div className="nib">
										<div className="the-notch">
											<div className="notch-fg">
												<svg>
													<path
														d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
												</svg>
											</div>
										</div>
									</div>
									<h2 className={style.sectionTitle}>Address Input</h2>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-8">
										<label className={style.lableTextS}>Address <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id="address" onChange={(event) => addressInputHandler(event)} value={addressObj.address} />
										{addressErrorObj.addressError ?
											<div className="invalid-feedback d-block">
												{addressErrorObj.addressError}
											</div> : null
										}
									</div>
									<div className="col-4">
										<label className={style.lableTextS}>Floor</label>
										<input type="text" className={style.inputFld} id='lv2' onChange={(event) => setAddressObj(prevAdd => ({ ...prevAdd, [event.target.id]: event.target.value }))} value={addressObj.floor} />
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-6">
										<label className={style.lableTextS}>City <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id='city' onChange={(event) => addressInputHandler(event)} value={addressObj.city} />
										{addressErrorObj.cityError ?
											<div className="invalid-feedback d-block">
												{addressErrorObj.cityError}
											</div> : null
										}
									</div>
									<div className="col-4">
										<label className={style.lableTextS}>State <span className={style.mandatory}>*</span></label>
										<select className={style.selectFldState} id='state' onChange={(event) => addressInputHandler(event)} value={addressObj.state}>
											{stateList.map((obj) => (
												<option key={obj.id} value={obj.id}>{obj.name}</option>
											))}
										</select>
										{addressErrorObj.stateError ?
											<div className="invalid-feedback d-block">
												{addressErrorObj.stateError}
											</div> : null
										}
									</div>
									<div className="col-2">
										<label className={style.lableTextS}>ZIP <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id='zip' onChange={(event) => addressInputHandler(event)} value={addressObj.zip} />
										{addressErrorObj.zipError ?
											<div className="invalid-feedback d-block">
												{addressErrorObj.zipError}
											</div> : null
										}
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-3">
										<label className={style.lableTextS}>Building Clli <span className={style.mandatory}>*</span></label>
										<input type="text" className={style.inputFld} id='cswc' onChange={(event) => setAddressObj(prevAdd => ({ ...prevAdd, [event.target.id]: event.target.value }))} value={addressObj.cswc} />
										{addressErrorObj.cswcError ?
											<div className="invalid-feedback d-block">
												{addressErrorObj.cswcError}
											</div> : null
										}
									</div>
								</div>
								<div className={`row ${style.rowGrp}`}>
									<div className="col-6">
										<button className={`ico-button light-button`} type="button" onClick={() => clearAddress()}>
											Clear
											<svg className="ml-2 ico-x">
												<path d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
											</svg>
										</button>
									</div>
									<div className="col-6">
										<button className={`ico-button light-button`} type="button" onClick={() => validate()}>
											Validate
											<svg className="ico light-ico ico-arrow">
												<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
											</svg>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapDispatchToProps = {
	// Administration Actions
	uid: setPopUid,
	mpid: setPopMpid,
	tier: setPopTier,
	cswc: setPopCswc,
	location_id: setPopLocationId,
	// Address Actions
	city: setPopCity,
	state: setPopState,
	country: setPopCountry,
	zip: setPopZip,
	npanxx: setPopNpanxx,
	vcoord: setPopVcoord,
	av_cswc: setPopAvCswc,
	address: setPopAddress,
	ocn: setPopOcn,
	lata: setPopLata,
	switch_clli: setPopSwitchClli,
	hcoord: setPopHcoord,
	validated: setPopValidated,
	county: setPopCounty,
	geocode: setPopGeocode,
	latitude: setPopLatitude,
	longitude: setPopLongitude,
	ratecenter: setPopRatecenter,
	sano: setPopSano,
	sapr: setPopSapr,
	sasd: setPopSasd,
	sasf: setPopSasf,
	sasn: setPopSasn,
	sass: setPopSass,
	sath: setPopSath,
	// POP Detail Actions
	lec_type: setPopLecType,
	uniti_mla: setPopUnitiMla,
	new_loc_type: setPopNewLocType,
	ld1: setPopLd1,
	ld2: setPopLd2,
	ld3: setPopLd3,
	lv1: setPopLv1,
	lv2: setPopLv2,
	lv3: setPopLv3,
	provider: setPopProvider,
	// Wave Details Actions
	icon: setPopIcon,
	wave: setPopWave,
	wave_xc: setPopWaveXc,
	landlord: setPopLandlord,
	dci: setPopDci,
	wave_tier: setPopWaveTier,
	wave_class: setPopWaveClass,
	wave_type: setPopWaveType,
	wave_metro_area: setPopWaveMetroArea,
	wave_lcn: setPopWaveLcn,
	g10: setPopG10,
	g100: setPopG100,
	g400: setPopG400,
	g800: setPopG800,
	// Ethernet Details Actions
	eline_enet: setPopElineEnet,
	ethernet: setPopEthernet,
	// Colo Details Actions
	colo: setPopColo,
	colo_tier: setPopColoTier,
	comments: setPopComments,
	active: setPopActive
};

export default connect(null, mapDispatchToProps)(POPCreator);