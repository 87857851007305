import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

const ODD_OPACITY = 0.04;
const HOVER_OPACITY = 0.09;

/*  Custom styled DataGrid component
* The shading here is done via opacity of black & white rather than using grey as the background color.
* The styling is handled by using a array of CSS classes in the DataGrid component.*/
const StripedDataGrid = styled(DataGrid)(({theme}) => ({
    // Styling for odd rows with a white background
    [`& .${gridClasses.row}:nth-of-type(odd)`]: {
        backgroundColor: theme.palette.common.white,
    },
    // Styling for even rows with a specific grey background
    [`& .${gridClasses.row}:nth-of-type(even)`]: {
        backgroundColor: alpha(theme.palette.common.black, ODD_OPACITY),
    },
    // Adding hover effect for all rows
    [`& .${gridClasses.row}:hover`]: {
        backgroundColor: alpha(theme.palette.common.black, HOVER_OPACITY),
    },
    // Disabling the default selected color for rows and cells
    // Styling for selected odd rows
    [`& .${gridClasses.row}.Mui-selected:nth-of-type(odd)`]: {
        backgroundColor: `${theme.palette.common.white} !important`, // Maintain white background for selected odd rows
    },
    // Styling for selected even rows
    [`& .${gridClasses.row}.Mui-selected:nth-of-type(even)`]: {
        backgroundColor: `${alpha(theme.palette.common.black, ODD_OPACITY)} !important`, // Maintain grey background for selected even rows
    },
    // Disabling the blue border highlight for selected cells
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}.Mui-selected, & .${gridClasses.cell}:focus-within`]: {
        outline: 'none',
        border: 'none',
    }
}));

export default StripedDataGrid;