import { useEffect, useState, useMemo, useCallback } from "react";
import ViewportWarning from "../../shared/ViewportWarning";
import Select from "react-select";
import style from './RouteCreator.module.css';
import { ConsoleLogger } from "../../../logger/ConsoleLogger";
import RouteCreatorHelper from "../../../services/RouteCreatorService";
import BootstrapTable from 'react-bootstrap-table-next';
import Loader from "../../shared/Loader";
import { useSelector, connect } from "react-redux";
import {
    setQuoteAvailabilityFlag,
    setQuoteAvailabilityIconFlag,
    setQuoteKmzURL,
    setQuoteProduct,
    setQuoteProductCode,
    setQuoteCircuitType,
    setQuoteStepCode,
    setQuoteAddressA,
    setQuoteAddressZ,
    setQuoteAvailabilityRouteDetails,
    setQuoteAvailabilityPathDetails,
    setQuoteAvailabilityPathNumber,
    setQuoteKmzFile,
    setQuoteRouteLatency,
    setQuoteBW,
    setQuoteTerm1,
	setQuoteTerm2,
	setQuoteTerm3,
	setQuoteTerm5,
    setQuoteRouteCapacity,
    setQuoteNetworkTypeCounts,
    setQuoteAutoPrices,
    setQuoteRouteHops,
    setQuoteRouteFiberMiles,
    setQuotePathLatency,
    setQuoteRouteBuildings,
    setQuoteNetworkEdges,
    setQuoteOtucPath,
    setQuoteNetworkPath,
    setQuoteRouteRegens,
    resetData
} from "../../../redux/actions/quoteActions";
import {
    setQuoteManualICB
} from '../../../redux/actions/quoteContactActions';
import CompanyDropdown from "../../shared/CompanyDropdown";
import { Redirect } from "react-router-dom";
import PathDetails from "./PathDetails";
import ReactTooltip from 'react-tooltip';
import RouteCreatorModal from "./RouteCreatorModal";

const numberOfPathOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
]

const routeDiversityOptions = [
    { value: "none", label: "none" },
    { value: "inclusion", label: "inclusion" },
    { value: "exclusion", label: "exclusion" }
]

const sortByOptions = [
    { value: "cost", label: "pricing" },
    { value: "weight", label: "efficiency" }
]

//Phase 4 Route Creator testers: Carlos, Teresa, Natalie, and Steve
const testers = ["e0187647", "e0146094", "e0187455", "e0155921"];

const RouteCreator = (props) => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
    const defaultLatlng = { 'lat': 39, 'lng': -98 };
    const [popList, setPopList] = useState([]);
    const [fullPopList, setFullPopList] = useState([]);
    const [iconList, setIconList] = useState([]);
    const [popLocationA, setPopLocationA] = useState([]);
    const [popLocationZ, setPopLocationZ] = useState([]);
    const [clliA, setClliA] = useState("");
    const [clliZ, setClliZ] = useState("");
    const [numberOfPathsSelected, setNumberOfPathsSelected] = useState({ value: "3", label: "3" });
    const [sortBySelected, setSortBySelected] = useState({ value: "", label: "" });
    const [routeCreatorData, setRouteCreatorData] = useState([]);
    const [iconNetwork, setIconNetwork] = useState(true);
    const [loader, setLoader] = useState(false);
    const [popupShow, setPopupShow] = useState(false);
    const [quoteFlag, setQuoteFlag] = useState(false);
    const [iconOnly, setIconOnly] = useState(false);
    const [popupAction, setPopUpAction] = useState("");
    const [avoidanceRoutes, setAvoidanceRoutes] = useState([]);
    const [includedRoutes, setIncludedRoutes] = useState([]);
    const [routeDiversitySelected, setRouteDiversitySelected] = useState({ value: "none", label: "none" });
    const [accountExecutive, setAccountExecutive] = useState({ name: "", email: "" });
    const [quoteLaunched, setQuoteLaunched] = useState(false);
    const [emailToSales, setEmailToSales] = useState(false);
    const [typeOfQuote, setTypeOfQuote] = useState("");

    const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
    const isCustomer = localStorage.getItem('isLoggedIn');

    const selectedInternalMpid = useSelector(state => state.internal.company?.value);
    const selectedQuoteAccess = useSelector(state => state.userReducer.user?.webaccess?.TRANS_QUOTE);

    const columns = [{
        dataField: 'selectField',
        text: 'Select',
        formatter: (cell, row) => selectFormatter(cell, row)
    }, {
        dataField: 'pathNumber',
        text: 'Rank',
        headerFormatter: (column) => rankFormatter(column),
        hidden: true
    }, {
        dataField: 'locationA',
        text: 'A Location',
        hidden: (isCustomer && Number(selectedQuoteAccess) > 2) || isEmployee ? true : false
    }, {
        dataField: 'locationZ',
        text: 'Z Location',
        hidden: (isCustomer && Number(selectedQuoteAccess) > 2) || isEmployee ? true : false
    }, {
        dataField: 'term1',
        text: '1 Year - 100 GB',
        hidden: (isCustomer && Number(selectedQuoteAccess) < 2) ? true : false,
        formatter: (cell, row, rowIndex) => termFormatter(cell, row, rowIndex, "term1")
    }, {
        dataField: 'term2',
        text: '2 Year - 100 GB',
        hidden: (isCustomer && Number(selectedQuoteAccess) < 2) ? true : false,
        formatter: (cell, row, rowIndex) => termFormatter(cell, row, rowIndex, "term2")
    }, {
        dataField: 'term3',
        text: '3 Year - 100 GB',
        hidden: (isCustomer && Number(selectedQuoteAccess) < 2) ? true : false,
        formatter: (cell, row, rowIndex) => termFormatter(cell, row, rowIndex, "term3")
    }, {
        dataField: 'term5',
        text: '5 Year - 100 GB',
        hidden: (isCustomer && Number(selectedQuoteAccess) < 2) ? true : false,
        formatter: (cell, row, rowIndex) => termFormatter(cell, row, rowIndex, "term5")
    }, {
        dataField: 'miles',
        text: 'Miles'
    }, {
        dataField: 'total_latency',
        text: 'Latency (RTD)'
    }, {
        dataField: 'num_hops',
        text: 'Hops',
        formatter: (cell, row, rowIndex) => hopsFormatter(cell, row, rowIndex)
    }, {
        dataField: 'kmz_file_url',
        text: 'KMZ',
        formatter: (cell, row) => kmzFileFormatter(cell, row)
    }];

    const selectFormatter = (cell, row) => {
        return (
            <input type="checkbox" checked={row.selectField ? "checked" : ""} onChange={(event) => handleCheckbox(event, row)} />
        );
    }

    const rankFormatter = (column) => {
        return (
            <>
                {column.text}
                <img data-tip data-for="rankToolTip" className="tipped cornertip-right ml-2 mb-1" src="../../../images/svg/ico-info-filled-dark-off.svg" width="20" height="20" alt="" />
                <ReactTooltip id="rankToolTip" delayHide={100} effect="solid" place="top">
                    Paths ranked by 'best available' according to Windstream Engineering path analysis methods.
                    <br></br>
                    Higher latency routes may present more cost-effective route for approval.
                </ReactTooltip>
            </>
        )
    }

    const kmzFileFormatter = (cell, row) => {
        let URL = `${row.kmz_file_url}`;
        return (
            <a href={URL} target="_self" download>
                <svg className={`ico ${style.availabilityIco}`} ><path d="M31,21.5v14H13V8.5h9l9,9v2H20v-6h2v4h7l-7-7H15v23H29v-12Zm-20,10H9V4.5H21v2H11Zm-4-2H5V.5H18v2H7Z"></path></svg>
            </a>
        );
    }

    const termFormatter = (cell, row, rowIndex, term) => {
        logger.info(`Cell ${cell}`);
        logger.info(`Row: ${JSON.stringify(row)}`);
        logger.info(`Row Index: ${rowIndex}`);
        logger.info(`Term clicked on: ${term}`);
        let toolTipMessage = "";
        if(isCustomer && row?.pricing_available === "Yes"){
            toolTipMessage = `Launch a quote to obtain price from your Account Executive: ${accountExecutive?.name}.`;
        } else {
            toolTipMessage = "Click to create a quote."
        }
        return (
            <>
                <button id={`termToolTip${rowIndex}-${term}`} data-tip data-for={`termToolTip${rowIndex}-${term}`} style={{ paddingLeft: '5px', textDecoration: 'underline', cursor: 'pointer', color: '#753bbd' }} onClick={() => updateQuoteValues(row, term)}>
                {cell}
                </button>
                <ReactTooltip id={`termToolTip${rowIndex}-${term}`} delayHide={100} effect="solid" place="top">
                    {toolTipMessage}
                </ReactTooltip>
            </>
        )
    }

    const hopsFormatter = (cell, row, rowIndex) => {
        //Only add the hops tooltip that breaks down the different hops in local, dev, and uat
        if((testers).includes(localStorage.getItem("eid"))){
            return (
                <>
                    <svg className={`ico ${style.hopsIco}`} data-tip data-for={`hopsToolTip${rowIndex}`}>
                        <path d="M19,34l9-15c.6-1-1.52-1.95-2-1L18,32,9.48,17.91A10.86,10.86,0,0,1,8,12.67a9.44,9.44,0,0,1,1.36-5A9.89,9.89,0,0,1,18,3a9.42,9.42,0,0,1,4.71,1A10.41,10.41,0,0,1,28,12H23a5.82,5.82,0,0,0-2-3,4.73,4.73,0,0,0-3.1-1.09c-4.76,0-4.86,4.76-4.86,4.76,0,4.62,4.91,4.76,4.86,4.76a.9.9,0,0,0,0-1.8,2.69,2.69,0,0,1-2-.82,2.89,2.89,0,0,1,0-4,2.83,2.83,0,0,1,4,0C21.37,12.3,21.24,14,23,14h5c1,0,2,0,2-2,0-4.25-2.61-7.33-6.1-9.36A11.29,11.29,0,0,0,18,1,12.12,12.12,0,0,0,7.61,6.89,12,12,0,0,0,6,13a10.9,10.9,0,0,0,1.68,5.93L17,34C17,34.94,19,35,19,34Z"></path>
                    </svg>
                    <ReactTooltip id={`hopsToolTip${rowIndex}`} delayHide={100} effect="solid" place="top">
                        <ui>
                            <li>Unique Metro/Regional 0-5 hops: {row?.network_type_counts?.hops_0_5}</li>
                            <li>Unique Metro/Regional 6-10 hops: {row?.network_type_counts?.hops_6_10}</li>
                            <li>Unique Metro/Regional 11+ hops: {row?.network_type_counts?.hops_11_plus}</li>
                            <li>Winterstate: {row?.network_type_counts?.winterstate}</li>
                            <li>Icon Edges: {row?.network_type_counts?.icon_edges}</li>
                        </ui>
                    </ReactTooltip>
                </>
            )
        } else {
            return row.num_hops
        }
    
    }

    //Calls the PathDetails component to display the individual route information
    const renderRow = (routeCreatorData) => {
        return (
            <PathDetails details={routeCreatorData.pathDetails} kmzUrl={routeCreatorData.kmz_file_url} path={routeCreatorData.pathNumber} />
        )
    }

    //Handles additional info column
    const expandRow = {
        renderer: renderRow,
        showExpandColumn: true,
        expandByColumnOnly: true,
        onlyOneExpanding: false,
        expandColumnPosition: 'right',
        parentClassName: `${style.routeCreatorDataTable}`,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return <span className="text-center" style={{ "font-weight": "bolder" }} onClick={(e) => { e.stopPropagation() }}>Additional Info</span>
        },
        expandColumnRenderer: () => {
            return (
                <svg className="ico-plus" fill="#753bbd">
                    <path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path>
                </svg>
            );
        }
    };


    var map;
    const google = window.google = window.google ? window.google : {};

    var styles = [
        {
            "stylers": [
                { "hue": "#EEEEEE" },
                { "saturation": -300 },
                { "lightness": 20 }
            ]
        }
    ]

    //Function to fetch the icon pop locations from our database
    const fetchRouteCreatorPopList = useCallback(() => {
        setLoader(true);
        RouteCreatorHelper.getRouteCreatorPopList().then(response => {
            logger.info(response.data);
            setPopList(response.data);
            setFullPopList(response.data);
            let filteredIconList = [];
            Object.entries(response?.data).forEach(([, val]) => {
                if (val.icon) {
                    filteredIconList.push(val);
                }
            })
            setIconList(filteredIconList);
            setLoader(false);
        }).catch(error => {
            logger.info(`getRouteCreatorPopList Error: ${error}`);
            setLoader(false);
        })
    },[logger])

    useEffect(() => {
        fetchRouteCreatorPopList();
        setSortBySelected({ value: "cost", label: "pricing" });
        setPopLocationA([]);
        setPopLocationZ([]);
        setAvoidanceRoutes([]);
        setIncludedRoutes([]);
        //clear any data cached from quote create
        props.resetQuoteData();
    }, [fetchRouteCreatorPopList, isEmployee, props, selectedInternalMpid])

    useEffect(() => {
        //Getting Account Executive Name and Email
        if ((selectedInternalMpid || isCustomer) && accountExecutive?.name === "" && accountExecutive?.email === "") {
            logger.info("Calling getAccountProfile");
            RouteCreatorHelper.getAccountProfile(selectedInternalMpid).then(response => {
                if (response.data?.nam) {
                    logger.info(`Account Executive EID: ${response.data.nam}`)
                    RouteCreatorHelper.getAccountExecutiveName(response.data.nam).then(response => {
                        logger.info(`Account Executive NAME: ${response.data}`);
                        setAccountExecutive((prevState) => ({ ...prevState, "name": response.data }))
                    }).catch(error => {
                        logger.error(`Error Getting Account Executive Name: ${error}`)
                    })
                    RouteCreatorHelper.getAccountExecutiveEmail(response.data.nam).then(response => {
                        logger.info(`Account Executive EMAIL: ${response.data}`);
                        setAccountExecutive((prevState) => ({ ...prevState, "email": response.data }))
                    }).catch(error => {
                        logger.error(`Error Getting Account Executive Email: ${error}`)
                    })
                } else {
                    logger.info("No Account Executive EID found")
                }
            }).catch(error => {
                logger.error(`Error Getting Account Profile: ${error}`)
            })
        }
    }, [logger, selectedInternalMpid, isCustomer, accountExecutive])

    //Initializes the map
    function initMap() {
        map = new google.maps.Map(document.getElementById('map'), {
            center: defaultLatlng,
            zoom: 5,
            mapTypeId: "terrain"
        });
        map.setOptions({ styles: styles });
    }

    //Initializes and draws the different KMZ layers
    const kmlInit = async (kmlLayers) => {
        await initMap();
        let numberOfPathsFound = 0;
        kmlLayers.forEach(function (url, index) {
            var kmlLayer = new google.maps.KmlLayer(url, {
                suppressInfoWindows: true,
                preserveViewport: false,
                map: map,
                zIndex: kmlLayers.length - index //z-index determines what path shows on top. By decreasing the z-index the first path will always show up first
            });

            //Adding info window for each pop location on the map
            kmlLayer.addListener('click', function (event) {
                var content = event.featureData.description;
                logger.info(JSON.stringify(event.featureData));
                if (content !== "" && content !== "description") {
                    let infoWindow = new google.maps.InfoWindow({
                        position: event.latLng,
                    });
                    infoWindow.setContent(content);
                    infoWindow.open(map);
                }
            });
            numberOfPathsFound = index + 1;
        })

        //Creating legend for map
        let legend = document.createElement("div");
        legend.id = "legend";
        legend.className = `${style.availabilityLegend}`;
        legend.innerHTML = '<h5>Legend</h5>';

        let colors = {
            "#ffb81c": 'Path 1', //Yellow
            "#0057b8": 'Path 2', //Dark Blue
            "#753bbd": 'Path 3', //Purple
            "#59cbe8": 'Path 4', //Light Blue
            "#00ff00": 'Path 5' // Green
        };

        let colorCount = 0;
        for (var key in colors) {
            if (colorCount < numberOfPathsFound) {
                let color = colors[key];
                var name = key;
                let div = document.createElement('div');
                div.innerHTML = `<span style="background-color:${name}; width:20px; height:20px; display:inline-block;"></span> <a href="${kmlLayers[colorCount]}">${color}</a>`;
                legend.appendChild(div);
                colorCount++;
            }
        }
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);
    }

    //Function to fetch the Route Creator data which will display a table and a google map with the routes available for the two selected pop locations
    const fetchRouteCreatorData = () => {
        setPopupShow(true);
        setPopUpAction("SEARCHING ROUTE");
        RouteCreatorHelper.getRouteCreatorPhase4Info(popLocationA, popLocationZ, numberOfPathsSelected.value, sortBySelected.value, avoidanceRoutes, includedRoutes).then(response => {
            logger.info(JSON.stringify(response.data));
            if (response.data?.length > 0 && response.data?.result !== "NO DATA") {
                if(popLocationA?.label.includes("ICON") && popLocationZ?.label.includes("ICON")){
                    logger.info("Both location a and location z are icon locations")
                    setTypeOfQuote("ICON");
                } else {
                    logger.info("Either location a or location z or both are non icon")
                    setTypeOfQuote("NOT ICON");
                }
                setRouteCreatorData(response.data);
                combineKmzUrls(response.data);
                setIconNetwork(false);
                //Only display auto pricing popup if it is available
                let pricingAvailable = false;
                Object.entries(response?.data).forEach(([, row]) => {
                    if (row.term1 !== "ICB" || row.term2 !== "ICB" || row.term3 !== "ICB" || row.term5 !== "ICB") {
                        pricingAvailable = true;
                    }
                })
                if (pricingAvailable) {
                    setPopUpAction("AUTO PRICING");
                } else {
                    setPopupShow(false);
                }

            } else if (Number(response.data?.responseCode) === 0) {
                setPopupShow(true);
                setPopUpAction("TIMEOUT");
            } else {
                setPopupShow(true);
                setPopUpAction("NO DATA");
            }
        }).catch(error => {
            if (error.response) {
                //The request was made and the server responded with a status code
                logger.info(`error.response.data: ${error.response.data}`);
                logger.info(`error.response.status: ${error.response.status}`);
                logger.info(`error.response.headers: ${error.response.headers}`);
                //With the php 8 upgrade this is the correct way to handle the 504 gateway timeout
                if (error.response.status === 504) {
                    logger.info(`error.request: ${JSON.stringify(error.request)}`);
                    logger.info("GATEWAY 504 TIMEOUT");
                    setPopUpAction("TIMEOUT");
                }
            }
            logger.info(`getRouteCreatorPricingInfo Error: ${error}`);
        })
        
    }
    //Function to filter and combine only the KMZs that are checked allowing the user to decide which routes they want to view
    const combineKmzUrls = (data) => {
        logger.info(`Inside of combineKmzUrls function`);
        let kmzUrlList = [];
        Object.entries(data).forEach(([, val]) => {
            if (val.kmz_file_url && val.selectField) {
                //Adding the kmz color url to the kmzUrlList
                kmzUrlList.push(val.top_kmz_file_url);
            }
        })

        logger.info(`List of KMZs to draw: ${JSON.stringify(kmzUrlList)}`);
        kmlInit(kmzUrlList);
    }

    //Function to set location A and location Z pop
    const findPopLoc = (e, type) => {
        logger.info(e);
        let popVal = '';
        if (!e) {
            popVal = ''
        } else {
            popVal = e;
        }

        if (type === 'locA') {
            setPopLocationA(popVal);
            setClliA(popVal?.label.trim().substring(0, popVal?.label.indexOf('-', 0)).trim());
        } else {
            setPopLocationZ(popVal);
            setClliZ(popVal?.label.trim().substring(0, popVal?.label.indexOf('-', 0)).trim());
        }
    }

    const checkAvailability = () => {
        fetchRouteCreatorData();
    }

    //Function to redirect to Quote Create and update values in redux store for selected pop locations
    const updateQuoteValues = (row, term) => {
        props.updateQuoteAvailabilityFlag(true);
        props.updateQuoteProductCode("WAVE");
        props.updateQuoteProduct(1);//Private Line DB ID
        props.updateQuoteCircuitType(7); //WAVE DB ID;
        props.updateQuoteStepCode(3); // Skip to step 3 of quote create
        props.updateQuotePopAddressA(popLocationA);
        props.updateQuotePopAddressZ(popLocationZ);

        switch(term) {
            case "term1":
                logger.info("term1 price selected");
                props.updateQuoteTerm1("on");
                break;
            case "term2":
                logger.info("term2 price selected");
                props.updateQuoteTerm2("on");
                break;
            case "term3":
                logger.info("term3 price selected");
                props.updateQuoteTerm3("on");
                break;
            case "term5":
                logger.info("term5 price selected");
                props.updateQuoteTerm5("on");
                break;
            default:
                break;
        }
        //default quote bandwidth to 15 which is 100GB if employee
        props.updateQuoteBW(600);

        //if we came from the no data pop up we won't have any of this information since the route creator api couldn't find a path
        if(popupAction !== "NO DATA"){
            props.updateQuoteAutoPrices({"term1": row?.term1, "term2": row?.term2, "term3": row?.term3, "term5": row?.term5});
            props.updateQuoteNetworkTypeCounts(row?.network_type_counts);
            props.updateQuoteRouteCapacity(row?.route_capacity);
            props.updateQuoteRouteHops(row?.num_hops);
            props.updateQuoteRouteFiberMiles(row?.miles);
            props.updateQuotePathLatency(row?.total_latency);
            props.updateQuoteRouteBuildings(row?.buildings);
            props.updateQuoteNetworkEdges(row?.network_edges);
            props.updateQuoteOtucPath(row?.building_path);
            props.updateQuoteNetworkPath(row?.network_path);
            props.updateQuoteRouteRegens(row?.regens);
        }

        props.updateQuoteManualICB(false);
        props.updateQuoteAvailabilityRouteDetails(row.routeDetails);
        props.updateQuoteKmzUrl(row.kmz_file_url);
        props.updateQuoteAvailabilityPathDetails(row.pathDetails);
        props.updateQuoteAvailabilityPathNumber(row.pathNumber);

        //This determines if locations being quoted are on the icon network or not
        props.updateQuoteAvailabilityIconFlag(typeOfQuote);
        setQuoteLaunched(true);
        setQuoteFlag(true);
    }

    //Function to handle the checkbox event and update the routeCreatorData state variable along with the google map
    const handleCheckbox = (event, row) => {
        let updatedRouteCreatorListData = [];
        updatedRouteCreatorListData = [...routeCreatorData];
        updatedRouteCreatorListData[row.id].selectField = event.target.checked;
        setRouteCreatorData(updatedRouteCreatorListData);
        combineKmzUrls(updatedRouteCreatorListData);
    }

    //Handles the filter icon checkbox to remove any non icon pops for the Location A and Location Z dropdowns
    const iconOnlyHander = (event) => {
        setIconOnly(event.target.checked);
        //Clearing any selected pop location a and z values
        setPopLocationA({ value: "", label: "" });
        setPopLocationZ({ value: "", label: "" });
        if (event.target.checked) {
            setPopList(iconList);
        } else {
            setPopList(fullPopList);
        }
    }

    //Handles the route diversity selection and calls the Route Creator Modal
    const routeDiversityHandler = (event) => {
        logger.info("Route Diversity: " + JSON.stringify(event));
        //If the selected route diversity is changed make sure to clear any existing avoidance or included routes
        if(routeDiversitySelected?.value !== event.value){
            setAvoidanceRoutes([]);
            setIncludedRoutes([]);
        }
        setRouteDiversitySelected(event);
        if (event.value === "inclusion" || event.value === "exclusion") {
            setPopupShow(true);
            setPopUpAction("ROUTE DIVERSITY");
        } else {
            //None is selected for the route diversity clear any existing included or excluded routes
            setAvoidanceRoutes([]);
            setIncludedRoutes([]);
        }
    }
    //Handles the route avoidance selection that happens in the AvailabiliyModal.js
    const routeAvoidanceHander = (event) => {
        logger.info("Routes to be avoided: " + JSON.stringify(event));
        setAvoidanceRoutes(event);
    }

    //Handles the route inclusion selection that happens in the AvailabiliyModal.js
    const includedRoutesHander = (event) => {
        logger.info("Included Routes: " + JSON.stringify(event));
        setIncludedRoutes(event);
    }

    const logRouteCreatorActivity = useCallback(() => {
        RouteCreatorHelper.postRouteCreatorActivityLog(popLocationA, popLocationZ, numberOfPathsSelected, sortBySelected, routeDiversitySelected, includedRoutes, avoidanceRoutes, typeOfQuote, quoteLaunched, emailToSales).then(response => {
            logger.info(response.data);
        }).catch(error => {
            logger.error(error)
        })
    },[avoidanceRoutes, emailToSales, typeOfQuote, includedRoutes, logger, numberOfPathsSelected, popLocationA, popLocationZ, quoteLaunched, sortBySelected, routeDiversitySelected])

    const handleEmailClick = () => {
        setEmailToSales(true);
    }

	useEffect(() => {
		//Triggers logging for every email to sales, quote launched, if icon data is returned, and if no data is returned
		if (emailToSales || quoteLaunched || routeCreatorData?.length > 0 || popupAction === "NO DATA"){
            logRouteCreatorActivity();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailToSales, quoteLaunched, routeCreatorData, popupAction])

    return (
        <>
            {loader ? <Loader /> : null}
            {quoteFlag ? <Redirect to="/Quote/Create" /> : null}
            <div className="midsection qm-create abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div className="wrap">
                        <h2 className="page-title mb-0">Route Creator</h2>
                        <h6 className="page-title mt-0 text-center"> 
                            Fiber routes over the Windstream network will be displayed by Pricing or Efficiency.<br></br> Efficiency routes best reflect Windstream Engineering path analysis methods available via automation. <br></br> More efficient designs may be available using the existing Engineering ICB process.
                        </h6>
                        {isEmployee ? <CompanyDropdown /> : null}
                        <div className="section mb-5">
                            <div className="content rel">                      
                                <div className="row justify-content-center">
                                    <div className="col-12 col-sm-5 col-md-5">
                                        <label>Location A</label>
                                        <Select
                                            value={popLocationA}
                                            onChange={(e) => { findPopLoc(e, 'locA') }}
                                            options={popList?.map((item) => {
                                                return { value: item.id, label: item.name, icon: item.icon }
                                            })}
                                            isSearchable
                                        />
                                    </div>
                                    <div className="col-12 col-sm-5 col-md-5">
                                        <label>Location Z</label>
                                        <Select
                                            value={popLocationZ}
                                            onChange={(e) => { findPopLoc(e, 'locZ') }}
                                            options={popList?.map((item) => {
                                                return { value: item.id, label: item.name, icon: item.icon }
                                            })}
                                            isSearchable
                                        /> 
                                    </div>
                                    <div className="col-12 col-sm-2 col-md-2 mt-4">
                                        <input type="checkbox" id="iconOnly" name="iconOnlyA" className={style.availabilityFilterCheckboxes} checked={iconOnly ? "checked" : ""} onClick={(event) => iconOnlyHander(event)} />
                                        <label htmlFor="iconOnly" className={`ml-1 ${style.availabilityFilterLabels}`}>Icon Only</label>
                                        <img data-tip data-for="iconToolTip" className="tipped cornertip-right ml-2 mb-1" src="../../../images/svg/ico-info-filled-dark-off.svg" width="20" height="20" alt="" />
                                        <ReactTooltip id="iconToolTip" delayHide={100} effect="solid" place="bottom">
                                            Display ICON only locations. Utilizing the next gen ICON Network offers better performance, monitoring, efficiency, and lower pricing.
                                        </ReactTooltip>
                                    </div>     
                                </div>
                                {popLocationA?.label && popLocationZ?.label ?
                                    <>
                                        <div className="row mt-3 justify-content-center">
                                            <div className="col-12 col-sm-3 col-md-3">
                                                <label>Paths</label>
                                                <Select
                                                    value={{ value: numberOfPathsSelected?.value, label: numberOfPathsSelected?.label }}
                                                    onChange={(event) => { setNumberOfPathsSelected(event) }}
                                                    options={numberOfPathOptions}
                                                    isSearchable
                                                />
                                            </div>
                                            <div className="col-12 col-sm-3 col-md-3">
                                                <label htmlFor="sortBy">Sort By</label>
                                                <img data-tip data-for="sortBy" className="tipped cornertip-right ml-2 mb-1" src="../../../images/svg/ico-info-filled-dark-off.svg" width="20" height="20" alt="" />
                                                <ReactTooltip id="sortBy" delayHide={100} effect="solid" place="right">
                                                Efficiency routes best reflect Windstream Engineering path analysis methods available via automation.<br></br>
                                                More efficient designs may be available using the existing Engineering ICB process.
                                                </ReactTooltip>
                                                <Select
                                                    value={{ value: sortBySelected?.value, label: sortBySelected?.label }}
                                                    onChange={(event) => { setSortBySelected(event) }}
                                                    options={sortByOptions}
                                                    isSearchable
                                                />
                                            </div>
                                            <div className="col-12 col-sm-3 col-md-3">
                                                <label>Diversity</label>
                                                <Select
                                                    value={{ value: routeDiversitySelected?.value, label: routeDiversitySelected?.label }}
                                                    onChange={(event) => { routeDiversityHandler(event) }}
                                                    options={routeDiversityOptions}
                                                    isSearchable
                                                />
                                            </div>
                                            <div className="col-12 col-sm-2 col-md-2 mt-1">
                                                <button className={style.availablityBtn} onClick={() => { checkAvailability() }}>Search Route</button>
                                            </div>
                                                
                                        </div>
                                    </> : null
                                }                      
                                <div className={`${style.mapWrap} row`}>
                                    {iconNetwork ?
                                        <iframe title="Icon Network" loading="lazy" src="https://windstream.maps.arcgis.com/apps/webappviewer/index.html?id=42c1794e8152463d821f3d3ccc71675a" width="100%" height="650" frameborder="0"></iframe> : null
                                    }
                                    {!iconNetwork ?
                                        <>
                                            <button className={style.availablityBtn} style={{ "float": "right" }} onClick={() => setIconNetwork(true)}>Icon Network</button>
                                        </> : null
                                    }
                                    <div id="map" className={`${style.map}`} hidden={iconNetwork ? "hidden" : ""}></div>
                                </div>
                                {popupShow ?
                                    <RouteCreatorModal popList={fullPopList} avoidanceRoutes={avoidanceRoutes} includedRoutes={includedRoutes} popupAction={popupAction}
                                        routeAvoidanceHander={routeAvoidanceHander} includedRoutesHander={includedRoutesHander} setPopupShow={(value) => setPopupShow(value)}
                                        updateQuoteValues={(value) => updateQuoteValues(value)} checkAvailability={checkAvailability} routeDiversitySelected={routeDiversitySelected?.value}
                                        accountExecutive={accountExecutive} clliA={clliA} clliZ={clliZ} popLocA={popLocationA} popLocZ={popLocationZ} handleEmailClick={handleEmailClick} selectedQuoteAccess={selectedQuoteAccess} isCustomer={isCustomer} isEmployee={isEmployee} /> : null
                                }
                                {!iconNetwork && routeCreatorData ?
                                    <>
                                        <div className={`row mb-4 ${style.routeCreatorPadding}`}>
                                            <span style={{ "font-size": "14px" }}>
                                                <p className="mb-0" style={{ "font-weight": "bolder", "color": "#753bbd" }}>Other route options may be available. </p>
                                                Consider using the route diversity option above to avoid or include specific markets on the route.
                                                <br></br>
                                                Contact your Account Executive <a href={`mailto:${encodeURIComponent(accountExecutive?.email)}?subject=Windstream Route Creator Inquiry: ${clliA} - ${clliZ}`} onClick={() => handleEmailClick()}>{accountExecutive?.name}&nbsp;</a>
                                                for route options not displayed.
                                            </span>
                                        </div>
                                        <div className={`row mb-2 ${style.routeCreatorPadding}`}>
                                            <BootstrapTable
                                                condensed
                                                bordered
                                                striped hover
                                                keyField='pathNumber'
                                                bootstrap4
                                                data={routeCreatorData}
                                                columns={columns}
                                                expandRow={expandRow}
                                                headerClasses={`${style.routeCreatorDataTable}`}
                                                headerWrapperClasses={`${style.routeCreatorDataTable}`}
                                                rowClasses={`${style.routeCreatorDataTable}`}
                                            />
                                        </div>
                                    </> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = {
    updateQuoteAvailabilityFlag: setQuoteAvailabilityFlag,
    updateQuoteAvailabilityIconFlag: setQuoteAvailabilityIconFlag,
    updateQuoteKmzUrl: setQuoteKmzURL,
    updateQuoteProduct: setQuoteProduct,
    updateQuoteProductCode: setQuoteProductCode,
    updateQuoteCircuitType: setQuoteCircuitType,
    updateQuoteStepCode: setQuoteStepCode,
    updateQuotePopAddressA: setQuoteAddressA,
    updateQuotePopAddressZ: setQuoteAddressZ,
    updateQuoteManualICB: setQuoteManualICB,
    updateQuoteAvailabilityRouteDetails: setQuoteAvailabilityRouteDetails,
    updateQuoteAvailabilityPathDetails: setQuoteAvailabilityPathDetails,
    updateQuoteAvailabilityPathNumber: setQuoteAvailabilityPathNumber,
    updateQuoteKmzFile: setQuoteKmzFile,
    updateQuoteRouteLatency: setQuoteRouteLatency,
    updateQuoteBW: setQuoteBW,
    updateQuoteTerm1: setQuoteTerm1,
    updateQuoteTerm2: setQuoteTerm2,
    updateQuoteTerm3: setQuoteTerm3,
    updateQuoteTerm5: setQuoteTerm5,
    updateQuoteRouteCapacity: setQuoteRouteCapacity,
    updateQuoteNetworkTypeCounts: setQuoteNetworkTypeCounts,
    updateQuoteAutoPrices: setQuoteAutoPrices,
    updateQuoteRouteHops: setQuoteRouteHops,
    updateQuoteRouteFiberMiles: setQuoteRouteFiberMiles,
    updateQuotePathLatency: setQuotePathLatency,
    updateQuoteRouteBuildings: setQuoteRouteBuildings,
    updateQuoteNetworkEdges: setQuoteNetworkEdges,
    updateQuoteOtucPath:setQuoteOtucPath,
    updateQuoteNetworkPath: setQuoteNetworkPath,
    updateQuoteRouteRegens: setQuoteRouteRegens,
    resetQuoteData: resetData
}

export default connect(null, mapDispatchToProps)(RouteCreator);
