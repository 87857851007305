import {
    // Administration
    POP_UID, POP_MPID, POP_TIER, POP_CSWC, POP_LOCATION_ID,
    // Address
    POP_CITY, POP_STATE, POP_COUNTRY, POP_ZIP, POP_NPANXX,
    POP_VCOORD, POP_AV_CSWC, POP_ADDRESS, POP_OCN,
    POP_LATA, POP_SWITCH_CLLI, POP_HCOORD, POP_VALIDATED,
    POP_COUNTY, POP_GEOCODE, POP_LATITUDE, POP_LONGITUDE,
    POP_RATECENTER, POP_SANO, POP_SAPR, POP_SASD, POP_SASF,
    POP_SASN, POP_SASS, POP_SATH,
    // POP Detail
    POP_NOTE, POP_LEC_TYPE, POP_UNITI_MLA, POP_VENDOR,
    POP_NEW_LOC_TYPE, POP_LD1, POP_LD2, POP_LD3, POP_LV1,
    POP_LV2, POP_LV3, POP_PROVIDER, POP_ADDRESS2,
    // Wave Details
    POP_ICON, POP_WAVE, POP_WAVE_XC, POP_LANDLORD, POP_DCI,
    POP_WAVE_TIER, POP_WAVE_CLASS, POP_WAVE_TYPE, POP_WAVE_METRO_AREA,
    POP_WAVE_LCN, POP_G10, POP_G100, POP_G400, POP_G800,
    // Ethernet Details
    POP_ELINE_ENET, POP_ETHERNET,
    // Colo Details
    POP_COLO, POP_COLO_TIER,
    POP_COMMENTS,
    POP_ACTIVE,
    RESET_ACTION
} from "../actions/types/actionTypes";

const initialState = {
    popData: {
        uid: "",
        mpid: "",
        tier: "",
        cswc: "",
        location_id: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        npanxx: "",
        vcoord: "",
        av_cswc: "",
        address: "",
        ocn: "",
        lata: "",
        switch_clli: "",
        hcoord: "",
        validated: false,
        county: "",
        geocode: "",
        latitude: 0.0,
        longitude: 0.0,
        ratecenter: "",
        sano: "",
        sapr: "",
        sasd: "",
        sasf: "",
        sasn: "",
        sass: "",
        sath: "",
        note: "",
        lec_type: "",
        uniti_mla: "",
        vendor: "",
        new_loc_type: "",
        ld1: "",
        ld2: "",
        ld3: "",
        lv1: "",
        lv2: "",
        lv3: "",
        provider: "",
        address2: "",
        icon: "",
        wave: "",
        wave_xc: "",
        landlord: "",
        dci: "",
        wave_tier: "",
        wave_class: "",
        wave_type: "",
        wave_metro_area: "",
        wave_lcn: "",
        g10: "",
        g100: "",
        g400: "",
        g800: "",
        eline_enet: "",
        ethernet: "",
        colo: "",
        colo_tier: "",
        comments: "",
        active: ""
    }
};

const popReducer = (state = initialState, action) => {
    switch (action.type) {
        case POP_UID:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    uid: action.payload
                }
            };
        case POP_MPID:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    mpid: action.payload
                }
            };
        case POP_TIER:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    tier: action.payload
                }
            };
        case POP_CSWC:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    cswc: action.payload
                }
            };
        case POP_LOCATION_ID:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    location_id: action.payload
                }
            };
        case POP_CITY:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    city: action.payload
                }
            };
        case POP_STATE:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    state: action.payload
                }
            };
        case POP_COUNTRY:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    country: action.payload
                }
            };
        case POP_ZIP:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    zip: action.payload
                }
            };
        case POP_NPANXX:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    npanxx: action.payload
                }
            };
        case POP_VCOORD:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    vcoord: action.payload
                }
            };
        case POP_AV_CSWC:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    av_cswc: action.payload
                }
            };
        case POP_ADDRESS:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    address: action.payload
                }
            };
        case POP_OCN:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    ocn: action.payload
                }
            };
        case POP_LATA:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    lata: action.payload
                }
            };
        case POP_SWITCH_CLLI:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    switch_clli: action.payload
                }
            };
        case POP_HCOORD:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    hcoord: action.payload
                }
            };
        case POP_VALIDATED:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    validated: action.payload
                }
            };
        case POP_COUNTY:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    county: action.payload
                }
            };
        case POP_GEOCODE:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    geocode: action.payload
                }
            };
        case POP_LATITUDE:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    latitude: action.payload
                }
            };
        case POP_LONGITUDE:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    longitude: action.payload
                }
            };
        case POP_RATECENTER:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    ratecenter: action.payload
                }
            };
        case POP_SANO:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    sano: action.payload
                }
            };
        case POP_SAPR:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    sapr: action.payload
                }
            };
        case POP_SASD:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    sasd: action.payload
                }
            };
        case POP_SASF:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    sasf: action.payload
                }
            };
        case POP_SASN:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    sasn: action.payload
                }
            };
        case POP_SASS:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    sass: action.payload
                }
            };
        case POP_SATH:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    sath: action.payload
                }
            };
        case POP_NOTE:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    note: action.payload
                }
            };
        case POP_LEC_TYPE:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    lec_type: action.payload
                }
            };
        case POP_UNITI_MLA:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    uniti_mla: action.payload
                }
            };
        case POP_VENDOR:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    vendor: action.payload
                }
            };
        case POP_NEW_LOC_TYPE:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    new_loc_type: action.payload
                }
            };
        case POP_LD1:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    ld1: action.payload
                }
            };
        case POP_LD2:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    ld2: action.payload
                }
            };
        case POP_LD3:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    ld3: action.payload
                }
            };
        case POP_LV1:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    lv1: action.payload
                }
            };
        case POP_LV2:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    lv2: action.payload
                }
            };
        case POP_LV3:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    lv3: action.payload
                }
            };
        case POP_PROVIDER:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    provider: action.payload
                }
            };
        case POP_ADDRESS2:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    address2: action.payload
                }
            };
        case POP_ICON:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    icon: action.payload
                }
            };
        case POP_WAVE:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    wave: action.payload
                }
            };
        case POP_WAVE_XC:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    wave_xc: action.payload
                }
            };
        case POP_LANDLORD:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    landlord: action.payload
                }
            };
        case POP_DCI:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    dci: action.payload
                }
            };
        case POP_WAVE_TIER:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    wave_tier: action.payload
                }
            };
        case POP_WAVE_CLASS:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    wave_class: action.payload
                }
            };
        case POP_WAVE_TYPE:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    wave_type: action.payload
                }
            };
        case POP_WAVE_METRO_AREA:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    wave_metro_area: action.payload
                }
            };
        case POP_WAVE_LCN:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    wave_lcn: action.payload
                }
            };
        case POP_G10:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    g10: action.payload
                }
            };
        case POP_G100:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    g100: action.payload
                }
            };
        case POP_G400:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    g400: action.payload
                }
            };
        case POP_G800:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    g800: action.payload
                }
            };
        case POP_ELINE_ENET:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    eline_enet: action.payload
                }
            };
        case POP_ETHERNET:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    ethernet: action.payload
                }
            };
        case POP_COLO:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    colo: action.payload
                }
            };
        case POP_COLO_TIER:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    colo_tier: action.payload
                }
            };
        case POP_COMMENTS:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    comments: action.payload
                }
            };
        case POP_ACTIVE:
            return {
                ...state,
                pop_values: {
                    ...state.pop_values,
                    active: action.payload
                }
            };
        case RESET_ACTION:
            return initialState;
        default:
            return state;
    }
};

export default popReducer;