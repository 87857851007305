import axios from "./axios/customAxios";

const getUserData = async (data) => {
	const manageURL = `${process.env.REACT_APP_LOGIN_URL}/manage.php`;
	return await axios.post(manageURL, JSON.stringify(data));
}

const updateUserStatus = async (data) => {

	const manageURL = `${process.env.REACT_APP_LOGIN_URL}/manage.php`;
	return await axios.post(manageURL, JSON.stringify(data));
}

const getUserAccessInfo = async () => {
	const accessURL = `${process.env.REACT_APP_LOGIN_URL}/access.php`;
	return await axios.get(accessURL);
}

const getPageAccessMap = async () => {
	const pageAccessURL = `${process.env.REACT_APP_LOGIN_URL}/pageAccessMap.php`;
	return await axios.get(pageAccessURL);
}

const UserManagementService = {
	getUserData,
	updateUserStatus,
	getUserAccessInfo,
	getPageAccessMap
}

export default UserManagementService;
