import axios from "./axios/customAxios";

const RfoRcaRequest = async (values) => {
	const requestURL = `${process.env.REACT_APP_API_URL}/tools/rfoRca/rfoRcaSubmit.php?data=${encodeURIComponent(JSON.stringify(values))}`;
	return await axios.get(requestURL);
}

const verifyWindstreamCase = async (caseNumber) => {
	const requestURL = `${process.env.REACT_APP_API_URL}/tools/rfoRca/verifyWindstreamCase.php?caseNumber=${caseNumber}`;
	return await axios.get(requestURL);
}


const getRfoRcaSummary = async () => {
	const requestURL = `${process.env.REACT_APP_API_URL}/tools/rfoRca/rfoRcaSummary.php`;
	return await axios.get(requestURL);
}

const SupportHelper = {
	RfoRcaRequest,
	verifyWindstreamCase,
	getRfoRcaSummary
}

export default SupportHelper;