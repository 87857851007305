import axios from "./axios/customAxios";
import { ConsoleLogger } from "../logger/ConsoleLogger";

const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });

const getQuoteData = async (dateFrom, dateTo, report, limit, product, excludeApiQuotes) => {
	const quoteURL =
		`${process.env.REACT_APP_API_URL}/reports/getQuoteOrderSummaries.php?report=${report}&dateFrom=${dateFrom}&dateTo=${dateTo}&limit=${limit}&product=${product}&excludeApiQuotes=${excludeApiQuotes}`;
	return await axios.get(quoteURL)
}

const getProductMenu = async (value) => {
	const productURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=productArray&value=${value}`;
	return await axios.get(productURL);
}

const getQuoteContact = async () => {
	const quoteContactsURL = `${process.env.REACT_APP_API_URL}/getQuoteContacts.php?action=contactOptions`;
	return await axios.get(quoteContactsURL)
}

const getQuoteContactInfo = async (value) => {
	const quoteContactInfoURL = `${process.env.REACT_APP_API_URL}/getQuoteContacts.php?action=contactInfo&data=quote&value=${value}`;
	return await axios.get(quoteContactInfoURL)
}


const getContactEmails = async () => {
	const quoteContactsURL = `${process.env.REACT_APP_API_URL}/getQuoteContacts.php?action=contactEmails`;
	return await axios.get(quoteContactsURL)
}

const createQuote = async (values) => {
	const createQuoteURL = `${process.env.REACT_APP_API_URL}/quoteSubmit.php?data=${encodeURIComponent(JSON.stringify(values))}`;
	return await axios.get(createQuoteURL);
}

const getQuoteView = async (refId, makeOrderable, pdf, simplifyView) => {
	const getQuoteViewURL = `${process.env.REACT_APP_API_URL}/getQuoteView.php?ref_id=${refId}&makeOrderable=${makeOrderable}&pdf=${pdf}&simplifyView=${simplifyView}`;
	return await axios.get(getQuoteViewURL);
}

const getQuoteDataTable = async (refId, bulk = 0) => {
	const getQuoteViewURL = `${process.env.REACT_APP_API_URL}/getQuoteData.php?ref_id=${refId}`;
	return await axios.get(getQuoteViewURL);
}

const getFileTypes = async () => {
	const getFileTypesURL = `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=uploadTypes`;
	return await axios.get(getFileTypesURL);
}

const getUploadedFiles = async (value) => {
	const productURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=uploadedFiles&ref_id=${value}`;
	return await axios.get(productURL);
}

// Used on Bulk Create
const createBulkQuote = async (formData, values) => {
	return axios({
		method: "post",
		url: `${process.env.REACT_APP_API_URL}/quoteBulkSubmit.php?data=${JSON.stringify(values)}`,
		data: formData,
		headers: { "Content-Type": "multipart/form-data" },
	});
}

//used for Bulk Quote Summary
const getBulkQuoteData = async (days, report) => {
	const getBulkQuoteDataURL = `${process.env.REACT_APP_API_URL}/reports/getQuoteOrderSummaries.php?report=${report}&days=${days}`;
	return await axios.get(getBulkQuoteDataURL);
}

//Used by Bulk Quote View -- Used for the list of quotes on Bulk Quote View
const getBulkQuoteDetail = async (bulkId, report) => {
	const getBulkQuoteDetailURL = `${process.env.REACT_APP_API_URL}/quoteBulkDetail.php?id=${bulkId}&report=${report}`;
	return await axios.get(getBulkQuoteDetailURL);
}
//Used by Bulk Quote View -- Used for the list of quotes on Bulk Quote View (employee)
const getDownloadBulkQuoteDetail = async (bulkId) => {
	const getBulkQuoteDetailURL = `${process.env.REACT_APP_API_URL}/quoteBulkDetail.php?id=${bulkId}&report=downloadDetails`;
	return await axios.get(getBulkQuoteDetailURL);
}

const bulkMakeOrderable = async (bulkId, order) => {
	const bulkMakeOrderableURL = `${process.env.REACT_APP_API_URL}/makeOrderBulk.php?bulk_id=${bulkId}&order=${order}`;
	logger.info(bulkMakeOrderableURL);
	return await axios.get(bulkMakeOrderableURL);
}

const uploadFile = async (formData, fileType, ref_id, mpid) => {
	return axios({
		method: "post",
		url: `${process.env.REACT_APP_API_URL}/uploadFile.php?ref_id=${ref_id}&upload_type=${fileType}&mpid=${mpid}`,
		data: formData,
		headers: { "Content-Type": "multipart/form-data" },
	});
}

const archiveQuote = async (refId) => {
	const archiveURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=archive&ref_id=${refId}`;
	return await axios.get(archiveURL);
}

const getQuoteCircuits = async (mpid) => {
	const quoteCircuitsURL = `${process.env.REACT_APP_API_URL}/TroubleTickets.php?action=circuit_menu&mpid=${mpid}`;
	return await axios.get(quoteCircuitsURL)
}

const getQuoteWfInfo = async (refId) => {
	const quoteWfInfoURL = `${process.env.REACT_APP_API_URL}/internal/getQuoteViewWfFields.php?ref_id=${refId}`;
	return await axios.get(quoteWfInfoURL)
}

const getQuoteProfile = async () => {
	const quoteMpidURL = `${process.env.REACT_APP_API_URL}/getAccountProfile.php`;
	return await axios.get(quoteMpidURL);
}

const createDarkFiberOrder = async (refId) => { // done from quote view
	const dfOrderURL = `${process.env.REACT_APP_API_URL}/QuoteFunctions.php?type=orderDarkFiber&ref_id=${refId}`;
	return await axios.get(dfOrderURL)
}


const QuoteHelper = {
	getQuoteData,
	getQuoteDataTable,
	createBulkQuote,
	getBulkQuoteData,
	getBulkQuoteDetail,
	getDownloadBulkQuoteDetail,
	bulkMakeOrderable,
	getProductMenu,
	getQuoteContact,
	getQuoteContactInfo,
	getContactEmails,
	createQuote,
	getQuoteView,
	getFileTypes,
	uploadFile,
	getUploadedFiles,
	archiveQuote,
	getQuoteCircuits,
	getQuoteWfInfo,
	getQuoteProfile,
	createDarkFiberOrder
}

export default QuoteHelper;
