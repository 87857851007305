import React, { useState, useCallback, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import ProspectingHelper from '../../services/ProspectService';
import Loader from "../shared/Loader";
import ViewportWarning from "../shared/ViewportWarning";
import XLSXExport from "../../utils/XLSXExport";
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import CustomToolbar from '../components/tables/CustomToolbar';
import style from './Prospecting.module.css';

const ProspectingTool = (props) => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL }), []);
    const [errorMSG, setErrorMSG] = useState('');
    const [loader, setLoader] = useState(false);
    const [pageSize, setPageSize] = useState(100); // Set default page size to 100
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [addressSelected, setAddressSelected] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [prospectingData, setProspectingData] = useState([]);

    const XLSXHeaders = [
        "Entity Name",
        "Address",
        "Website",
        "Founded Year",
        "Phone",
        "Linked In",
        "Facebook",
        "Twitter",
        "No Of Employees",
        "Monthly Network Spend",
        "NAICS Code",
        "Industry"
    ];

    const columns = [
        {
            field: 'EntityName',
            headerName: 'Entity Name',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.8, // Make Entity column twice as wide
        },
        {
            field: 'Website',
            headerName: 'Website',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.75,
        },
        {
            field: 'Phone',
            headerName: 'Phone',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.3,
        },
        /*
        {
            field: 'NoOfEmployees',
            headerName: 'No Of\nEmployees',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.3,
            headerClassName: 'wrapHeader',
        },
        {
            field: 'MonthlyNetworkSpend',
            headerName: 'Monthly Network\nSpending',
            renderHeader: (params) => formatSearchHeader(params),
            renderCell: (params) => (
                <div style={{ textAlign: 'center', width: '100%' }}>
                    {params.value}
                </div>
            ),
            flex: 0.4,
            headerClassName: 'wrapHeader',
        },
        */
        {
            field: 'Industry',
            headerName: 'Industry',
            renderHeader: (params) => formatSearchHeader(params),
            renderCell: (params) => (
                <div style={{ textAlign: 'left', width: '100%' }}>
                    {params.value}
                </div>
            ),
            flex: 1.5,
        }
    ];

    function formatSearchHeader(params) {
        const isCenterAligned = params.colDef.field === 'industry';
        const headerStyle = {
            marginLeft: isCenterAligned ? 0 : 8,
            textAlign: isCenterAligned ? 'center' : 'left',
            color: "#753BBD",
            width: '100%',
            display: 'block',
            whiteSpace: 'pre-line', // Allow line breaks
        };

        return (
            <div style={{ display: 'flex', justifyContent: isCenterAligned ? 'center' : 'flex-start' }}>
                <span style={headerStyle}>{params.colDef.headerName}</span>
            </div>
        );
    }

    // Debounce function implementation
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedFetch = debounce(async (inputValue, callback) => {
        if (inputValue?.length > 2) {
            try {
                const response = await ProspectingHelper.getProspectLocations(inputValue, "");
                const options = response?.data.map((obj) => ({
                    value: {
                        address: obj.address,
                        city: obj.city,
                        state: obj.state,
                        country: obj.country,
                        zipcode: obj.zipcode
                    },
                    label: `${obj.address}${obj.address2 ? " " + obj.address2 : ""}, ${obj.city}, ${obj.state} ${obj.zipcode}`
                }));
                logger.info("Address options: " + JSON.stringify(options));
                callback(options);
            } catch (error) {
                logger.error("error: " + error);
            }
        }
    }, 500);

    const loadProspectOptions = (inputValue, callback) => {
        debouncedFetch(inputValue, callback);
    };

    const addressChangeHandler = (selectedAddress) => {
        logger.info("addressChangeHandler: " + JSON.stringify(selectedAddress));
        logger.info("Value Being Passed: " + JSON.stringify(selectedAddress.value));
        setErrorMSG('');
        setProspectingData([]);
        setAddressSelected(true);
        setSelectedAddress(selectedAddress.value.address);
        fetchProspectingData(selectedAddress);
    };

    const fetchProspectingData = useCallback((address) => {
        setLoader(true);
        ProspectingHelper.fetchProspectingData(address).then(resp => {
            logger.info("Received Response from getProspectingList");
            console.log("Prospecting Data before adding unique IDs:", resp.data);
            setProspectingData(resp?.data);
            setLoader(false);
            setErrorMSG('');
        }).catch(error => {
            logger.info("DID NOT Receive Response from getProspectingList");
            setLoader(false);
            setErrorMSG('No Prospects Found');
        });
    }, [logger]);

    const prepareDataForExport = (data) => {
        return data.map(({ Index, ...rest }) => rest);
    };

    const handleDownload = () => {
        const dataToExport = prepareDataForExport(prospectingData);
        const formatDate = () => {
            const date = new Date();
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            const yyyy = date.getFullYear();
            return `${mm}-${dd}-${yyyy}`;
        };
        const fileNamePrefix = selectedAddress ? selectedAddress.replace(/\s+/g, '') : 'ProspectingData';
        XLSXExport.exportToExcel(XLSXHeaders, dataToExport, `${fileNamePrefix}_TenantData_${formatDate()}`);
    };

    return (
        <>
            {loader ? <Loader /> : null}
            <div className="midsection qm-upload abs z1">
                <div className="rel">
                    <ViewportWarning />
                    <div className="wrap">
                        <h1 className="page-title">Employee Prospecting Summary</h1>
                        <div className="section">
                            <div className="content">
                                <div className="header-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                    <div className={`row ${style.rowGrp}`} style={{ flex: 1 }}>
                                        <div className="col-6">
                                            <label className={style.labelText}>Address Search</label>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadProspectOptions}
                                                onChange={addressChangeHandler}
                                                onFocus={() => {
                                                    // Clear the input box but do not clear the results
                                                    setSelectedAddress(null);
                                                }}
                                                value={selectedAddress ? { label: selectedAddress, value: selectedAddress } : null}
                                                isClearable // This allows the user to manually clear the selected value
                                            />
                                        </div>
                                    </div>
                                    {!loader && prospectingData?.length > 0 ? (
                                        <div>
                                            <button className="saveButton ico-button light-button" onClick={handleDownload}>
                                                <span><strong>Download</strong></span>
                                                <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
                                                    <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z" transform="translate(-1 -1)" />
                                                </svg>
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                                {errorMSG ? <p className={`text-center my-4 text-danger`}>{errorMSG}</p> : null}
                                <div style={{ marginBottom: '20px' }}></div>
                                <div style={{ width: "100%" }}>
                                    {loader || !addressSelected ? null : (
                                        prospectingData.length > 0 ? (
                                            <StripedDataGrid
                                                autoHeight
                                                theme={CustomTheme}
                                                rows={prospectingData}
                                                rowHeight={50}
                                                getRowId={(row) => row.Index}
                                                columns={columns}
                                                filterModel={filterModel}
                                                onFilterModelChange={(model) => setFilterModel(model)}
                                                pageSize={pageSize}
                                                rowsPerPageOptions={[]}
                                                resultsPerPageOptions={[]}
                                                pageSizeOptions={[]}
                                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                slots={{
                                                    pagination: TablePagination,
                                                    toolbar: CustomToolbar
                                                }}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                            pageSize: 100
                                                        },
                                                    },
                                                }}
                                            />
                                        ) : (
                                            null
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProspectingTool;