import style from "./BillingDispute.module.css";

const BillingDisputeComment = (props) => {
    
    return (
        <div>
            <div className="overlay show"></div>
            <div className="modal fade show d-block">
                <div className={` modal-dialog modal-dialog-centered modal-md`}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div class="modal-header">
                                <h5 class="modal-title m-auto text-dark">Additonal Dispute Comments</h5>
                            </div>
                            <p>
                                {`Please include the comments you wish to add to dispute case ${props?.caseNumber} and then click 'Save'.`}
                            </p>
                            <p>
                                Once saved, the comments will be added to dispute case.
                            </p>
                            <textarea className={`${style.disputeTextArea}`} onChange={(event) => props.newDisputeComment(event.target.value)}>
                            </textarea>
                        </div>
                        <div className="modal-footer text-right">
                            <button type="button" className="btn-style btn-cancel text-uppercase" data-dismiss="modal" onClick={() => props.hideAddCommentModal()}>Cancel</button>
                            <button type="button" className="btn-style btn-theme text-uppercase" onClick={() => props.addDisputeComment()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BillingDisputeComment;