import { useEffect, useRef, useState, useMemo } from 'react';
import MapView from '@arcgis/core/views/MapView';
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import WMSLayer from "@arcgis/core/layers/WMSLayer";
import Color from "@arcgis/core/Color";
import DirectionalPad from "@arcgis/core/widgets/DirectionalPad";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import Map from '@arcgis/core/Map';
import PopupTemplate from "@arcgis/core/PopupTemplate";
import NetworkViewerHelper from '../../services/NetworkViewerService';
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import CustomToolbar from '../components/tables/CustomToolbar';
import Loader from '../shared/Loader';
import Switch from '@mui/material/Switch';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import CompanyDropdown from '../shared/CompanyDropdown';
import CountDownTimer from '../components/CountDownTimer';
import Button from "@mui/material/Button";
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import ReactTooltip from 'react-tooltip';
import style from "./NetworkViewer.module.css"

const PurpleSwitch = styled(Switch)(() => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
	  color: '#753BBD',
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
	  backgroundColor: '#753BBD',
	},
}));

const NetworkViewerTable = styled(StripedDataGrid)(() => ({
	'& .MuiCheckbox-root svg path': {
	  fill: '#753BBD'
	}
}));

const NetworkViewer = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL }), []);
	const mapDiv = useRef(null);
	const viewRef = useRef(null);
	const iconNetworkLayer     = useRef(null);
	const ilecExchangesLayer   = useRef(null);
	const longhaulNetworkLayer = useRef(null);
	const weatherLayer         = useRef(null);
	const outageLayer = useRef(null);
	const customerLayer = useRef(null);
	const purple = new Color("#753BBD");
	const orange = new Color("#FFB81C");
	const blue = new Color("#0057B8");
	const black = new Color("#000000");

	const [commsId, setCommsId] = useState("");
	const [circuitTableData, setCircuitTableData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [circuitLoader, setCircuitLoader] = useState(true);
	const [leafletData, setLeafletData] = useState([]);
	const [allDesignIds, setAllDesignIds] = useState([]);
	const [outageData, setOutageData] = useState([]);
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const [outagesOn, setOutagesOn] = useState(true);
	const [noOutagePopup, setNoOutagePopUp] = useState(false);
	const [noOutageMessage, setNoOutageMessage] = useState("");

	const isCustomer = localStorage.getItem("isLoggedIn");
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const user = localStorage.getItem("user");

	useEffect(() => {

		const webMap = new Map({
			basemap: "topo-vector"
		});

		viewRef.current = new MapView({
			container: mapDiv.current,
			map: webMap,
			center: [39, -65]
		});

		/*Phase 2 potential item to revisit later 
		var searchWidget = new Search({
			view: viewRef.current
		});
		
		// Add the search widget to the top right corner of the view
		viewRef.current.ui.add(searchWidget, {
			position: "top-right"
		});
		*/

		const directionalPad = new DirectionalPad({
			view: viewRef.current
		});
		// adds the d-pad to the bottom left corner of the MapView
		viewRef.current.ui.add(directionalPad, "bottom-left");

		viewRef.current.when(function() {
			// MapView is now ready for display and can be used. Here we will
			// use goTo to view a particular location at a given zoom level and center
			viewRef.current.goTo({
			  center: [-98, 38],
			  zoom: 5
			});
		}).catch(function(err) {
			// A rejected view indicates a fatal error making it unable to display.
			// Use the errback function to handle when the view doesn't load properly
			logger.error("MapView rejected:", err);
		});

		//Weather Layer
		weatherLayer.current = new WMSLayer({
			url: "http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi",
			opacity: 0.50
		});
		viewRef.current.map.add(weatherLayer.current);

		//ICON Network Layer
		iconNetworkLayer.current = new GeoJSONLayer({
			url: window.location.origin + '/assets/json/iconNetwork.geojson', 
			visible: false, 
			renderer: {
				type:'simple', 
				symbol: { 
					type: "simple-line", 
					color: purple,
					width: '2px'
				}
			}
		})

		viewRef.current.map.add(iconNetworkLayer.current);

		//Longhaul Network Layer
		longhaulNetworkLayer.current = new GeoJSONLayer({
			url: window.location.origin + '/assets/json/longhaulNetwork.geojson',
			visible: false, 
			renderer: {
				type:'simple', 
				symbol: { 
					type: 'simple-line',
					color: black,
					width: '2px'
				}
			}
		})
		viewRef.current.map.add(longhaulNetworkLayer.current);

		//ILEC Exchange Layer
		ilecExchangesLayer.current = new GeoJSONLayer({
			url: window.location.origin + '/assets/json/ilecExchanges.geojson', 
			visible: false, 
			opacity: 0.50,
			renderer: {
				type:'simple', 
				symbol: { 
					type: "simple-fill",
					color: blue,
					outline: {
						color: blue
					}
				}
			}
		})

		viewRef.current.map.add(ilecExchangesLayer.current);

		return () => {
			if (viewRef.current) {
				viewRef.current.destroy();
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const styles = {
		layerToggle: {
			top: '375px',
			right: '90px',
			position: 'absolute',
			zIndex: '100',
			backgroundColor: 'white',
			borderRadius: '8px',
			padding: '10px',
			width: '200px',
			opacity: '0.75'
	  },
	  staticLayerToggle:{
			top: '525px',
			right: '90px',
			position: 'absolute',
			zIndex: '100',
			backgroundColor: 'white',
			borderRadius: '8px',
			padding: '10px',
			width: '200px',
			opacity: '0.75'
	  }
	}

	useEffect(() => {
		if ((isEmployee && selectedInternalMpid) || isCustomer) {
			setLoader(true);
			setCircuitLoader(true);
			setNoOutagePopUp(false);
			setNoOutageMessage("");
			let fetchCircuitsPromise;

			if(document.getElementById("weatherToggle")){
				document.getElementById("weatherToggle").checked = true;
			}
			if(selectedInternalMpid === 8683 || user === "Wholesale_Demo"){
				fetchCircuitsPromise = NetworkViewerHelper.getAllCircuitDemoIds();
			} else {
				logger.info("Getting network viewer circuit ids");
				fetchCircuitsPromise = NetworkViewerHelper.getNetworkViewerCircuits();
			}
			
	
			fetchCircuitsPromise.then(response => {
				setCircuitTableData(response.data);
				let designIdArray = [];
				let comms_id = "";
				// Collect all design IDs from the response
				response?.data.forEach((obj) => {
					designIdArray.push(obj.designId);
					comms_id = obj.gaid
				});
	
				// Log the design ID array for debugging purposes
				logger.info(`designIdArray: ${JSON.stringify(designIdArray)}`);
				
				// Update state with design IDs
				setRowSelectionModel(designIdArray);
				setAllDesignIds(designIdArray);
				setCommsId(comms_id);
				getActiveOutages();
				// Ensure all design IDs are set before calling fetchLeafletJsonData
				fetchLeafletJsonData(comms_id, designIdArray);
	
			}).catch(error => {
				logger.info("Error getting circuit route ids");
				setLoader(false);
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedInternalMpid]);

	const handleIconNetworkToggle = (event) => {
		iconNetworkLayer.current.visible = event.target.checked;
	};

	const handleIlecExchangesToggle = (event) => {
		ilecExchangesLayer.current.visible = event.target.checked;
	};

	const handleLonghaulNetworkToggle = (event) => {
		longhaulNetworkLayer.current.visible = event.target.checked;
	};

	const handleWeatherToggle = (event) => {
		weatherLayer.current.visible = event.target.checked;
	};

	const handleOutageToggle = (event) => {
		logger.info("Outage Toggle Clicked: ", event.target.checked);
		if (!event.target.checked) {
			logger.info("Deselecting outages");
			clearMapLayers("points");
		} else {
			logger.info("Selecting outages");
			drawOutages(outageData);
		}
	};

	const handleFullFiberPathToggle = (event) => {
		logger.info("Fiber Toggle Clicked: ", event.target.checked);
		if (!event.target.checked) {
			logger.info("deselecting full fiber path");
			clearMapLayers("polylines");
			setRowSelectionModel([]);
		} else {
			logger.info("selecting full fiber path");
			logger.info("allDesignIds: " + JSON.stringify(allDesignIds));
			drawLeafletData(leafletData, allDesignIds);
			setRowSelectionModel(allDesignIds);
		}
	};
	
	const fetchLeafletJsonData = (gaid, designIdArray) => {
		logger.info("inside of fetchLeafletJsonData");
		const fullFiberPathToggle = document.getElementById("fullFiberPathToggle");
		if(selectedInternalMpid === 8683 || user === "Wholesale_Demo"){
			NetworkViewerHelper.getLeafletDemoJson().then(response => {
				setLeafletData(response?.data?.leaflet_json);
				drawLeafletData(response?.data?.leaflet_json, designIdArray);
				if(fullFiberPathToggle){
					fullFiberPathToggle.checked = true;
				}
				setCircuitLoader(false);	
			}).catch(error => {
				logger.info("error fetching leaflet data");
			})
		} else {
			NetworkViewerHelper.getLeafletJsonData(gaid, designIdArray).then(response => {
				setLeafletData(response?.data?.leaflet_json);
				drawLeafletData(response?.data?.leaflet_json, designIdArray);
				if(fullFiberPathToggle){
					fullFiberPathToggle.checked = true;
				}	
				setCircuitLoader(false);
			}).catch(error => {
				logger.info("error fetching leaflet data");
			})
		}
		
	}

	const drawLeafletData = async (data, includedCircuits) => {

		logger.info("circuits to be drawn: " + JSON.stringify(includedCircuits));

		//We need to clear the map layer everytime we render the map
		await clearMapLayers("polylines");
		logger.info("inside of drawLeafletData");
		data.forEach((obj) => {
			Object.entries(obj).forEach(([designId, value]) => {
				if ((includedCircuits).includes(designId)) {
					let pathCoordinates = [];
					//looping through all the fiber lines
					value.edges.forEach((item) => {
						for (var key1 in item) {
							for (var key2 in item[key1]) {
								var coordinates = item[key1][key2].coordinates;
								pathCoordinates.push(chunkAndReverseArray(coordinates));
							}
						}
					});	

					if(pathCoordinates.flat().length > 0){
						let polyline = new Polyline({paths: pathCoordinates.flat()}) // autocasts as new Polyline()
										
						// Create a symbol for the polyline
						const lineSymbol = {
							type: "simple-line",
							color: orange,
							width: '3px'
						};

						// Create a graphic and add it to the graphics layer
						customerLayer.current = new Graphic({
							geometry: polyline,
							symbol: lineSymbol
						});

						viewRef.current.graphics.add(customerLayer.current);	
					}
				}			
			});	
		});	

	}

	const chunkAndReverseArray = (array) => {
		const chunks = [];
		let currentChunk = [];
		for (let i = 0; i < array.length; i++) {
			if (array[i].length === 0) {
				// Push the current chunk to the chunks array if it's not empty
				if (currentChunk.length > 0) {
					chunks.push(currentChunk);
					currentChunk = [];
				}
			} else {
				// Reverse the elements of the non-empty array and add to current chunk
				currentChunk.push([array[i][1], array[i][0]]);
			}
		}
	
		// Push the last chunk if it has elements
		if (currentChunk.length > 0) {
			chunks.push(currentChunk);
		}
	
		return chunks;
	}

	const clearMapLayers = (type) => {
		if (viewRef.current) {
			const graphicsToRemove = [];
	
			// Iterate over the graphics and find polylines
			viewRef?.current?.graphics.forEach((graphic) => {
				if (graphic.geometry.type === "polyline" && type === "polylines") {
					graphicsToRemove.push(graphic);
				}

				if (graphic.geometry.type === "point" && type === "points"){
					graphicsToRemove.push(graphic);
				}
			});
	
			// Remove the collected polylines
			graphicsToRemove.forEach((graphic) => {
				viewRef.current.graphics.remove(graphic);
			});
	
			logger.info(`Removed polylines: ${graphicsToRemove.length}`);
		}

	}

	const columns = [
		{
			field: 'designId',
			headerName: 'Design Id',
			flex: 1.0
		},
		{
			field: 'id',
			headerName: 'Circuit Id',
			flex: 1.0
		},
		{
			field: 'locA',
			headerName: 'Location A',
			flex: 1.75
		},
		{
			field: 'locZ',
			headerName: 'Location Z',
			flex: 1.75
		},
		{
			field: 'product',
			headerName: 'Product',
			flex: 0.5
		},
		{
			field: 'bandwidth',
			headerName: 'Bandwidth',
			flex: 0.5
		},
		{
			field: 'kmz',
			headerName: 'KMZ',
			renderCell: (params) =>  kmzFileFormatter(params),
			flex: 0.5
		}
	];
	
	const handleCheckboxSelection = (includedCircuits) => {
		logger.info(`Design IDs selected: ${includedCircuits}`);
		setRowSelectionModel(includedCircuits);
		drawLeafletData(leafletData, includedCircuits)
	}

	const kmzFileFormatter = (params) => {
		return (
			<button style={{padding: "0px"}} onClick={() => fetchKmzDownloadLink(params?.row?.designId)}>
				<svg className="ico"><path style={{ fill: '#753BBD'}} d="M31,21.5v14H13V8.5h9l9,9v2H20v-6h2v4h7l-7-7H15v23H29v-12Zm-20,10H9V4.5H21v2H11Zm-4-2H5V.5H18v2H7Z"></path></svg>
			</button>
	   );
	}

	const fetchKmzDownloadLink = (id) => {
		logger.info(`KMZ to be downloaded ${id}`);
		setLoader(true);
		NetworkViewerHelper.getKmzLink(id).then(response => {
			if (response.data.status === "SUCCESS") {
				//downloading excel file
				let kmzDownloadLink = document.createElement('a');
				kmzDownloadLink.href = response.data.url;
				kmzDownloadLink.download = response.data.filename;
				kmzDownloadLink.click();
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`Error Getting KMZ LINK: ${error}`);
			setLoader(false);
		})
	}

	const outageColumns = [
		{
			field: 'INCIDENTNUMBER',
			headerName: 'Incident Number',
			renderHeader: () => incidentHeaderFormatter(),
			flex: 1.0,
		},
		{
			field: 'TICKET_TYPE__C',
			headerName: 'Ticket Type',
			flex: 0.75
		},
		{
			field: 'PRIORITY',
			headerName: 'Priority',
			renderCell: (params) =>  priorityFormatter(params),
			flex: 0.5
		},
		{
			field: 'E911_STATE_CITY_LIST__C',
			headerName: 'Location',
			flex: 1
		},
		{
			field: 'LAT',
			headerName: 'Latitude',
			renderCell: (params) => coordinateFormatter(params),
			flex: 0.5
		},
		{
			field: 'LONG',
			headerName: 'Longitude',
			renderCell: (params) => coordinateFormatter(params),
			flex: 0.5
		},
		{
			field: 'IMPACT_START',
			headerName: 'Impact Start',
			flex: 0.5
		},
		{
			field: 'DURATION',
			headerName: 'Duration',
			flex: 0.75
		}
	];

	const incidentHeaderFormatter = () => {
		return (
			<>
				{'Incident Number'}
				<img data-tip data-for="incidentToolTip" className="tipped cornertip-right ml-2 mb-1" src="../../../images/svg/ico-info-filled-dark-off.svg" width="20" height="20" alt="" />
				<ReactTooltip id="incidentToolTip" effect="solid" delayHide={1500} place="top" clickable={true}>
					<div className={style.incidentToolTip}>
						NOC Outage Incident may not be impacting your service(s).  
						<br></br>
						Please investigate proactive ticketing associations to your services via the <a href="/Network/TroubleTickets" target="_blank" rel="noopener noreferrer">Trouble Ticket Summary</a> tool.
					</div>
				</ReactTooltip>
			</>
		)
	}

	const coordinateFormatter = (params) => {
		return params?.value?.toFixed(6)
	}

	const priorityFormatter = (params) => {
		let priority = params.row.PRIORITY;
		let color = "";
		switch(priority){
			case "High":
				color = "#FF0000";
				break;
			case "Medium":
				color = "#000000";
				break;
			case "Low":
				color = "#000000";
				break;
			default:
				color = "#FF0000";
		}
		return <span style={{color: color}}>{params.row.PRIORITY}</span>
	}

	const getActiveOutages = async (flag) => {
		if(flag === "refresh"){
			setLoader(true);
		}
		const outageToggle = document.getElementById("outageToggle");
		NetworkViewerHelper.getNetworkOutageData("active").then(response => {
			let tempOutageData = response.data;
			let activeOutageCount = response.data?.length;
			if(activeOutageCount > 0){
				response.data.forEach((obj, index) => {
					tempOutageData[index]["DURATION"] = calculateDuration(obj.IMPACT_START, obj.IMPACT_STOP);
					tempOutageData[index]["E911_STATE_CITY_LIST__C"] = obj.E911_STATE_CITY_LIST__C?.replace(/;/g, '');
				});
				fetchCoordinates(tempOutageData);
				if(outageToggle){
					outageToggle.checked = true;
				}
				setOutagesOn(true);
			} else {
				setNoOutageMessage("No outages impacting Windstream Wholesale services at this time!");
				setNoOutagePopUp(true);
				setOutagesOn(false);
				if(outageToggle){
					outageToggle.checked = false;
				}
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`Error getting Network Outages ${error}`);
		})
	}

	const fetchCoordinates = async (networkOutageData) => {
		setLoader(true);
		// Create an array of promises from the map operation
		const promises = networkOutageData.map((obj, index) => {
			return NetworkViewerHelper.getLatLong(obj.E911_STATE_CITY_LIST__C)
				.then(response => {
					if (response?.data?.status === "OK") {
						if (response?.data?.results[0].geometry) {
							networkOutageData[index]["LAT"] = response?.data?.results[0].geometry.location.lat;
							networkOutageData[index]["LONG"] = response?.data?.results[0].geometry.location?.lng;
						}
					}
				})
				.catch(error => {
					logger.info(`fetchCoordinates error: ${error}`);
				});
		});
	
		// Wait for all promises to complete
		await Promise.all(promises);
	
		logger.info(`NetworkOutageData: ${JSON.stringify(networkOutageData)}`);
	
		setOutageData(networkOutageData);
		drawOutages(networkOutageData);
		// Set the loader to false and proceed with the rest of the code
		setLoader(false);
	};

	const drawOutages = async (outages) => {
		logger.info("Inside of drawOutages");
		await clearMapLayers("points");
		let color = "";
		let numberOfOutages = outages?.length;
		logger.info(`Number of outages: ${numberOfOutages}`);
		outages.forEach((obj, index) => {
			//Red = Critcal/High | Yellow = Med/Low
			switch(obj.PRIORITY){
				case "High":
					color = "red";
					break;
				case "Medium":
					color = "yellow";
					break;
				case "Low":
					color = "yellow";
					break;
				default:
					color = "red";
			}

			// Creating Outage Point
			let outagePoint = {
				type: "point", // autocasts as new Point()
				latitude: obj.LAT,
				longitude: obj.LONG
			};

			// Creating outage symbol
			let outageMarkerSymbol = {
				type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
				size: "20px",
				color: color
			};

			let outagettributes = {
				"ObjectID": index+1,
				"Incident Number": obj.INCIDENTNUMBER,
				"Location": obj.E911_STATE_CITY_LIST__C,
				"Latitude/Longitude": "[" + obj.LAT + ", " + obj.LONG + "]",
				"Ticket Type": obj.TICKET_TYPE__C,
				"Priority": obj.PRIORITY,
				"Impact Start": obj.IMPACT_START,
				"Impact Stop": obj.IMPACT_STOP,
				"Duration": obj.DURATION
			}

			let outagePopupTemplate = new PopupTemplate({
				title: "Incident: {Incident Number}",
				content: [{
					type: "fields",
					fieldInfos: [
						{fieldName: "Location"},
						{fieldName: "Impact Start"},
						{fieldName: "Duration"},
						{fieldName: "Ticket Type"},
						{fieldName: "Priority"}
					]
				}]
			})

			// Create the outage graphic layer
			outageLayer.current = new Graphic({
				geometry: outagePoint,
				symbol: outageMarkerSymbol,
				attributes: outagettributes,
				popupTemplate: outagePopupTemplate
			});
			
			viewRef.current.graphics.add(outageLayer.current);
		})
	}
	
	const calculateDuration = (impactStart, impactStop) => {
		// Parse the impact start date-time string into a Date object
		const start = new Date(impactStart);
	
		logger.info(`startTime: ${start}`);
		// Determine the end time: use current time if impactStop is null, otherwise parse impactStop
		const end = impactStop ? new Date(impactStop) : new Date();

		logger.info(`endTime: ${end}`);
	
		// Calculate the duration in milliseconds
		const durationMillis = end - start;
	
		// Convert milliseconds to days, hours, minutes, and seconds
		const seconds = Math.floor((durationMillis / 1000) % 60);
		const minutes = Math.floor((durationMillis / (1000 * 60)) % 60);
		const hours = Math.floor((durationMillis / (1000 * 60 * 60)) % 24);
		const days = Math.floor(durationMillis / (1000 * 60 * 60 * 24));
	
		// Create an array to store the parts of the duration
		const durationParts = [];
	
		// Add non-zero components to the durationParts array
		if (days > 0) durationParts.push(`${days} day${days > 1 ? 's' : ''}`);
		if (hours > 0) durationParts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
		if (minutes > 0) durationParts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
		if (seconds > 0) durationParts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
	
		return durationParts.join(', ');
	}

	const handleRefresh = (countDown) => {
		if (countDown < 2) {
			setNoOutageMessage("");
			getActiveOutages("refresh");
		}
	};

	const downloadAllKMZ = () => {
		setLoader(true);
		NetworkViewerHelper.getAllCircuitsKmz(commsId).then(response => {
			if (response.data.result === "SUCCESS") {
				logger.info("Successfully got a token for the kmz that contains all circuits");
				let allCircuitKmzlink = document.createElement('a');
				allCircuitKmzlink.href = response.data.url;
				allCircuitKmzlink.download = response.data.filename;
				allCircuitKmzlink.click();
				setLoader(false);
			} else {
				logger.info("Error getting token for all circuit kmz download file");
				setLoader(false);
			}
		}).catch(error => {
			setLoader(false);
			logger.error(`Error getAllCircuitsKmz ${error}`);
		})
	}

	return (
		<>
			{loader ? <Loader /> : null}
			<div className="midsection employee abs z1">
				<div className="rel">
					<div className="wrap">
						<h1 className="page-title mb-0">Network Viewer</h1>
						{isEmployee ? <CompanyDropdown/> : null}
						<h6 className="page-title mt-0 mb-2">View active Network Outages and Full Fiber Paths (transport services).</h6>
						<div className="section">
							<div className="content">
								<div className="sub-section">
									<div ref={mapDiv} style={{ height: '70vh', width: '100%' }} />
									<div className="layerToggle esri-widget mb-2" style={styles.layerToggle}>
										<h6>Operational Layers</h6> 
										<div><input type="checkbox" id="outageToggle" className='mr-2 mb-1' onClick={handleOutageToggle}/>Outages</div>
										<div><input type="checkbox" id="fullFiberPathToggle" className='mr-2 mb-1' onClick={handleFullFiberPathToggle}/>Full Fiber Paths</div>
									</div>
									<div className="layerToggle esri-widget" style={styles.staticLayerToggle}> 
										<h6>Informational Layers</h6> 
										<div><input type="checkbox" id="weatherToggle" className='mr-2 mb-1' onClick={handleWeatherToggle}/>Weather</div>	
										<div><input type="checkbox" id="iconNetworkToggle" className='mr-2 mb-1' onClick={handleIconNetworkToggle}/>Icon Network</div>
										<div><input type="checkbox" id="longhaulNetworkToggle" className='mr-2 mb-1' onClick={handleLonghaulNetworkToggle}/>Longhaul Network</div>
										<div><input type="checkbox" id="ilecExchangesToggle" className='mr-2 mb-1' onClick={handleIlecExchangesToggle}/>ILEC Exchanges</div>
									</div>
								</div>
								{(selectedInternalMpid || isCustomer) && (circuitTableData?.length > 0 || outageData?.length > 0 ) ?
									<div className="sub-section"> 
										<div className="row ml-2">
											<Stack direction="row" component="label" alignItems="center" sx={{ mb: 1, textTransform: "none" }}>
												<Typography>
													{circuitLoader ? <div className='spinner-border spinner-border-sm ml-2' role='status'/> :  null } Circuits
												</Typography>
												<PurpleSwitch checked={outagesOn ? "checked": ""} onChange={() => setOutagesOn(!outagesOn)}/>
												<Typography >
													Active Outages<span className="text-danger"> (Beta)</span>
												</Typography>
												{outagesOn ? 
												<>
													<span className="ml-3 mt-1">Outages Refresh In<CountDownTimer changeFunction={handleRefresh} color={"#753BBD"} fontSize={"12px"} seconds={300}/></span>
												</>		:
													<Button variant="outlined" sx={{ml: 3, color: "#753BBD", borderColor: "#753BBD", "&:hover": { borderColor: "#753BBD"}}} onClick={() => downloadAllKMZ()}>Download All KMZ</Button>
												}
											</Stack>
										</div>
										{outagesOn ? 
											<div className="row ml-2">
												<span style={{color: "black", fontSize: "12px", textTransform: "none"}}>*Toggle to <b>Circuits</b> view to download KMZ file for each circuit or entire footprint.</span>
											</div> : null
										}
										{circuitTableData?.length > 0 && !outagesOn ? 
											<NetworkViewerTable
												autoHeight
												theme={CustomTheme}
												rows={circuitTableData}
												rowHeight={50}
												getRowId={(row) => row.designId}
												columns={columns}
												rowsPerPageOptions={10}
												resultsPerPageOptions={[]}
												pageSizeOptions={[]}
												slots={{
													pagination: TablePagination,
													toolbar: CustomToolbar
												}}
												initialState={{
													pagination: {
														paginationModel: {
															pageSize: 10
														},
													},
													columns: {
														columnVisibilityModel: {
															designId: false
														}
													}
												}}
												checkboxSelection={true}
												onRowSelectionModelChange={(newRowSelectionModel) => {
													handleCheckboxSelection(newRowSelectionModel)
												}}
												rowSelectionModel={rowSelectionModel}
												disableRowSelectionOnClick
												keepNonExistentRowsSelected
											/> : null
										}
										{outageData?.length > 0 && outagesOn ? 
											<StripedDataGrid
												autoHeight
												theme={CustomTheme}
												rows={outageData}
												rowHeight={50}
												getRowId={(row) => row.INCIDENTNUMBER}
												columns={outageColumns}
												rowsPerPageOptions={10}
												resultsPerPageOptions={[]}
												pageSizeOptions={[]}
												slots={{
													pagination: TablePagination,
													toolbar: CustomToolbar
												}}
												initialState={{
													pagination: {
														paginationModel: {
															pageSize: 10
														},
													},
												}}
											/> : null
										}
										{noOutageMessage ? 
											<span style={{color: "#753BBD"}}>{noOutageMessage}</span> : null
										}
									</div> : null
								}
								{noOutagePopup ? 
									<div>
										<div className="overlay show"></div>
										<div className="modal fade show d-block">
											<div className={` modal-dialog modal-dialog-centered modal-md`}>
												<div className="modal-content">
													<div className="modal-body">
														<p>
															Outages may not be impacting Windstream Services!
														</p>
													</div>
													<div className="modal-footer text-right">
														<button type="button" className="btn-style btn-cancel text-uppercase" data-dismiss="modal" onClick={() => setNoOutagePopUp(false)}>OK</button>
													</div>
												</div>
											</div>
										</div>
									</div> : null
								}

							</div>
						</div>	
					</div> 
				</div>
			</div>
		</>
	)
}

export default NetworkViewer;
