import {GridToolbarContainer, GridToolbarQuickFilter} from "@mui/x-data-grid";
import {ThemeProvider} from "@mui/material/styles";
import CustomTheme from "./CustomTheme";

const CustomToolbar = () => {
    return (
        <ThemeProvider theme={CustomTheme}>
            <GridToolbarContainer>
                <GridToolbarQuickFilter/>
            </GridToolbarContainer>
        </ThemeProvider>
    );
}

export default CustomToolbar