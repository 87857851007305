import axios from "./axios/customAxios";

const getCircuitList = async (circuitId, gaid) => {
    if(!gaid) gaid = "";
    if(!circuitId) circuitId = "";
    const circuitListURL =  `${process.env.REACT_APP_API_URL}/TroubleTickets.php?action=circuit_menu&circuitId=${circuitId}&gaid=${gaid}`;
    return await axios.get(circuitListURL)
 }
 const getCircuitListCount = async (circuitId) => {
     const circuitListCountURL = `${process.env.REACT_APP_API_URL}/TroubleTickets.php?action=circuitCount&circuitId=${circuitId}`;
     return await axios.get(circuitListCountURL);
 }

 const CircuitListHelper = {
    getCircuitList,
    getCircuitListCount
 }

 export default CircuitListHelper;
