import { useState } from 'react';
import ViewportWarning from "../../shared/ViewportWarning";
import Account from "./Account";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import TabsStaff from "./TabsStaff";

const Staff = () => {
    const [navLink, setNavLink] = useState('Account');
    const [formValues, setFormValues] = useState({}); // Shared state for user data

    const handleNavClick = (e) => {
        console.log(`Navigating to ${e}`);
        if (navLink !== e) {
            setNavLink(e);
        }
    };

    console.log("Staff.js");

    const LoadComponent = (() => {
        switch (navLink) {
            case 'Account':
                return <Account formValues={formValues} setFormValues={setFormValues} />;
            case 'AddUser':
                return <AddUser setFormValues={setFormValues} setNavLink={setNavLink} />;
            case 'EditUser':
                return <EditUser formValues={formValues} setFormValues={setFormValues} />;
            default:
                return null;
        }
    });

    return (
        <div className="midsection employee abs z1">
            <div className="rel">
                <ViewportWarning />
                <div className="wrap">
                    <h1 className="page-title"> Staff Management</h1>
                    <div className="section">
                        <TabsStaff handleNav={handleNavClick} navLink={navLink} />
                        <div className="content">
                            {LoadComponent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Staff;