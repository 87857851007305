import {
	QUOTE_A_ADDRESS,
	QUOTE_A_BW_DISPLAY,
	QUOTE_A_BW_DISPLAY_FLAG,
	QUOTE_AVAILABILITY_FLAG,
	QUOTE_A_VPN_PORT_1,
	QUOTE_A_VPN_PORT_2,
	QUOTE_A_VPN_PORT_3,
	QUOTE_ACCESS_TYPE,
	QUOTE_ADD_BW_1,
	QUOTE_ADD_BW_2,
	QUOTE_ATT_EXCLUSION,
	QUOTE_BAM_KEY,
	QUOTE_BREAKER_AMP,
	QUOTE_BROADBAND_ADSL,
	QUOTE_BROADBAND_BANDWIDTHS,
	QUOTE_BROADBAND_CABLE,
	QUOTE_BURSTABLE_BILLING_1,
	QUOTE_BURSTABLE_BILLING_2,
	QUOTE_BURSTABLE_BILLING_3,
	QUOTE_BW,
	QUOTE_CAM_QTY,
	QUOTE_CIRCUIT_TYPE,
	QUOTE_CLONE,
	QUOTE_COMCAST_EXCLUSION,
	QUOTE_COMMIT_BW_1,
	QUOTE_COMMIT_BW_2,
	QUOTE_COMMIT_BW_3,
	QUOTE_COS,
	QUOTE_COS_TYPE_1,
	QUOTE_COS_TYPE_2,
	QUOTE_COS_TYPE_3,
	QUOTE_CPE_TYPE,
	QUOTE_DDOS,
	QUOTE_DDOS_TYPE,
	QUOTE_DIVERSITY,
	QUOTE_DIVERSITY_NOTE,
	QUOTE_ENNI_EXTENSION,
	QUOTE_ERROR_MSG,
	QUOTE_FIBER_CROSS_CONNECT,
	QUOTE_FUSE_PANEL,
	QUOTE_INTERNET_ROUTER,
	QUOTE_IPS,
	QUOTE_KMZ_FILE,
	QUOTE_DF_OPTIONS,
	QUOTE_DF_TERM,
	QUOTE_DF_NUM_FIBERS,
	QUOTE_DF_COMMENTS,
	QUOTE_LOCA_UNI,
	QUOTE_LOCZ_UNI,
	QUOTE_LUMEN_EXCLUSION,
	QUOTE_NNI_LOCATION,
	QUOTE_POP_LOCATION,
	QUOTE_PRIMARY_POWER,
	QUOTE_PRODUCT,
	QUOTE_PRODUCT_CODE,
	QUOTE_PROTOCOL_1,
	QUOTE_PROTOCOL_2,
	QUOTE_PROTOCOL_3,
	QUOTE_QTY,
	QUOTE_RACK_SPACE,
	QUOTE_ROUTE_LATENCY,
	QUOTE_ROUTE_TYPE,
	QUOTE_SECONDARY_POWER,
	QUOTE_SF_GAID,
	QUOTE_SF_ID,
	QUOTE_SPECTRUM_EXCLUSION,
	QUOTE_STEP_CODE,
	QUOTE_TERM_1,
	QUOTE_TERM_2,
	QUOTE_TERM_3,
	QUOTE_TERM_4,
	QUOTE_TERM_5,
	QUOTE_TERM_10,
	QUOTE_VENDOR_EXCLUSION,
	QUOTE_VERIZON_EXCLUSION,
	QUOTE_Z_ADDRESS,
	QUOTE_Z_BW_DISPLAY,
	QUOTE_Z_BW_DISPLAY_FLAG,
	QUOTE_Z_VPN_PORT_1,
	QUOTE_Z_VPN_PORT_2,
	QUOTE_Z_VPN_PORT_3,
	QUOTE_ROUTE_DETAILS,
	QUOTE_PATH_DETAILS,
	QUOTE_PATH_NUMBER,
	QUOTE_KMZ_URL,
	QUOTE_ROUTE_CAPACITY,
	QUOTE_NETWORK_TYPE_COUNTS,
	QUOTE_AUTO_PRICES,
	QUOTE_ROUTE_HOPS,
	QUOTE_ROUTE_FIBER_MILES,
	QUOTE_PATH_LATENCY,
	QUOTE_ROUTE_BUILDINGS,
	QUOTE_NETWORK_EDGES,
	QUOTE_OTUC_PATH,
	QUOTE_NETWORK_PATH,
	QUOTE_ROUTE_REGENS,
	QUOTE_AVAILABILITY_ICON_FLAG,
	QUOTE_BROADBAND_OBJ,
	QUOTE_UQUAL_SEARCH_BY_FLAG,
	QUOTE_UQUAL_ID,
	QUOTE_TERM_MONTH_TO_MONTH,
	QUOTE_TYPE_OF_IPS,
	QUOTE_MODEM,
	QUOTE_TLC_THIS_LOC,
	QUOTE_ENT_GROUP,
	QUOTE_EXT_TO_EXT,
	QUOTE_EXT_LENGTH,
	QUOTE_PORTING,
	QUOTE_BTN,
	QUOTE_OFFICESUITE_QUANTITIES,
	QUOTE_OFFICESUITE_FEATURES,
	UPDATE_FEATURE_PRICING,
	QUOTE_FEATURE_TOTAL_MRC,
	QUOTE_FEATURE_TOTAL_NRC,
	QUOTE_SUB_TOTAL_MRC,
	QUOTE_SUB_TOTAL_NRC,
	QUOTE_TOTAL_MRC,
	QUOTE_TOTAL_NRC,
	QUOTE_ASSOC_REF_ID,
	RESET_ACTION
} from "./types/actionTypes";

export const setQuoteClone = (payload) => {
	return {
		type: QUOTE_CLONE,
		payload: payload
	}
}

export const setQuoteAvailabilityFlag = (flag) => {
	return {
		type: QUOTE_AVAILABILITY_FLAG,
		payload: flag
	}
}

export const setQuoteAvailabilityIconFlag = (icon) => {
	return {
		type: QUOTE_AVAILABILITY_ICON_FLAG,
		payload: icon
	}
}

export const setQuoteAvailabilityRouteDetails = (details) => {
	return {
		type: QUOTE_ROUTE_DETAILS,
		payload: details
	}
}

export const setQuoteAvailabilityPathDetails = (pathDetails) => {
	return {
		type: QUOTE_PATH_DETAILS,
		payload: pathDetails
	}
}

export const setQuoteAvailabilityPathNumber = (pathNumber) => {
	return {
		type: QUOTE_PATH_NUMBER,
		payload: pathNumber
	}
}

export const setQuoteKmzURL = (url) => {
	return {
		type: QUOTE_KMZ_URL,
		payload: url
	}
}

export const setQuoteRouteCapacity = (capacity) => {
	return {
		type: QUOTE_ROUTE_CAPACITY,
		payload: capacity
	}
}

export const setQuoteNetworkTypeCounts = (network_type_counts) => {
	return {
		type: QUOTE_NETWORK_TYPE_COUNTS,
		payload: network_type_counts
	}
}

export const setQuoteAutoPrices = (prices) => {
	return {
		type: QUOTE_AUTO_PRICES,
		payload: prices
	}
}

export const setQuoteRouteHops = (hops) => {
	return {
		type: QUOTE_ROUTE_HOPS,
		payload: hops
	}
}

export const setQuoteRouteFiberMiles = (miles) => {
	return {
		type: QUOTE_ROUTE_FIBER_MILES,
		payload: miles
	}
}

export const setQuotePathLatency = (latency) => {
	return {
		type: QUOTE_PATH_LATENCY,
		payload: latency
	}
}

export const setQuoteRouteBuildings = (buildings) => {
	return {
		type: QUOTE_ROUTE_BUILDINGS,
		payload: buildings
	}
}

export const setQuoteNetworkEdges = (edges) => {
	return {
		type: QUOTE_NETWORK_EDGES,
		payload: edges
	}
}

export const setQuoteOtucPath = (path) => {
	return {
		type: QUOTE_OTUC_PATH,
		payload: path
	}
}

export const setQuoteNetworkPath = (path) => {
	return {
		type: QUOTE_NETWORK_PATH,
		payload: path
	}
}

export const setQuoteRouteRegens = (regens) => {
	return {
		type: QUOTE_ROUTE_REGENS,
		payload: regens
	}
}

export const setQuoteProduct = (product) => {
	return {
		type: QUOTE_PRODUCT,
		payload: product
	}
}

export const setQuoteProductCode = (code) => {
	return {
		type: QUOTE_PRODUCT_CODE,
		payload: code
	}
}

export const setQuoteCircuitType = (type) => {
	return {
		type: QUOTE_CIRCUIT_TYPE,
		payload: type
	}
}

export const setQuoteStepCode = (step_code) => {
	return {
		type: QUOTE_STEP_CODE,
		payload: step_code
	}
}

export const setQuoteTerm1 = (term1) => {
	return {
		type: QUOTE_TERM_1,
		payload: term1
	}
}

export const setQuoteTerm2 = (term2) => {
	return {
		type: QUOTE_TERM_2,
		payload: term2
	}
}

export const setQuoteTerm3 = (term3) => {
	return {
		type: QUOTE_TERM_3,
		payload: term3
	}
}

export const setQuoteTerm4 = (term4) => {
	return {
		type: QUOTE_TERM_4,
		payload: term4
	}
}

export const setQuoteTerm5 = (term5) => {
	return {
		type: QUOTE_TERM_5,
		payload: term5
	}
}

export const setQuoteTerm10 = (term10) => {
	return {
		type: QUOTE_TERM_10,
		payload: term10
	}
}

export const setQuoteBW = (bw) => {
	return {
		type: QUOTE_BW,
		payload: bw
	}
}

export const setQuoteAddBW1 = (bw1) => {
	return {
		type: QUOTE_ADD_BW_1,
		payload: bw1
	}
}

export const setQuoteAddBW2 = (bw2) => {
	return {
		type: QUOTE_ADD_BW_2,
		payload: bw2
	}
}

export const setQuoteNNILoc = (nni_loc) => {
	return {
		type: QUOTE_NNI_LOCATION,
		payload: nni_loc
	}
}

export const setQuoteENNIExtension = (extension) => {
	return {
		type: QUOTE_ENNI_EXTENSION,
		payload: extension
	}
}

export const setQuotePopLocation = (popLoc) => {
	return {
		type: QUOTE_POP_LOCATION,
		payload: popLoc
	}
}

export const setQuoteQty = (qty) => {
	return {
		type: QUOTE_QTY,
		payload: qty
	}
}

export const setQuoteIps = (ips) => {
	return {
		type: QUOTE_IPS,
		payload: ips
	}
}

export const setQuoteDDOS = (ddos) => {
	return {
		type: QUOTE_DDOS,
		payload: ddos
	}
}

export const setQuoteCAMQty = (cam_qty) => {
	return {
		type: QUOTE_CAM_QTY,
		payload: cam_qty
	}
}

export const setQuoteDDOSType = (ddos_type) => {
	return {
		type: QUOTE_DDOS_TYPE,
		payload: ddos_type
	}
}


export const setQuoteCrossConnect = (cross_connect) => {
	return {
		type: QUOTE_FIBER_CROSS_CONNECT,
		payload: cross_connect
	}
}

export const setQuoteRackSpace = (rack_space) => {
	return {
		type: QUOTE_RACK_SPACE,
		payload: rack_space
	}
}

export const setQuoteBreakerAmp = (amp) => {
	return {
		type: QUOTE_BREAKER_AMP,
		payload: amp
	}
}

export const setQuotePrimaryPower = (primary_power) => {
	return {
		type: QUOTE_PRIMARY_POWER,
		payload: primary_power
	}
}

export const setQuoteSecondaryPower = (secondary_power) => {
	return {
		type: QUOTE_SECONDARY_POWER,
		payload: secondary_power
	}
}

export const setQuoteSfGAID = (sf_gaid) => {
	return {
		type: QUOTE_SF_GAID,
		payload: sf_gaid
	}
}
export const setQuoteSfID = (sf_id) => {
	return {
		type: QUOTE_SF_ID,
		payload: sf_id
	}
}

export const setQuoteFusePanel = (fuse_panel) => {
	return {
		type: QUOTE_FUSE_PANEL,
		payload: fuse_panel
	}
}

export const setQuoteCPEType = (cpe_type) => {
	return {
		type: QUOTE_CPE_TYPE,
		payload: cpe_type
	}
}

export const setQuoteInternetRouter = (router_service) => {
	return {
		type: QUOTE_INTERNET_ROUTER,
		payload: router_service
	}
}

export const setQuoteVendorExclusion = (vendor) => {
	return {
		type: QUOTE_VENDOR_EXCLUSION,
		payload: vendor
	}
}

export const setQuoteATTExclusion = (att) => {
	return {
		type: QUOTE_ATT_EXCLUSION,
		payload: att
	}
}

export const setQuoteVerizonExclusion = (verizon) => {
	return {
		type: QUOTE_VERIZON_EXCLUSION,
		payload: verizon
	}
}

export const setQuoteLumenExclusion = (lumen) => {
	return {
		type: QUOTE_LUMEN_EXCLUSION,
		payload: lumen
	}
}

export const setQuoteComcastExclusion = (comcast) => {
	return {
		type: QUOTE_COMCAST_EXCLUSION,
		payload: comcast
	}
}

export const setQuoteSpectrumExclusion = (spectrum) => {
	return {
		type: QUOTE_SPECTRUM_EXCLUSION,
		payload: spectrum
	}
}

export const setQuoteBroadbandCable = (cable) => {
	return {
		type: QUOTE_BROADBAND_CABLE,
		payload: cable
	}
}

export const setQuoteBroadbandAdsl = (adsl) => {
	return {
		type: QUOTE_BROADBAND_ADSL,
		payload: adsl
	}
}

export const setQuoteAddressA = (a_add) => {
	return {
		type: QUOTE_A_ADDRESS,
		payload: a_add
	}
}

export const setQuoteAddressZ = (z_add) => {
	return {
		type: QUOTE_Z_ADDRESS,
		payload: z_add
	}
}

export const setQuoteProtocol1 = (protocol1) => {
	return {
		type: QUOTE_PROTOCOL_1,
		payload: protocol1
	}
}

export const setQuoteProtocol2 = (protocol2) => {
	return {
		type: QUOTE_PROTOCOL_2,
		payload: protocol2
	}
}

export const setQuoteProtocol3 = (protocol3) => {
	return {
		type: QUOTE_PROTOCOL_3,
		payload: protocol3
	}
}


export const setQuoteRouteType = (route) => {
	return {
		type: QUOTE_ROUTE_TYPE,
		payload: route
	}
}

export const setQuoteDiversity = (diversity) => {
	return {
		type: QUOTE_DIVERSITY,
		payload: diversity
	}
}

export const setQuoteDiversityNote = (div_note) => {
	return {
		type: QUOTE_DIVERSITY_NOTE,
		payload: div_note
	}
}

export const setQuoteRouteLatency = (latency) => {
	return {
		type: QUOTE_ROUTE_LATENCY,
		payload: latency
	}
}

export const setQuoteKmzFile = (kmz_file) => {
	return {
		type: QUOTE_KMZ_FILE,
		payload: kmz_file
	}
}

export const setQuoteCOS = (cos) => {
	return {
		type: QUOTE_COS,
		payload: cos
	}
}

export const setQuoteDfOptions = (df_options) => {
	return {
		type: QUOTE_DF_OPTIONS,
		payload: df_options
	}
}

export const setQuoteDfTerm = (df_term) => {
	return {
		type: QUOTE_DF_TERM,
		payload: df_term
	}
}

export const setQuoteDfNumFibers = (df_num_fibers) => {
	return {
		type: QUOTE_DF_NUM_FIBERS,
		payload: df_num_fibers
	}
}

export const setQuoteDfComments = (df_comments) => {
	return {
		type: QUOTE_DF_COMMENTS,
		payload: df_comments
	}
}

export const setQuoteLocAUni = (locA_uni) => {
	return {
		type: QUOTE_LOCA_UNI,
		payload: locA_uni
	}
}

export const setQuoteLocZUni = (locZ_uni) => {
	return {
		type: QUOTE_LOCZ_UNI,
		payload: locZ_uni
	}
}

export const setQuoteVpnAPort1 = (a_vpn_port_1) => {
	return {
		type: QUOTE_A_VPN_PORT_1,
		payload: a_vpn_port_1
	}
}

export const setQuoteVpnAPort2 = (a_vpn_port_2) => {
	return {
		type: QUOTE_A_VPN_PORT_2,
		payload: a_vpn_port_2
	}
}

export const setQuoteVpnAPort3 = (a_vpn_port_3) => {
	return {
		type: QUOTE_A_VPN_PORT_3,
		payload: a_vpn_port_3
	}
}

export const setQuoteVpnZPort1 = (z_vpn_port_1) => {
	return {
		type: QUOTE_Z_VPN_PORT_1,
		payload: z_vpn_port_1
	}
}

export const setQuoteVpnZPort2 = (z_vpn_port_2) => {
	return {
		type: QUOTE_Z_VPN_PORT_2,
		payload: z_vpn_port_2
	}
}

export const setQuoteVpnZPort3 = (z_vpn_port_3) => {
	return {
		type: QUOTE_Z_VPN_PORT_3,
		payload: z_vpn_port_3
	}
}

export const setQuoteCosType1 = (cos1) => {
	return {
		type: QUOTE_COS_TYPE_1,
		payload: cos1
	}
}

export const setQuoteCosType2 = (cos2) => {
	return {
		type: QUOTE_COS_TYPE_2,
		payload: cos2
	}
}

export const setQuoteCosType3 = (cos3) => {
	return {
		type: QUOTE_COS_TYPE_3,
		payload: cos3
	}
}

export const setQuoteAccessType = (accessType) => {
	return {
		type: QUOTE_ACCESS_TYPE,
		payload: accessType
	}
}

export const setQuoteBurstBilling1 = (burstbilling1) => {
	return {
		type: QUOTE_BURSTABLE_BILLING_1,
		payload: burstbilling1
	}
}

export const setQuoteBurstBilling2 = (burstbilling2) => {
	return {
		type: QUOTE_BURSTABLE_BILLING_2,
		payload: burstbilling2
	}
}

export const setQuoteBurstBilling3 = (burstbilling3) => {
	return {
		type: QUOTE_BURSTABLE_BILLING_3,
		payload: burstbilling3
	}
}

export const setQuoteCommitBw1 = (commitbw1) => {
	return {
		type: QUOTE_COMMIT_BW_1,
		payload: commitbw1
	}
}

export const setQuoteCommitBw2 = (commitbw2) => {
	return {
		type: QUOTE_COMMIT_BW_2,
		payload: commitbw2
	}
}
export const setQuoteCommitBw3 = (commitbw3) => {
	return {
		type: QUOTE_COMMIT_BW_3,
		payload: commitbw3
	}
}

export const setQuoteBroadbandBWOptions = (bandwidths) => {
	return {
		type: QUOTE_BROADBAND_BANDWIDTHS,
		payload: bandwidths
	}
}

export const setQuoteBamKey = (key) => {
	return {
		type: QUOTE_BAM_KEY,
		payload: key
	}
}

export const setErrorMsg = (error) => {
	return {
		type: QUOTE_ERROR_MSG,
		payload: error
	}
}


export const resetData = (data) => {
	return {
		type: RESET_ACTION,
		payload: data
	}
}

export const setBwLocADisplay = (data) => {
	return {
		type: QUOTE_A_BW_DISPLAY,
		payload: data
	}
}

export const setBwLocZDisplay = (data) => {
	return {
		type: QUOTE_Z_BW_DISPLAY,
		payload: data
	}
}

export const setBwLocAFlag = (data) => {
	return {
		type: QUOTE_A_BW_DISPLAY_FLAG,
		payload: data
	}
}

export const setBwLocZFlag = (data) => {
	return {
		type: QUOTE_Z_BW_DISPLAY_FLAG,
		payload: data
	}
}

export const setBroadbandObj = (data) => {
	return {
		type: QUOTE_BROADBAND_OBJ,
		payload: data
	}
}

export const setUqualSearchByFlag = (flag) => {
	return {
		type: QUOTE_UQUAL_SEARCH_BY_FLAG,
		payload: flag
	}
}

export const setUqualId = (id) => {
	return {
		type: QUOTE_UQUAL_ID,
		payload: id
	}
}

export const setQuoteTermMonthToMonth = (data) => {
	return {
		type: QUOTE_TERM_MONTH_TO_MONTH,
		payload: data
	}
}

export const setQuoteTypeOfIPs = (type) => {
	return {
		type: QUOTE_TYPE_OF_IPS,
		payload: type
	}
}

export const setQuoteModem = (modem) => {
	return {
		type: QUOTE_MODEM,
		payload: modem
	}
}
export const setAssocRefId = (data) => {
	return {
		type: QUOTE_ASSOC_REF_ID,
		payload: data
	}
}

export const setTlcThisLoc = (data) => {
	return {
		type: QUOTE_TLC_THIS_LOC,
		payload: data
	}
}
export const setEntGroup = (data) => {
	return {
		type: QUOTE_ENT_GROUP,
		payload: data
	}
}
export const setExtToExt = (data) => {
	return {
		type: QUOTE_EXT_TO_EXT,
		payload: data
	}
}
export const setExtLength = (data) => {
	return {
		type: QUOTE_EXT_LENGTH,
		payload: data
	}
}
export const setPorting = (data) => {
	return {
		type: QUOTE_PORTING,
		payload: data
	}
}
export const setBtn = (data) => {
	return {
		type: QUOTE_BTN,
		payload: data
	}
}
export const setOfficeSuiteQuantities = (data) => {
	return {
		type: QUOTE_OFFICESUITE_QUANTITIES,
		payload: data
	}
}
export const setOfficeSuiteFeatures = (data) => {
	return {
		type: QUOTE_OFFICESUITE_FEATURES,
		payload: data
	}
}
export const updateFeaturePricing = (catId, itemId, mrc, nrc) => {
	return {
		type: UPDATE_FEATURE_PRICING,
		payload: { catId, itemId, mrc, nrc },
	}
}
export const setFeatureTotalMrc = (data) => {
	return {
		type: QUOTE_FEATURE_TOTAL_MRC,
		payload: data
	}
}
export const setFeatureTotalNrc = (data) => {
	return {
		type: QUOTE_FEATURE_TOTAL_NRC,
		payload: data
	}
}
export const setSubTotalMrc = (data) => {
	return {
		type: QUOTE_SUB_TOTAL_MRC,
		payload: {
			newSubTotalMrc: data
		}
	}
}
export const setSubTotalNrc = (data) => {
	return {
		type: QUOTE_SUB_TOTAL_NRC,
		payload: {
			newSubTotalNrc: data
		}
	}
}
export const setTotalMrc = (data) => {
	return {
		type: QUOTE_TOTAL_MRC,
		payload: {
			updatedTotalMrc: data
		}
	}
}
export const setTotalNrc = (data) => {
	return {
		type: QUOTE_TOTAL_NRC,
		payload: {
			updatedTotalNrc: data
		}
	}
}
