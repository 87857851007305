import React, { useEffect, useState, lazy, Suspense } from 'react';
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import style from "./Profile.module.css";
import { connect, useSelector } from 'react-redux';
import ProfileService from "../../services/ProfileServices";
import Loader from "../shared/Loader";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import axios from 'axios';
import { resetCompanyMpid, setCompanyMenu, setCompanyMpid } from "../../redux/actions/internalActions";
import {
  account_type,
  acna,
  account_vertical,
  ae,
  agent_flag,
  api_address_flag,
  ban,
  billingProfileGlobalID,
  cam,
  commsAccount,
  company,
  company_rollup,
  eis,
  email,
  ext,
  fName,
  jurisdiction,
  lName,
  nctc,
  newProfile,
  offnetVendorVisible,
  onNetOnly,
  phone,
  profileChange,
  profileUserTable,
  resetProfile,
  se,
  selectedProfile,
  unique_id,
  wf_company,
} from '../../redux';
const ProfileUsers = lazy(() => import('./ProfileUsers'));

const Profile = (props) => {
  const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
  const userAdminPermissions = Number(useSelector(state => state.userReducer.user.webaccess?.UserAdmin)) || 0;
  const orderStaffPermission = Number(useSelector(state => state.userReducer.user.webaccess?.TRANS_ORDER_STAFF)) || 0;
  const [profileSelected, setProfileSelected] = useState(false);
  const [isNewProfile, setIsNewProfile] = useState(false);
  const [aeOptions, setAeOptions] = useState({});
  const [camOptions, setCamOptions] = useState({});
  const [seOptions, setSeOptions] = useState({});
  const [loader, setLoader] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [uniqueIdField, setUniqueIdField] = useState(false);
  const agent_flag = useSelector(state => state.employeeProfile?.profile?.agent_flag);
  const api_address_flag = useSelector(state => state.employeeProfile?.profile?.api_address_flag);
  const [agentFlag, setAgentFlag] = useState('');
  const [apiAddressFlag, setApiAddressFlag] = useState('');
  const [MSG, setMSG] = useState('');
  const profileFromStore = useSelector(state => state.employeeProfile.profile);
  const selectedMpidFromStore = useSelector(state => state.internal.company);
  const [popupShow, setPopupShow] = useState(false);
  const [popUpMsg, setPopupMsg] = useState("");
  const [popUpAction, setPopUpAction] = useState("");
  const [profileUserKey, setProfileUserKey] = useState(0);

  const handleNav = (link, userId) => {
    logger.info(`In Profile,js handleNav navigating to: ${link} userId: ${userId}`);
    props.updateProfileSelectedTab("EDIT_USER");
  };

  useEffect(() => {
    if (agent_flag !== undefined) {
      setAgentFlag(agent_flag);
    }
  }, [agent_flag]);

  useEffect(() => {
    if (api_address_flag !== undefined) {
      setApiAddressFlag(api_address_flag);
    }
  }, [api_address_flag]);

  useEffect(() => {
    if (selectedMpidFromStore !== profileFromStore.selectedProfile && selectedMpidFromStore?.value !== "") {
      props.clearProfile();
      profileHandle(selectedMpidFromStore);
    }

    if (profileFromStore.selectedProfile.value !== "") {
      setProfileSelected(true);
    }

  }, [selectedMpidFromStore]);

  const getProfileData = () => {
    setLoader(true);
    ProfileService.getSelectedProfile().then(resp => {
      logger.info(JSON.stringify(resp.data));
      props.company(resp.data.company?.length > 0 ? resp.data.company : "");
      props.wf_company(resp.data.wf_company?.length > 0 ? resp.data.wf_company : "");
      props.company_rollup(resp.data.company_rollup?.length > 0 ? resp.data.company_rollup : "");
      props.lName(resp.data.lname?.length > 0 ? resp.data.lname : "");
      props.fName(resp.data.fname?.length > 0 ? resp.data.fname : "");
      props.email(resp.data.contact_email?.length > 0 ? resp.data.contact_email : "");
      props.phone(resp.data.contact_phone?.length > 0 ? resp.data.contact_phone : "");
      props.ext(resp.data.contact_ext?.length > 0 ? resp.data.contact_ext : "");
      props.jurisdiction(resp.data.jurisdiction?.length > 0 ? resp.data.jurisdiction : "");
      props.account_vertical(resp.data.account_vertical?.length > 0 ? resp.data.account_vertical : "");
      props.account_type(resp.data.acct_type?.length > 0 ? resp.data.acct_type : "");
      props.agent_flag(resp.data.agent_flag?.length > 0 ? resp.data.agent_flag : "");
      setAgentFlag(resp.data.agent_flag);
      props.api_address_flag(resp.data.api_strict_addr?.length > 0 ? resp.data.api_strict_addr : "");
      setApiAddressFlag(resp.data.api_strict_addr);
      props.ae(resp.data.nam?.length > 0 ? resp.data.nam : "");
      props.cam(resp.data.cam?.length > 0 ? resp.data.cam : "");
      props.se(resp.data.se?.length > 0 ? resp.data.se : "");
      props.onNetOnly(resp.data.onnet_only?.length > 0 ? resp.data.onnet_only : "");
      props.nctc(resp.data.nctc_customer.length > 0 ? resp.data.nctc_customer : "");
      props.eis(resp.data.eis_flag?.length > 0 ? resp.data.eis_flag : "");
      props.offnetVendorVisible(resp.data.offnet_vendor_visible?.length > 0 ? resp.data.offnet_vendor_visible : "");
      props.billingProfileGlobalID(resp.data.rc8_acct?.length > 0 ? resp.data.rc8_acct : "");
      props.commsAccount(resp.data.df_salesforce_id?.length > 0 ? resp.data.df_salesforce_id : "");
      props.ban(resp.data.cdg_ban?.length > 0 ? resp.data.cdg_ban : "");
      props.acna(resp.data.acna?.length > 0 ? resp.data.acna : "");
      props.newProfile(resp.data.create_email_sent?.length > 0 && resp.data.create_email_sent === "0");
      if (resp.data.create_email_sent === "0") {
        props.updateProfileSelectedTab("ADD_USER");
      }
      setLoader(false);
      setProfileUserKey(prevKey => prevKey + 1);
    }).catch(error => {
      logger.error("Error Getting Profiles: " + error);
      setLoader(false);
      setHasError(error);
    })
  }

  const profileHandle = () => {
    setMSG('');
    props.selectedProfile(selectedMpidFromStore);
    if (!selectedMpidFromStore) {
      props.clearProfile();
      setProfileSelected(false);
      setLoader(false);
    } else if (selectedMpidFromStore.value !== 'new') {
      setIsNewProfile(false);
      setProfileSelected(true);
      getProfileData();
    } else {
      setIsNewProfile(true);
      props.clearProfile();
      setProfileSelected(true);
      props.selectedProfile(selectedMpidFromStore);
      setLoader(true);
      ProfileService.getAeCamSeOptions().then(axios.spread(({ data: aeData }, { data: camData }, { data: seData }) => {
        logger.info({ aeData, camData, seData });
        setAeOptions(aeData);
        setCamOptions(camData);
        setSeOptions(seData);
        setLoader(false);
      })).catch(error => {
        setLoader(false);
        logger.error("Error Getting AeCamSeOptions: " + error);
      })
      setLoader(false);
    }
  }

  const submitProfile = () => {
    let validationError = false;

    if (!profileFromStore['lName'] || !profileFromStore['fName'] || !profileFromStore['email'] || !profileFromStore['phone'] ||
      !profileFromStore['ae'] || !profileFromStore['cam'] || !profileFromStore['se'] ||
      !profileFromStore['billingProfileGlobalID'] || !profileFromStore['commsAccount'] || !profileFromStore['acna']) {
      validationError = true;
    }

    if (validationError && isNewProfile) {
      setMSG('Please verify all required fields are completed')
    } else {
      const submitJSON = {
        // how will we store MPID to pass across pages and submission
        "selectedProfile": profileFromStore.selectedProfile,
        "company": profileFromStore.company,
        "wf_company": profileFromStore.wf_company,
        "company_rollup": profileFromStore.company_rollup,
        "fName": profileFromStore.fName,
        "lName": profileFromStore.lName,
        "email": profileFromStore.email,
        "phone": profileFromStore.phone,
        "ext": profileFromStore.ext,
        "jurisdiction": profileFromStore.jurisdiction,
        "account_vertical": profileFromStore.account_vertical,
        "account_type": profileFromStore.account_type,
        "agent_flag": agentFlag,
        "api_address_flag": apiAddressFlag,
        "ae": profileFromStore.ae,
        "cam": profileFromStore.cam,
        "se": profileFromStore.se,
        "fixedWirelessLayer3": profileFromStore.fixedWirelessLayer3,
        "fixedWirelessEAccess": profileFromStore.fixedWirelessEAccess,
        "onNetOnly": profileFromStore.onNetOnly,
        "nctc": profileFromStore.nctc,
        "eis": profileFromStore.eis,
        "offnetVendorVisible": profileFromStore.offnetVendorVisible,
        "billingProfileGlobalID": profileFromStore.billingProfileGlobalID,
        "commsAccount": profileFromStore.commsAccount,
        "ban": profileFromStore.ban,
        "acna": profileFromStore.acna,
        "uid": profileFromStore.unique_id
      }

      logger.info("Profile Data: " + JSON.stringify(submitJSON));
      setLoader(true);
      ProfileService.submitProfile(submitJSON).then(response => {
        logger.info('Profile Submit Response: ' + JSON.stringify(response?.data));
        logger.info('Profile Submit Result: ' + encodeURIComponent(response?.data?.result).replace(/%20/g, " "));
        if (response?.data?.result === "SUCCESS") {
          let profileList = null;
          let str = '';
          ProfileService.getProfiles().then(resp => {
            logger.info('Get Profiles: ' + encodeURIComponent(JSON.stringify(resp?.data)).replace(/%20/g, " "));
            profileList = resp?.data;
            profileList = profileList.map(item => {
              str = `${item.name}`;
              return { mpid: item.id, name: str }
            });
            props.updateCompanyMenu(profileList);
            props.updateCompanyMenu(profileList);
            props.updateCompanyMpid(response?.data?.newProfile);
            props.newProfileCreated();
            profileHandle(response?.data?.newProfile);
            setLoader(false);
          }).catch(error => {
            logger.error("Getting Profiles: " + error);
            setLoader(false);
          });
          setMSG('Success');
          if (isNewProfile) {
            props.newProfile(true);
            props.updateProfileSelectedTab("ADD_USER");
          }
        } else {
          if (response?.data?.uniqueIdField) {
            setUniqueIdField(true);
          } else {
            setUniqueIdField(false);
          }
          setMSG(response?.data?.error);
        }
        setLoader(false);
      }).catch(error => {
        logger.error("Create Profile ERROR: " + error);
        setMSG('An error has occurred.');
        setLoader(false);
        setHasError(error);
      });
    }
  }

  const inputChange = (e, field) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (field === 'agent_flag') {
      if (value) {
        setAgentFlag('1');
      } else {
        setAgentFlag('');
      }
    } else if (field === 'agent_customer_flag') {
      if (value) {
        setAgentFlag('2');
      } else {
        setAgentFlag('');
      }
    } else if (field === 'api_address_flag') {
      setApiAddressFlag(value);
    } else {
      props[field](value);
    }
  }

  const resetData = () => {
    setMSG('');
    props.clearProfile();
  }

  const cancelAction = () => {
    setPopupShow(true);
    setPopUpAction("cancel new profile");
    setPopupMsg("Are you sure you want to cancel?");
  }

  const popupOk = (action) => {
    switch (action) {
      case "cancel new profile":
        resetData();
        break;
      default:
        break;
    }
    popupCancel();
  }

  const popupCancel = () => {
    setPopupShow(false);
  }

  return (
    <>
      {hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
      <div>
        {loader ? <Loader /> : null}
        {logger.info("orderStaffPermission: " + orderStaffPermission + " userAdminPermissions: " + userAdminPermissions)}
        {loader ? null : Number(orderStaffPermission) < 1 ?
          <div className={`sub-hdr alt0`}>
            <div className="nib">
              <div className="the-notch">
                <div className="notch-fg">
                  <svg>
                    <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                  </svg>
                </div>
              </div>
            </div>
            <p>No Access to the Page</p>
          </div>
          :
          <>
            <p className='text-right'><span className={`${style.mandatoryProfile}`}>*</span> Denotes a required field</p>
            {profileSelected ?
              <>
                <div className={`sub-hdr alt0`}>
                  <div className="nib">
                    <div className="the-notch">
                      <div className="notch-fg">
                        <svg>
                          <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <h2 className={`m-0 `}>Customer Information</h2>
                </div>

                <div className='px-3'>
                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      {selectedMpidFromStore?.value === 'new' ?
                        <>
                          <div className='my-3'>
                            <label className='labelText'> COMPANY <span className={` ${style.mandatoryProfile} `}>*</span></label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['company']} onChange={(e) => {
                                inputChange(e, 'company')
                              }} />
                          </div>
                          <div className='my-3'>
                            <label className='labelText'> First Name <span className={` ${style.mandatoryProfile} `}>*</span></label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['fName']} onChange={(e) => {
                                inputChange(e, 'fName')
                              }} />
                          </div>
                          <div className='my-3'>
                            <label className='labelText'> Last Name <span className={` ${style.mandatoryProfile} `}>*</span></label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['lName']} onChange={(e) => {
                                inputChange(e, 'lName')
                              }} />
                          </div>
                          <div className='my-3'>
                            <label className='labelText'> Email <span className={` ${style.mandatoryProfile} `}>*</span></label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['email']} onChange={(e) => {
                                inputChange(e, 'email')
                              }} />
                          </div>
                          {uniqueIdField ?
                            <div className='my-3'>
                              <label className='labelText'> Unique ID <span className={` ${style.mandatoryProfile} `}>*</span></label>
                              <input type="text" className={`${style.inputFldTextProfile} `}
                                value={profileFromStore['unique_id']} onChange={(e) => {
                                  inputChange(e, 'unique_id')
                                }} />
                            </div>
                            : null}
                          <div className='d-flex'>
                            <div className='mr-3'>
                              <label className='labelText'> phone <span className={` ${style.mandatoryProfile} `}>*</span></label>
                              <input type="text"
                                className={`${style.inputFldTextProfile} ${style.inputFldPhoneProfile} `}
                                value={profileFromStore['phone']} onChange={(e) => {
                                  inputChange(e, 'phone')
                                }} />
                            </div>
                            <div className=''>
                              <label className='labelText'> Ext</label>
                              <input type="text"
                                className={`${style.inputFldTextProfile} ${style.inputFldPhoneProfile} `}
                                value={profileFromStore['ext']} onChange={(e) => {
                                  inputChange(e, 'ext')
                                }} />
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <div className='my-3'>
                            <label className='labelText'> COMPANY </label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['company']} onChange={(e) => {
                                inputChange(e, 'company')
                              }} />
                          </div>
                          <div className='my-3'>
                            <label className='labelText'> WinForce Company </label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['wf_company']} disabled="disabled" />
                          </div>
                          <div className='my-3'>
                            <label className='labelText'> Company Rollup </label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['company_rollup']} disabled="disabled" />
                          </div>
                          <div className='my-3'>
                            <label className='labelText'> Vertical </label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['account_vertical']} onChange={(e) => {
                                inputChange(e, 'account_vertical')
                              }} disabled="disabled" />
                          </div>
                          <div className='my-3'>
                            <label className='labelText'> Account Type </label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['account_type']} disabled="disabled" />
                          </div>
                          <div className='my-3'>
                            <label className='labelText'> Jurisdictional Traffic Certification </label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['jurisdiction']} onChange={(e) => {
                                inputChange(e, 'jurisdiction')
                              }} disabled="disabled" />
                          </div>
                          {profileFromStore['account_vertical'] === 'Global Accounts' ?
                            <>
                              <div className='my-3'>
                                <input type="checkbox" className="" id="agent" checked={agentFlag === '1'}
                                  onChange={(e) => {
                                    inputChange(e, 'agent_flag')
                                  }} />
                                <label className={`${style.agentLabelTextProfile}`}
                                  htmlFor="agent">Agent</label>
                              </div>
                              <div className='my-3'>
                                <input type="checkbox" className="" id="agent_customer" checked={agentFlag === '2'}
                                  onChange={(e) => {
                                    inputChange(e, 'agent_customer_flag')
                                  }} />
                                <label className={`${style.agentLabelTextProfile}`}
                                  htmlFor="agent_customer">Agent Customer</label>
                              </div>
                            </>
                            : null}
                          <div className='my-3'>
                            <input type="checkbox" className="" id="api_address_flag"
                              checked={apiAddressFlag} onChange={(e) => {
                                inputChange(e, 'api_address_flag')
                              }} />
                            <label className={`${style.agentLabelTextProfile}`}
                              htmlFor="api_address_flag">Use Strict Address Checks for Quote API</label>
                          </div>
                        </>
                      }
                    </div>

                    <div className='col-12 col-md-6'>
                      {selectedMpidFromStore?.value === 'new' ?
                        <>
                          <div className={`my-3 rel ${style.selectWrap} `}>
                            <label className='labelText'> AE <span className={` ${style.mandatoryProfile} `}>*</span></label>
                            <select className={`form-control  ${style.selectFld}`}
                              value={profileFromStore['ae']} onChange={(e) => {
                                inputChange(e, 'ae')
                              }}>
                              {aeOptions?.length > 0 ?
                                <>
                                  {aeOptions.map(item => (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                                :
                                null}
                            </select>
                          </div>

                          <div className={`my-3 rel ${style.selectWrap} `}>
                            <label className='labelText'> CAM <span className={` ${style.mandatoryProfile} `}>*</span></label>
                            <select className={`form-control  ${style.selectFld}`}
                              value={profileFromStore['cam']} onChange={(e) => {
                                inputChange(e, 'cam')
                              }}>
                              {camOptions?.length > 0 ?
                                <>
                                  {camOptions.map(item => (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                                :
                                null}
                            </select>
                          </div>

                          <div className={`my-3 rel ${style.selectWrap} `}>
                            <label className='labelText'> SE<span className={` ${style.mandatoryProfile} `}>*</span></label>
                            <select className={`form-control  ${style.selectFld}`}
                              value={profileFromStore['se']} onChange={(e) => {
                                inputChange(e, 'se')
                              }}>
                              {seOptions?.length > 0 ?
                                <>
                                  {seOptions.map(item => (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                                :
                                null}
                            </select>
                          </div>

                        </>
                        :
                        <>
                          <div className='my-3'>
                            <label className='labelText'> AE </label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['ae']} onChange={(e) => {
                                inputChange(e, 'ae')
                              }} disabled="disabled" />
                          </div>
                          <div className='my-3'>
                            <label className='labelText'> CAM </label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['cam']} onChange={(e) => {
                                inputChange(e, 'cam')
                              }} disabled="disabled" />
                          </div>
                          <div className='my-3'>
                            <label className='labelText'> SE </label>
                            <input type="text" className={`${style.inputFldTextProfile} `}
                              value={profileFromStore['se']} onChange={(e) => {
                                inputChange(e, 'se')
                              }} disabled="disabled" />
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>

                <div className={`sub-hdr alt0`}>
                  <div className="nib">
                    <div className="the-notch">
                      <div className="notch-fg">
                        <svg>
                          <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <h2 className={` m-0 `}>Quoting Options</h2>
                </div>

                <div className={`my-3 ${style.quotingOptionProfile}`}>
                  <div className='row ml-2'>
                    <div className="mx-2">
                      <div className="">
                        <input type="checkbox" className="" id="noNet"
                          checked={profileFromStore['onNetOnly']} onChange={(e) => {
                            inputChange(e, 'onNetOnly')
                          }} />
                        <label className={`${style.labelText}`} htmlFor="noNet">OnNet Only</label>
                      </div>
                    </div>
                    {selectedMpidFromStore?.value !== 'new' ?
                      <>
                        <div className="mx-2">
                          <div className="">
                            <input type="checkbox" className="" id="NCTC"
                              checked={profileFromStore['nctc']} onChange={(e) => {
                                inputChange(e, 'nctc')
                              }} disabled="disabled" />
                            <label className={`${style.labelText}`} htmlFor="NCTC">NCTC</label>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div className="mx-2">
                          <div className="">
                            <input type="checkbox" className="" id="NCTC"
                              checked={profileFromStore['nctc']} onChange={(e) => {
                                inputChange(e, 'nctc')
                              }} />
                            <label className={`${style.labelText}`} htmlFor="NCTC">NCTC</label>
                          </div>
                        </div>
                      </>
                    }
                    <div className="mx-2">
                      <div className="">
                        <input type="checkbox" className="" id="EIS" checked={profileFromStore['eis']}
                          onChange={(e) => {
                            inputChange(e, 'eis')
                          }} />
                        <label className={`${style.labelText}`} htmlFor="EIS">EIS</label>
                      </div>
                    </div>
                    <div className="mx-2">
                      <div className="">
                        <input type="checkbox" className="" id="ovv"
                          checked={profileFromStore['offnetVendorVisible']} onChange={(e) => {
                            inputChange(e, 'offnetVendorVisible')
                          }} />
                        <label className={`${style.labelText}`} htmlFor="ovv">OffNet Vendor Visible</label>
                      </div>
                    </div>
                  </div>
                </div>

                {selectedMpidFromStore?.value === 'new' ?
                  <>
                    <div className={`sub-hdr alt0`}>
                      <div className="nib">
                        <div className="the-notch">
                          <div className="notch-fg">
                            <svg>
                              <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <h2 className={` m-0 `}>Accounts</h2>
                    </div>

                    <div className='my-3 px-3'>
                      <div className='row'>
                        <div className='col-12 col-sm-12 col-md-4'>
                          <label className='labelText'> Billing Profile global ID <span className={` ${style.mandatoryProfile} `}>*</span></label>
                          <input type="text" className={`${style.inputFldTextProfile} w-100 `}
                            value={profileFromStore['billingProfileGlobalID']} onChange={(e) => {
                              inputChange(e, 'billingProfileGlobalID')
                            }} />
                        </div>
                        <div className='col-12 col-sm-12 col-md-4'>
                          <label className='labelText'> Comms Account <span className={` ${style.mandatoryProfile} `}>*</span></label>
                          <input type="text" className={`${style.inputFldTextProfile} w-100 `}
                            value={profileFromStore['commsAccount']} onChange={(e) => {
                              inputChange(e, 'commsAccount')
                            }} />
                        </div>
                        <div className='col-12 col-sm-12 col-md-4'>
                          <label className='labelText'> CDG BAN (ILEC BB) </label>
                          <input type="text" className={`${style.inputFldTextProfile} w-100 `}
                            value={profileFromStore['ban']} onChange={(e) => {
                              inputChange(e, 'ban')
                            }} />
                        </div>
                        <div className='col-12 col-sm-12 col-md-4'>
                          <label className='labelText'> ACNA<span className={` ${style.mandatoryProfile} `}>*</span></label>
                          <input type="text" className={`${style.inputFldTextProfile} w-100 `}
                            value={profileFromStore['acna']} onChange={(e) => {
                              inputChange(e, 'acna')
                            }} />
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className={`sub-hdr alt0`}>
                      <div className="nib">
                        <div className="the-notch">
                          <div className="notch-fg">
                            <svg>
                              <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <h2 className={` m-0 `}>Accounts</h2>
                    </div>

                    <div className='my-3 px-3'>
                      <div className='row'>
                        <div className='col-12 col-sm-12 col-md-4'>
                          <label className='labelText'> Billing Profile global ID </label>
                          <input type="text" className={`${style.inputFldTextProfile} w-100 `}
                            value={profileFromStore['billingProfileGlobalID']} onChange={(e) => {
                              inputChange(e, 'billingProfileGlobalID')
                            }} />
                        </div>
                        <div className='col-12 col-sm-12 col-md-4'>
                          <label className='labelText'> Comms Account </label>
                          <input type="text" className={`${style.inputFldTextProfile} w-100 `}
                            value={profileFromStore['commsAccount']} onChange={(e) => {
                              inputChange(e, 'commsAccount')
                            }} />
                        </div>
                        <div className='col-12 col-sm-12 col-md-4'>
                          <label className='labelText'> CDG BAN (ILEC BB) </label>
                          <input type="text" className={`${style.inputFldTextProfile} w-100 `}
                            value={profileFromStore['ban']} onChange={(e) => {
                              inputChange(e, 'ban')
                            }} />

                        </div>
                      </div>
                    </div>
                  </>
                }

                {MSG?.length ?
                  <div>
                    <p className={`text-center my-4 ${MSG === 'Success' ? 'text-success' : 'text-danger'} `}>{MSG}</p>
                  </div> : null}

                <div className='text-center mt-4'>
                  <button className={` ico-button light-button  ${style.cancelBtn} mr-4`} onClick={() => {
                    cancelAction()
                  }}>
                    Cancel
                    <svg className="ico-x">
                      <path
                        d="M18,17l-1,1L9,10,1,18,0,17,8,9,0,1,1,0,9,8l8-8,1,1L10,9Z"></path>
                    </svg>
                  </button>

                  <button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn} `}
                    onClick={() => {
                      submitProfile()
                    }}>
                    Submit
                    <svg className="ico light-ico ico-arrow ml-2">
                      <path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
                    </svg>
                  </button>
                </div>

                {userAdminPermissions > 0 && profileSelected && selectedMpidFromStore?.value !== 'new' && (
                  <Suspense fallback={<Loader />}>
                    <ProfileUsers key={profileUserKey} handleNav={handleNav} onUserIdSelected={props.onUserIdSelected} />
                  </Suspense>
                )}

                {popupShow &&
                  <div>
                    <div className={`${style.overlyProfile}`}></div>
                    <div className="modal fade show d-block">
                      <div className={`modal-dialog modal-dialog-centered modal-sm`}>
                        <div className="modal-content">
                          <div className="modal-body">
                            {popUpMsg}
                          </div>
                          <div className="modal-footer d-block text-center">
                            <button type="button" className="btn-style btn-theme" data-dismiss="modal"
                              onClick={() => popupOk(popUpAction)}>Ok
                            </button>
                            <button type="button" className="btn-style btn-cancel" data-dismiss="modal"
                              onClick={() => popupCancel(popUpAction)}>Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </>
              :
              null
            }
          </>
        }
      </div>
    </>
  );
}

const mapDispatchToProps = {
  account_type: account_type,
  acna: acna,
  account_vertical: account_vertical,
  ae: ae,
  agent_flag: agent_flag,
  api_address_flag: api_address_flag,
  ban: ban,
  billingProfileGlobalID: billingProfileGlobalID,
  cam: cam,
  clearCompanyMpid: resetCompanyMpid,
  clearProfile: resetProfile,
  commsAccount: commsAccount,
  company: company,
  company_rollup: company_rollup,
  eis: eis,
  email: email,
  ext: ext,
  fName: fName,
  jurisdiction: jurisdiction,
  lName: lName,
  nctc: nctc,
  newProfile: newProfile,
  offnetVendorVisible: offnetVendorVisible,
  onNetOnly: onNetOnly,
  phone: phone,
  profileUserTable: profileUserTable,
  se: se,
  selectedProfile: selectedProfile,
  unique_id: unique_id,
  updateCompanyMenu: setCompanyMenu,
  updateCompanyMpid: setCompanyMpid,
  updateProfileSelectedTab: profileChange,
  wf_company: wf_company,
}
export default connect(null, mapDispatchToProps)(Profile);