// CustomDatePicker.js
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

// Function to calculate holidays
const calculateHolidays = (year) => {
  const holidays = [];
  // New Year's Day
  holidays.push(dayjs(`${year}-01-01`));
  // Martin Luther King Jr. Day (third Monday in January)
  holidays.push(dayjs(`${year}-01-01`).startOf('month').day(15).add(1, 'week'));
  // Memorial Day (last Monday in May)
  holidays.push(dayjs(`${year}-05-31`).day(-1)); // Last Monday in May
  // Independence Day
  holidays.push(dayjs(`${year}-07-04`));
  // Labor Day (first Monday in September)
  holidays.push(dayjs(`${year}-09-01`).startOf('month').day(1));
  // Thanksgiving Day (fourth Thursday in November)
  holidays.push(dayjs(`${year}-11-01`).startOf('month').day(22).add(3, 'week'));
  // Christmas Day
  holidays.push(dayjs(`${year}-12-25`));
  return holidays;
};

// Function to get holidays for a range of years
const getHolidaysForYears = (startYear, endYear) => {
  const allHolidays = [];
  for (let year = startYear; year <= endYear; year++) {
    allHolidays.push(...calculateHolidays(year));
  }
  return allHolidays;
};

const holidays = getHolidaysForYears(2025, 2035);

// Function to check if a date is a holiday
const isHoliday = (date) => {
  return holidays.some(holiday => date.isSame(holiday, 'day'));
};

// Function to disable weekends and holidays
const shouldDisableDate = (date) => {
  const day = date.day();
  // Disable weekends (Saturday=6, Sunday=0) and holidays
  return day === 0 || day === 6 || isHoliday(date);
};

// Styled component for DatePicker
const CustomStyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none', // Remove all borders
    },
    '&:hover fieldset': {
      border: 'none', // Remove hover borders
    },
    '&.Mui-focused fieldset': {
      border: 'none', // Remove focused borders
    },
    '& input': {
      borderRadius: 0, // Ensure no border radius
    },
  },
  '& .MuiInputBase-root': {
    border: 0,
    borderBottom: '1px solid #999',
    color: '#75787b',
    height: '30px',
    fontSize: '16px',
  },
  '& .css-1rq1mwr': { // Still using dynamically generated class
    width: '100% !important',
  },
}));


const CustomDatePicker = ({ value, onChange, id, name, className }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div>
      <CustomStyledDatePicker
        shouldDisableDate={shouldDisableDate}
        value={value ? dayjs(value) : null}
        onChange={(newValue) => onChange(newValue ? newValue.format('YYYY-MM-DD') : '')}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div>
            <input
              ref={inputRef}
              {...inputProps}
              id={id}
              name={name}
              type="date"
              className={className}
            />
            {InputProps?.endAdornment}
          </div>
        )}
      />
    </div>
  </LocalizationProvider>
);

export default CustomDatePicker;