import {SET_USER, PAGE_ACCESS, LOG_OUT, USER_MANAGE, CLEAR_REDUX_STORE} from "./types/actionTypes"

export const setUser = (userObj) => {
    return {
        type: SET_USER,
        payload: userObj
    }
}

export const setPageAccess  = (accessMap) => {
    return {
        type: PAGE_ACCESS,
        payload: accessMap
    }
}

export const logOut = () => {
    return {
        type: LOG_OUT
    }
}

export const userManage = (userObj) => {
    return {
        type: USER_MANAGE,
        payload: userObj
    }
}

export const clearReduxStore = () => {
    return {
        type: CLEAR_REDUX_STORE
    }
}
