import React, { useEffect, useState, useRef } from "react";
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import { connect, useSelector } from 'react-redux';
import { profileChange } from "../../redux";
import ViewportWarning from "../shared/ViewportWarning";
import CompanyDropdown from "../shared/CompanyDropdown";
import TabsEmployee from "./TabsEmployee";
import Profile from "./Profile";
import Accounts from "./Accounts";
import Products from "./Products";
import UserInfoSection from "../login/UserInfoSection";
import APIProfile from "./APIProfile";
import Nnis from "./Nnis";
import History from "./History";

const Employee = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL });
    const [userId, setUserId] = useState("");
    const profileChangeTabFromStore = useSelector(state => state.employeeProfile?.profile?.selectedProfileTab);
    const topOfPageRef = useRef(null);

    useEffect(() => {
        // Set default tab to "Profile" on component mount
        props.updateProfileSelectedTab("Profile");
    }, []); // Ensure this hook runs only once when the component mounts

    const handleUserIdSelection = (selectedUserId) => {
        setUserId(selectedUserId);
        logger.info(`User ID selected: ${selectedUserId}`);
    };

    const changeTab = (e, userIdFromProfile) => {
        logger.info('changeTab function inside of Employee.js');
        logger.info(`Change Tab: ${e} UserId: ${userIdFromProfile}`);
        props.updateProfileSelectedTab(e);
        if (e === "EDIT_USER") {
            setUserId(userIdFromProfile);
        }
    };

    useEffect(() => {
        topOfPageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, [profileChangeTabFromStore]);

    return (
        <div className="midsection employee abs z1">
            <div className="rel">
                <ViewportWarning />
                <div className="wrap">
                    <h1 className="page-title">Customer Profile</h1>
                    <div ref={topOfPageRef} />
                    <CompanyDropdown area={"Profile"} tab={profileChangeTabFromStore}></CompanyDropdown>
                    <div className="section">
                        <TabsEmployee handleNav={(e) => changeTab(e, userId)} tab={profileChangeTabFromStore} />
                        <div className="content">
                            {profileChangeTabFromStore === "Profile" ?
                                <Profile handleNav={(e, userInfoRow) => changeTab(e, userInfoRow)} onUserIdSelected={handleUserIdSelection} />
                                : null}
                            {profileChangeTabFromStore === "Accounts" ? <Accounts /> : null}
                            {profileChangeTabFromStore === "Products" ? <Products /> : null}
                            {profileChangeTabFromStore === "NNIs" ? <Nnis /> : null}
                            {profileChangeTabFromStore === "API" ? <APIProfile /> : null}
                            {profileChangeTabFromStore === "ADD_USER" ? <UserInfoSection handleNav={(e) => changeTab(e)} /> : null}
                            {profileChangeTabFromStore === "EDIT_USER" ? <UserInfoSection userIdFromProfile={userId} handleNav={(e) => changeTab(e)} /> : null}
                            {profileChangeTabFromStore === "History" ? <History /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateProfileSelectedTab: profileChange
};

export default connect(null, mapDispatchToProps)(Employee);