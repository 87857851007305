import { useState } from "react";
import { useSelector } from "react-redux";
import style from "../Employee.module.css";
import CancelModal from "./CancelModal";
import { SelectComponent } from "../../components/SelectComponent";
import { InputComponent } from "../../components/InputComponent";
import StaffManagementService from "../../../services/StaffManagementService";
import { ConsoleLogger } from "../../../logger/ConsoleLogger";
import Loader from "../../shared/Loader";

const AddUser = ({ setFormValues, setNavLink }) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [cancelWindow, setCancelWindow] = useState(false);
    const selectedUser = useSelector(state => state.userReducer.user.webaccess);

    // Rename the local state and its setter
    const [localFormValues, setLocalFormValues] = useState({
        uid: "",
        fname: "",
        lname: "",
        email: "",
        phone: "",
        is_active: "1",
        webaccess: {
            UserAdmin: "0",
            EmployeeAdmin: "0",
            RemedyTicket: "0",
            TRANS_QUOTE: "0",
            TRANS_ORDER: "0",
            TRANS_INVOICE: "0",
            BULK_AV: "0",
            PROFILE: "0",
            POP_UTILS: "0",
            ASR_MAP: "0"
        },
        role: "0",
        level: "1"
    });

    const webaccessOptions = [
        { label: 'No Access', value: 0 },
        { label: 'Read Only', value: 1 },
        { label: 'Write Access', value: 2 },
        { label: 'Full Access', value: 3 }
    ];

    const roleTypes = [
        { label: 'System User', value: 'SYS' },
        { label: 'Vice President', value: 'VP' },
        { label: 'Executive Vice President', value: 'EVP' },
        { label: 'Chief Financial Officer', value: 'CFO' },
        { label: 'Account Executive', value: 'NAM' },
        { label: 'Sales Engineer', value: 'SE' },
        { label: 'Technical Design Specialist', value: 'TDS' },
        { label: 'Customer Account Manager', value: 'CAM' },
        { label: 'M6 Account Team', value: 'M6' },
        { label: 'VOX Account Team', value: 'VOX' },
        { label: 'Colocation Team', value: 'COLO' },
        { label: 'ICB Pricing Team', value: 'ICB' },
        { label: 'Super User', value: 'SUPER' },
        { label: 'Off Premises', value: 'OP' }
    ];

    const levels = [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
    ];

    const addCallback = (e) => {
        const { name, value } = e.target;
        if (name in localFormValues.webaccess) {
            setLocalFormValues({
                ...localFormValues,
                webaccess: { ...localFormValues.webaccess, [name]: value }
            });
        } else {
            setLocalFormValues({ ...localFormValues, [name]: value });
        }
    };

    const submitAction = () => {
        setErrorMsg('');
        setSuccessMsg('');
        const validation = ValidationRule();
        if (Object.keys(validation).length === 0) {
            const submitJSON = {
                action: "add",
                uid: localFormValues.uid,
                fname: localFormValues.fname,
                lname: localFormValues.lname,
                email: localFormValues.email,
                phone: localFormValues.phone,
                is_active: localFormValues.is_active,
                webaccess: {
                    UserAdmin: localFormValues.webaccess.UserAdmin,
                    EmployeeAdmin: localFormValues.webaccess.EmployeeAdmin,
                    RemedyTicket: localFormValues.webaccess.RemedyTicket,
                    TRANS_QUOTE: localFormValues.webaccess.TRANS_QUOTE,
                    TRANS_ORDER: localFormValues.webaccess.TRANS_ORDER,
                    TRANS_INVOICE: localFormValues.webaccess.TRANS_INVOICE,
                    BULK_AV: localFormValues.webaccess.BULK_AV,
                    PROFILE: localFormValues.webaccess.PROFILE,
                    POP_UTILS: localFormValues.webaccess.POP_UTILS,
                    ASR_MAP: localFormValues.webaccess.ASR_MAP
                },
                role: localFormValues.role,
                level: localFormValues.level
            };

            console.log("Submitting JSON for Add User: ", JSON.stringify(submitJSON, null, 2));
            setLoader(true);
            StaffManagementService.getUserData(submitJSON)
                .then(response => {
                    if (response.data.result === "FAIL") {
                        const errorMessage = response.data.error || 'Failed to add user information.';
                        logger.error("Add failed: " + errorMessage);
                        setErrorMsg(errorMessage);
                    } else {
                        logger.info("Add successful: " + JSON.stringify(response.data));
                        const successMessage = response.data.update || 'User added successfully!';
                        setSuccessMsg(successMessage);
                        // Set the form values to the new user and navigate to EditUser
                        setFormValues(localFormValues);
                        setNavLink('EditUser');
                    }
                    setLoader(false);
                })
                .catch(error => {
                    console.error("Error adding user:", error);
                    setLoader(false);
                });
        }
    };

    const ValidationRule = () => {
        const error = {};
        if (!localFormValues.uid) {
            error.uid = "Employee ID is Required";
        }
        if (!localFormValues.fname) {
            error.fname = "First Name is Required";
        }
        if (!localFormValues.lname) {
            error.lname = "Last Name is Required";
        }
        if (!localFormValues.email) {
            error.email = "Email is Required";
        }
        if (!localFormValues.phone) {
            error.phone = "Phone Number is Required";
        }
        if (!localFormValues.role) {
            error.role = "Role Type is Required";
        }
        if (!localFormValues.is_active) {
            error.is_active = "Status is Required";
        }
        Object.keys(localFormValues.webaccess).forEach(key => {
            if (!localFormValues.webaccess[key]) {
                error[key] = `${key.replace(/([A-Z])/g, ' $1')} is Required`;
            }
        });
        setFormErrors(error);
        return error;
    };

    const cancelAction = () => {
        setCancelWindow(true);
    };

    const cancelEmit = (action) => {
        if (action === 'Ok') {
            setCancelWindow(false);
            setLocalFormValues({
                uid: "",
                fname: "",
                lname: "",
                email: "",
                phone: "",
                is_active: "1",
                webaccess: {
                    UserAdmin: "0",
                    EmployeeAdmin: "0",
                    RemedyTicket: "0",
                    TRANS_QUOTE: "0",
                    TRANS_ORDER: "0",
                    TRANS_INVOICE: "0",
                    BULK_AV: "0",
                    PROFILE: "0",
                    POP_UTILS: "0",
                    ASR_MAP: "0"
                },
                role: "0",
                level: "0"
            });
            setFormErrors({});
        } else {
            setCancelWindow(false);
        }
    };

    return (
        <div className="addUserPage">
            {loader ? <Loader /> : null}
            {Number(selectedUser?.EmployeeAdmin) >= 3 ? (
                <>
                    <p className="text-secondary text-right mb-5"><span className={style.mandatory}>*</span> Denotes a required field</p>
                    <div className="row">
                        <div className="col-md-5 col-sm-12">
                            <InputComponent label='Employee Id' name="uid" className="w-100" value={localFormValues.uid} error={formErrors.uid} callback={addCallback} />
                            <div className="d-flex justify-content-between">
                                <InputComponent label='First Name' name="fname" className={style.w45} value={localFormValues.fname} error={formErrors.fname} callback={addCallback} />
                                <InputComponent label='Last Name' name="lname" className={style.w45} value={localFormValues.lname} error={formErrors.lname} callback={addCallback} />
                            </div>
                            <InputComponent label='Email' name="email" className="w-100" value={localFormValues.email} error={formErrors.email} callback={addCallback} />
                            <InputComponent label='Phone Number' name="phone" className="w-100" value={localFormValues.phone} error={formErrors.phone} callback={addCallback} />
                            <div className="d-flex justify-content-between">
                                <SelectComponent label="Role Type" name="role" className={style.w45} value={localFormValues.role} options={roleTypes} error={formErrors.role} callback={addCallback} />
                                <SelectComponent label="level" name="level" className={style.w45} value={localFormValues.level} options={levels} error={formErrors.level} callback={addCallback} />
                            </div>
                            <div className="my-5">
                                <label className="mr-5">Status <span className={` ${style.mandatory} `}>*</span></label>
                                <input type="radio" name="is_active" id="is_active" value='1' checked={localFormValues.is_active === '1'} onChange={addCallback}></input>
                                <label className="mr-4 text-secondary" htmlFor="active">Active</label>

                                <input type="radio" name="is_active" id="inActive" value='0' checked={localFormValues.is_active === '0'} onChange={addCallback}></input>
                                <label className="text-secondary" htmlFor="inActive">InActive</label>
                                <p className={style.errorMsg}>{formErrors.is_active}</p>
                            </div>
                        </div>

                        <div className="col-md-7 col-sm-12">
                            <label className="d-block w-100 text-center mb-5">Web Access</label>
                            <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                                <SelectComponent label="Web Customer Administration" name="webCustomerAdmin" className={style.w45} value={localFormValues.webaccess.webCustomerAdmin} options={webaccessOptions} error={formErrors.webCustomerAdmin} callback={addCallback} />
                                <SelectComponent label="Web Employee Administration" name="webEmployeeAdmin" className={style.w45} value={localFormValues.webaccess.webEmployeeAdmin} options={webaccessOptions} error={formErrors.webEmployeeAdmin} callback={addCallback} />
                            </div>
                            <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                                <SelectComponent label="Trouble Tickets" name="RemedyTicket" className={style.w45} value={localFormValues.webaccess.RemedyTicket} options={webaccessOptions} error={formErrors.RemedyTicket} callback={addCallback} />
                                <SelectComponent label="Invoice System" name="TRANS_INVOICE" className={style.w45} value={localFormValues.webaccess.TRANS_INVOICE} options={webaccessOptions} error={formErrors.TRANS_INVOICE} callback={addCallback} />
                            </div>
                            <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                                <SelectComponent label="Transport Quote" name="TRANS_QUOTE" className={style.w45} value={localFormValues.webaccess.TRANS_QUOTE} options={webaccessOptions} error={formErrors.TRANS_QUOTE} callback={addCallback} />
                                <SelectComponent label="Transport Order" name="TRANS_ORDER" className={style.w45} value={localFormValues.webaccess.TRANS_ORDER} options={webaccessOptions} error={formErrors.TRANS_ORDER} callback={addCallback} />
                            </div>
                            <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                                <SelectComponent label="Profile" name="PROFILE" className={style.w45} value={localFormValues.webaccess.PROFILE} options={webaccessOptions} error={formErrors.PROFILE} callback={addCallback} />
                                <SelectComponent label="POP Utils" name="POP_UTILS" className={style.w45} value={localFormValues.POP_UTILS} options={webaccessOptions} error={formErrors.POP_UTILS} callback={addCallback} />
                            </div>
                            <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                                <SelectComponent label="Bulk AV" name="BULK_AV" className={style.w45} value={localFormValues.webaccess.BULK_AV} options={webaccessOptions} error={formErrors.BULK_AV} callback={addCallback} />
                                <SelectComponent label="ASR Map" name="ASR_MAP" className={style.w45} value={localFormValues.webaccess.ASR_MAP} options={webaccessOptions} error={formErrors.ASR_MAP} callback={addCallback} />
                            </div>
                        </div>
                    </div>
                    <div className="text-center my-5">
                        {errorMsg?.length > 0 ? <p className="text-danger text-center">{errorMsg}</p> : null}
                        {successMsg.length ? <p className={`text-center text-success`}>{successMsg}</p> : null}
                    </div>
                    <div className="text-center my-5">
                        <button type="button" className={`ico-button lhs light-button ml-3 ${style.cancelBtn}`} onClick={cancelAction}>
                            Cancel
                        </button>

                        <button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn}`} onClick={submitAction}>
                            Submit
                        </button>
                    </div>
                    {cancelWindow ? <CancelModal cancelEmit={cancelEmit} /> : null}
                </>
            ) : (
                <p className="text-danger text-center">No Access</p>
            )}
        </div>
    );
};

export default AddUser;