import style from "./QuoteCreate.module.css";
import { useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import Loader from '../shared/Loader';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import BroadbandHelper from "../../services/BroadbandServices";
import UqualAddress from "./UqualAddress";
import Term from "./Term";
import {setQuoteBW, setQuoteTypeOfIPs, setQuoteIps, setQuoteModem} from '../../redux/actions/quoteActions';

const Broadband = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }),[]);
	const [loader, setLoader] = useState(false);
	const [bandwidthOptions, setBandwidthOptions] = useState([]);
	const [modemOptions, setModemOptions] = useState([]);

	const selectedKineticBroadbandValues = useSelector(state => state.quote.quoteValues);
	const selectedUqualBroadbandValues = useSelector(state => state.quote.quoteValues.kineticBroadbandObj);

	useEffect(() => {
		if((props.stepCode === 3) && (props.section === "bandwidth")){
			setLoader(true);
			BroadbandHelper.getKineticBroadbandBWs(selectedUqualBroadbandValues?.bandwidthId, selectedUqualBroadbandValues?.ranged).then((response) => {
				logger.info(response.data);
				setBandwidthOptions(response.data);
				setLoader(false);
			}).catch(error => {
				setLoader(false);
				logger.error(`Error getting Kinetic Broadband BWs: ${error}`);
			})
		}
	},[logger, props.section, props.stepCode, selectedUqualBroadbandValues?.bandwidthId, selectedUqualBroadbandValues?.ranged])

	useEffect(() => {
		if((props.stepCode === 3) && props.section === "Options"){
			BroadbandHelper.getModemOptions().then((response) => {
				logger.info(response.data);
				setModemOptions(response.data);
			}).catch(error => {
				logger.error(`Error getting modem ids: ${error}`);
			})
		}
	},[logger, props.section, props.stepCode])

	const ipTypeHandler = (event) => {
		//clearing any ip selected if the type of ip changes
		props.updateKineticBroadbandIps("");
		props.updateKineticBroadbandTypeOfIps(event.target.value);
	}

	return (
		<>
			{loader ? <Loader /> : null}
			{props.stepCode === 2 ?
				<UqualAddress ilecBroadbandRedirect={props.ilecBroadbandRedirect} /> : null
			}
			{(props.stepCode === 3) && (props.section === "term") ?
				<Term product={"BROADBAND"} /> : null
			}
			{(props.stepCode === 3) && (props.section === "bandwidth") ?
				<div className={style.bandwidthWrap}>
					<div className={`row ${style.rowGrp} `}>
						<div className="col-6 col-sm-4 col-md-3">
							<label className={style.labelSelectRequiredFld}>Loop Bandwidth<span className={style.mandatory}>*</span></label>
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw" value={selectedKineticBroadbandValues.bw} onChange={(event) => props.updateKineticBroadbandBw(event.target.value)}>
								{bandwidthOptions.map((option) => {
									return <option value={option.id}>{option.label}</option>
								})}
							</select>
						</div>
					</div>
				</div> : null
			}
			{(props.stepCode === 3) && (props.section === "productConfig") ?
				<>
					<div className="col-12 col-sm-4 col-md-3 mb-5">
						<label className={style.labelSelectRequiredFld}>IP Addressing<span className={style.mandatory}>*</span></label>
						<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="typeOfIps" value={selectedKineticBroadbandValues.typeOfIps} onChange={(event) => ipTypeHandler(event)}>
							<option value=""></option>
							<option value="dynamic">Dynamic</option>
							<option value="static">Static</option>
						</select>
					</div>
					<div className="col-12 col-sm-4 col-md-3 mb-5">
						<label className={style.labelSelectRequiredFld}>IPs<span className={style.mandatory}>*</span></label>
						<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="ips" value={selectedKineticBroadbandValues.ips} onChange={(event) => props.updateKineticBroadbandIps(event.target.value)}>
							<option value=""></option>
							{selectedKineticBroadbandValues.typeOfIps === "dynamic" ?
								<option value="1">1 IP Addresses (/31 Subnet)</option> : null
							}
							{selectedKineticBroadbandValues.typeOfIps === "static" ?
								<>
									<option value="2">2 Wholesale Static IP (/30 Subnet)</option>
									<option value="6">6 Wholesale Static IP (/29 Subnet)</option>
								</> : null
							}
						</select>
					</div>
				</> : null
			}
			{(props.stepCode === 3) && (props.section === "Options") ?
				<>
					<div className={`row ${style.rowGrp} `}>
						<div className="col-12 col-sm-6 col-md-6 mb-5">
							<label className={style.labelSelectRequiredFld}>Equipment<span className={style.mandatory}>*</span></label>
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="modemId" value={selectedKineticBroadbandValues.modem} onChange={(event) => props.updateKineticBroadbandModemId(event.target.value)}>
								<option value=""></option>
								{modemOptions.map((option) => {
									return <option key={option.id} value={option.id}>{option.label}</option>
								})}
							</select>
						</div>
					</div>
				</> : null
			}
		</>
	)


}


const mapDispatchToProps = {
	updateKineticBroadbandBw: setQuoteBW,
	updateKineticBroadbandTypeOfIps: setQuoteTypeOfIPs,
	updateKineticBroadbandIps: setQuoteIps,
	updateKineticBroadbandModemId: setQuoteModem
}

export default connect(null, mapDispatchToProps)(Broadband);
