import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation, Link } from 'react-router-dom'; // Import Link from react-router-dom
import POPServices from "../../services/POPService";
import PermissionService from "../../services/InternalQuoteService";
import Loader from "../shared/Loader";
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import { Checkbox, FormControlLabel } from "@mui/material";
import style from "./POPViewer.module.css";
import XLSXExport from "../../utils/XLSXExport"; // Importing the export utility
import CustomToolbar from '../components/tables/CustomToolbar';

const POPViewer = () => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const location = useLocation();
    const selectedUser = useSelector(state => state.userReducer.user.webaccess);
    const successMessage = location.state?.successMessage;
    const duplicateData = location.state?.duplicateData; // Retrieve the duplicate data
    const [popData, setPopData] = useState([]);
    const [exportData, setExportData] = useState([]); // This is with address broken up
    const [loader, setLoader] = useState(false);
    const [errorMSG, setErrorMSG] = useState('');
    const [pageSize, setPageSize] = useState(25);
    const [showAll, setShowAll] = useState(false); // State to toggle active/all records
    const [aeUser, setAeUser] = useState(false); // AE/NAM
    const [systemSuperUser, setSystemSuperUser] = useState(false);
    const [filterModel, setFilterModel] = useState({
        items: [],
    });
    const [permissionChecked, setPermissionChecked] = useState(false); // New state for permission check
    const localURL = "iconnect-local.windstreamwholesale.com";
    const devURL = "iconnect-dev.windstreamwholesale.com";
    const uatURL = "iconnect-uat.windstreamwholesale.com";

    useEffect(() => {
        logger.info("Calling to get systemsPermissionCheck");
        PermissionService.permissionCheck().then(response => {
            logger.info("Permissions Check: " + JSON.stringify(response?.data));
            if (response?.data?.userType === "NAM") {
                setAeUser(true);
            }
            if (response?.data?.system === 1 || response?.data?.super === 1) {
                setSystemSuperUser(true);
            }
            setPermissionChecked(true);
        }).catch(error => {
            logger.error("Error getting system user permission " + error);
            setLoader(false);
        })
    }, []);

    // Display duplicate data if present
    useEffect(() => {
        if (duplicateData) {
            setErrorMSG("Duplicate POP detected. Please review the POP(s) and clone if necessary.");
            setPopData(duplicateData);
            setShowAll(true);
        }
    }, [duplicateData, logger]);

    // Second useEffect to fetch POP list only after permissions are checked
    useEffect(() => {
        if (permissionChecked && !duplicateData) { // Only run if permission check is complete
            setLoader(true);
            POPServices.getPOPList().then((resp) => {
                const popDetailsArray = resp.data.popData; // Get the pop details array from response
                const popExportArray = resp.data.exportData;
                // Filter the popDetails based on mpid and user permissions
                const filteredPopDetails = popDetailsArray.filter((popDetail) => {
                    if (Number(popDetail.mpid) !== 0 && (Number(selectedUser?.POP_UTILS) === 1 || aeUser)) { // Read Only or AE/NAM
                        //logger.info(`Location with UID ${popDetail.uid} is not displayed due to mpid != 0 (${popDetail.mpid}) and ReadOnly or aeUser`);
                        return false; // Exclude this item
                    }
                    //logger.info(`Displaying UID ${popDetail.uid} with mpid: ${popDetail.mpid}`);
                    return true; // Include this item
                });
                //logger.info("setting POPDATA: " + JSON.stringify(filteredPopDetails));
                setPopData(filteredPopDetails); // Update state with filtered data

                // Filter the popDetails based on mpid and user permissions
                const filteredExportPopDetails = popExportArray.filter((pops) => {
                    if (Number(pops.mpid) > 0 && (Number(selectedUser?.POP_UTILS) === 1 || aeUser)) { // Read Only or AE/NAM
                        return false; // Exclude this item
                    }
                    return true; // Include this item

                });
                setExportData(filteredExportPopDetails);
                setLoader(false);
            })
                .catch(error => {
                    setLoader(false);
                    setErrorMSG("Error Getting POPs");
                    logger.error("Error Getting POPs: " + error);
                });
        }
    }, [permissionChecked]);

    const columns = [
        {
            field: 'cswc',
            headerName: 'Building CLLI',
            flex: 1,
            renderCell: (params) => formatCellLink(params),
        },
        {
            field: 'address',
            headerName: 'Address',
            flex: 2
        },
        {
            field: 'provider',
            headerName: 'Provider',
            flex: 1
        },
        {
            field: 'icon',
            headerName: 'ICON Enabled',
            flex: 1
        },
        {
            field: 'wave',
            headerName: 'Wave Enabled',
            flex: 1
        },
        {
            field: 'colo',
            headerName: 'Colo Enabled',
            flex: 1
        },
        {
            field: 'eline_enet',
            headerName: 'Eline Enabled',
            flex: 1
        },
        // Conditionally include the 'Active' column
        ...(showAll ? [{
            field: 'status',
            headerName: 'Active',
            flex: 1,
        }] : [])
    ];

    function formatCellLink(params) {
        const uid = params.row.uid;
        const cswc = params.row.cswc;
        return (
            <Link to={`/POPEditor/${uid}`} style={{ color: "#753BBD" }}>
                {cswc}
            </Link>
        );
    }

    // Filter data based on the checkbox state
    const filteredData = showAll ? popData : popData.filter(row => row.status === 'Active');
    const exportFilteredData = showAll ? exportData : exportData.filter(row => row.status === 'Active');

    const handleExport = (format) => {
        const headers = ["UID", "Building CLLI", "Address", "Location Designator 2", "Location Value 2", "Location Designator 3", "Location Value 3", "City", "State", "Zip", "Provider", "Lata", "Latitude", "Longitude", "XC Required", "ICON Enabled", "Wave Enabled", "Colo Enabled", "Eline Enabled", "Active"];
        const fileName = `POP_Report_${new Date().toLocaleDateString('en-US').replaceAll('/', '-')}`;
        // Export to Excel by default
        XLSXExport.exportToExcel(headers, exportFilteredData, fileName);
    };

    return (
        <div className="midsection qm-upload abs z1">
            {loader ? <Loader /> : null}
            <div className="rel">
                <div className="wrap">
                    <h1 className="page-title">{"POP Viewer"}</h1>
                    <div className="section">
                        <div className={`content ${style.contentQueryPage}`}>
                            {errorMSG?.length ? (
                                <div>
                                    <p className="text-danger text-center">{errorMSG}</p>
                                </div>
                            ) : null}
                            {successMessage ? (
                                <div>
                                    <p className="text-success text-center">{successMessage}</p>
                                </div>
                            ) : null}
                            <form>
                                <div className={`sub-hdr alt ${style.subHeader}`}>
                                    <div className="nib">
                                        <div className="the-notch">
                                            <div className="notch-fg">
                                                <svg>
                                                    <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className={style.sectionTitle}>{showAll ? "All POPs" : "Active POPs"}</h2>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
                                        <div style={{ flexGrow: 1 }}>
                                            {Number(selectedUser?.POP_UTILS) > 1 || systemSuperUser ?
                                                <Link to="/POPCreator">Create New POP</Link>
                                                : null}
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={showAll}
                                                        onChange={() => setShowAll(!showAll)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Show all active and inactive POPs"
                                            />
                                            <button className="ico-button light-button" onClick={(e) => { e.preventDefault(); handleExport('xlsx'); }}>
                                                <span><strong>Download</strong></span>
                                                <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
                                                    <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
                                                        transform="translate(-1 -1)">
                                                    </path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <StripedDataGrid
                                    autoHeight
                                    theme={CustomTheme}
                                    rows={filteredData}
                                    getRowId={(row) => row.uid}
                                    columns={columns}
                                    filterModel={filterModel}
                                    onFilterModelChange={(model) => setFilterModel(model)}
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[]}
                                    resultsPerPageOptions={[]}
                                    pageSizeOptions={[]}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    slots={{
                                        pagination: TablePagination,
                                        toolbar: CustomToolbar
                                    }}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 25
                                            },
                                        },
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default POPViewer;