import axios from "./axios/customAxios";

const getPopLocations = async () => {
	const popLocationURL = `${process.env.REACT_APP_API_URL}/getAddress.php?type=colo`;
	return await axios.get(popLocationURL);
}

const getUnitiMlaFlag = async (uid) => {
	const UnitiMlaFlagURL = `${process.env.REACT_APP_API_URL}/getUnitiMlaFlag.php?uid=${uid}`;
	return await axios.get(UnitiMlaFlagURL);
}

const getColoAddressFields = async (uid) => {
    const macdColoAddressURL =  `${process.env.REACT_APP_API_URL}/getOrderMenus.php?type=coloAddressFields&coloPopId=${uid}`;
    return await axios.get(macdColoAddressURL);
}

//Load all menus required for step 3 of colo quotes
const getColoMenus = async () => {
	let coloMenuUrls =
		[`${process.env.REACT_APP_API_URL}/getQuoteMenus.php?type=rack_space`,
		`${process.env.REACT_APP_API_URL}/getQuoteMenus.php?type=breaker_amp`,
		`${process.env.REACT_APP_API_URL}/getQuoteMenus.php?type=primary_power`


	];
	return await Promise.all(coloMenuUrls.map(url => axios.get(url)));
}

const validateGAID = async (gaid) => {
	const gaidURL = `${process.env.REACT_APP_API_URL}/quote/validateGAID.php?uid=${gaid}`;
	return await axios.get(gaidURL);
}

const ColocationHelper = {
	getPopLocations,
	getUnitiMlaFlag,
	getColoAddressFields,
	getColoMenus,
	validateGAID
}

export default ColocationHelper;
