import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StaffManagementService from "../../../services/StaffManagementService";
import { ConsoleLogger } from "../../../logger/ConsoleLogger";
import style from "../Employee.module.css";
import Loader from "../../shared/Loader";

const Input = ({ inputEmit, label, disabled, value, name }) => {
    const inputHandle = (e) => {
        inputEmit(e);
    };
    return (
        <div className="my-3">
            <label className="labelText"> {label}</label>
            <input
                type="text"
                name={name}
                className={`${style.inputFldText} w-100`}
                value={value}
                disabled={disabled}
                onChange={inputHandle}
            />
        </div>
    );
};

const Account = ({ formValues, setFormValues }) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
    const eid = localStorage.getItem("eid");
    const selectedUser = useSelector(state => state.userReducer.user.webaccess);

    const getJSON = {
        action: "get",
        uid: isEmployee ? eid : null,
    };


    console.log("Account.js");

    useEffect(() => {
        if (Number(selectedUser?.EmployeeAdmin) >= 1) {
            setLoader(true);
            StaffManagementService.getUserData(getJSON)
                .then((resp) => {
                    logger.info("UserData returned: " + JSON.stringify(resp.data));
                    setFormValues(resp.data);
                    setLoader(false);
                })
                .catch((error) => {
                    logger.error("StaffManagementService ERROR: " + error);
                    setLoader(false);
                    setErrorMsg(error.message || 'An error occurred');
                });
        } else {
            setErrorMsg('No Access');
            logger.warn('Insufficient permissions to fetch data.');
        }
    }, [selectedUser]);

    return (
        <div className="staffPage">
            {loader ? <Loader /> : null}
            {errorMsg ? <p className="text-danger text-center">{errorMsg}</p> : null}
            {Number(selectedUser?.EmployeeAdmin) >= 1 ? (
                <>
                    <div className={`sub-hdr alt0 mt-0`}>
                        <h2 className={`m-0`}>User Information</h2>
                    </div>
                    <div className={`row ${style.rowGrp}`}>
                        <div className="col-4">
                            <Input label="Login" name="login" disabled={true} value={formValues.uid || ''} />
                        </div>
                        <div className="col-4">
                            <Input label="First Name" name="firstName" disabled={true} value={formValues.fname || ''} />
                        </div>
                        <div className="col-4">
                            <Input label="Last Name" name="lastName" disabled={true} value={formValues.lname || ''} />
                        </div>
                        <div className="col-4">
                            <Input label="Email" name="email" disabled={true} value={formValues.email || ''} />
                        </div>
                        <div className="col-4">
                            <Input label="Role" name="role" disabled={true} value={formValues.roleName || ''} />
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default Account;