import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import ViewportWarning from "../shared/ViewportWarning";
import style from "../quote/QuoteCreate.module.css";

const RfoRcaDetails = () => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL });
    const location = useLocation();
    const adoData = location.state; // Access the passed data directly
    const [ErrorMSG, setErrorMSG] = useState('');

    logger.info('ADO DATA: ' + JSON.stringify(location.state));
    if (!adoData) {
        setErrorMSG('No data available for this ADO.');
    }

    return (
        <div className="midsection qm-upload abs z1">
            <div className="rel">
                <ViewportWarning />
                <div className="wrap">
                    <h1 className="page-title">RFO/RCA Request Details</h1>
                    <div className="section">
                        <div className={`content ${style.contentQueryPage}`}>
                            <div className={`sub-hdr alt ${style.subHeader}`}>
                                <div className="nib">
                                    <div className="the-notch">
                                        <div className="notch-fg">
                                            <svg>
                                                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h2 className={style.sectionTitle}>RFO /RCA Details</h2>
                            </div>
                            {ErrorMSG.length ? (
                                <div>
                                    <p className={`text-center my-4 text-danger`}>{ErrorMSG}</p>
                                </div>
                            ) : null}
                            <div className={`row ${style.rowGrp}`}>
                                <div className="col-4">
                                    <label className={style.labelSelectRequiredFld}>ADO ID</label>
                                    <p>{adoData.ado}</p>
                                </div>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Request Type</label>
                                    <p>{adoData.requestType}</p>
                                </div>
                                <div className="col-4">
                                    <label className={style.labelSelectRequiredFld}>Requestor Full Name</label>
                                    <p>{adoData.requestorName}</p>
                                </div>
                            </div>
                            <div className={`row ${style.rowGrp}`}>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Requestor Phone #</label>
                                    <p>{adoData.requestorCallback}</p>
                                </div>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Requestor Email</label>
                                    <p>{adoData.requestorEmail}</p>
                                </div>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Windstream Case #</label>
                                    <p>{adoData.winCase}</p>
                                </div>
                            </div>
                            <div className={`row ${style.rowGrp}`}>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Customer Internal Case # (If Applicable)</label>
                                    <p>{adoData.customerCase || 'N/A'}</p>
                                </div>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Customer/Account Name</label>
                                    <p>{adoData.customerName}</p>
                                </div>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Email Addresses for Completion Notification</label>
                                    <p>{adoData.emailAddresses || 'N/A'}</p>
                                </div>
                            </div>
                            <div className={`row ${style.rowGrp}`}>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Status</label>
                                    <p>{adoData.status}</p>
                                </div>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Days to Complete</label>
                                    <p>{adoData.daysToComplete}</p>
                                </div>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Open Date</label>
                                    <p>{adoData.openDate}</p>
                                </div>
                            </div>
                            <div className={`row ${style.rowGrp}`}>
                                <div className="col-4">
                                    <label className={style.lableTextS}>Close Date</label>
                                    <p>{adoData.closeDate}</p>
                                </div>
                                <div className="col-8">
                                    <label className={style.lableTextS}>Specific Questions or Concerns</label>
                                    <p>{adoData.specificQuestions || 'N/A'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RfoRcaDetails;