import {useEffect, useState} from "react";

const CountDownTimer = ({ seconds, changeFunction, color, fontSize }) => {
    let [countDown, setCountDown] = useState(seconds);
    const lastUpdated = `Updated on ${new Date().toLocaleString('en-US')}`;

    useEffect(() => {
        let interval = setInterval(() => {
            setCountDown(--countDown <= 0 ? seconds : countDown);
        }, 1000);
        return () => clearInterval(interval);
    }, [countDown]);

    return (
        <div id="countdown" data-tip={lastUpdated}>
            {/*<ReactTooltip />*/}
            <div id="countdown-number" className="ml-3" style={{fontSize: fontSize, color: color}} onChange={changeFunction(countDown)}>{countDown > 60 ?
                `${Math.floor(countDown / 60)}:${String(countDown % 60).padStart(2, '0')}` : countDown}
            </div>
            <svg style={{color: color}}>
                <circle style={{stroke: color}} r="19" cx="20" cy="20"></circle>
            </svg>
        </div>
    )
}

export default CountDownTimer;
