import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import style from "../Employee.module.css";
import StaffManagementService from "../../../services/StaffManagementService";
import { ConsoleLogger } from "../../../logger/ConsoleLogger";
import CancelModal from "./CancelModal";
import { SelectComponent } from "../../components/SelectComponent";
import { InputComponent } from "../../components/InputComponent";
import Loader from "../../shared/Loader";

const EditUser = ({ formValues, setFormValues }) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [loader, setLoader] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [cancelWindow, setCancelWindow] = useState(false);
    const [employeeID, setEmployeeID] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [employeeList, setEmployeeList] = useState([]);
    const selectedUser = useSelector(state => state.userReducer.user.webaccess);

    useEffect(() => {
        logger.info("formValues: " + JSON.stringify(formValues));
    }, [formValues]);

    const webaccessOptions = [
        { label: 'No Access', value: '0' },
        { label: 'Read Only', value: '1' },
        { label: 'Write Access', value: '2' },
        { label: 'Full Access', value: '3' },
    ];

    const roleTypes = [
        { label: 'System User', value: 'SYS' },
        { label: 'Vice President', value: 'VP' },
        { label: 'Executive Vice President', value: 'EVP' },
        { label: 'Chief Financial Officer', value: 'CFO' },
        { label: 'Account Executive', value: 'NAM' },
        { label: 'Sales Engineer', value: 'SE' },
        { label: 'Technical Design Specialist', value: 'TDS' },
        { label: 'Customer Account Manager', value: 'CAM' },
        { label: 'M6 Account Team', value: 'M6' },
        { label: 'VOX Account Team', value: 'VOX' },
        { label: 'Colocation Team', value: 'COLO' },
        { label: 'ICB Pricing Team', value: 'ICB' },
        { label: 'Super User', value: 'SUPER' },
        { label: 'Off Premises', value: 'OP' }
    ];

    const levels = [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
    ];

    useEffect(() => {
        if (employeeList.length === 0) {
            setLoader(true);
            const staffListJSON = {
                "action": "list",
            };
            logger.info("Calling to get Staff List");
            StaffManagementService.getUserData(staffListJSON)
                .then((resp) => {
                    logger.info("UserData returned: " + JSON.stringify(resp.data.data));
                    const formattedData = Array.isArray(resp.data.data) ? resp.data.data.map(employee => ({
                        value: employee.uid,
                        label: `${employee.uid} - ${employee.lname}, ${employee.fname}`,
                        ...employee
                    })) : [resp.data.data].map(employee => ({
                        value: employee.uid,
                        label: `${employee.uid} - ${employee.lname}, ${employee.fname}`,
                        ...employee
                    }));
                    setEmployeeList(formattedData); // Set state with employee data
                    setLoader(false);
                })
                .catch((error) => {
                    logger.error("StaffManagementService ERROR: " + error);
                    setLoader(false);
                    setErrorMsg(error.message || 'An error occurred');
                });
        }
    }, []);

    useEffect(() => {
        // Set the initial employeeID based on formValues.uid
        if (formValues.uid) {
            const selectedEmployee = employeeList.find(emp => emp.value === formValues.uid);
            setEmployeeID(selectedEmployee || null);
        }
    }, [formValues, employeeList]);

    const employeIDHandle = (selectedOption) => {
        if (selectedOption) {
            // Log the selected option to debug
            console.log("Selected Option:", selectedOption);

            setEmployeeID(selectedOption);
            const getJSON = {
                action: "get",
                uid: selectedOption.value,
            };

            StaffManagementService.getUserData(getJSON).then((resp) => {
                logger.info("UserData returned: " + JSON.stringify(resp.data));
                // Update form values only if there is a change in the selection
                setFormValues({
                    uid: resp.data.uid || '',
                    fname: resp.data.fname || '',
                    lname: resp.data.lname || '',
                    email: resp.data.email || '',
                    phone: resp.data.phone || '',
                    role: resp.data.role || '',
                    level: resp.data.level || 1,
                    is_active: resp.data.is_active || '0',
                    webaccess: {
                        UserAdmin: resp.data.webaccess?.UserAdmin || "0",
                        EmployeeAdmin: resp.data.webaccess?.EmployeeAdmin || "0",
                        TRANS_QUOTE: resp.data.webaccess?.TRANS_QUOTE || "0",
                        TRANS_ORDER: resp.data.webaccess?.TRANS_ORDER || "0",
                        TRANS_INVOICE: resp.data.webaccess?.TRANS_INVOICE || "0",
                        RemedyTicket: resp.data.webaccess?.RemedyTicket || "0",
                        BULK_AV: resp.data.webaccess?.BULK_AV || "0",
                        PROFILE: resp.data.webaccess?.PROFILE || "0",
                        POP_UTILS: resp.data.webaccess?.POP_UTILS || "0",
                        ASR_MAP: resp.data.webaccess?.ASR_MAP || "0"
                    }
                });
                setLoader(false);
            })
                .catch((error) => {
                    logger.error("StaffManagementService ERROR: " + error);
                    setLoader(false);
                    setErrorMsg(error.message || 'An error occurred');
                });

            logger.info("EDIT USER selectedOption: " + JSON.stringify(selectedOption));

            logger.info("EDIT USER setFormValues: " + JSON.stringify(formValues));

            setFormErrors({});  // Reset form errors
        } else {
            setEmployeeID(null);
            setFormValues({}); // Reset form values if no employee is selected
            setFormErrors({});  // Reset form errors if no employee is selected
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        logger.info("In handleInputChange: name: " + name + " value: " + value);

        setFormValues((prevState) => {
            if (name in prevState.webaccess) {
                return {
                    ...prevState,
                    webaccess: {
                        ...prevState.webaccess,
                        [name]: value,
                    }
                };
            } else {
                return {
                    ...prevState,
                    [name]: value,
                };
            }
        });
    };

    const submitJSON = {
        action: "update",
        uid: formValues.uid,
        fname: formValues.fname,
        lname: formValues.lname,
        email: formValues.email,
        phone: formValues.phone,
        role: formValues.role,
        is_active: formValues.is_active,
        level: formValues.level,
        webaccess: {
            UserAdmin: formValues.webaccess?.UserAdmin || 0,
            EmployeeAdmin: formValues.webaccess?.EmployeeAdmin || 0,
            TRANS_QUOTE: formValues.webaccess?.TRANS_QUOTE || 0,
            TRANS_ORDER: formValues.webaccess?.TRANS_ORDER || 0,
            TRANS_INVOICE: formValues.webaccess?.TRANS_INVOICE || 0,
            RemedyTicket: formValues.webaccess?.RemedyTicket || 0,
            BULK_AV: formValues.webaccess?.BULK_AV || 0,
            PROFILE: formValues.webaccess?.PROFILE || 0,
            POP_UTILS: formValues.webaccess?.POP_UTILS || 0,
            ASR_MAP: formValues.webaccess?.ASR_MAP || 0
        }
    };

    const submitAction = () => {
        setErrorMsg('');
        setSuccessMsg('');
        const validation = ValidationRule();
        if (Object.keys(validation).length === 0) {
            console.log("Submitting JSON: ", JSON.stringify(submitJSON, null, 2));
            setLoader(true);

            StaffManagementService.getUserData(submitJSON)
                .then((response) => {
                    if (response.data.result === "FAIL") {
                        const errorMessage = response.data.error || 'Failed to update user information.';
                        logger.error("Update failed: " + errorMessage);
                        setErrorMsg(errorMessage);
                    } else if (response.data.result === "SUCCESS") {
                        logger.info("Update successful: " + JSON.stringify(response.data));
                        const successMessage = response.data.update || 'User updated successfully!';
                        setSuccessMsg(successMessage);
                    } else {
                        logger.error("Unknown Error response: " + JSON.stringify(response.data));
                        const errorMessage = 'Unknown Error: ' + JSON.stringify(response.data);;
                        setErrorMsg(errorMessage);
                    }
                    setLoader(false);
                })
                .catch((error) => {
                    logger.error("Update failed: " + error);
                    setErrorMsg(error.message || 'Failed to update user information.');
                    setLoader(false);
                });
        } else {
            logger.error("Validation errors: " + JSON.stringify(validation));
        }
    };

    const ValidationRule = () => {
        const error = {};
        if (!formValues.uid) {
            error.uid = "Employee ID is Required";
        }
        if (!formValues.fname) {
            error.fname = "First Name is Required";
        }
        if (!formValues.lname) {
            error.lname = "Last Name is Required";
        }
        if (!formValues.email) {
            error.email = "Email is Required";
        }
        if (!formValues.phone) {
            error.phone = "Phone Number is Required";
        }
        if (!formValues.role) {
            error.role = "Role Type is Required";
        }
        if (!formValues.is_active) {
            error.is_active = "Status is Required";
        }
        if (!formValues.webaccess.UserAdmin) {
            error.UserAdmin = "Web Customer Administration is Required";
        }
        if (!formValues.webaccess.EmployeeAdmin) {
            error.EmployeeAdmin = "Web Employee Administration is Required";
        }
        if (!formValues.webaccess.RemedyTicket) {
            error.RemedyTicket = "Trouble Tickets are Required";
        }
        if (!formValues.webaccess.TRANS_INVOICE) {
            error.TRANS_INVOICE = "Invoice System is Required";
        }
        if (!formValues.webaccess.TRANS_QUOTE) {
            error.TRANS_QUOTE = "Transport Quote is Required";
        }
        if (!formValues.webaccess.TRANS_ORDER) {
            error.TRANS_ORDER = "Transport Order is Required";
        }
        if (!formValues.webaccess.POP_UTILS) {
            error.POP_UTILS = "POP UTILs is Required";
        }
        if (!formValues.webaccess.BULK_AV) {
            error.BULK_AV = "Bulk AV is Required";
        }
        if (!formValues.webaccess.ASR_MAP) {
            error.ASR_MAP = "ASR MAP is Required";
        }
        setFormErrors(error);
        return error;
    };

    const cancelAction = () => {
        setCancelWindow(true);
    };

    const cancelEmit = (action) => {
        if (action === 'Ok') {
            setCancelWindow(false);
            setFormValues({});  // Reset form values
            setFormErrors({});
            setEmployeeID(null);
        } else {
            setCancelWindow(false);
        }
    };

    return (
        <div className="EditUserPage">
            {loader ? <Loader /> : null}
            {Number(selectedUser?.EmployeeAdmin) >= 2 ? (
                <>
                    <p className="text-secondary text-right mb-5"><span className={style.mandatory}>*</span> Denotes a required field</p>
                    <div className="row">
                        <div className="col-md-5 col-sm-12">
                            <div className={`${style.selectWrap} w-100`}>
                                <label className='labelText'> Employee Id <span className={` ${style.mandatory} `}>*</span></label>
                                <div className="rel">
                                    <Select
                                        value={employeeID}
                                        onChange={employeIDHandle}
                                        options={employeeList}
                                        isSearchable
                                        isClearable
                                        className={`autoCompleteStyle`}
                                        classNamePrefix='profile-autoComplete'
                                        noOptionsMessage={() => 'No Profile Found'} />
                                    <p className={style.errorMsg}>{formErrors.userId}</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <InputComponent label='First Name' name="fname" className={style.w45} value={formValues.fname} error={formErrors.fname} callback={handleInputChange} />
                                <InputComponent label='Last Name' name="lname" className={style.w45} value={formValues.lname} error={formErrors.lname} callback={handleInputChange} />
                            </div>
                            <InputComponent label='Email' name="email" className="w-100" value={formValues.email} error={formErrors.email} callback={handleInputChange} />
                            <InputComponent label='Phone Number' name="phone" className="w-100" value={formValues.phone} error={formErrors.phone} callback={handleInputChange} />
                            <div className="d-flex justify-content-between">
                                <SelectComponent label="Role Type" name="role" className={style.w45} value={formValues.role} options={roleTypes} error={formErrors.role} callback={handleInputChange} />
                                <SelectComponent label="level" name="level" className={style.w45} value={formValues.level} options={levels} error={formErrors.level} callback={handleInputChange} />
                            </div>

                            <div className="my-5">
                                <label className="mr-5">Status <span className={` ${style.mandatory} `}>*</span></label>
                                <input type="radio" name="is_active" id="active" value='1' checked={formValues.is_active === '1'} onChange={handleInputChange}></input>
                                <label className="mr-4 text-secondary" htmlFor="active">Active</label>

                                <input type="radio" name="is_active" id="inActive" value='0' checked={formValues.is_active === '0'} onChange={handleInputChange}></input>
                                <label className="text-secondary" htmlFor="inActive">InActive</label>
                                <p className={style.errorMsg}>{formErrors.is_active}</p>
                            </div>
                        </div>

                        <div className="col-md-7 col-sm-12">
                            <label className="d-block w-100 text-center mb-5">Web Access</label>
                            <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                                <SelectComponent label="Web Customer Administration" name="UserAdmin" className={style.w45} value={formValues.webaccess?.UserAdmin} options={webaccessOptions} error={formErrors.UserAdmin} callback={handleInputChange} />
                                <SelectComponent label="Web Employee Administration" name="EmployeeAdmin" className={style.w45} value={formValues.webaccess?.EmployeeAdmin} options={webaccessOptions} error={formErrors.EmployeeAdmin} callback={handleInputChange} />
                            </div>
                            <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                                <SelectComponent label="Trouble Tickets" name="RemedyTicket" className={style.w45} value={formValues.webaccess?.RemedyTicket} options={webaccessOptions} error={formErrors.RemedyTicket} callback={handleInputChange} />
                                <SelectComponent label="Invoice System" name="TRANS_INVOICE" className={style.w45} value={formValues.webaccess?.TRANS_INVOICE} options={webaccessOptions} error={formErrors.TRANS_INVOICE} callback={handleInputChange} />
                            </div>
                            <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                                <SelectComponent label="Transport Quote" name="TRANS_QUOTE" className={style.w45} value={formValues.webaccess?.TRANS_QUOTE} options={webaccessOptions} error={formErrors.TRANS_QUOTE} callback={handleInputChange} />
                                <SelectComponent label="Transport Order" name="TRANS_ORDER" className={style.w45} value={formValues.webaccess?.TRANS_ORDER} options={webaccessOptions} error={formErrors.TRANS_ORDER} callback={handleInputChange} />
                            </div>
                            <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                                <SelectComponent label="Profile" name="PROFILE" className={style.w45} value={formValues.webaccess?.PROFILE} options={webaccessOptions} error={formErrors.PROFILE} callback={handleInputChange} />
                                <SelectComponent label="POP Utils" name="POP_UTILS" className={style.w45} value={formValues.webaccess?.POP_UTILS} options={webaccessOptions} error={formErrors.POP_UTILS} callback={handleInputChange} />
                            </div>
                            <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                                <SelectComponent label="Bulk AV" name="BULK_AV" className={style.w45} value={formValues.webaccess?.BULK_AV} options={webaccessOptions} error={formErrors.BULK_AV} callback={handleInputChange} />
                                <SelectComponent label="ASR Map" name="ASR_MAP" className={style.w45} value={formValues.webaccess?.ASR_MAP} options={webaccessOptions} error={formErrors.ASR_MAP} callback={handleInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="text-center my-5">
                        {errorMsg?.length > 0 ? <p className="text-danger text-center">{errorMsg}</p> : null}
                        {successMsg.length ? <p className={`text-center text-success`}>{successMsg}</p> : null}
                    </div>
                    <div className="text-center my-5">
                        <button type="button" className={`ico-button lhs light-button ml-3 ${style.cancelBtn}`} onClick={cancelAction}>
                            Cancel
                        </button>

                        <button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn}`} onClick={submitAction}>
                            Submit
                        </button>
                    </div>
                    {cancelWindow ? <CancelModal cancelEmit={cancelEmit} /> : null}
                </>
            ) : (
                <p className="text-danger text-center">No Access</p>
            )}
        </div>
    );
};

export default EditUser;